import { Col, Row } from "reactstrap";


export default function CarDetails(data) {
  const argbToRgb = (argbValue) => {
    const alpha = (argbValue >>> 24) & 0xFF;
    const red = (argbValue >>> 16) & 0xFF;
    const green = (argbValue >>> 8) & 0xFF;
    const blue = argbValue & 0xFF;
  
    return { red, green, blue };
  };
  
  let car = data.data;
  const renderData = car?.values
    ?.filter((e) => e.type !== "image"&& e.type !== "multipleImages")
    .map((c, index) => {
      return (
        <Col sm="6" key={index}>
          <div className="info">
            <div className="title">{c?.label.charAt(0).toUpperCase() + c?.label.slice(1)}</div>

            <div className="count-details">         
            {
  (() => {
    switch (true) {
      case c.label === "Mileage":
        return c.value?.toLocaleString() + " KM";
      case c.key === "carColor" && typeof c.value === 'number':
        const rgbColor = argbToRgb(c?.value);
        return  <div style={{boxShadow: "0 2px 4px rgba(0, 0, 0, 0.4)",backgroundColor: `rgb(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue})` , width: '20px', height: '20px' }}></div>
        // return `#${c.value.toString(16).padStart(6, '0')}`;
      case c.key === "carColor" && typeof c.value === 'string':
          return c.value;
      case typeof c.value === 'object':
        return c.value?.name;
      default:
        return c.value;
    }
  })()
}


              </div>
          </div>
        </Col>
      );
    });

  return (
    <li>
      <Row>{renderData}</Row>
    </li>
  );
}
