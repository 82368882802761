import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormMultiImageUpload from '../../../components/form/FormMultiImageUpload'; // The component you provided
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Stack,
  Typography
} from '@mui/material';
import { Image } from '../../../components/Component';
import FormSingleImageUpload from '../../../components/form/FormSingleImageUpload';

export default function BannerSection2({
  data,
  onSubmit,
  toggleEdit,
  editMode
}) {
  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      banners2: data?.banners2,
    }
  });

  useEffect(() => {
    if (data) {
      reset({
        banners2: data?.banners2,
      });
    }
  }, [data, reset]);

  const handleFormSubmit = (data) => {
  
    if (editMode) {
      onSubmit(data);
      toggleEdit('bannerSection2');  // or whatever section name is appropriate
    }
  };

  return (
      <Card sx={{ mb: 2 }}>
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <CardHeader title={'Banner section 2 '} />
          <Box sx={{ pr: 2 }}>
            {editMode ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="button"
                onClick={handleSubmit(handleFormSubmit)}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                 type="button"
                onClick={() => toggleEdit('bannerSection2')}
              >
                Edit
              </Button>
            )}
          </Box>
        </Stack>
        <CardContent>
        <Grid2 container spacing={2}>
            <Grid2 item size={12}>
              {editMode ? (
                <FormMultiImageUpload
                  name={'banners2'}
                  label={''}
                  control={control}
                  ratio={1268/500}
                />
              ) : (
                <Stack direction='row' gap={1}>
                {data?.banners2?.map((banner, index) => (
                  <Box key={index} height={100} >
                    <Image image={banner} size={100} />
                  </Box>
                ))}
              </Stack>
              )}
            </Grid2>
          
          </Grid2>
        </CardContent>
      </Card>

  );
}
