import React, { useState } from 'react';
import {
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import {
  DefaultAuctionsStatistics,
  DefaultRealTimeUsers
} from '../charts/default/DefaultCharts';

const AuctionStatistics = ({ data }) => {
  return (
    <Card>
      <div className="nk-ecwg nk-ecwg8 h-100">
        <div className="card-inner">
          <div className="card-title-group mb-3">
            <div className="card-title">
              <h6 className="title">Auction Statistics</h6>
            </div>
          </div>
          <div className="card-tools"></div>

          <ul className="nk-ecwg8-legends">
            {data?.datasets?.map((item) => (
              <li>
                <div className="title">
                  <span
                    className="dot dot-lg sq"
                    style={{ background: item?.color ?? '#6576ff' }}
                  ></span>
                  <span>{item?.title}</span>
                </div>
              </li>
            ))}

            {/* <li>
              <div className="title">
                <span
                  className="dot dot-lg sq"
                  style={{ background: '#eb6459' }}
                ></span>
                <span>UnSold Auction</span>
              </div>
            </li> */}
          </ul>
          <div className="nk-ecwg8-ck">
            <DefaultAuctionsStatistics data={data} />
          </div>
          {/* <div className="chart-label-group ps-5">
            <div className="chart-label">{lastDate.toDateString()}</div>
            <div className="chart-label">{startDate.toDateString()}</div>
          </div> */}
        </div>
      </div>
    </Card>
  );
};

export const RealtimeUsers = ({ data }) => {
  return (
    <Card>
      <div className="nk-ecwg nk-ecwg8 h-100">
        <div className="card-inner">
          <div className="card-title-group mb-3">
            <div className="card-title">
              <h6 className="title">Realtime Users</h6>
            </div>
          </div>
          <div className="card-tools"></div>

          <ul className="nk-ecwg8-legends">
            {data?.datasets?.map((item) => (
              <li>
                <div className="title">
                  <span
                    className="dot dot-lg sq"
                    style={{
                      background: item?.title
                        ? item?.color ?? '#6576ff'
                        : 'transparent'
                    }}
                  ></span>
                  <span>{item?.title}</span>
                </div>
              </li>
            ))}
          </ul>
          <div className="nk-ecwg8-ck">
            <DefaultRealTimeUsers data={data} />
          </div>
          {/* <div className="chart-label-group ps-5">
            <div className="chart-label">{lastDate.toDateString()}</div>
            <div className="chart-label">{startDate.toDateString()}</div>
          </div> */}
        </div>
      </div>
    </Card>
  );
};
export default AuctionStatistics;
