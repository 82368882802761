import { Icon, UserAvatar } from "../../components/Component";
import { SlickArrowLeft, SlickArrowRight } from "../../components/partials/slick/SlickComponents";
import User3 from "../../images/avatar/a-sm.jpg";

export const dataTableColumns2 = [
  {
    name: "UserName",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <div className="user-card mt-2 mb-2">
        <div className="user-info">
          <span className="tb-lead">
            {row.name}{"  "}
            <Icon className="text-success" name="check-circle"></Icon>
            <span
              className={`dot dot-${
                row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
              } d-md-none ms-1`}
            ></span>
          </span>
          <span>{row.phone}</span>
        </div>

      </div>
    ),
    sortable: true,
  },
  // {
  //   name: "Transactioned Amount",
  //   selector: (row) => row.balance,
  //   minWidth: "230px",
  //   cell: (row) => (
  //     <span className="tb-amount">
  //       <span className="currency">AED</span> {row.balance}
  //     </span>
  //   ),
  //   sortable: true,
  //   hide: 480,
  // },
  {
    name: "RM",
    selector: (row) => row.phone,
    sortable: true,
    cell: (row) => (
      <div className="user-card mt-2 mb-2">
        <UserAvatar text={"ddd"}></UserAvatar>
      </div>
    ),
    hide: "md",
  },
  // {
  //   name: "Verified",
  //   selector: (row) => row.verified,
  //   sortable: true,
  //   minWidth: "200px",
  //   hide: "md",
  //   cell: (row) => (
  //     <ul className="list-status d-flex">
  //       <li>
  //         <Icon
  //           className={`text-${
  //             row.emailStatus === "success" ? "success" : row.emailStatus === "pending" ? "info" : "secondary"
  //           }`}
  //           name={`${
  //             row.emailStatus === "success"
  //               ? "check-circle"
  //               : row.emailStatus === "alert"
  //               ? "alert-circle"
  //               : "alarm-alt"
  //           }`}
  //         ></Icon>{" "}
  //         <span>Account</span>
  //       </li>
  //       {/* <li>
  //         <Icon
  //           className={`text-${
  //             row.kycStatus === "success"
  //               ? "success"
  //               : row.kycStatus === "pending"
  //               ? "info"
  //               : row.kycStatus === "warning"
  //               ? "warning"
  //               : "secondary"
  //           }`}
  //           name={`${
  //             row.kycStatus === "success" ? "check-circle" : row.kycStatus === "pending" ? "alarm-alt" : "alert-circle"
  //           }`}
  //         ></Icon>{" "}
  //         <span>KYC</span>
  //       </li> */}
  //     </ul>
  //   ),
  // },
  {
    name: "Last Login",
    selector: (row) => row.lastLogin,
    sortable: true,
    cell: (row) => <span>{row.lastLogin}</span>,
    hide: "lg",
  },
];

export const watchersData = [
  // {
  //   id: 1,
  //   avatarBg: "purple",
  //   name: "Abu Bin Ishtiyak",
  //   displayName: "Ishtiak",
  //   dob: "10 Aug, 1980",
  //   role: "Customer",
  //   checked: false,
  //   phone: "05221144878",
  //   balance: "10,000,000",

  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "10 Feb 2020",
  //   status: "Active",
  //   address: "2337 Kildeer Drive",
  //   state: "Kentucky",
  //   country: "Canada",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 2,
  //   avatarBg: "purple",
  //   image: User3,
  //   name: "Ashley Lawson",
  //   dob: "10 Sept, 1990",
  //   role: "Investor",
  //   phone: "05221144878",
  //   balance: "10,000,000,000",
  //   checked: false,

  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "07 Feb 2020",
  //   status: "Pending",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
];

export const sliderSettings = {
  className: "slider-init row",
  slidesToShow: 2,
  centerMode: false,
  slidesToScroll: 1,
  infinite: false,
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />,
  responsive: [
    { breakpoint: 3000, settings: { slidesToShow: 4 } },
    { breakpoint: 1540, settings: { slidesToShow: 3 } },
    { breakpoint: 992, settings: { slidesToShow: 2 } },
    { breakpoint: 576, settings: { slidesToShow: 1 } },
  ],
};
