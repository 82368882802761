import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";

export default function DateFilter({ onChange, ...props }) {
  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  useEffect(() => {
    if (rangeEnd && rangeStart) {
      onChange({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    }
  }, [rangeEnd, rangeStart]);

  const setRangeStart = (e) => {
    setStart(e);
    // if (rangeEnd) {
    //   onChange({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    // }
  };
  const setRangeEnd = (e) => {
    setEnd(e);
    // if (rangeStart) {
    //   onChange({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    // }
  };
  return (
    <div {...props}>
      <div className="form-control-wrap">
        <div className="input-daterange date-picker-range input-group">
          <ReactDatePicker
            selected={rangeStart}
            onChange={setRangeStart}
            selectsStart
            startDate={rangeStart}
            endDate={rangeEnd}
            maxDate={rangeEnd ?? new Date()}
            wrapperClassName="start-m"
            className="form-control"
          />{" "}
          <div className="input-group-addon">TO</div>
          <ReactDatePicker
            selected={rangeEnd}
            onChange={setRangeEnd}
            startDate={rangeStart}
            endDate={rangeEnd}
            selectsEnd
            minDate={rangeStart}
            maxDate={new Date()}
            wrapperClassName="end-m"
            className="form-control"
          />
        </div>
      </div>
    </div>
  );
}
