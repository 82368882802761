import React from 'react';
import { useState } from 'react';
import {
  Button,
  FormSelect,
  FormTextField,
  Icon,
  RSelect
} from '../../../components/Component';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import {
  repostAuction,
  resetRepostAuction
} from '../../../redux/features/auctionSlice/repushAuctionSlice';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormDateTime from '../../../components/form/FormDateTime';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PriceInput from '../../verticals/components/PriceInput';
import ReactDatePicker from 'react-datepicker';
import { fetchVerticalDetails } from '../../../redux/features/verticalsSlice/verticalDetailsSlice';
import 'react-datepicker/dist/react-datepicker.css';
import { TransportTypes, Emirates } from '../../../utils/Utils';
import {
  formatDuration,
  parseDurationString
} from '../../../utils/formatDuration';

// const durationOptions = ['10 Mins', '20 Mins', '1 Hr', '24 Hrs'];

// const getDurationFromString = (data) => {
//   switch (data) {
//     case '10 Mins':
//       return 600000;
//     case '20 Mins':
//       return 1200000;
//     case '1 Hr':
//       return 3600000;
//     case '24 Hrs':
//       return 86400000;
//   }
// };

export default function RepushButtonWithModal({
  id,
  vertical_id,
  baseform_id,
  reserve_price,
  starting_bid,
  auction_emirate,
  auction_transport
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [startingPrice, setStartingPrice] = useState(starting_bid);
  const [reservePrice, setReservePrice] = useState(reserve_price);
  const [emirate, setEmirate] = useState(
    auction_emirate ? { label: auction_emirate, value: auction_emirate } : null
  );
  const [transport, setTransport] = useState(
    auction_transport
      ? { label: auction_transport, value: auction_transport }
      : null
  );
  const [duration, setDuration] = useState(null);
  const [startingPriceDisabled, setStartingPriceDisabled] = useState(true);

  // const [startTime, setStartTime] = useState(new Date());
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    dispatch(fetchVerticalDetails(vertical_id));
  }, [vertical_id]);

  // useEffect(() => {
  //   setEmirate({ label: auction_emirate, value: auction_emirate });
  //   setTransport({ label: auction_transport, value: auction_transport });
  // }, [auction_emirate, auction_transport]);

  // useEffect(() => {
  //   setReservePrice(reserve_price);
  //   setStartingPrice(starting_bid);
  // }, [reserve_price, starting_bid]);

  const selectedVertical = useSelector((state) => state.verticalDetails?.data);
  const formattedDurations =
    selectedVertical?.verticalsettings_id?.duration.map(formatDuration);

  const handleReservePriceChange = (e) => {
    const newReservePrice = e.target.value;
    // Update reserve price
    setReservePrice(newReservePrice);
    // Calculate starting price as 75% of reserve price and round off decimal places
    const calculatedStartingPrice = Math.round(newReservePrice * 0.75);
    setStartingPrice(calculatedStartingPrice);
  };
  // const methods = useForm({
  //   defaultValues: {
  //     duration: "",
  //     startTime: Date.now(),
  //     initialPrice: "",

  //   },
  // });

  // const { handleSubmit, control } = methods;
  const dispatch = useDispatch();
  const repost = (e) => {
    e.preventDefault();

    if (
      !reservePrice ||
      reservePrice == null ||
      reservePrice === '0' ||
      reservePrice === 0 ||
      !startingPrice ||
      startingPrice == null ||
      startingPrice === '0' ||
      startingPrice === 0
    ) {
      toast.error('Please enter the Reserve price');
      return;
    }

    if (!duration) {
      toast.error('Please select Duration');
      return;
    }

    const minAllowedTime = new Date();
    minAllowedTime.setMinutes(minAllowedTime.getMinutes() + 9);

    if (startTime < minAllowedTime) {
      toast.error(
        'Please select a time that is at least 10 minutes in the future.'
      );
      return;
    }

    let data = {
      initial_price: Number(startingPrice),
      reserve_price: Number(reservePrice),
      duration: parseDurationString(duration),
      start_time: startTime.getTime(),
      baseform_id: baseform_id,
      vertical_id: vertical_id,
      auction_id: id,
      emirate: emirate.value ?? auction_emirate.value,
      transport: transport.value ?? auction_transport.value
    };
    dispatch(repostAuction({ id, body: data }));
  };
  const { status, newId } = useSelector((state) => state.repostAuction);
  const navigate = useNavigate();
  useEffect(() => {
    if (status === 'success') {
      toast.success('Auction Reposted Successfully');
      dispatch(resetRepostAuction());
      setIsOpen(false);
      navigate(`/auctions`);
    } else if (status === 'error') {
      toast.error('Auction repost failed');
      dispatch(resetRepostAuction());
    }
  }, [status]);

  // const minAllowedTime = new Date();
  // minAllowedTime.setMinutes(minAllowedTime.getMinutes() + 10);

  // const handleDateChange = (date) => {
  //   setStartTime(date);
  // };
  const initialStartTime = new Date();
  initialStartTime.setMinutes(initialStartTime.getMinutes() + 10);

  const [startTime, setStartTime] = useState(initialStartTime);

  const minAllowedTime = new Date();
  minAllowedTime.setMinutes(minAllowedTime.getMinutes() + 10);

  const handleDateChange = (date) => {
    setStartTime(date);
  };

  return (
    <div>
      <div className="me-3">
        <Button color="light" onClick={toggle} style={{ width: '100%' }}>
          Repush
        </Button>
      </div>
      <Modal isOpen={isOpen}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Repost Auction
        </ModalHeader>
        <ModalBody>
          <form onSubmit={(e) => repost(e)}>
            <Col sm="12">
              <div className="form-group mb-2">
                <label className="form-label" htmlFor="first-name">
                  Reserve / Asking Price (Minimum AED{' '}
                  {selectedVertical?.verticalsettings_id?.auctionStartingPrice})
                </label>
                <div className="form-control-wrap">
                  <PriceInput
                    error={errors?.reservePrice}
                    value={reservePrice}
                    onChange={handleReservePriceChange}
                    // onChange={(e) => {
                    //   setReservePrice(e.target.value);
                    //   setErrors((prev) => ({
                    //     ...prev,
                    //     reservePrice: null
                    //   }));
                    // }}
                    label="AED"
                  />
                </div>
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group mb-2">
                <label className="form-label" htmlFor="first-name">
                  Starting Bid (75% of Reserve Price)
                </label>
                <div className="form-control-wrap">
                  <PriceInput
                    error={errors?.startingPrice}
                    value={startingPrice}
                    onChange={(e) => {
                      setStartingPrice(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        startingPrice: null
                      }));
                    }}
                    label="AED"
                    disabled={startingPriceDisabled}
                  />
                </div>
              </div>
            </Col>

            <Col sm="12">
              <div className="form-group mb-2">
                <label className="form-label" htmlFor="first-name">
                  Duration
                </label>
                <div className="form-control-wrap">
                  {formattedDurations && (
                    <RSelect
                      options={formattedDurations.map((e) => ({
                        label: e,
                        value: e
                      }))}
                      // value={{ label: field.value, value: field.value }}
                      onChange={(e) => setDuration(e.value)}
                      style={{ width: '100%' }}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col sm="12">
              <label className="form-label" htmlFor="first-name">
                Start Time
              </label>
              {/* <FormDateTime label="Start Time" control={control}  name="startTime" /> */}
              <ReactDatePicker
                className="form-control"
                minTime={minAllowedTime}
                minDate={new Date()}
                dateFormat="MMMM d, yyyy h:mm aa"
                showTimeInput
                selected={startTime}
                onChange={handleDateChange}
                style={{ width: '100%' }}
              />
            </Col>
            <Col sm="12">
              <div className="form-group mb-2">
                <label className="form-label" htmlFor="emirate">
                  Emirate
                </label>
                <div className="form-control-wrap">
                  {auction_emirate && (
                    <RSelect
                      isMulti={false}
                      value={emirate}
                      onChange={(e) => setEmirate(e)}
                      options={Emirates.map((e) => ({ label: e, value: e }))}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group mb-2">
                <label className="form-label" htmlFor="transport">
                  Transport
                </label>
                <div className="form-control-wrap">
                  {auction_transport && (
                    <RSelect
                      isMulti={false}
                      value={transport}
                      onChange={(e) => setTransport(e)}
                      options={TransportTypes.map((e) => ({
                        label: e,
                        value: e
                      }))}
                    />
                  )}
                </div>
              </div>
            </Col>
          </form>
        </ModalBody>
        <ModalFooter className="bg-light">
          <Button color="primary" onClick={(e) => repost(e)}>
            Repost
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
