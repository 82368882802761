import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import {

  PreviewCard,
  HistoryTable,
} from "../../../components/Component";
import { Badge, Button, Modal, Spinner } from "reactstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { clearHistory, fetchHistory } from "../../../redux/features/usersSlice/historySlice";
import { useNavigate } from "react-router-dom";
import { startCase } from "lodash";


export default function AuctionHistoryPage({id}) {
    const dispatch = useDispatch();
    const navigate =useNavigate();

  const auctionDetails = useSelector((state) =>  state.auctionDetails.details);
  const {data,totalCount,loading} =useSelector((state) => state.history);

  const [currentPage, setCurrentPage] = useState(1);
  const [history, setHistory] = useState(null);

  const [itemPerPage] = useState(10);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const dateOptions = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
const timeOptions = { hour: '2-digit', minute: '2-digit' };

  useEffect(() => {
    if(id){
    let data = {
      query: {
        "auctionId":id
      },
      options: {
        page: currentPage,
        limit: itemPerPage,
        sort: "-createdAt",
      },
    };

    dispatch(fetchHistory(data))
  }
  }, [id,currentPage]);

  useEffect(() => {
    return () => {
      dispatch(clearHistory());
    };
  }, [dispatch]);

  const dataTableColumns = [
    {
      name: "User",
      selector: (row) => row?.userId?.full_name,
      compact: true,
      grow: 2,
      style: { paddingRight: "20px" },
      cell: (row) => (
        <div
        onClick={ 
          (row?.verticalId&& row?.auctionId)?
          () =>{navigate(`/v/${row.verticalId}/auctions/${row.auctionId}`,{ replace: true })
          window.scrollTo( {
            top: 0,
            behavior: 'smooth'
        } );} // Scroll to the top of the page
        
        :
          null
        }
         className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {startCase(row?.userId?.full_name)}{"  "}              
            </span>        
          </div>  
        </div>
      ),
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => row.name,
      compact: true,
      grow: 2,
      style: { paddingRight: "20px" },
      cell: (row) => (
        <div
        onClick={ 
          (row?.verticalId&& row?.auctionId)?
          () =>{navigate(`/v/${row.verticalId}/auctions/${row.auctionId}`,{ replace: true })
          window.scrollTo( {
            top: 0,
            behavior: 'smooth'
        } );} // Scroll to the top of the page
          :
          (row?.verticalId&& row?.baseformId)?
          () =>{navigate(`/v/${row.verticalId}/items/${row.baseformId}`,{ replace: true })
          window.scrollTo( {
            top: 0,
            behavior: 'smooth'
        } );} 
        :
          null
        }
         className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.action}{"  "}              
            </span>        
          </div>  
        </div>
      ),
      sortable: false,
    },
    {
      name: "Date",
      selector: (row) => row.name,
      compact: true,
      grow: 2,
      style: { paddingRight: "20px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
            {new Date(row?.createdAt).toLocaleDateString(undefined, dateOptions)} {new Date(row?.createdAt).toLocaleTimeString(undefined, timeOptions)}             
            </span>        
          </div>  
        </div>
      ),
      sortable: false,
    },

  ];

  return (
    <Grid item sm={8}>
    <Paper elevation={0} sx={{ height: "100%" }}>
      <PreviewCard style={{ height: "100%" }}>
        {data && data?.data?.length>0 && auctionDetails&&
        <HistoryTable
          data={data?.data}
          selectableRows
          columns={dataTableColumns}
          pagination
          currentPage={currentPage}
          itemPerPage={itemPerPage}
           totalCount={totalCount}
           paginate ={paginate}           
          className="nk-tb-list"
          style={{ height: "100%" }}
        />
}
      </PreviewCard>
    </Paper>
  </Grid>
  );
}