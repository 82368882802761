import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
} from "../../components/Component";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Button, Badge, Spinner, Col, Modal, ModalHeader, ModalBody } from "reactstrap";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearItems, debouncedFetchCars, fetchCars, setAllSelected, setSelected } from "../../redux/features/itemsSlice/itemsSlice";
import { random, startCase } from "lodash";
import { getColor } from "../../utils/getColor";
import { Link, useParams, useSearchParams } from "react-router-dom";
import TableHeader from "../../components/table/TableHeader";
import DateFilter from "../../components/date-filter/DateFilter";
import ListBlockHeader from "../../components/list-block-header/ListBlockHeader";
import { fetchVerticalDetails } from "../../redux/features/verticalsSlice/verticalDetailsSlice";
import { carStatus } from "../../utils/Utils";
import ReactDatePicker from "react-datepicker";
import AddItemForm from "./components/AddItemForm";

const CarsData = () => {
  const { verticalName } = useParams();

  const [listData, setListData] = useState([]);
  const [smOption, setSmOption] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    firstName: "",
    lastName: "",
    date: new Date(),
    status: "",
    customer: "",
    purchased: "",
    total: "",
    check: false,
  });
  const [view, setView] = useState({
    add: false,
    details: false,
  });
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [sortByField, setSortByField] = useState("createdAt");
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);
  const [headers, setHeaders] = useState({});
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let v_id=searchParams.get("v_id")
    dispatch(fetchVerticalDetails(v_id));
  }, [searchParams]);
  const selectedVertical = useSelector((state) => state.verticalDetails?.data);
  const { data, totalCount, loading,perPage } = useSelector((state) => state.items);
  const [itemPerPage] = useState(perPage);

  const toggleModal = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();
  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    }
  }, [rangeEnd, rangeStart]);
  const setRangeStart = (e) => {
    setStart(e);

  };
  const setRangeEnd = (e) => {
    setEnd(e);

  };


  // Changing state value when searching name
  useEffect(() => {
    if(selectedVertical){
    let data = {
      'query': {'vertical_id': selectedVertical?.id},
      
      "options":{
        page: currentPage,
        limit:perPage,
        // sort:"-createdAt",
        sort: sortByValue === 1 ? sortByField:'-'+sortByField,
        "populate": [
          {
              "path": "user_id",
              "select": "full_name"
          }
      ],

      }
      //  page: currentPage, status, sortBy: { [sortByField]: sortByValue }
       };
       if (status ) {
        setCurrentPage(1);
        data.query.status = status;
      }
    if (dateFilter) {
      data.query.date= dateFilter;
    }
    if (onSearchText !== "") {
      data.query.search= onSearchText.trim();
      dispatch(debouncedFetchCars(data));
    } else {
      dispatch(fetchCars(data));
    }
    setHeaders(selectedVertical?.verticalsettings_id?.item_list_mappings);
  }
  }, [onSearchText.trim(), currentPage, status, sortByValue, dateFilter,selectedVertical]);

  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  useEffect(() => {
    if(data){
    setListData(data);
    }
  }, [data]);

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let clearBadge = status ||onSearchText!==''||rangeEnd ||rangeStart;

  return (
    <React.Fragment>
      <Head title={`${verticalName} List`}></Head>
      <Content>
        <ListBlockHeader msg={totalCount?`Total items:  ${totalCount}`:""} title={verticalName} onSearchText={onSearchText} onSearchChanged={onFilterChange} onDateChanged={setDateFilter}>
        <div style={{ flexShrink: 0, flexGrow: 1 }}>
      <div className="form-control-wrap">
        <div className="input-daterange date-picker-range input-group">
          <ReactDatePicker
            selected={rangeStart}
            onChange={setRangeStart}
            selectsStart
            startDate={rangeStart}
            endDate={rangeEnd}
            maxDate={rangeEnd ?? new Date()}
            wrapperClassName="start-m"
            className="form-control"
          />{" "}
          <div className="input-group-addon">TO</div>
          <ReactDatePicker
            selected={rangeEnd}
            onChange={setRangeEnd}
            startDate={rangeStart}
            endDate={rangeEnd}
            selectsEnd
            minDate={rangeStart}
            maxDate={new Date()}
            wrapperClassName="end-m"
            className="form-control"
          />
        </div>
      </div>
    </div>
        <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
              {status !== "" ? startCase(status) : "Status"}
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setStatus("")}>
                    <span>ALL</span>
                  </DropdownItem>
                </li>
                {Object.keys(carStatus)?.map((key)=> 
                <li key={key}>              
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setStatus(carStatus[key])}>
                    <span>{carStatus[key]}</span>
                  </DropdownItem>
                </li>
                )}

              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
          {verticalName==="Cars" &&
          <Button color="primary" 
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          >
            <Icon name="plus"></Icon>
            <span>Add Item</span>
          </Button>
}
        </ListBlockHeader>

        <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
          {clearBadge && (
              <Badge className="selected-option bg-primary me-1" color="danger">
                {'Clear All'}
                <Icon
                  name="cross-sm"
                  className="ms-1"
                  onClick={() => {
                    setSearchText('');
                    setRangeEnd(null);
                    setRangeStart(null);
                    setDateFilter(null);
                    setStatus("")
                  }}
                />
              </Badge>
            )}
            </Col>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                  />
                  <label className="custom-control-label" htmlFor="pid-all"></label>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
            <span>ID</span>
          </DataTableRow>
              <DataTableRow>
                <TableHeader
                customLabel={"Title"}
                  name={'title'}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>
              <DataTableRow size="sm">
            <span>User</span>
          </DataTableRow>
              <DataTableRow>
                <TableHeader
                customLabel={headers?.subtitle1}
                name={'subtitle1'}
          
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>

              <DataTableRow size="md">
                <TableHeader
                  customLabel={headers?.subtitle2}
                  name={'subtitle2'}
                  // name={headers?.subtitle2}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader
                   customLabel={headers?.subtitle3}
                   name={'subtitle3'}
                  // name={headers?.subtitle3}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader
                   customLabel={headers?.subtitle4}
                   name={'subtitle4'}
                  // name={headers?.subtitle4}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader
                 customLabel={headers?.subtitle5}
                 name={'subtitle5'}
                  // name={headers?.subtitle5}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>
              <DataTableRow>
              <TableHeader
                 name={'status'}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
                {/* <span className="sub-text">Status</span> */}
              </DataTableRow>

              <DataTableRow className="nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1 my-n1">
                  <li>
                    {/* <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="btn btn-trigger dropdown-toggle btn-icon me-n1">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#markasdone"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="truck"></Icon>
                              <span>Mark As Delivered</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#remove"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="trash"></Icon>
                              <span>Remove Cars</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </li>
                </ul>
              </DataTableRow>
            </DataTableHead>

            {listData?.length > 0
              ? listData.map((item, index) => (
                  <DataTableItem key={item.id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={item.check}
                          id={item.id + "oId-all"}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, index)}
                        />
                        <label className="custom-control-label" htmlFor={item.id + "oId-all"}></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <Link to={`/v/${verticalName}/items/${item.id}`}>
                        {item?.baseform_id}
                      </Link>
                    </DataTableRow>
                    <DataTableRow>
                      <Link to={`/v/${verticalName}/items/${item.id}`}>
                        {item?.title}
                      </Link>
                    </DataTableRow>
                    <DataTableRow>
                     {startCase(item?.user_id?.full_name)}
                    </DataTableRow>
                    <DataTableRow>{item?.values?.find((e) => e?.key === headers?.subtitle1)?.value?.toLocaleString()} {headers?.subtitle1==="Mileage"?"KM":""}</DataTableRow>
                    <DataTableRow>{item?.values?.find((e) => e?.key === headers?.subtitle2)?.value}</DataTableRow>
                    <DataTableRow>{item?.values?.find((e) => e?.key === headers?.subtitle3)?.value}</DataTableRow>
                    <DataTableRow>{item?.values?.find((e) => e?.key === headers?.subtitle4)?.value}</DataTableRow>
                    <DataTableRow size="md">
                      <span>{item?.values?.find((e) => e?.key === headers?.subtitle5)?.value}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className={`dot bg-${getColor(item?.status)} d-sm-none`}></span>
                      <Badge
                        className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                        color={getColor(item?.status)}
                      >
                        {startCase(item?.status)}
                      </Badge>
                    </DataTableRow>

                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li
                          className="nk-tb-action-hidden"
                          onClick={() => {
                            toggle("details");
                          }}
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={"view" + item._id}
                            icon="eye"
                            direction="top"
                            text="View Details"
                          />
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();

                                      toggle("details");
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>View Details</span>
                                  </DropdownItem>
                                </li>
                                {item.active === true && (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdown"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="truck"></Icon>
                                      <span>Approve</span>
                                    </DropdownItem>
                                  </li>
                                )}
                                {/* <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Delete User</span>
                                  </DropdownItem>
                                </li> */}
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {listData?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No items found</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
        <Modal
              size="md"
              isOpen={open}
              toggle={toggleModal}
              backdrop="static"
            >
              <ModalHeader toggle={toggleModal}>
                Add New Item
                <button className="close" onClick={toggleModal}></button>
              </ModalHeader>
              <ModalBody>
              <AddItemForm />
              </ModalBody>
            </Modal>
      </Content>
    </React.Fragment>
  );
};

export default CarsData;