import React from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { format } from 'date-fns';

const DateRangePickerCustom = ({ filters, handleDateRangeChange }) => {
    const [startDate, endDate] = filters.dateRange;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newStartDate) => handleDateRangeChange([newStartDate, endDate])}
                    slots={{
                        field: (params) => (
                            <TextField
                                {...params}
                                size="small"
                                value={startDate ? startDate.format( 'YYYY-MM-DD') : ''} // Display formatted date
                            />
                        )
                    }}
                />
                <Box sx={{ mx: 1, fontSize: '0.875rem' }}>to</Box>
                <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newEndDate) => handleDateRangeChange([startDate, newEndDate])}
                    slots={{
                        field: (params) => (
                            <TextField
                                {...params}
                                size="small"
                                value={endDate ?endDate.format('YYYY-MM-DD') : ''}
                            />
                        )
                    }}
                    minDate={startDate || null} // Prevent end date from being before start date
                />
            </Box>
        </LocalizationProvider>
    );
};

export default DateRangePickerCustom;
