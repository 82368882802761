import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  loading: false,
  error: null,
};
const fetchRoles = createAsyncThunk("roles", (data) => {
  let url = `/admin/role/list/`;
  return axios.post(url,data).then((response) => {
    return response.data.data;
  });
});

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    // setSelected: (state, action) => {
    //   const { index, value } = action.payload;
    //   // console.log(index);
    //   let data = state.data;
    //   data[index].check = value;
    //   state.data = data;
    // },
    // setAllSelected: (state, action) => {
    //   const { value } = action.payload;
    //   // console.log(value);
    //   let data = state.data;
    //   state.data = data.map((e) => {
    //     e.check = value;
    //     return e;
    //   });
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoles.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data??null;
    });
    builder.addCase(fetchRoles.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default rolesSlice.reducer;
// export const { setSelected, setAllSelected } = rolesSlice.actions;
export { fetchRoles };
