import { useEffect, useState } from "react";

export default function RenderPreview({ file }) {
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(true);

    const FileIcon = ({ extension, color = '#000000' }) => (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="50" rx="5" fill={color} />
            <text x="20" y="30" fontSize="12" fill="white" textAnchor="middle">{extension.toUpperCase()}</text>
        </svg>
    );
    const getIconColor = (extension) => {
        switch (extension.toLowerCase()) {
            case 'pdf':
                return '#FF0000';
            case 'doc':
            case 'docx':
                return '#295495';
            case 'xls':
            case 'xlsx':
                return '#1F6E43';
            case 'ppt':
            case 'pptx':
                return '#D24625';
            case 'txt':
                return '#4A4A4A';
            case 'zip':
            case 'rar':
                return '#FFA500';
            case 'mp3':
            case 'wav':
                return '#1DB954';
            case 'mp4':
            case 'avi':
            case 'mov':
                return '#FF1493';
            default:
                return '#808080';
        }
    };

    useEffect(() => {
        let objectUrl;

        const generatePreview = async () => {
            setLoading(true);
            try {
                const fileType = file.type.split('/')[0];
                const fileExtension = file.name.split('.').pop().toLowerCase();
                const fileName = file.name;

                const previewContent = (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100px' }}>
                        {fileType === "image" ? (
                            <img
                                src={URL.createObjectURL(file)}
                                alt="Preview"
                                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                            />
                        ) : (
                            <FileIcon extension={fileExtension} color={getIconColor(fileExtension)} />
                        )}
                        <div style={{
                            marginTop: '5px',
                            fontSize: '12px',
                            textAlign: 'center',
                            wordBreak: 'break-word',
                            maxWidth: '100px'
                        }}>
                            {fileName}
                        </div>
                    </div>
                );
                setPreview(previewContent)
            } catch (error) {
                console.error("Error generating preview:", error);
                setPreview(
                    <div style={{ width: "50px", height: "50px", backgroundColor: "#f0f0f0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Error
                    </div>
                );
            } finally {
                setLoading(false);
            }
        };

        generatePreview();

        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        };
    }, [file]);

    if (loading) {
        return <div style={{ width: "50px", height: "50px", backgroundColor: "#f0f0f0", display: "flex", alignItems: "center", justifyContent: "center" }}>Loading...</div>;
    }

    return preview;
};
