import { startCase } from "lodash";
import React from "react";

export default function TableHeader({ name, sortByField, sortByValue, setSort, customLabel }) {
  const activeArrow = (name, type) => {
    switch (type) {
      case "asc":
        return sortByField === name && sortByValue === 1 ? "active" : "";
      case "dsc":
        return sortByField === name && sortByValue === -1 ? "active" : "";
    }
  };
  return (
    <div
      className="tb-header"
      onClick={() => {
        setSort(name);
      }}
      style={{ minWidth: "100%", cursor: 'pointer'  }}
    >
      <span className="sub-text">{customLabel ?? startCase(name)}</span>
      <div className={"data-sort"}>
        <span className={`data-sort-item ${activeArrow(name, "asc")}`}>&darr;</span>
        <span className={`data-sort-item ${activeArrow(name, "dsc")}`}>&uarr;</span>
      </div>
    </div>
  );
}
