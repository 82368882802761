import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import axios from "axios";
import { apiUrl } from "./utils/configEnv";
import { ThemeProvider, createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import { ToastContainer } from "react-toastify";

const Error404Modern = lazy(() => import("./pages/error/404-modern"));

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1073C0",
    },
    secondary: {
      main: "#1073C0",
    },
    error: {
      main: red.A400,
    },
    background: {
      main: '#ffffff'
    },

  },
  components:{
  
    MuiListItem: {
      styleOverrides: {
        primary: {
          fontSize: '30px', // Custom font size for sidebar items
          fontWeight: '500', // Custom font weight
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: '#1a237e', // Set a global default text color (Blue in this case)
    },
    subtitle1:{
      color:"#1073C0",
      fontWeight:500
    },
    subtitle2:{
      color:"#1073C0",
      fontWeight:700
    },
    body1:{
      color:"#000000",
      fontSize:12
    }

  },
  shadows: [
    'none',
    '0px 1px 3px rgba(0, 0, 0, 0.1)',
    '0px 3px 6px rgba(0, 0, 0, 0.3)',
    '0px 10px 20px rgba(0, 0, 0, 0.15)',
    '0px 15px 30px rgba(0, 0, 0, 0.2)',
    '0px 20px 40px rgba(0, 0, 0, 0.25)',
    '0px 25px 50px rgba(0, 0, 0, 0.3)',
    '0px 30px 60px rgba(0, 0, 0, 0.35)',
    '0px 35px 70px rgba(0, 0, 0, 0.4)', // Add more shadows as needed
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Disable uppercase transformation
          borderRadius: '25px',  // Rounded corners
        },
        contained: {
          backgroundColor: 'primary',
          color: 'white',
          borderRadius: '25px',
          textTransform: 'none',
          boxShadow: '0px 4px 6px rgba(0, 123, 255, 0.1)', // Subtle shadow
          '&:hover': {
            backgroundColor: '#0056b3',
            boxShadow: '0px 6px 8px rgba(0, 123, 255, 0.3)', // Stronger shadow on hover
          },
        },
        outlined: {
          borderColor: '#007bff',
          color: '#007bff',
          '&:hover': {
            borderColor: '#0056b3',
            color: '#0056b3',
          },
        },
        // Adding size variants for buttons
        sizeSmall: {
          fontSize: '12px',      // Smaller font size
          padding: '6px 16px',   // Smaller padding
        },
        sizeMedium: {
          fontSize: '14px',      // Medium font size (default)
          padding: '8px 24px',   // Medium padding (default)
        },
        sizeLarge: {
          fontSize: '18px',      // Larger font size
          padding: '12px 48px',  // Larger padding
        },
      },
    },
  },
});
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Suspense fallback={<div />}>
          <App />
          <ToastContainer />
        </Suspense>
      </React.Fragment>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
