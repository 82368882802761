
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid2, Paper, Typography } from '@mui/material'
import React from 'react'
import { Container } from 'reactstrap'
import Price from '../../../components/price/Price'
import { cancelOrder } from '../../../redux/features/ordersSlice/orderUpdateSlice';
import { useDispatch } from 'react-redux';

function CancelOrder({ order, open, onClose }) {

    const dispatch = useDispatch();

    const handleDialogClose = (e) => {
        onClose();
    }

    const onSubmit = (e) => {
        dispatch(cancelOrder(order.id));
        onClose();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h5" sx={{ fontWeight: 'bold', }}>
                        Cancel Order
                    </Typography>

                </DialogTitle>
                <DialogContent>
                    <Box>

                        <br />
                        <Paper style={{ padding: 12 }}>
                            <Grid2 item xs={12} >
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: 'bold', ml: 1, display: 'block', fontSize: 14 }} // Underline and margin-left
                                    >
                                        User Info:
                                    </Typography>
                                </Box>

                            </Grid2>

                            <Typography
                                variant='body'
                                sx={{ fontWeight: 'w400', ml: 1, display: 'block', fontSize: 16 }} >
                                Name:  {(order?.user?.full_name ?? order?.user?.guest_name) ?? ""}

                            </Typography>
                            <Typography
                                variant='body'
                                sx={{ fontWeight: 'w400', ml: 1, display: 'block', fontSize: 16 }} >
                                Phone:  {((order?.user?.phone.dial_code + " " + order?.user?.phone.number) ?? order?.user?.guest_phone) ?? ""}

                            </Typography>

                        </Paper>
                        <br /><br />
                        <Box>
                            <Grid2 container spacing={2}>
                                <Grid2 item xs={4}>
                                    <Price label={"Total Amount:"} amount={Math.round(Number(order.amount))} />
                                </Grid2>
                                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                                <Grid2 item xs={4}>
                                    <Price label={"Received Amount:"} amount={Math.round(Number(order.amount) - Number(order.balanceAmount))} />

                                </Grid2>
                                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                                <Grid2 item xs={4}>
                                    <Price label={"Balance Amount:"} amount={Math.round(Number(order.balanceAmount))} />

                                </Grid2>
                            </Grid2>

                        </Box>
                        <br /> <br />
                        <Grid2>
                            <Container style={{ color: "teal", backgroundColor: "aliceblue", padding: "8px 8px" }}>
                                Note: On cancelling the order, the refund will be initiated if there is any. The vehicle will be moved back to stock
                            </Container>
                        </Grid2>
                    </Box>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Close</Button>
                    <Button onClick={onSubmit} autoFocus>
                        Cancel Order
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default CancelOrder