import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import io from "socket.io-client";
import { apiUrl } from "../../../utils/configEnv";
import { store } from "../../store";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  details: { relatedCars: [] },
  watchers: [],
  bids: [],
  totalCount: 0,
  bidsCount:0,
  loading: false,
  error: null,
  socket: null,
  bidRejected: null,
  placeBidStatus: "init",
  placeBidError: null,
};

const fetchAuctionDetails = createAsyncThunk("auctions/details", (id) => {
  let url = `/admin/auction/${id}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const addAuction = createAsyncThunk("addAuction",async (data) => {
  try {
    let url = `/admin/auction/create`;
    const response = await axios.post(url, data);
    console.log(response.data)
    toast.success('Added');
    // window.location.reload();
    return response.data;
  } catch (error) {
    console.log(error)
    toast.error(error?.response?.data?.message);
  }
});
const updateAuctionDetails = createAsyncThunk("auctions/update",  async (data) => {
  const { id, body } = data;
  let url = `/admin/auction/update/${id}`;
  try {
    const response = await axios.put(url, body);
  toast.success('Auctions Updated');
    window.location.reload();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error with the message
  }
});
const fetchBids = createAsyncThunk("auctions/bids", (data) => {
  let url = `/admin/bid/list`;
  return axios.post(url,data).then((response) => {
    return response?.data?.data;
  });
});
const connectToSocket = createAsyncThunk("auctions/socket", async (id, { getState, dispatch }) => {
  // try {
  //   await axios.post("/bids/socketio");
  // } catch (error) {}

  let socket = io(apiUrl, { path: "/socket", transports: ["websocket"] });
  socket.on("connect", () => {
    console.log("connected")
    const body = {
      user: getState().auth.loggedInUser.full_name,
      userId: getState().auth.loggedInUser.id,
      message: "Connect to auction",
      auctionRoom: id,
    };
    console.log(body);
    socket.emit("join", body);
  });
  socket.on("connect_error", (e) => {
    console.log(e);
  });
  socket.on("bidAdded", (bid) => {
    console.log(bid);
    console.log("biddd")
    dispatch(onNewBid(bid));
  });
  socket.on("bidRejected", (message) => {});
  socket.on("addTime", (duration) => {});
  return socket;
});
const placeBid = createAsyncThunk("auctions/placeBid", (amount, { getState }) => {
  const auctionId = getState().auctionDetails.details.id;
  const data = {
    user_id: getState().auth.loggedInUser.id,
    auction_id: auctionId,
    amount: Number(amount),
    socketId: getState().auctionDetails.socket.id,
  };

  // console.log(body);
  // return axios.post(`/admin/bid/create/`, data).then((response) => response.data);
});
const auctionDetailsSlice = createSlice({
  name: "auctionDetails",
  initialState,
  reducers: {
    onReposted: (state, action) => {
      state.details = action.payload;
    },
    onNewBid: (state, action) => {
      const bidder = action.payload.userObject;
      bidder.type = bidder.roles.includes("DBA") ? "dealer" : "endUser";
      const bid = {
        bidder,
        amount: action.payload.amount,
      };
      state.bids.unshift(bid);
    },
    onWatcherJoined: (state, actions) => {
      state.watchers.push(actions.payload);
    },
    onWatcherLeft: (state, actions) => {
      const index = state.watchers.indexOf(actions.payload);
      if (index != -1) state.watchers.splice(index, 1);
    },
    closeSocket: (state) => {
      if (state.socket) {
        state.socket?.close();
        state.socket = null;
      }
    },
    resetBidRejected: (state) => {
      state.bidRejected = null;
    },
    resetPlaceBid: (state) => {
      state.placeBidStatus = "init";
      state.placeBidError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuctionDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.details = {};
    });
    builder.addCase(fetchAuctionDetails.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.details = action.payload?.data;
      // state.bids = action.payload?.data?.bids ?? [];
    });
    builder.addCase(fetchAuctionDetails.rejected, (state, action) => {
      state.loading = false;
      state.details = {};
      state.error = action.error.message;
    });
    // socket
    builder.addCase(connectToSocket.pending, (state) => {
      if (state.socket) {
        state.socket?.close();
        state.socket = null;
      }
    });
    builder.addCase(connectToSocket.fulfilled, (state, action) => {
      state.socket = action.payload;
    });
    builder.addCase(connectToSocket.rejected, (state, action) => {});
    //placebid
    builder.addCase(placeBid.pending, (state) => {});
    builder.addCase(placeBid.fulfilled, (state, action) => {
      state.placeBidStatus = "success";
    });
    builder.addCase(placeBid.rejected, (state, action) => {
      // console.log(action.error.message);
      state.placeBidStatus = "error";
      state.placeBidError = action.error.message;
    });
    builder.addCase(fetchBids.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchBids.fulfilled, (state, action) => {
      state.loading = false;
      state.bids = action.payload?.data ?? [];
      state.bidsCount =action.payload?.paginator?.itemCount;
    });
    builder.addCase(fetchBids.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAuctionDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateAuctionDetails.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.details = action.payload?.data ?? [];
    });
    builder.addCase(updateAuctionDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default auctionDetailsSlice.reducer;
export const { resetBidRejected, closeSocket, resetPlaceBid, onNewBid } = auctionDetailsSlice.actions;
export { fetchAuctionDetails, connectToSocket, placeBid ,fetchBids,updateAuctionDetails,addAuction};
