import React, { useCallback } from "react";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { motion, AnimatePresence } from "framer-motion";
import { Upload } from "lucide-react";
import RenderPreview from "./RenderUploadPreview";
import './FormMultiImageUpload.css'

const FormSingleImageUpload = ({ control, name, label, ...props }) => {
  const {
    active = "false",
    dragenter = () => {},
    dragleave = () => {},
    drop = () => {},
    handleFileChange = () => {},
    removeImage = () => {},
    errorMessage = "",
    acceptTypes = 'image/*',
    inputProps,
  } = props;

  const inputId = uuidv4();

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    drop(e);
  }, [drop]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    dragenter(e);
  }, [dragenter]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className="upload-container">
          <label className="upload-label">{label}</label>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`upload-area ${
              active === "true"
                ? "upload-active"
                : active === "bad"
                ? "upload-error"
                : "upload-default"
            }`}
            onDragEnter={dragenter}
            onDragLeave={dragleave}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              name="fileupload"
              id={inputId}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  field.onChange(file);
                  handleFileChange(e);
                }
              }}
              className="upload-input"
              {...inputProps}
              accept={acceptTypes}
              hidden
            />

            <div className="upload-preview-container">
              <AnimatePresence>
                {field.value ? (
                  <motion.div
                    key="preview"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                    className="upload-preview"
                  >
                    <RenderPreview 
                      file={field.value} 
                      onRemove={() => {
                        field.onChange(null);
                        removeImage();
                      }}
                    />
                  </motion.div>
                ) : (
                  <motion.label
                    key="upload"
                    htmlFor={inputId}
                    className="upload-label-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <Upload size={40} className="upload-icon" />
                    <span className="upload-text">Drag and drop or click to upload</span>
                  </motion.label>
                )}
              </AnimatePresence>
            </div>
            {error && <motion.p className="upload-error-text">{error.message}</motion.p>}
            {!field.value && (
              <motion.label
                htmlFor={inputId}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="upload-add-more"
              >
                Select file
              </motion.label>
            )}
          </motion.div>
        </div>
      )}
    />
  );
};

export default React.memo(FormSingleImageUpload);