import React, { useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { axios } from '../../utils/axios';

export default function DealerAutoComplete({
  transformer,
  onSelect,
  ...props
}) {
  const [value, setValue] = useState(null); // Default value is set to null
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);



  const fetchAutocompleteOptions = async (data) => {
    try {
      const response = await axios.get('/admin/companies', { params: data });
      return response.data?.['data']?.['data'] ?? [];
    } catch (e) {
      console.error('Error fetching autocomplete options:', e);
      return [];
    }
  };

  // Debounced fetching to avoid excessive API calls
  const debouncedFetch = React.useMemo(
    () =>
      debounce(async (inputValue, callback) => {
        const options = await fetchAutocompleteOptions({ search: inputValue });
        callback(options);
      }, 400),
    []
  );

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let active = true;
    setLoading(true);

    debouncedFetch(inputValue, (results) => {
      if (active) {
        setOptions(results);
        setLoading(false);
      }
    });

    return () => {
      active = false;
      debouncedFetch?.cancel();
    };
  }, [inputValue, value, debouncedFetch]);

  const handleOpen = () => {
    setOpen(true);
    (async () => {
      setLoading(true);
      const options = await fetchAutocompleteOptions({ search: inputValue });
      setLoading(false);
      setOptions(options);
    })();
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  return (
    <div style={{ minWidth: '100px' }}>
      <Autocomplete
        sx={{ minWidth: 250 }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        getOptionLabel={(option) => {
            return option?.name ?? '';
        }}
        options={options}
        loading={loading}
        filterOptions={(x) => x} // Avoid filtering client-side, rely on server
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            onSelect(newValue)
          }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Dealers"
            size="small"
            InputProps={{
                ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </div>
  );
}
