import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  DataTableItem,
  DataTableRow,
  Icon,
  InputSwitch
} from '../../../components/Component';
import { getImage } from '../../../utils/uploadImageToS3';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateAutocompleteOptions } from '../../../redux/features/verticalsSlice/autocompleteOptionsSlice';


const ItemRow = ({ item, setSelectedItem, toggleUpdateProfileImageModal,secondary }) => {
  const dispatch = useDispatch();
  const [displayAvatar, setDisplayAvatar] = useState('');
  const [trendingSearch, setTrendingSearch] = useState(false);
  
  const handleSearch = async (e) => {
    setTrendingSearch(e)
    let formData={
      "trendingSearch":e
    }
    try {
      dispatch(updateAutocompleteOptions({ id: item?.id, data: formData }));
      toast.success('Updated successfully');

    } catch (err) {
      toast.error('Not modified with changes!');
    }
  };
  useEffect(() => {
    if (item.icon) {
      async function fetchImage() {
        const s3url = await getImage(item.icon);
        setDisplayAvatar(s3url);
      }
      fetchImage();
    }
    if(item.trendingSearch){
      setTrendingSearch(item.trendingSearch);
    }
  }, [item]);

  return (
    <DataTableItem key={item.id}>
      <DataTableRow>
        <span className="tb-sub">
          <span>{item?.value}</span>
        </span>
      </DataTableRow>

     
      <>
      {secondary&&
        <DataTableRow>
        <span className="tb-sub">
          <span>{item?.referenceValue??'-'}</span>
        </span>
      </DataTableRow>
      }
            <DataTableRow>
        <span className="tb-sub">
        <div className="form-group">
                   
          <div className="form-control-wrap">
            <InputSwitch checked={trendingSearch} id={item.id} onClick={(e) => handleSearch(e)} />
          </div>
        </div>
        </span>
      </DataTableRow>
      <DataTableRow>
        <span
          className="tb-sub"
          style={{
            display: 'flex',
            justifyItems: 'center',
            justifyContent: 'center'
          }}
          onClick={(ev) => {
            ev.preventDefault();
            toggleUpdateProfileImageModal();
            setSelectedItem(item);
          }}
        >
          {displayAvatar ? (
            <div style={{ position: 'relative', width: '60px' }}>
              <img src={displayAvatar} alt="icon" style={{ width: '100%' }} />
            </div>
          ) : (
            <Icon name="upload"></Icon>
          )}
        </span>
      </DataTableRow>
      </>

    </DataTableItem>
  );
};

export default ItemRow;
