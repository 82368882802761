import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Spinner } from "reactstrap";
import { getImage } from "../../utils/uploadImageToS3";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = ({ className, file, fileAlt, previewHeight = 150, previewWidth = 150, ...props }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function fetchPdf() {
      if (!file) return;
      
      try {
        setLoading(true);
        const obj = await getImage(file);
        if (obj) setPdfUrl(obj);
      } catch (e) {
        console.error("Failed to load PDF:", e);
      } finally {
        setLoading(false);
      }
    }

    fetchPdf();
  }, [file]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className={className}>
      {loading ? (
        <Spinner />
      ) : pdfUrl ? (
        <>
          <div onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Spinner />}
            >
              <Page pageNumber={pageNumber} height={previewHeight} width={previewWidth} />
            </Document>
            <p>Click to view full PDF</p>
          </div>

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullScreen
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
              sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Spinner />}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <p>No PDF Available</p>
      )}
      {props.children}
    </div>
  );
};

export default PdfViewer;