import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { debounce } from 'lodash';
import uuid from 'react-uuid';
import { uploadToS3 } from '../../../utils/uploadImageToS3';
import { verticalId } from '../../../utils/configEnv';

const initialState = {
  data: {},
  loading: false,
  error: null,
  updateStatus: 'init',
  updateError: null
};
export const fetchAppSettings = createAsyncThunk('settings/app', (data) => {
  let url = `/admin/verticalsetting/vertical/${verticalId}`;

  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});

async function uploadImages(images) {
  let imagePaths = [];
  for (let i = 0; i < images.length; i++) {
    let image = images[i];
    if (image instanceof File) {
      const fileType = image.name.split('.').pop();
      const filePath = `banners/${uuid()}.${fileType}`;
      await uploadToS3(image, filePath, image.type);
      imagePaths.push(filePath);
    } else {
      imagePaths.push(image);
    }
  }
  return imagePaths;
}

// Async thunk for updating dealer details (replace with actual API call)
export const updateAppSettings = createAsyncThunk(
  'settings/updateAppSettings',
  async (data, { getState }) => {
    if(data.banners){
     data.banners = await uploadImages(data.banners) || [];
    }
    if(data.banners2){
     data.banners2 = await uploadImages(data.banners2) || [];
    }
    console.log(data)
    const response = await axios.put(
      `/admin/verticalsetting/update/${verticalId}`,
      data
    );
    return response.data;
  }
);

const AppSettingsSlice = createSlice({
  name: 'appSe',
  initialState,
  reducers: {
    resetAppSettingsUpdate(state) {
      state.updateStatus = 'init';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppSettings.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchAppSettings.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchAppSettings.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(updateAppSettings.pending, (state) => {
      state.updateStatus = 'loading';
    });
    builder.addCase(updateAppSettings.fulfilled, (state, action) => {
      state.updateStatus = 'success';
      // Update dealer details
      state.data = action.payload.data;
    });
    builder.addCase(updateAppSettings.rejected, (state, action) => {
      state.updateStatus = 'error';
      state.error = action.error.message;
    });
  }
});

const debounced = debounce(
  ({ ...arg }, dispatch) => dispatch(fetchAppSettings({ ...arg })),
  500
);
export const debouncedfetchAppSettings =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export const AppSettingsSliceReducer = AppSettingsSlice.reducer;
export const { resetAppSettingsUpdate } = AppSettingsSlice.actions;
