import React from "react";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
} from "../../../components/Component";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createOrUpdateSettings } from "../../../redux/features/verticalsSlice/createOrUpdateSettingsSlice";
import ItemListFieldMappingSettings from "./ItemListFieldMappingSettings";

export default function Settings() {
  return (
    <React.Fragment>
      <Head title="Vertical Settings"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Vertical Settings</BlockTitle>
            <BlockDes>
              <p>Vertical configuration</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button className={`toggle btn btn-icon btn-trigger mt-n1  active`} onClick={() => {}}>
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "stretch",
            flexDirection: "column",
          }}
        >
          <ItemListFieldMappingSettings />
        </div>
      </Block>
    </React.Fragment>
  );
}
