import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchVerticalDetails } from "../../../redux/features/verticalsSlice/verticalDetailsSlice";
import { Block } from "../../../components/Component";
import FieldFormMapper from "../../verticals/components/FieldFormMapper";

const InspectionForm = ({ vertical_id ,baseform_id}) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.verticalDetails);

  useEffect(() => {
    dispatch(fetchVerticalDetails(vertical_id));
  }, [vertical_id]);
  return (
    <>
      <section>
        <Block>
          <FieldFormMapper vertical_id={vertical_id} baseform_id={baseform_id} values={data?.advancedinspectionformsettings?.tabs} />
        </Block>
        {/* <Row className="gy-4">
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="first-name">
                Title *{' '}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="first-name"
                  className="form-control"
                  name="firstName"
                //   value={values.propertyTitle}
                //   onChange={handleFormData('propertyTitle')}
                />
              </div>
            </div>
          </Col>
          <Col sm={6}>

          </Col>

      </Row> */}
      </section>
    </>
  );
};

export default InspectionForm;
