import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Input, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import { RSelect } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import TwodDiagram from './TwodDiagram';
import { uploadToS3 } from '../../../utils/uploadImageToS3';
import { v4 as uuidv4 } from 'uuid';
import { fetchAutocompleteOptions } from '../../../redux/features/verticalsSlice/autocompleteOptionsSlice';
import { useSelector } from 'react-redux';
import ImageUpload from '../../cars/components/ImageUpload';
import { fetchAutocompleteOptionsSecondary } from '../../../redux/features/verticalsSlice/autocompleteOptionsSecondarySlice';
import { addItem } from '../../../redux/features/itemsSlice/itemsSlice';
import { toast } from 'react-toastify';

export default function BaseFieldFormMapper({
  values,
  vertical_id,
}) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [formFields, setFormFields] = useState([]); // State to store field values
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let data = {
      query: {
        key: 'CAR_MAKE'
      },
      options: {
        page: 1,
        limit: 800,
        sort: 'value'
      }
    };
    dispatch(fetchAutocompleteOptions(data));
  }, [vertical_id]);

  function handleFieldChange(key, label, value, type) {
    // Find the index of the field with the same key
    const fieldIndex = formFields.findIndex((field) => field.key === key);

    if (fieldIndex !== -1) {
      // If the field already exists in the state, update its value
      const updatedFields = [...formFields];
      updatedFields[fieldIndex] = { key, label, value, type };
      setFormFields(updatedFields);
    } else {
      // If the field doesn't exist in the state, add it
      setFormFields([...formFields, { key, label, value, type }]);
    }
  }
  async function processArray(data) {
    const updatedData = await Promise.all(
      data.map(async (item) => {
        if (item.value.type === 'file') {
          const fileUrl = await uploadToS3(item.value.file, item.value.name); // Upload the file and get the URL
          item.value.fileUrl = fileUrl; // Update the value with the file URL
        }
        return item;
      })
    );

    return updatedData;
  }

  const handleAddItem = async (e) => {
    e.preventDefault();
    if (formFields?.length === 0) {
      toast.error("Please fill all the mandatory fields");
      return;
    }
    
    let keys =formFields?.map(x=>x.key)
    for (let i = 0; i < (values[0]?.fields || []).length; i++) {
      const e = values[0]?.fields[i];
      if (e.isRequired && !keys.includes(e.name)) {
        toast.error("Please fill all the mandatory fields");
        break; 
      }
    }

    if (formFields?.length > 0) {
      let images = formFields?.filter((v) => v.type === 'multipleImages');
      if (images?.length > 0) {
        const fileListArray = Array.from(images[0].value);
        if(fileListArray?.length ===0) {
          toast.error("Please upload at least one image");
          return;
        }
        const updatedFormFields = await Promise.all(
          fileListArray?.map(async (doc) => {
            const fileName = `temp/${uuidv4()}.${doc?.name?.split('.').pop()}`;
            await uploadToS3(doc, fileName);
            return fileName;
          })
        );
        images[0].value.splice(0, images[0].value.length, ...updatedFormFields);
      } 
    }
    
    let data = {
      values: formFields,
      vertical_id: vertical_id
    };
    
    setLoading(true);
    
    try {
      await dispatch(addItem(data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add item. Please try again.");
    }
    
  };
  return (
    <div >
      {/* <Nav tabs style={{ width: '100%' }}>
        {values?.map((e, index) => (
          <NavItem key={index}>
            <NavLink
              className={classNames({ active: selectedTab === index })}
              onClick={(ev) => {
                ev.preventDefault();
                setSelectedTab(index);
              }}
            >
              {e.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav> */}
      {values &&
        values[selectedTab]?.fields?.map((e) => (
          <SelectedField
            key={e.name}
            formField={e}
            handleFieldChange={handleFieldChange}
          />
        ))}
      <div className="actions clearfix">
        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
          <li>
            {selectedTab < values?.length - 1 && (
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => setSelectedTab(selectedTab + 1)}
                color="primary"
                type="button"
              >
                Next
              </Button>
            )}
            <Button
              disabled={selectedTab < values?.length - 1}
              //   onClick={(e) => submitFormData(e)}
              onClick={(e) => handleAddItem(e)}
              color="secondary"
              type="submit"
            >
              {loading ? <Spinner size="sm" color="light"/>:"Save"}
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
}

function SelectedField({ formField, handleFieldChange }) {
  const dispatch = useDispatch();
  const options = useSelector(
    (state) => state?.autocompleteOptions?.data?.data
  );
  const optionsModel = useSelector(
    (state) => state?.autocompleteOptionsSecondary?.data?.data
  );
  const [make, setMake] = useState(''); // State to store field values
  useEffect(() => {
    let data = {
      query: {
        key: 'CAR_MODEL',
        referenceKey: 'CAR_MAKE',
        hasReference: true
      },
      options: {
        page: 1,
        limit: 100,
        sort: 'value'
      }
    };
    if (make !== '') {
      data.query.referenceValue = make.trim();
      dispatch(fetchAutocompleteOptionsSecondary(data));
    }
  }, [make]);
  const handleImageChange = (e, field) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      handleFieldChange(
        formField.name,
        formField.label,
        selectedFile,
        formField.type
      );
    } else {
    }
  };
  const handleChange = (e) => {
    // Call the handleFieldChange function with the field data
    handleFieldChange(
      formField.name,
      formField.label,
      e.target.value,
      formField.type
    );
  };
  const handleChangeDistance = (e) => {
    // Call the handleFieldChange function with the field data
    handleFieldChange(
      formField.name,
      formField.label,
      Number(e.target.value),
      formField.type
    );
  };

  switch (formField.type) {
    case 'text':
      return (
        <label style={{ width: '100%' }}>
          {formField.label}{formField.isRequired?"*":""}
          <input
            type="text"
            className="form-control"
            style={{ width: '100%' }}
            onChange={handleChange}
          />
        </label>
      );

    case 'distanceField':
      return (
        <>
          <label style={{ width: '100%' }}>{formField.label}{formField.isRequired?"*":""}</label>
          <div className="form-control-wrap">
            <div className="input-group">
              <Input type="text" onChange={handleChangeDistance} />
              <div className="input-group-prepend">
                <span className="input-group-text">{formField.unit}</span>
              </div>
            </div>
          </div>
        </>
      );

    case 'dropdown':
      return (
        <label style={{ width: '100%' }}>
          {formField.label}{formField.isRequired?"*":""}
          <RSelect
            isMulti={false}
            options={formField?.options.map((e) => ({ label: e, value: e }))}
            style={{ width: '100%' }}
            onChange={(selectedOption) => {
              // Extract the selected value from the option
              const selectedValue = selectedOption
                ? selectedOption.value
                : null;
              // Call the handleFieldChange function with the field data
              handleFieldChange(
                formField.label,
                formField.name,
                selectedValue,
                formField.type
              );
            }}
          />
        </label>
      );

    case 'radio':
      return (
        <label style={{ width: '100%' }}>
          {formField.label}{formField.isRequired?"*":""}
          <RSelect
            isMulti={false}
            options={formField?.options.map((e) => ({ label: e, value: e }))}
            style={{ width: '100%' }}
            onChange={(selectedOption) => {
              // Extract the selected value from the option
              const selectedValue = selectedOption
                ? selectedOption.value
                : null;
              // Call the handleFieldChange function with the field data
              handleFieldChange(
                formField.label,
                formField.name,
                selectedValue,
                formField.type
              );
            }}
          />
        </label>
      );

    case 'multiLine':
      return (
        <label style={{ width: '100%' }}>
          {formField.label}{formField.isRequired?"*":""}
          <input
            type="text"
            className="form-control"
            style={{ width: '100%' }}
            onChange={handleChange}
          />
        </label>
      );

    case 'switch':
      return (
        <label style={{ width: '100%' }}>
          {formField.label}{formField.isRequired?"*":""}
          {/* Render switch field here */}
        </label>
      );
    case 'image':
      return (
        <label style={{ width: '100%' }}>
          {formField.label}{formField.isRequired?"*":""}
          <div className="form-control">
            <div className="form-file">
              <input
                type="file"
                onChange={(e) => handleImageChange(e, formField)}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </label>
      );
    case '2dDiagram':
      return (
        <TwodDiagram
          formField={formField}
          diagram={formField.diagram}
          handleFieldChange={handleFieldChange}
        />
      );
    // Add cases for other field types here
    case 'autocomplete':
      return (
        <>
          {formField?.key === 'CAR_MAKE' ? (
            <label style={{ width: '100%' }}>
              {formField.label}{formField.isRequired?"*":""}
              <RSelect
                isMulti={false}
                options={options?.map((e) => ({
                  label: e.value,
                  value: e.id,
                  icon: e.icon
                }))}
                style={{ width: '100%' }}
                onChange={(selectedOption) => {
                  setMake(selectedOption?.label);
                  // Extract the selected value from the option
                  const selectedValue = selectedOption
                    ? selectedOption.label
                    : null;
                  // Call the handleFieldChange function with the field data
                  handleFieldChange(
                    formField.label,
                    formField.name,
                    selectedValue,
                    formField.type
                  );
                }}
              />
            </label>
          ) : (
            <label style={{ width: '100%' }}>
              {formField.label}{formField.isRequired?"*":""}
              <RSelect
                isMulti={false}
                options={optionsModel?.map((e) => ({
                  label: e.value,
                  value: e.id,
                  icon: e.icon
                }))}
                style={{ width: '100%' }}
                onChange={(selectedOption) => {
                  // Extract the selected value from the option
                  const selectedValue = selectedOption
                    ? selectedOption.label
                    : null;
                  // Call the handleFieldChange function with the field data
                  handleFieldChange(
                    formField.label,
                    formField.name,
                    selectedValue,
                    formField.type
                  );
                }}
              />
            </label>
          )}
        </>
      );
    // Add cases for other field types here
    case 'multipleImages':
      return (
        <>
          <label style={{ width: '100%' }}>{formField.label}{formField.isRequired?"*":""}</label>
          <ImageUpload
            formField={formField}
            handleFieldChange={handleFieldChange}
            handleRemoveAll={null}
            propImages={[]}
          />
        </>
      );
    default:
      return null; // Handle unknown field types or return null
  }
}
