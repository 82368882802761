import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
} from "../../components/Component";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Button, Badge, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  debouncedFetchServiceProviders,
  fetchServiceProviders,
  setAllSelected,
  setSelected,
} from "../../redux/features/serviceProvidersSlice/serviceProvidersSlice";
import TableHeader from "../../components/table/TableHeader";
import DateFilter from "../../components/date-filter/DateFilter";
import ListBlockHeader from "../../components/list-block-header/ListBlockHeader";

const ServiceProvidersData = () => {
  const [smOption, setSmOption] = useState(false);

  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);

  const [filter, setFilter] = useState("active");
  const [sortByField, setSortByField] = useState("createdAt");
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);

  const { data, totalCount, loading } = useSelector((state) => state.serviceProviders);

  const dispatch = useDispatch();

  // Changing state value when searching name
  useEffect(() => {
    let data = { page: currentPage, status: filter, sortBy: { [sortByField]: sortByValue } };
    if (dateFilter) {
      data.dateFilter = dateFilter;
    }
    if (onSearchText !== "") {
      data.searchText = onSearchText;
      dispatch(debouncedFetchServiceProviders(data));
    } else {
      dispatch(fetchServiceProviders(data));
    }
  }, [onSearchText, currentPage, filter, sortByValue, dateFilter]);

  // toggle function to view order details
  const toggle = (type) => {};

  // selects all the order
  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Service Providers"></Head>
      <Content>
        <ListBlockHeader title="Service Providers" onSearchChanged={onFilterChange} onDateChanged={setDateFilter}>
          <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
              Status
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setFilter("active")}>
                    <span>Active</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setFilter("inactive")}>
                    <span>Inactive</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
          <Button
            color="primary"
            onClick={() => {
              toggle("add");
            }}
          >
            <Icon name="plus"></Icon>
            <span>Add User</span>
          </Button>
        </ListBlockHeader>

        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                    value={"off"}
                  />
                  <label className="custom-control-label" htmlFor="pid-all"></label>
                </div>
              </DataTableRow>
              <DataTableRow>
                <TableHeader name="firstName" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>

              <DataTableRow size="md">
                <TableHeader name="lastName" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader name="phone" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader name="email" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader name="createdAt" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Status</span>
              </DataTableRow>

              <DataTableRow className="nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1 my-n1">
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="btn btn-trigger dropdown-toggle btn-icon me-n1">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#markasdone"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="truck"></Icon>
                              <span>Mark As Delivered</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#remove"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="trash"></Icon>
                              <span>Remove Orders</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </DataTableRow>
            </DataTableHead>

            {data?.length > 0
              ? data.map((item, index) => (
                  <DataTableItem key={item._id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={item.check}
                          id={item._id + "oId-all"}
                          key={Math.random()}
                          onChange={(e) => {
                            onSelectChange(e, index);
                          }}
                        />
                        <label className="custom-control-label" htmlFor={item._id + "oId-all"}></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <a href="#id" onClick={(ev) => ev.preventDefault()}>
                        {item.firstName}
                      </a>
                    </DataTableRow>
                    <DataTableRow>{item.lastName}</DataTableRow>
                    <DataTableRow>{item.phone}</DataTableRow>
                    <DataTableRow>{item.email}</DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.createdAt}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className={`dot bg-${item.active === true ? "success" : "warning"} d-sm-none`}></span>
                      <Badge
                        className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                        color={item.active === true ? "success" : "warning"}
                      >
                        {item.active ? "Active" : "Inactive"}
                      </Badge>
                    </DataTableRow>

                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li
                          className="nk-tb-action-hidden"
                          onClick={() => {
                            toggle("details");
                          }}
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={"view" + item.id}
                            icon="eye"
                            direction="top"
                            text="View Details"
                          />
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();

                                      toggle("details");
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>Edit</span>
                                  </DropdownItem>
                                </li>
                                {item.active === true && (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdown"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="truck"></Icon>
                                      <span>Approve</span>
                                    </DropdownItem>
                                  </li>
                                )}
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Delete User</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {data?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No Services found</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ServiceProvidersData;
