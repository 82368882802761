import React, { useState ,useEffect} from 'react';
import { getImage } from '../../../utils/uploadImageToS3';
import { ModalBody, ModalFooter ,Modal, ModalHeader, Col, Label, Button} from 'reactstrap';

function TwodDiagram({diagram,handleFieldChange,formField}) {
  const [pins, setPins] = useState([]);

  const [imageUrl, setImageUrl] = useState(null); // Replace with your image URL
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPin, setSelectedPin] = useState(null);
  const [note, setNote] = useState('');

  // Function to add a new pin
  const addPin = (x, y) => {
    const newPin = { x, y, note: '' };
    setPins([...pins, newPin]);
  };

  // Function to open the modal for a specific pin
  const openModal = (pin) => {
    setSelectedPin(pin);
    setNote(pin.note);
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedPin(null);
    setModalIsOpen(false);
  };

  const toggleModal = () => {
    setModalIsOpen(!openModal);
  };
  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  // Function to save the note to the selected pin
  const saveNote = (e) => {
    e.preventDefault();
    const updatedPins = pins.map((pin) =>
      pin === selectedPin ? { ...pin, note: note,"image":null } : pin
    );
    setPins(updatedPins);
    closeModal();
  };
  useEffect(() => {
    const value={
      image: diagram,
      pins: pins,
      type: formField.type,
    }
handleFieldChange(formField.label,formField.name, value,  'twodDiagram',)
},[pins]);
  useEffect(() => {
    const fetchData = async () => {
      let src = await getImage(diagram);
      setImageUrl(src)
    }
 
    fetchData();
  
  }, []);



// Function to handle click events on the image
const handleImageClick = (event) => {
  const offsetX = event.nativeEvent.offsetX;
  const offsetY = event.nativeEvent.offsetY;

  const x = offsetX / event.target.width;
  const y = offsetY / event.target.height;

  addPin(x, y);
};

  return (
    <div style={{ position: 'relative' }}>
  
    <img
        src={imageUrl}
        alt="Interactive Image"
        style={{ maxWidth: '50%' }}
        onClick={handleImageClick}
      />
      {pins.map((pin, index) => (
        <div
          key={index}
          onClick={(e) =>
           { e.preventDefault()
             openModal(pin)}
            }
          style={{
            position: 'absolute',
            left: `${pin.x * 50}%`, // Convert relative position to percentage
            top: `${pin.y * 100}%`,
            transform: 'translate(-50%, -50%)', // Center the pin icon
            cursor: 'pointer',
          }}
        >
             <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 21L12 17H6L9 21Z" fill="#E52128"/>
<circle cx="9" cy="9" r="9" fill="#E52128"/>
<path d="M10.8802 13.0288L10.7537 13.5428C10.3743 13.6917 10.0711 13.805 9.84541 13.883C9.61944 13.9611 9.35688 14 9.05773 14C8.59833 14 8.241 13.8881 7.98615 13.6659C7.73129 13.4428 7.60383 13.1601 7.60383 12.8171C7.60383 12.6843 7.61308 12.5478 7.6323 12.4086C7.6517 12.2693 7.68253 12.1124 7.72472 11.9371L8.19897 10.2683C8.24116 10.1086 8.27702 9.95715 8.30575 9.81398C8.33487 9.67169 8.34891 9.54077 8.34891 9.42293C8.34891 9.20982 8.30453 9.06076 8.21617 8.97695C8.12781 8.89338 7.95921 8.85095 7.7089 8.85095C7.5863 8.85095 7.46029 8.87047 7.33185 8.90822C7.20284 8.94614 7.09258 8.98276 7 9.0168L7.12682 8.50233C7.43749 8.37658 7.73446 8.2689 8.01852 8.17953C8.30258 8.08991 8.57099 8.04506 8.82486 8.04506C9.2811 8.04506 9.63316 8.15468 9.88038 8.37392C10.1276 8.59332 10.2512 8.87773 10.2512 9.22821C10.2512 9.30072 10.2431 9.42849 10.2256 9.61111C10.2086 9.79413 10.1769 9.96183 10.1306 10.1144L9.65839 11.7764C9.61969 11.9099 9.58488 12.0625 9.55462 12.2342C9.52338 12.4048 9.50845 12.5352 9.50845 12.6226C9.50845 12.8433 9.55794 12.994 9.65717 13.0741C9.75713 13.1542 9.92931 13.194 10.1739 13.194C10.2887 13.194 10.4193 13.1738 10.5645 13.134C10.7092 13.0942 10.8148 13.0593 10.8802 13.0288ZM11 6.05102C11 6.3406 10.8902 6.58791 10.6696 6.79126C10.4496 6.99533 10.1844 7.09745 9.87422 7.09745C9.56305 7.09745 9.29725 6.99533 9.07469 6.79126C8.85253 6.58783 8.74121 6.3406 8.74121 6.05102C8.74121 5.76201 8.85253 5.5143 9.07469 5.30837C9.29684 5.10276 9.56314 5 9.87422 5C10.1843 5 10.4496 5.10301 10.6696 5.30837C10.8904 5.5143 11 5.76209 11 6.05102Z" fill="white"/>
</svg>
        </div>
      ))}
      <Modal 
        isOpen={modalIsOpen}
        size="lg"
        toggle={toggleModal}
      >
         <ModalHeader  toggle={toggleModal}>
         Add Pin Note
        </ModalHeader>
        <ModalBody>
        <Col md="12">
            <div className="form-group">
              <Label className="form-label" htmlFor="fv-message">
                Note
              </Label>
              <div className="form-control-wrap">
                <textarea
                  type="textarea"
                  className="form-control form-control-sm"
                  id="fv-message"
                  name="message"
                  placeholder="Write your message"
                  onChange={handleNoteChange}
                />
                
              </div>
            </div>
          </Col>
        </ModalBody>
        <ModalFooter>
        <Button
       
              //   onClick={(e) => submitFormData(e)}
              onClick={(e) => saveNote(e)}
              color="secondary"
              type="submit"
            >
              Save
            </Button>
  
        </ModalFooter>
      </Modal>
    </div>
  
  );
}

export default TwodDiagram;
