import React, { useState } from "react";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import { Button, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import EditVerticalModal from "./EditVerticalModal";
import { useNavigate } from "react-router-dom";
import { updateVerticals } from "../../../redux/features/verticalsSlice/updateVerticalsSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmModal from "./ConfirmModal";

export default function BasicDetails() {
  const { data } = useSelector((state) => state.verticalDetails);
  const [updateProfileModal, setUpdateProfileModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);



  const navigate =useNavigate();
  const dispatch =useDispatch();

  const toggleUpdateProfileModal = () => {
    setUpdateProfileModal(!updateProfileModal);
    };
    const handleUpdateVertical = async (e) => {
      e.preventDefault();
  if( !data?.verticalsettings_id?.auctionStartingPrice && !data?.verticalsettings_id?.item_list_mappings ){
      toast.error("Please add basic form and vertical settings");
      return
        }
      try {
        dispatch(updateVerticals({ id:data?.id,isActive:active }));
        setLoading(false);
        navigate(0);
        setLoading(false);
      } catch (err) {
        // console.log(err);
        toast.error("Failed to update vertical");
      } finally {
        setLoading(false);
      }
    };


    const closeModal = () => {
      setShowModal(false);
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
};
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h6">*Please fill the basic form,inspection form and settings to make vertical available in the app</BlockTitle>
                 
          </BlockHeadContent>

        </BlockBetween>
      </BlockHead>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Verticals Information</BlockTitle>
            <BlockDes>
              <p>Basic info</p>
            </BlockDes>            
          </BlockHeadContent>
        {!data?.isActive &&
        
          <Button className="toggle d-none d-md-inline-flex" outline color="secondary" onClick={(ev) => {           
            ev.preventDefault();
              // handleUpdateVertical(ev) ;  
              setActive(true);
              setShowModal(true);      
            }}>
            <span>
            {loading ? <Spinner size="sm" /> : "Make Active"}
            </span>
          </Button>
}
{data?.isActive &&
          <Button className="toggle d-none d-md-inline-flex" outline color="secondary" onClick={(ev) => {           
            ev.preventDefault();
              // handleUpdateVertical(ev) ;  
              setActive(false);
              setShowModal(true);      
            }}>
            <span>
            {loading ? <Spinner size="sm" /> : "Make Inactive"}
            </span>
          </Button>
}
          
          <Button className="toggle d-none d-md-inline-flex" outline color="secondary" onClick={(ev) => {
              ev.preventDefault();
              toggleUpdateProfileModal();
              
            }}  >
            <span>Edit</span>
          </Button>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button className={`toggle btn btn-icon btn-trigger mt-n1  active`} onClick={() => {}}>
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item" onClick={() => {}}>
            <div className="data-col">
              <span className="data-label">Name</span>
              <span className="data-value">{data?.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => {}}>
            <div className="data-col">
              <span className="data-label">Description</span>
              <span className="data-value">{data?.description}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more ">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => {}}>
            <div className="data-col">
              <span className="data-label">Basic Form Added</span>
              <span className="data-value">{data?.baseformsettings ? "Yes" : "No"}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more ">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => {}}>
            <div className="data-col">
              <span className="data-label">Simplified Inspection Form Added</span>
              <span className="data-value">{data?.simplifiedinspectionformsettings ? "Yes" : "No"}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => {}}>
            <div className="data-col">
              <span className="data-label">Advanced Inspection Form Added</span>
              <span className="data-value">{data?.advancedinspectionformsettings ? "Yes" : "No"}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => {}}>
            <div className="data-col">
              <span className="data-label">Active</span>
              <span className="data-value">{data?.isActive ? "Yes" : "No"}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
        </div>
            {/* ****** Modal Start ****** */}
            {showModal ? (                   
            <ConfirmModal
              msg={"make this vertical "}
              name={active?"Active":"Inactive"}
              open={showModal}
              loading={loading}
              handleClick={handleModal}
              handleConfirm={handleUpdateVertical}
              handlecloseModal={closeModal}
            />
          ) : null}
          {/* ****** Modal End ****** */}
        {updateProfileModal &&<EditVerticalModal isOpen={updateProfileModal} toggle={toggleUpdateProfileModal} vertical={data}/>}

      </Block>

    </React.Fragment>
  );
}
