import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { fetchVerticalDetails } from "./verticalDetailsSlice";

const initialState = {
  data: {},
  status: "init",
  error: null,
};
const updateVerticals = createAsyncThunk("updateVerticals", (data, { dispatch }) => {
  let url;

  url = `/admin/vertical/update/${data.id}`;
  return axios.put(url, data).then((response) => {
    dispatch(fetchVerticalDetails(data.vertical_id));
    return response.data;
  });
});

const updateVerticalsSlice = createSlice({
  name: "updateVerticalsSlice",
  initialState,
  reducers: {
    resetUpdateVerticals: (state, action) => {
      state.data = null;
      state.status = "init";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateVerticals.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(updateVerticals.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      state.data = action.payload.data;
    });
    builder.addCase(updateVerticals.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
  },
});

export default updateVerticalsSlice.reducer;
export const { resetUpdateVerticals } = updateVerticalsSlice.actions;
export { updateVerticals };
