const name = {
  label: "Name",
  value: "name",
  type: "string",
  placeholder: "field key stored in database",
};

const label = {
  label: "Label",
  value: "label",
  type: "string",
  placeholder: "field label in UI",
};
const unit = {
  label: "Unit",
  value: "unit",
  type: "string",
  placeholder: "Unit",
};
const labelAr = {
  label: "Arabic Label",
  value: "labelAr",
  type: "string",
  placeholder: "Arabic field label in UI",
};
const radioOptions = {
  label: "Radio Options",
  value: "radioOptions",
  type: "object",
  placeholder: "",
};
const description = {
  label: "Description",
  value: "description",
  type: "string",
  placeholder: "field description in UI",
};
const diagramImage = {
  label: "Diagram Image",
  value: "diagram",
  type: "diagramImage",
  placeholder: "diagram for marking deffects in UI",
};
const isRequired = {
  label: "Is Required",
  value: "isRequired",
  type: "boolean",
  placeholder: "is the field mandatory to fill",
};
const isHidden = {
  label: "Is Hidden",
  value: "isHidden",
  type: "boolean",
  placeholder: "hide the field or not",
};
const isFilterable = {
  label: "Is Filterable",
  value: "isFilterable",
  type: "boolean",
  placeholder: "is the field is filterable",
};
const filterType = {
  label: "Filter Type",
  value: 'filterType',
  type: "dropdown",
  options: ['single', 'multi', 'range'],
  placeholder: "filter type",
};
const isColorCoded = {
  label: "Is Color Coded",
  value: "isColorCoded",
  type: "boolean",
  placeholder: "Does the field have color code?",
};
const maxChar = {
  label: "Maximum Characters",
  value: "maxChar",
  type: "string",
  placeholder: "Maximum Characters",
};
const from = {
  label: "From",
  value: "from",
  type: "string",
  placeholder: "From",
};
const to = {
  label: "To",
  value: "to",
  type: "string",
  placeholder: "To",
};
const options = {
  label: "Options",
  value: "options",
  type: "array",
  placeholder: "Options values seperated by commas",
};
const tableKey = {
  label: "Table key (Please add as {verticalname}_primary or {verticalname}_secondary)",
  value: "key",
  type: "string",
  placeholder: "vertical_primary",
};
const hasReference = {
  label: "Has Reference",
  value: "hasReference",
  type: "boolean",
  placeholder: "Does the field has any refernce",
};
const referenceTableKey = {
  label: "Reference Table key",
  value: "referenceKey",
  type: "string",
  placeholder: "{verticalname}_primary",
};
const firstDate = {
  label: "First Date",
  value: "firstDate",
  type: "dropdown",
  placeholder: "Select the least selectable day from the selection date",
  options: ["Today", "Last Week", "Last Month", "Last 6 Months", "Last Year", "From 2000", "From 1920"],
};

const lastDate = {
  label: "Last Date",
  value: "lastDate",
  type: "dropdown",
  placeholder: "Select the least selectable day from the selection date",
  options: [
    "Today",
    "Next Week",
    "Next Month",
    "Next 6 Months",
    "Next Year",
    "Next 2 Year",
    "Next 5 Year",
    "Next 10 Year",
  ],
};

const extensions = {
  label: "Extensions",
  value: "extensions",
  type: "multiselect",
  placeholder: "Select the extensions accepted",
  options: ["pdf", "docx", "csv", "jpg", "png"],
};

export const fieldTypes = [
  {
    type: "text",
    fieldLabel: "Text Field",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired, maxChar,],
  },
  {
    type: "dropdown",
    fieldLabel: "Drop Down",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired, options, isFilterable, filterType],
  },
  {
    type: "switch",
    fieldLabel: "Switch",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired, options],
  },
  {
    type: "radio",
    fieldLabel: "Radio",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired, isColorCoded, options, radioOptions],
  },
  {
    type: "date",
    fieldLabel: "Date Field",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired, firstDate, lastDate],
  },
  // {
  //   type: "dateRange",
  //   fieldLabel: "Date Range Field",
  //   icon: "",
  //   requiredFields: [name, label,labelAr, isRequired, firstDate, lastDate],
  // },
  {
    type: "image",
    fieldLabel: "Image Upload",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired],
  },
  {
    type: "multipleImages",
    fieldLabel: "Multiple Image Upload",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired, isHidden],
  },
  // {
  //   type: "engineSize",
  //   fieldLabel: "Engine Size",
  //   icon: "",
  //   requiredFields: [name, label,labelAr, isRequired],
  // },
  {
    type: "multiLineText",
    fieldLabel: "Multi Line Text",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired],
  },
  {
    type: "color",
    fieldLabel: "Color",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired],
  },
  {
    type: "document",
    fieldLabel: "Document upload",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired, extensions],
  },
  {
    type: "checkbox",
    fieldLabel: "CheckBox",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired],
  },
  {
    type: "number",
    fieldLabel: "Number",
    icon: "",
    requiredFields: [name, label, labelAr, from, to, isRequired, isFilterable, filterType],
  },
  {
    type: "distanceField",
    fieldLabel: "Distance Field",
    icon: "",
    requiredFields: [name, label, unit, labelAr, from, to, isRequired, isFilterable, filterType],
  },
  {
    type: "2dDiagram",
    fieldLabel: "2d Diagram",
    icon: "",
    requiredFields: [name, label, labelAr, description, diagramImage, isRequired],
  },

  {
    type: "imageDiagram",
    fieldLabel: "Image Diagram",
    icon: "",
    requiredFields: [name, label, labelAr, description, isRequired],
  },
  {
    type: "autocomplete",
    fieldLabel: "Auto Complete",
    icon: "",
    requiredFields: [name, label, labelAr, isRequired, tableKey, hasReference, referenceTableKey, isFilterable, filterType],
  },
];
