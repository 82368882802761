import React from "react";
import PropTypes from "prop-types";
import {
    Block,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    PaginationComponent,
    PreviewAltCard,
} from "../../components/Component";
import { Badge, Spinner } from "reactstrap";
import { getColor } from "../../utils/getColor";
import { Paper, Box, TableCell, TableRow } from "@mui/material";

const TableComponent = ({
    data = [],
    columns = [],
    totalCount = 0,
    perPage = 10,
    currentPage = 1,
    onPageChange = () => { },
    onSortChange = () => { },
    loading = false,
    sortByField = "",
    sortByValue = 1,
    onRowClick = () => { },
    onSelectAll = () => { },
    onSelectRow = () => { },
    selectedRows = [],
    selectable = true
}) => {
    // Handle sorting logic and trigger API call
    const handleSort = (field) => {
        if (!field) return; // Ensure field is valid
        const newSortDirection = sortByField === field ? sortByValue * -1 : 1;
        if (typeof onSortChange === "function") {
            onSortChange(field, newSortDirection); // Trigger parent function to call API
        }
    };

    // Check if all rows are selected
    const allRowsSelected = data?.length > 0 && selectedRows?.length === data?.length;

    return (
        <Block>
            <div className="nk-tb-list is-separate is-medium mb-3">
                {data?.length > 0 && !loading ? (
                    <>
                        <DataTableHead className="nk-tb-item">
                            {selectable && <DataTableRow className="nk-tb-col-check ">
                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="select-all"
                                        checked={allRowsSelected}
                                        onChange={(e) => onSelectAll?.(e.currentTarget.checked)}
                                    />
                                    <label className="custom-control-label" htmlFor="select-all"></label>
                                </div>
                            </DataTableRow>}

                            {columns.map((col) => (
                                <DataTableRow
                                    key={col.field}
                                    size={col.size || "sm"}
                                    onClick={() => col.sortable && handleSort(col.field)}
                                    style={{ cursor: col.sortable ? "pointer" : "default" }}
                                >
                                    <span>{col.label}</span>
                                    {col.sortable && sortByField === col.field && (
                                        <span>{sortByValue === 1 ? " 🔼" : " 🔽"}</span>
                                    )}
                                </DataTableRow>
                            ))}
                        </DataTableHead>

                        {
                            data.map((item, index) => (
                                <DataTableItem key={item?.id || index}>
                                    {selectable && <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={`select-${index}`}
                                                checked={selectedRows.includes(item?.id)}
                                                onChange={(e) => onSelectRow?.(item?.id, e.currentTarget.checked)}
                                            />
                                            <label className="custom-control-label" htmlFor={`select-${index}`}></label>
                                        </div>
                                    </DataTableRow>}

                                    {columns.map((col) => (
                                        <DataTableRow key={col.field}>
                                            {col.render ? col.render(item) : item?.[col.field] || "N/A"}
                                        </DataTableRow>
                                    ))}
                                </DataTableItem>
                            ))
                        }
                    </>
                ) : (
                    loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px', width: '100%' }}>

                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
                                <Spinner color="primary" />
                            </Box>
                        </div>
                    ) :
                        // Rendering a different layout when there are no items
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px', width: '100%' }}>
                            <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                                No Items Found
                            </Paper>
                        </div>
                )}
            </div>

            <PreviewAltCard>
                <PaginationComponent
                    itemPerPage={perPage}
                    totalItems={totalCount}
                    paginate={onPageChange}
                    currentPage={currentPage}
                />
            </PreviewAltCard>
        </Block>
    );
};

// Define PropTypes for validation and safety
TableComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            size: PropTypes.string,
            sortable: PropTypes.bool,
            render: PropTypes.func,
        })
    ),
    totalCount: PropTypes.number,
    perPage: PropTypes.number,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
    loading: PropTypes.bool,
    sortByField: PropTypes.string,
    sortByValue: PropTypes.number,
    onRowClick: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectRow: PropTypes.func,
    selectedRows: PropTypes.arrayOf(PropTypes.any),
};

// Define default props to ensure robustness
TableComponent.defaultProps = {
    data: [],
    columns: [],
    totalCount: 0,
    perPage: 10,
    currentPage: 1,
    onPageChange: () => { },
    onSortChange: () => { },
    loading: false,
    sortByField: "",
    sortByValue: 1,
    onRowClick: () => { },
    onSelectAll: () => { },
    onSelectRow: () => { },
    selectedRows: [],
};

export default TableComponent;
