import React, { useState } from 'react';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import { Icon, RSelect } from '../../../components/Component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';

import { rejectUserDocuments } from '../../../redux/features/usersSlice/userDetails';

const RejectModal = ({ isOpen, toggle, userId }) => {
  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState(null);
  const [rejectLoading, setRejectLoading] = useState(null);
  const dispatch = useDispatch();

  let options = [
    'Expired Document',
    'Invalid Document Type',
    'Inaccurate Information',
    'Forgery or Tampering',
    'Low Image Quality',
    'Document Damage',
    'Missing or Incomplete Information',
    'Duplicity',
    'Security Concerns',
    'Suspected Fraud',
    'Unverified Identity',
    'Other'
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!reason) {
      toast.error('Plese enter the reason');
      return;
    } 
    else if(reason==='Other'&&!otherReason){
      toast.error('Plese type the reason');
      return;
    }
    else {
      setRejectLoading(true);
      dispatch(
        rejectUserDocuments({
          userId: userId,
          reason: reason!=='Other'?reason:otherReason
        })
      ).then(() => setRejectLoading(false));
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader
        toggle={toggle}
        close={
          <button
            className="close"
            onClick={(ev) => {
              ev.preventDefault();
              toggle();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        Reject
      </ModalHeader>
      <ModalBody>
        <form id="add-vertical-form" onSubmit={(e) => null}>
          <Row className="g-3">
            <Col sm="12">
              <label style={{ width: '100%' }}>
                Reason
                <RSelect
                  className=""
                  style={{ width: '100%' }}
                  options={options.map((e) => ({ label: e, value: e }))}
                  onChange={(e) => setReason(e.value)}
                />
                {reason==='Other'&&
                <Col sm="12">
                <Label>Enter reason</Label>
                <Input
                  autoComplete="off"
                  name="name"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                  placeholder="Enter reason"
                ></Input>
              </Col>
}
              </label>
            </Col>
          </Row>
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
              <li>
                <Button color="primary" type="submit" onClick={handleSubmit}>
                  {!rejectLoading ? (
                    <>Reject</>
                  ) : (
                    <Spinner size="sm" color="light" />
                  )}
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default RejectModal;
