import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { Icon } from "../Component";

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <Button color="light" outline className="bg-white d-none d-sm-inline-flex mb-2" onClick={() => navigate(-1)}>
      <Icon name="arrow-left"></Icon>
      <span>Back</span>
    </Button>
  );
}
