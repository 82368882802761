import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
  Col,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Badge,
  Spinner,
  ButtonGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearAuctions,
  debouncedFetchAuctions,
  fetchAuctions,
  setAllSelected,
  setSelected,
} from "../../redux/features/auctionSlice/auctionSlice";
import msToTime from "../../utils/msToTime";
import formatDate from "../../utils/dateFormat";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { startCase } from "lodash";
import { getAuctionStatus, getAuctionStatusBgColor, getAuctionStatusDotColor, getColor } from "../../utils/getColor";
import TableHeader from "../../components/table/TableHeader";
import DateFilter from "../../components/date-filter/DateFilter";
import { TimerText } from "../../components/timer/timer";
import ListBlockHeader from "../../components/list-block-header/ListBlockHeader";
import { auctionStatus } from "../../utils/Utils";
import { fetchVerticalDetails } from "../../redux/features/verticalsSlice/verticalDetailsSlice";
import ReactDatePicker from "react-datepicker";

const AuctionsData = () => {
  const { verticalName } = useParams();

  const [listData, setData] = useState([]);
  const [smOption, setSmOption] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    firstName: "",
    lastName: "",
    date: new Date(),
    status: "",
    customer: "",
    purchased: "",
    total: "",
    check: false,
  });
  const [view, setView] = useState({
    add: false,
    details: false,
  });
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [sortByField, setSortByField] = useState("createdAt");
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);
  
  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    }
  }, [rangeEnd, rangeStart]);
  const setRangeStart = (e) => {
    setStart(e);

  };
  const setRangeEnd = (e) => {
    setEnd(e);

  };
  let clearBadge = status|| onSearchText!==''||rangeEnd ||rangeStart||type;
  // Extract selectedVertical from the Redux store
  const selectedVertical = useSelector((state) => state.verticalDetails?.data);
  const [searchParams] = useSearchParams();
  let v_id=searchParams.get("v_id")

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchVerticalDetails(v_id));
  }, [v_id]);
  // Changing state value when searching name
  useEffect(() => {
    // let data = { page: currentPage, status, sortBy: { [sortByField]: sortByValue } };
    if(selectedVertical){
    let data = {
      'query': {'vertical_id': selectedVertical?.id},
    'options': {
      'page': currentPage,
      'limit': perPage,
      "sort":"status",
    }}
    if (status ) {
      setCurrentPage(1);
      data.query.status = [status];
    }
    if (type ) {
      setCurrentPage(1);
      data.query.type = type;
    }
    if (dateFilter) {
      data.query.date= dateFilter;
    }
    if (onSearchText !== "") {
      data.query.search = onSearchText.trim();
      dispatch(debouncedFetchAuctions(data));
    } else {
      dispatch(fetchAuctions(data));
    }
  }
  }, [onSearchText.trim(), currentPage, status, sortByValue,type, dateFilter,selectedVertical]);

  useEffect(() => {
    return () => {
      dispatch(clearAuctions());
    };
  }, [dispatch]);

  const { data, totalCount, loading,perPage } = useSelector((state) => state.auctions);
  const [itemPerPage] = useState(perPage);
  useEffect(() => {
    if(data){
    setData(data.data);
    }
  }, [data]);

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, handleSubmit } = useForm();

  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  return (
    <React.Fragment>
      <Head title={`${verticalName} Auctions List`}></Head>
      <Content>
        <ListBlockHeader msg={totalCount?`Total auctions:  ${totalCount}`:""} title={`${verticalName} Auctions`} placeholder="Search title" onSearchText={onSearchText} onSearchChanged={onFilterChange} onDateChanged={setDateFilter}>
        <div style={{ flexShrink: 0, flexGrow: 1 }}>
      <div className="form-control-wrap">
        <div className="input-daterange date-picker-range input-group">
          <ReactDatePicker
            selected={rangeStart}
            onChange={setRangeStart}
            selectsStart
            startDate={rangeStart}
            endDate={rangeEnd}
            maxDate={rangeEnd ?? new Date()}
            wrapperClassName="start-m"
            className="form-control"
          />{" "}
          <div className="input-group-addon">TO</div>
          <ReactDatePicker
            selected={rangeEnd}
            onChange={setRangeEnd}
            startDate={rangeStart}
            endDate={rangeEnd}
            selectsEnd
            minDate={rangeStart}
            maxDate={new Date()}
            wrapperClassName="end-m"
            className="form-control"
          />
        </div>
      </div>
    </div>
          <ButtonGroup>
            <Button color="secondary" outline onClick={() => setType("CONCIERGE")} active={type === "CONCIERGE"}>
            CONCIERGE
            </Button>
            <Button color="secondary" outline onClick={() => setType("PUBLIC")} active={type === "PUBLIC"}>
             PUBLIC
            </Button>
          </ButtonGroup>
          <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
              {status !== "" ? getAuctionStatus(status): "Status"}
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setStatus("")}>
                    <span>ALL</span>
                  </DropdownItem>
                </li>
                {Object.keys(auctionStatus)?.map((key)=> 
                <li key={key}>              
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setStatus(auctionStatus[key])}>
                  <span>{key}</span>
                  </DropdownItem>
                </li>
                )}
                {/* <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setStatus("pending")}>
                    <span>Pending</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setStatus("Sold")}>
                    <span>Sold</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setStatus("Unsold")}>
                    <span>Unsold</span>
                  </DropdownItem>
                </li> */}
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* <Button
            color="primary"
            onClick={() => {
              toggle("add");
            }}
          >
            <Icon name="plus"></Icon>
            <span>Add User</span>
          </Button> */}
        </ListBlockHeader>
        <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
          {clearBadge && (
              <Badge className="selected-option bg-primary me-1" color="danger">
                {'Clear All'}
                <Icon
                  name="cross-sm"
                  className="ms-1"
                  onClick={() => {
                   setSearchText('');
                   setRangeEnd(null);
                   setRangeStart(null);
                   setDateFilter(null);
                   setType("");
                   setStatus("")

                  }}
                />
              </Badge>
            )}
            </Col>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item ">
              <DataTableRow className="nk-tb-col-check ">
                <div className="custom-control custom-control-sm custom-checkbox notext ">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                  />
                  <label className="custom-control-label" htmlFor="pid-all"></label>
                </div>
              </DataTableRow>
              <DataTableRow>
              <span className="sub-text">Title</span>
                {/* <TableHeader name="title" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} /> */}
              </DataTableRow>
              <DataTableRow>
              <span className="sub-text">User</span>              
              </DataTableRow>

              <DataTableRow size="md">
                <TableHeader name="startTime" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader name="duration" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>

              <DataTableRow size="md">
              <span className="sub-text">Initial Price (AED)</span>
                {/* <TableHeader
                  name="initialPrice"
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                /> */}
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader name="bidCount" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Status</span>
              </DataTableRow>

              <DataTableRow className="nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1 my-n1">
                  {/* <li>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="btn btn-trigger dropdown-toggle btn-icon me-n1">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#markasdone"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="truck"></Icon>
                              <span>Mark As Delivered</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#remove"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="trash"></Icon>
                              <span>Remove Orders</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li> */}
                </ul>
              </DataTableRow>
            </DataTableHead>

            {listData?.length > 0
              ? listData.map((item, index) => (
                  <DataTableItem key={item._id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={item.check}
                          id={item._id + "oId-all"}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, index)}
                        />
                        <label className="custom-control-label" htmlFor={item.id + "oId-all"}></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <Link to={`/v/${selectedVertical?.name}/auctions/${item.id}`} replace={false}>{startCase(item?.baseform?.title)}</Link>
                    </DataTableRow>
                    <DataTableRow>
                   {startCase(item?.user?.full_name)}
                    </DataTableRow>
                    <DataTableRow>{formatDate(new Date(item.start_time))}</DataTableRow>
                    <DataTableRow>
                      {item.status === auctionStatus.LIVE ? (
                        <TimerText
                          startTime={item.start_time}
                          duration={item.duration}
                          onTimerEnd={() => {
                            console.log("Timer ended");
                          }}
                        />
                      ) : (
                        msToTime(item.duration)
                      )}
                    </DataTableRow>
                    <DataTableRow>{item.initial_price?.toLocaleString('en-US')}</DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.bid_count}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className={`dot bg-${getAuctionStatusDotColor(item.status)} d-sm-none`}></span>
                      <Badge
                        className={`badge-sm badge-dot has-bg d-none d-sm-inline-flex ${
                          item.status === auctionStatus.LIVE ? "live" : ""
                        }`}
                        color={getAuctionStatusBgColor(item.status)}
                      >
                        {startCase(getAuctionStatus(item.status))}
                      </Badge>
                    </DataTableRow>

                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li
                          className="nk-tb-action-hidden"
                          onClick={() => {
                            toggle("details");
                          }}
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={"view" + item._id}
                            icon="eye"
                            direction="top"
                            text="View Details"
                          />
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                              <li key={index + Math.random()}>
                                      <a
                                        href={`/v/${selectedVertical?.name}/auctions/${item.id}`} 
                                      >
                                        <Icon name="eye"></Icon>
                                        <span>View Details</span>
                                      </a>
                                    </li>
                                {/* <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Delete User</span>
                                  </DropdownItem>
                                </li> */}
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
          {listData?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No Auctions found</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AuctionsData;
