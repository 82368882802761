
import React, { useEffect, useState } from 'react';
import {
  Paper
} from '@mui/material';
import Content from '../../layout/content/Content';
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle
} from '../../components/Component';
import { Badge, Spinner } from 'reactstrap';
import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchAppSettings, resetAppSettingsUpdate, updateAppSettings } from '../../redux/features/settingsSlice/SettingsSlice';
import { toast } from 'react-toastify';
import BannerSection2 from './components/BannerSection2';
import BannerSection1 from './components/BannerSection1';


const SettingsPage = () => {
  const dispatch = useDispatch();
  const {data,updateStatus} = useSelector((state) => state.settingsSlice);

  useEffect(() => {
    dispatch(fetchAppSettings()); // Fetch dealer details on mount
  }, [dispatch]);
  const [editingSections, setEditingSections] = useState({
    bannerSection1: false,
    bannerSection2: false,

  });

  useEffect(()=>{
    if(updateStatus === 'success'){
        toast.success('Successfully updated data')
        dispatch(resetAppSettingsUpdate());
        setEditingSections({
            images: false,
            location: false,
            account: false
          });
    }else if(updateStatus === 'error'){
        toast.error('Failed to update data')
        dispatch(resetAppSettingsUpdate());
    }
  },[updateStatus])



  const onSubmitImages = (data) => {
    dispatch(updateAppSettings({ ...data, }));
  };
  



  const toggleEdit = (sectionName) => {
    setEditingSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName]
    }));
  };
  if(data == null){
    return <Spinner/>
  }

  return (
    <React.Fragment>
      <Content>
        <Paper sx={{ padding: 4 }}>
          <div style={{ padding: '16px' }}>
            <BlockHead size="lg">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="h4">App Settings</BlockTitle>
                  <BlockDes>
                    <p>Basic information</p>
                    <Badge>{startCase(data?.name)}</Badge>
                  </BlockDes>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <BannerSection1 data={data} onSubmit={onSubmitImages} toggleEdit={toggleEdit} editMode={editingSections['bannerSection1']}/>
            <BannerSection2 data={data} onSubmit={onSubmitImages} toggleEdit={toggleEdit} editMode={editingSections['bannerSection2']}/>
          </div>
        </Paper>
      </Content>
    </React.Fragment>
  );
};

export default SettingsPage;
