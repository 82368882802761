import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { logout } from "../../../../redux/features/authSlice/authSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const User = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const {full_name,email, id, roles } = useSelector(
    ( state ) => state?.auth?.loggedInUser
);

  const handleSignout = async(e) => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <Dropdown isOpen={ open } className="user-dropdown" toggle={ toggle }>
    <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={ ( ev ) =>
        {
            ev.preventDefault();
        } }
    >
        <div className="user-toggle">
            <UserAvatar icon="user-alt" className="sm" />
            <div className="user-info d-none d-md-block">
                <div className="user-status">{ roles?.length>0?roles[0]?.name :"Admin" }</div>
                <div className="user-name dropdown-indicator">
                { full_name}
                </div>
            </div>
        </div>
    </DropdownToggle>
    <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
            <div className="user-card sm">
                <div className="user-avatar">
                    <span>AB</span>
                </div>
                <div className="user-info">
                    <span className="lead-text">
                    { full_name }
                    </span>
                    <span className="sub-text">{ email }</span>
                </div>
            </div>
        </div>
        {/* <div className="dropdown-inner">
            <LinkList>
                <LinkItem
                    link={ `/user-profile/${ id }/user-profile` }
                    icon="user-alt"
                    onClick={ 
                        (e)=>e.preventDefault()
                     }
                >
                    View Profile
                </LinkItem>
            </LinkList>
        </div> */}
        {/* <div className="dropdown-inner">
            <LinkList>
                <a href={ `/change-password` }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              >
                    <Icon name="account-setting-alt"></Icon>
                    <span>Change Password</span>
                </a>
            </LinkList>
        </div> */}
        <div className="dropdown-inner">
        <LinkList>
        <a href="#" onClick={(e) => handleSignout(e)}>
                <Icon name="signout"></Icon>
                <span>Sign Out</span>
            </a>
        </LinkList>
        </div>
    </DropdownMenu>
</Dropdown>
  );
};

export default User;
