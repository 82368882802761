import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
} from "../../components/Component";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Button, Badge, Spinner, Col, Modal, ModalHeader, ModalBody } from "reactstrap";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearItems, debouncedFetchCars, fetchCars, setAllSelected, setSelected } from "../../redux/features/itemsSlice/itemsSlice";
import { random, startCase } from "lodash";
import { getColor } from "../../utils/getColor";
import { Link, useParams, useSearchParams ,useNavigate} from "react-router-dom";
import TableHeader from "../../components/table/TableHeader";
import DateFilter from "../../components/date-filter/DateFilter";
import ListBlockHeader from "../../components/list-block-header/ListBlockHeader";
import { fetchVerticalDetails } from "../../redux/features/verticalsSlice/verticalDetailsSlice";
import { carStatus } from "../../utils/Utils";
import ReactDatePicker from "react-datepicker";


const AllItems = () => {
  const { verticalName } = useParams();
  const navigate = useNavigate();

  const [listData, setListData] = useState([]);
  const [smOption, setSmOption] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    firstName: "",
    lastName: "",
    date: new Date(),
    status: "",
    customer: "",
    purchased: "",
    total: "",
    check: false,
  });
  const [view, setView] = useState({
    add: false,
    details: false,
  });
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [sortByField, setSortByField] = useState("createdAt");
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);
  const [headers, setHeaders] = useState({});
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);

  const user = useSelector((state) => state.userDetails.data);


  const verticals = useSelector((state) => state.verticals);



  const dateOptions = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
const timeOptions = { hour: '2-digit', minute: '2-digit' };

  const verticalItemGet = localStorage.getItem('verticalItemGetValue') || '';


  useEffect(() => {
    let v_id=searchParams.get("v_id")
    dispatch(fetchVerticalDetails(v_id));
  }, [searchParams]);
  const selectedVertical = useSelector((state) => state.verticalDetails?.data);
  const { data, totalCount, loading,perPage } = useSelector((state) => state.items);
  const [itemPerPage] = useState(perPage);

  const toggleModal = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();
  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  const [vertical, setVertical ]= useState("");
  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    }
  }, [rangeEnd, rangeStart]);
  const setRangeStart = (e) => {
    setStart(e);

  };
  const setRangeEnd = (e) => {
    setEnd(e);

  };

  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    }
  }, [rangeEnd, rangeStart]);



  // Changing state value when searching name
  useEffect(() => {
    let data = {
      'query': {},
      
      "options":{
        page: currentPage,
        limit:perPage,
        // sort:"-createdAt",
        sort: sortByValue === 1 ? sortByField:'-'+sortByField,
        "populate": [
          {
              "path": "user_id",
              "select": "full_name"
          }
      ],

      }
      //  page: currentPage, status, sortBy: { [sortByField]: sortByValue }
       };

    if (vertical ) {
        data.query.vertical_id = vertical.id;
      }
      else if (verticalItemGet) {      
        data.query.vertical_id =JSON.parse( verticalItemGet).id;
      }

    if (user?.id) {
      data.query.user =user?.id;
    }

    if (status ) {
      setCurrentPage(1);
      data.query.status = status;
    }
  if (dateFilter) {
    data.query.date= dateFilter;
  }
  if (onSearchText !== "") {
    data.query.search= onSearchText.trim();
    dispatch(debouncedFetchCars(data));
  } else {
    dispatch(fetchCars(data));
  }
    
  
  }, [onSearchText.trim(), currentPage, status, sortByValue,dateFilter,vertical,user,verticalItemGet]);

  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  useEffect(() => {
    if(data){
    setListData(data);
    }
  }, [data]);

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let clearBadge = status|| onSearchText!==''||rangeEnd ||rangeStart||vertical||verticalItemGet;


  return (
    <React.Fragment>
      <Head title={`Inventory`}></Head>
      <Content>
      <ListBlockHeader msg={totalCount?`Total items:  ${totalCount}`:""} title="All Inventory" placeholder="Search title" onSearchText={onSearchText} onSearchChanged={onFilterChange} onDateChanged={setDateFilter}>
        {!user?.id &&
        <div style={{ flexShrink: 0, flexGrow: 1 }}>
      <div className="form-control-wrap">
        <div className="input-daterange date-picker-range input-group">
          <ReactDatePicker
            selected={rangeStart}
            onChange={setRangeStart}
            selectsStart
            startDate={rangeStart}
            endDate={rangeEnd}
            maxDate={rangeEnd ?? new Date()}
            wrapperClassName="start-m"
            className="form-control"
          />{" "}
          <div className="input-group-addon">TO</div>
          <ReactDatePicker
            selected={rangeEnd}
            onChange={setRangeEnd}
            startDate={rangeStart}
            endDate={rangeEnd}
            selectsEnd
            minDate={rangeStart}
            maxDate={new Date()}
            wrapperClassName="end-m"
            className="form-control"
          />
        </div>
      </div>
    </div>}

    <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
              {vertical !== "" ? startCase(vertical?.name) : "Vertical"}
            </DropdownToggle>
  
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" 
                  onClick={(ev) => 
                   { 
                    setVertical("");
                    localStorage.removeItem('verticalItemGetValue');
                  }
                  }>
                    <span>ALL</span>
                  </DropdownItem>
                </li>
                {verticals?.data?.filter((vertical) => vertical?.isActive).map((vertical)=> 
                <li key={vertical.id}>              
                  <DropdownItem tag="a" href="#dropdownitem" 
                  onClick={(ev) => 
                 {
                   setVertical(vertical)
                  const selectedOptionString = JSON.stringify( vertical );
                  localStorage.setItem( "verticalItemGetValue", selectedOptionString );
                }
                  }>
                    <span>{vertical?.name}</span>
                  </DropdownItem>
                </li>
                )}

              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
          
          {verticalName==="Cars" &&
          <Button color="primary" 
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          >
            <Icon name="plus"></Icon>
            <span>Add Item</span>
          </Button>
}
        </ListBlockHeader>

        <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
        {verticalItemGet && (
              <div className="selected-options d-flex flex-wrap">
                <Badge
                  className="selected-option bg-primary me-1"
                  color="primary"
                >
                { ( JSON.parse( verticalItemGet ).name ) }
                  <Icon
                    name="cross-sm"
                    className="ms-1"
                    onClick={() => {
                      setVertical("");
                      localStorage.removeItem('verticalItemGetValue');
                    }}
                  />
                </Badge>
              </div>
            )}
          {clearBadge && (
              <Badge className="selected-option bg-primary me-1" color="danger">
                {'Clear All'}
                <Icon
                  name="cross-sm"
                  className="ms-1"
                  onClick={() => {
                    setSearchText('');
                    setRangeEnd(null);
                    setRangeStart(null);
                    setDateFilter(null);
                    setStatus("")
                    setVertical("");

                    localStorage.removeItem('verticalItemGetValue')
                   }}
                />
              </Badge>
            )}
            </Col>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                  />
                  <label className="custom-control-label" htmlFor="pid-all"></label>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
            <span>ID</span>
          </DataTableRow>
              <DataTableRow>
                <TableHeader
                customLabel={"Title"}
                  name={'title'}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>
              <DataTableRow size="sm">
            <span>User</span>
          </DataTableRow>
     
           
       
   
              <DataTableRow>
              <TableHeader
                 name={'status'}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
                {/* <span className="sub-text">Status</span> */}
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader name="createdAt" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1 my-n1">
                  <li>

                  </li>
                </ul>
              </DataTableRow>
            </DataTableHead>

            {listData?.length > 0
              ? listData.map((item, index) => (
                  <DataTableItem key={item.id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={item.check}
                          id={item.id + "oId-all"}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, index)}
                        />
                        <label className="custom-control-label" htmlFor={item.id + "oId-all"}></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <Link to={`/v/${verticalName}/items/${item.id}`}>
                        {item?.baseform_id}
                      </Link>
                    </DataTableRow>
                    <DataTableRow>
                      <Link to={`/v/${verticalName}/items/${item.id}`}>
                        {item?.title}
                      </Link>
                    </DataTableRow>
                    <DataTableRow>
                     {startCase(item?.user_id?.full_name)}
                    </DataTableRow>
                   
                    <DataTableRow>
                      <span className={`dot bg-${getColor(item?.status)} d-sm-none`}></span>
                      <Badge
                        className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                        color={getColor(item?.status)}
                      >
                        {startCase(item?.status)}
                      </Badge>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>
                      {item?.createdAt &&new Date(item?.createdAt).toLocaleDateString(undefined, dateOptions)} {new Date(item?.createdAt).toLocaleTimeString(undefined, timeOptions)}           

                        </span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li
                          className="nk-tb-action-hidden"
                          onClick={() => {
                            toggle("details");
                            navigate(`/v/${verticalName}/items/${item.id}`);
                            window.scrollTo( {
                              top: 0,
                              behavior: 'smooth'
                          }
                            )}}
                           
                          
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={"view" + item._id}
                            icon="eye"
                            direction="top"
                            text="View Details"
                          />
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();

                                      toggle("details");
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>View Details</span>
                                  </DropdownItem>
                                </li>
                                {item.active === true && (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdown"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="truck"></Icon>
                                      <span>Approve</span>
                                    </DropdownItem>
                                  </li>
                                )}

                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {listData?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No items found</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>

      </Content>
    </React.Fragment>
  );
};

export default AllItems;