import { Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Col, ModalBody, Row } from "reactstrap";
import { RSelect } from "../../../components/Component";
import { toast } from "react-toastify";
import { fetchRM } from "../../../redux/features/usersSlice/rmSlice";
import { fetchUsers } from "../../../redux/features/usersSlice/usersSlice";
import { updateUserDetails } from "../../../redux/features/usersSlice/userDetails";

export const AssignRM = ({
    toggle,
    user
  }) => {
    const [optRM, setOptRM] = useState([]);
    const [selectedRM, setSelectedRM] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [agentGet, setRM] = useState(undefined);
    const [saveLoading, setSaveLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchRM());
    }, []);
  
    const { data } = useSelector((state) => state?.rm);
  

    useEffect(() => {
      let options = [];
      let result =
      data &&
      data.length > 0 &&
      data
          .map((a) =>
            options.push({
              value: a.id,
              label: a?.full_name
            })
          );
      setOptRM(options);
    }, [data]);
  
    if (!selectedRM) {
      setSelectedRM(optRM.length > 0 ? optRM[0].value : null);
    }
  
    const validate = (selectedRM) => {
      if (!selectedRM.length || selectedRM === 'Select') {
        toast.error('Select RM');
        return false;
      } else {
        return true;
      }
    };


    const assignRmToDealer = async (e) => {
      setSaveLoading(false);
      e.preventDefault();
      if (validate(selectedRM)) {
        const formData = {
          assignedRM: selectedRM,
        };
        try {
          setSaveLoading(true);
          dispatch(updateUserDetails({ id:user.id, body: formData}));
          toast.success('Assigned RM Successfully');
          setSaveLoading(false);
          toggle();
        } catch (err) {
          setSaveLoading(false);
          toast.error('Server error. Try Again !');
        }
      }
  
      toggle();
    };
  
    return (
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Assign RM</h5>
          <div className="mt-4">
            <Row className="gy-4">
              <Col md="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="address-county">
                    RM
                  </label>
                  <RSelect
                    options={optRM}
                    value={selectedRM.label ? selectedRM.label : agentGet}
                    placeholder={
                      selectedRM.label ? selectedRM.label : 'Select RM'
                    }
                    onChange={(selectedOption) => {
                      setRM(selectedOption);
                      setSelectedRM(selectedOption.value);
                    }}
                  />
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                  <li>
                    <Button
                      color="primary"
                      size="lg"
                      onClick={(e) => assignRmToDealer(e)}
                      disabled={saveLoading || !selectedRM.length}
                    >
                      {saveLoading ? 'Updating...' : 'Update'}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>
    );
  };