import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { debounce } from 'lodash';

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 100,
  loading: false,
  error: null
};
const fetchDealers = createAsyncThunk('dealers', (data) => {
  let url = `/admin/companies`;
  return axios.get(url, data).then((response) => {
    return response.data;
  });
});

const dealersSlice = createSlice({
  name: 'dealers',
  initialState,
  reducers: {
    setAllSelected: (state, action) => {
      const { value } = action.payload;
      if (value) {
        state.selected = state.data?.map((e) => e.id);
      } else {
        state.selected = [];
      }
    },
    setSelected: (state, action) => {
      const { id, value } = action.payload;
      if (value) {
        state.selected.push(id);
      } else {
        state.selected = state.selected.filter((v) => v !== id);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDealers.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchDealers.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data?.data ?? null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchDealers.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  }
});

export const  dealerReducer = dealersSlice.reducer;
export const { setSelected, setAllSelected } = dealersSlice.actions;
export { fetchDealers };
