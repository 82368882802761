import React, { useEffect } from "react";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  FormSelect,
  FormTextField,
  Icon,
  RSelect,
} from "../../../components/Component";
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { resetUpdateStatus, updateUserDetails } from "../../../redux/features/usersSlice/userDetails";
import FormSwitch from "../../../components/form/FormSwitch";
import { accountType, roles } from "./label-data/edit_profile_options";
import FormMultiSelect from "../../../components/form/FormMultiSelect";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { UserVerificationStatus, formatCreatedAtDateTime } from "../../../utils/Utils";

export default function UserProfileRegularPage() {
  const [modal, setModal] = useState(false);

  const { data, updateStatus ,updateError} = useSelector((state) => state.userDetails);

  const methods = useForm({
    defaultValues: {},
  });
  const { handleSubmit, control, reset } = methods;
  useEffect(() => {
    reset(data);
  }, [data]);
  const dispatch = useDispatch();
  const update = (e) => {
    dispatch(updateUserDetails({ id: data._id, body: e }));
  };
  useEffect(() => {
    if (updateStatus === "success") {
      toast.success("Updated successfully");
      setModal(false);
      dispatch(resetUpdateStatus());
    } else if (updateStatus === "error") {
      // toast.error("Failed to Update");
      toast.error(updateError);
      dispatch(resetUpdateStatus());
    }
  }, [updateStatus,updateError]);
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
            <BlockDes>
              <p>Basic info</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button className={`toggle btn btn-icon btn-trigger mt-n1  active`} onClick={() => {}}>
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Full Name</span>
              <span className="data-value">{data?.full_name}</span>
              {data.is_verified===UserVerificationStatus.APPROVED?
                    <Icon 
                    className={`text-success`}
                    name="check-circle"></Icon>
                    :null}
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{data?.email}</span>
              {data.is_email_verified?
                    <Icon 
                    className={`text-success`}
                    name="check-circle"></Icon>
                    :null}
            </div>
            <div className="data-col data-col-end">
              <span className="data-more ">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
      
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Phone Number</span>
              <span className="data-value">{data?.phone?.dial_code}{data?.phone?.number}</span>
              {data.is_phone_verified?
                    <Icon 
                    className={`text-success`}
                    name="check-circle"></Icon>
                    :null}
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {data?.date_of_birth&&
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Date of Birth</span>
              <span className="data-value">{new Date(data?.date_of_birth).toDateString()}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>}
          {data?.gender&&
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Gender</span>
              <span className="data-value">{data?.gender}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>}
          {/* <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Phone Verified</span>
              <span className="data-value">{data?.is_phone_verified ? "Yes" : "No"}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more ">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div> */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">User register type</span>
              <span className="data-value">{data?.user_register_type} {data?.dealer_type&&`(${data?.dealer_type})`}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more ">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {data?.company_id &&
                 <div className="data-item" onClick={() => setModal(true)}>
                 <div className="data-col">
                   <span className="data-label">Comapny Name</span>
                   <span className="data-value">{data?.company_id?.name} </span>
                 </div>
                 <div className="data-col data-col-end">
                   <span className="data-more ">
                     <Icon name="forward-ios"></Icon>
                   </span>
                 </div>
               </div>
          }
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Account Type</span>
              <span className="data-value">{data?.userType===1?"User":"Admin"}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Roles</span>
              <span className="data-value">
                {data?.roles?.map((e) => (
                  <div key={e.id}>{e.name}</div>
                ))}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Location</span>
              <span className="data-value">
                {data?.city}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {data?.lastLoggedIn&&
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Last Login Time</span>
              <span className="data-value">
                {formatCreatedAtDateTime((data?.lastLoggedIn))}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>}
          {data?.selected_verticals &&
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Selected Verticals</span>
              <span className="data-value">
              {data?.selected_verticals && data?.selected_verticals.map((item, idx)=>
              <>    
                   <Badge
                        pill color="light" style={{ marginLeft: 8 }}
                    
                      >
                      <span className="data-value"> {item.name}</span>
                      </Badge>        
              
              </>
           
              )}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
}
        </div>
        {/* <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Preferences</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Language</span>
              <span className="data-value">English (United State)</span>
            </div>
            <div className="data-col data-col-end">
              <a
                href="#language"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                className="link link-primary"
              >
                Change Language
              </a>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Date Format</span>
              <span className="data-value">MM/DD/YYYY</span>
            </div>
            <div className="data-col data-col-end">
              <a
                href="#link"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                className="link link-primary"
              >
                Change
              </a>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Timezone</span>
              <span className="data-value">{data.timeZoneName}</span>
            </div>
            <div className="data-col data-col-end">
              <a
                href="#link"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                className="link link-primary"
              >
                Change
              </a>
            </div>
          </div>
        </div> */}
      </Block>

      {/* <Modal isOpen={modal} className="modal-dialog-centered" size="lg">
        <ModalHeader
          close={
            <button className="close" onClick={() => setModal(false)}>
              <Icon name="cross" />
            </button>
          }
        >
          Update Profile
        </ModalHeader>
        <ModalBody>
          <div className="p-2">
            <Row className="gy-4">
              <Col md="6">
                <FormTextField name="name" label="Full Name" control={control} />
              </Col>
              <Col md="6">
                <FormTextField name="email" label="Email" control={control} />
              </Col>
              <Col md="6">
                <FormSwitch name="emailVerified" label="Email Verified" control={control} />
              </Col>
              <Col md="6">
                <FormTextField name="phone" label="Phone Number" control={control} />
              </Col>
              <Col md="6">
                <FormSwitch name="phoneVerified" label="Phone Verified" control={control} />
              </Col>

              <Col md="6">
                <FormSelect name="accountType" label="Account Type" options={accountType} control={control} />
              </Col>
              <Col md="6">
                <FormMultiSelect name="roles" label="Roles" options={roles} control={control} />
              </Col>
              <Col md="6">
                <FormTextField name="city" label="City" control={control} />
              </Col>
              <Col md="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="lg" onClick={handleSubmit(update)}>
                      Update Profile
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#dropdownitem"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setModal(false);
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal> */}
    </React.Fragment>
  );
}
