import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  options: null,
  totalCount: 0,
  perPage:10,
  loading: false,
  error: null,
};
const fetchAutocompleteOptionsSecondary = createAsyncThunk("AutocompleteOptionsSecondary", (data) => {
  let url = `/admin/api/v1/autocomplete/list`;
  return axios.post(url,data).then((response) => {
    return response.data;
  });
});
const updateAutocompleteOptionsSecondary = createAsyncThunk("updateAutocompleteOptionsSecondary", (data) => {
  let url = `/admin/api/v1/autocomplete/update/${data.id}`;
  return axios.put(url,data.data).then((response) => {
    window.location.reload();
    return response.data;
  });
});
const bulkInsertAutocompleteOptionsSecondary = createAsyncThunk("bulkInserAutocompleteOptionsSecondary", (data) => {
  let url = `/admin/api/v1/autocomplete/addBulk`;
  return axios.post(url,data).then((response) => {
    window.location.reload();
    return response.data;
  });
});
const bulkInsertAutocompleteOptionsSecondaryRef = createAsyncThunk("bulkInsertAutocompleteOptionsSecondaryRef", (data) => {
  let url = `/admin/api/v1/autocomplete/addBulkRef`;
  return axios.post(url,data).then((response) => {
    window.location.reload();
    return response.data;
  });
});
const clearOptions= createAsyncThunk('clearOptions', async () => {
  return [];
});
const autocompleteOptionsSlice = createSlice({
  name: "AutocompleteOptionsSecondary",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchAutocompleteOptionsSecondary.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchAutocompleteOptionsSecondary.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data??null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchAutocompleteOptionsSecondary.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(clearOptions.fulfilled, (state) => {
      state.data = [];
    });
    builder.addCase(updateAutocompleteOptionsSecondary.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(updateAutocompleteOptionsSecondary.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      // state.data = action.payload.data;
    });
    builder.addCase(updateAutocompleteOptionsSecondary.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
    builder.addCase(bulkInsertAutocompleteOptionsSecondary.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(bulkInsertAutocompleteOptionsSecondary.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      // state.data = action.payload.data;
    });
    builder.addCase(bulkInsertAutocompleteOptionsSecondary.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
    builder.addCase(bulkInsertAutocompleteOptionsSecondaryRef.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(bulkInsertAutocompleteOptionsSecondaryRef.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      // state.data = action.payload.data;
    });
    builder.addCase(bulkInsertAutocompleteOptionsSecondaryRef.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
  },
});

export default autocompleteOptionsSlice.reducer;
const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchAutocompleteOptionsSecondary({ ...arg })), 500);
const debouncedFetchAutocompleteOptionsSecondary =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export { fetchAutocompleteOptionsSecondary, debouncedFetchAutocompleteOptionsSecondary,clearOptions,updateAutocompleteOptionsSecondary ,bulkInsertAutocompleteOptionsSecondary,bulkInsertAutocompleteOptionsSecondaryRef};
