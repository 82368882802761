import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";

const initialState = {
  data: [],
  details: {},
  watchers: [],
  bids: [],
  totalCount: 0,
  loading: false,
  error: null,
};
const fetchLiveAuctions = createAsyncThunk("liveAuctions", (data) => {
  let url = `/admin/auction/list`;
  return axios.post(url,data).then((response) => {
    return response.data.data;
  });
});

const liveAuctionsSlice = createSlice({
  name: "liveAuctions",
  initialState,
  reducers: {
    onNewBid: (state, actions) => {
      state.bids.push(actions.payload);
    },
    onWatcherJoined: (state, actions) => {
      state.watchers.push(actions.payload);
    },
    onWatcherLeft: (state, actions) => {
      const index = state.watchers.indexOf(actions.payload);
      if (index != -1) state.watchers.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLiveAuctions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchLiveAuctions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action?.payload?.data||null;
      state.totalCount = action?.payload?.totalCount;
    });
    builder.addCase(fetchLiveAuctions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default liveAuctionsSlice.reducer;
export { fetchLiveAuctions };
