import React, { useEffect } from "react";
import { Card, Badge, Row } from "reactstrap";
import { DataTableHead, DataTableRow, DataTableItem, UserAvatar } from "../Component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchLiveAuctions } from "../../redux/features/auctionSlice/liveAuctionsSlice";
import formatDate from "../../utils/dateFormat";
import { TimerText } from "../timer/timer";
import { useNavigate } from "react-router-dom";
import { auctionStatus, findUpper } from "../../utils/Utils";
import msToTime from "../../utils/msToTime";
import { startCase } from "lodash";

const LiveAuctions = () => {
  const {data} = useSelector((state) => state?.liveAuctions)||null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let data = {
      'query': {'status':[auctionStatus.LIVE]},
      'options': {
        'page': 1,
        'limit': 10,
        "populate": [
          {
              "path": "vertical_id",
              "select": "name"
          },
                      {
              "path": "user",
              "select": "full_name"
          }
      ],
      }
}
    dispatch(fetchLiveAuctions(data));
    const interval = setInterval(() => {
      dispatch(fetchLiveAuctions(data));
    }, 1000 * 60);
    return () => {
      clearInterval(interval);
    };
  }, []);

  console.log(data)
  return (
    <Card className="card-full">
      <div className="card-inner">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title">Live Auctions</h6>
          </div>
        </div>
      </div>
      <div className="nk-tb-list mt-n2">
        <DataTableHead>
          <DataTableRow>
            <span>Owner</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>Item</span>
          </DataTableRow>
          <DataTableRow size="md">
            <span>Start Time</span>
          </DataTableRow>
          <DataTableRow>
            <span>Time Left</span>
          </DataTableRow>
          <DataTableRow>
            <span className="d-none d-sm-inline">Bids</span>
          </DataTableRow>
        </DataTableHead>
        {data?.length>0 ? data?.map((item, idx) => (
          <DataTableItem
            key={idx}
            onClick={(e) => {
              navigate(`/v/${item.vertical_id}/auctions/${item.id}`,{ replace: true });
            }}
            className={'cursor-pointer'}
          >
            <DataTableRow>
              <div className="user-card">
              {item?.user?.profile_image &&
                    <UserAvatar text={item?.user?.full_name}  theme="primary"  image={item?.user?.profile_image}/>}
                    {!item?.user?.profile_image &&
                    <UserAvatar text={item?.user?.full_name} theme="primary"  />
                    }
                <span className="user-name">
                
                    {startCase(item?.user?.full_name)}
                
                </span>
              </div>
            </DataTableRow>
            <DataTableRow size="sm">
              <div className="user-card">
                <div className="user-name">
                  <span className="tb-lead">{startCase(item?.baseform?.title)}</span>
                </div>
              </div>
            </DataTableRow>
            <DataTableRow size="md">
              <span className="tb-sub">{formatDate(new Date(item?.start_time))}</span>
            </DataTableRow>
            <DataTableRow>
            {item.status === auctionStatus.LIVE ? (
                        <TimerText
                          startTime={item.start_time}
                          duration={item.duration}
                          onTimerEnd={() => {
                          return 0;
                          }}
                        />
                      ) : (
                        msToTime(item.duration)
                      )}
              {/* <TimerText startTime={item?.start_time} duration={item?.duration} /> */}
            </DataTableRow>
            <DataTableRow>{item?.bid_count}</DataTableRow>
          </DataTableItem>
        )):
        <DataTableItem>
          <DataTableRow>
              <div className="user-card">
                    No live Auctions</div>
                  </DataTableRow>
                </DataTableItem>
                }
      </div>
    </Card>
  );
};
export default LiveAuctions;
