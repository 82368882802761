import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { debounce } from 'lodash';

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 100,
  fetchOrderStatus: 'init',
  error: null,
  page:1,
  selected:[]
};
const fetchOrders = createAsyncThunk('orders', (data) => {
  let url = `/admin/order`;
  return axios.get(url, {params:data}).then((response) => {
    return response.data?.data;
  });
});

const ordersListSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const { id, value } = action.payload;
      if (value) {
        state.selected.push(id);
      } else {
        state.selected = state.selected.filter((v) => v !== id);
      }
    },
    setAllSelected: (state, action) => {
      const { value } = action.payload;
      if (value) {
        state.selected = state.data?.map((e) => e.id);
      } else {
        state.selected = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.fetchOrderStatus = 'loading';
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.fetchOrderStatus = 'success';
      state.data = action.payload?.data ?? null;
      state.totalCount = action.payload?.paginator?.itemCount;
      state.perPage = action.payload?.paginator?.perPage;
      state.page = action.payload?.paginator?.currentPage;
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.fetchOrderStatus = 'error';
      state.data = [];
      state.error = action.error.message;
    });
  }
});

export default ordersListSlice.reducer;
export const { setSelected, setAllSelected } = ordersListSlice.actions;
export { fetchOrders };
