import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  totalCount: 0,
  perPage:10,
  loading: false,
  error: null,
};
const fetchHistory = createAsyncThunk("history", (data) => {

  let url = `admin/api/v1/userhistory`;
  return axios.post(url,data).then((response) => {
    return response.data;
  });
});

const clearHistory= createAsyncThunk('clearHistory', async () => {
  return [];
});
const rmSlice = createSlice({
  name: "history",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data??null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchHistory.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(clearHistory.fulfilled, (state) => {
      state.data = [];
    });
  },
});

export default rmSlice.reducer;
// export const { setSelected, setAllSelected } = rolesSlice.actions;
export { fetchHistory ,clearHistory};
