import React, { useEffect, useState } from "react";
import { ModalBody, Col,Input } from "reactstrap";
import { Icon, Button, RSelect,Row } from "../../../components/Component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { getImage, uploadToS3 } from "../../../utils/uploadImageToS3";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../../redux/features/usersSlice/userDetails";


export const EditUserProfileImage = ({
    toggle,
    user, 
  }) => {
const dispatch =useDispatch();
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState("");
    const folderName = "User";
    const [displayAvatar, setDisplayAvatar] = useState("");


    useEffect(() => {
      async function fetchImage() {
        const resData = await getImage(user?.profile_image);
        const s3url = resData.signedRequest;
        setDisplayAvatar(s3url);
      }
    
      fetchImage();
    }, []);
    
    
    



    const editImage = async (e) => {
      e.preventDefault();
      let fileName = user?.profile_image;
      if (avatar && avatar.name) {
        setLoading(true);
        fileName = `${folderName}/${uuidv4()}.${avatar.name.split(".").pop()}`;
        await uploadToS3(avatar, fileName, 'image/*');
        const formData = {
          profile_image: fileName,
        };
        try {
          dispatch(updateUserDetails({ id: user?.id, body: formData }));        
        
            toast.success("User image modified successfully");
            setLoading(false);
            toggle();  
              
        } catch (err) {
          toast.error("User not modified with changes!");
        }
      }
    };
  
    const handleImage = async (e) => {
      if (e.target.files) {
        let result = e.target.files[0];
        let url = URL.createObjectURL(e.target.files[0]);
        setDisplayAvatar(url);
        setAvatar(result);
      }
    };
    return (
      <ModalBody>
              <a href="#cancel" className="close">
                {" "}
                <Icon
                  name="cross-sm"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggle();
                  }}
                ></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">{"Edit User Image"}</h5> 
                <div className="mt-4">
                  <form onSubmit={(e) => editImage(e)}>
                    <Row className="g-3">
                      <Col sm="12">
                        <div className="form-group">
                          <label className="form-label">Image</label>
                          <div className="form-control-wrap">
                            <div className="form-file">
                              <Input
                                type="file"
                                id="customFile"
                                onChange={(e) => handleImage(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <Col sm="6">
                          {displayAvatar && (
                            <img
                              src={displayAvatar}
                              alt={user?.findUpper}
                              style={{ height: "8rem" }}
                            />
                          )}
                        </Col>
                      </Col>
                      <Col size="12" className="mt-3">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                      <li>
                      <Button
                        color="primary"
                        size="lg"
                        disabled={loading} 
                      >
                        {loading ? 'Updating...' : 'Update'}
                      </Button>
                      </li>
                    </ul>
                  </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </ModalBody>
    );
  };


