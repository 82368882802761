import { Modal, ModalBody, Spinner } from "reactstrap";
import { Button, Icon, Col, Row } from "../../../components/Component";

export default function ConfirmModal ( {
  open,
  handleConfirm,
  handlecloseModal,
  msg,
  name,
  loading,
  action = "Update",
} )
{
  return (
    <Modal
      isOpen={ open }
      toggle={ () => handlecloseModal() }
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a href="#cancel" className="close">
          <Icon
            name="cross-sm"
            onClick={ ( ev ) =>
            {
              ev.preventDefault();
              handlecloseModal();
            } }
          ></Icon>
        </a>
        <div className="nk-tnx-details mt-sm-3">
          <div className="nk-modal-head mb-3">
            <h5 className="title">Confirm</h5>
          </div>
          <Row className="gy-3">
            <Col size="12">
              Are you sure you want to { msg }
              <b>{ name }</b> ? This action cannot be undone.
            </Col>
            <Col size="12">
              { !loading && (
                <Button
                  onClick={ () => handlecloseModal() }
                  className="text-center"
                  color="light"
                  style={ { marginRight: "10px" } }
                >
                  <>
                    <Icon className="plus"></Icon>
                    Cancel
                  </>
                </Button>
              ) }
              <Button
                onClick={ ( e ) => handleConfirm( e ) }
                color="primary"
                type="submit"
              >
                { !loading ? (
                  <>
                    <Icon className="plus"></Icon>
                    { action }
                  </>
                ) : (
                  <Spinner size="sm" color="light" />
                ) }
              </Button>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
}
