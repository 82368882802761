import { Box, Typography } from '@mui/material'
import React from 'react'

function Price({ label, amount, currency = "AED" }) {
    return (
        <>
            <Typography variant="body1" sx={{ fontWeight: 'w300', }}>
                {label}
            </Typography>
            <Box display="flex" alignItems="baseline">
                <Typography variant="label" sx={{ mr: 0.3 }}>
                    {currency}
                </Typography>

                {/* Larger amount */}
                <Typography variant='h6'>
                    {Math.round(Number(amount))}
                </Typography>
            </Box>
        </>
    )
}

export default Price