import React from "react";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import FieldMapper from "./FieldMapper";
import { useSelector } from "react-redux";

export default function SimplifiedInspectionForms() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useSelector((state) => state.verticalDetails);
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Simplified Inspection Form</BlockTitle>
            <BlockDes>
              <p>Form required to add inspection details</p>
            </BlockDes>
          </BlockHeadContent>
          {data?.simplifiedinspectionformsettings && (
            <BlockHeadContent className="align-self-start">
              <Button
                onClick={() => {
                  navigate(
                    `/verticals/${id}/create_form?formId=${data?.simplifiedinspectionformsettings.id}&formType=Simple`
                  );
                }}
              >
                Edit
              </Button>
            </BlockHeadContent>
          )}
        </BlockBetween>
      </BlockHead>

      <Block>
        {!data?.simplifiedinspectionformsettings ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>No Inspection Form Found</div>
            <Button
              color="primary"
              style={{ margin: 10 }}
              onClick={() => navigate(`/verticals/${id}/create_form?formType=Simple`)}
            >
              Create
            </Button>
          </div>
        ) : (
          <FieldMapper values={data?.simplifiedinspectionformsettings?.tabs} />
        )}
      </Block>
    </React.Fragment>
  );
}
