import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { RedirectAs404 } from './utils/Utils';
import ProtectedRoutes from './route/ProtectedRoutes';

import Layout from './layout/Index';

import Error404Classic from './pages/error/404-classic';
import Error404Modern from './pages/error/404-modern';
import Error504Modern from './pages/error/504-modern';
import Error504Classic from './pages/error/504-classic';

import Faq from './pages/others/Faq';
import Terms from './pages/others/Terms';

import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import Success from './pages/auth/Success';
import { io } from 'socket.io-client';

const App = (props) => {
  // const client = io('http://localhost:5000', {
  //   path: '/socket'
  // });

  // client.emit('joinRoom', {
  //   user_id: '65464a0702d92dac384aaa70',
  //   room: '65464067b4624e9e829fc62d',
  //   isAdmin: true
  // });

  // client.on('newJoiner', (msg) => {
  //   client.emit('getRoomMembers', { room: msg?.room });
  // });

  // client.on('allRoomMembers', (msg) => console.log(msg));

  return (
    <BrowserRouter>
      <>
        <Routes>
          {/* Auth Pages */}
          <Route path={`/auth-success`} element={<Success />} />
          <Route path={`/auth-reset`} element={<ForgotPassword />} />
          <Route path={`/auth-register`} element={<Register />} />
          <Route path={`/auth-login`} element={<Login />} />

          {/* Helper pages */}
          <Route path={`/auths/terms`} element={<Terms />} />
          <Route path={`/auths/faq`} element={<Faq />} />

          {/*Error Pages*/}
          <Route path={`/errors/404-classic`} element={<Error404Classic />} />
          <Route path={`/errors/504-modern`} element={<Error504Modern />} />
          <Route path={`/errors/404-modern`} element={<Error404Modern />} />
          <Route path={`/errors/504-classic`} element={<Error504Classic />} />

          {/*Main Routes*/}
          <Route element={<ProtectedRoutes />}>
            <Route path="*" element={<Layout />} />
          </Route>
          {/* <Route element={<RedirectAs404 />} /> */}
        </Routes>
      </>
    </BrowserRouter>
  );
};
export default App;
