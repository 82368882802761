import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchVerticalDetails } from "../../../redux/features/verticalsSlice/verticalDetailsSlice";
import { Block } from "../../../components/Component";
import BaseFieldFormMapper from "../../verticals/components/BaseFieldFormMapper";
import { fetchAutocompleteOptions } from "../../../redux/features/verticalsSlice/autocompleteOptionsSlice";

const AddItemForm = ({ vertical_id="65166d7bf68365d2ad399855" ,baseform_id="65167151c1598f12ca7adc6c"}) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.verticalDetails);

  useEffect(() => {
    dispatch(fetchVerticalDetails(vertical_id));
    
  }, [vertical_id]);
  
  return (
    <>
      <section>
        <Block>
          <BaseFieldFormMapper vertical_id={vertical_id} baseform_id={baseform_id} values={data?.baseformsettings?.tabs} />
        </Block>
      </section>
    </>
  );
};

export default AddItemForm;
