import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";
import { fetchVerticals } from "./verticalsSlice";
import { fetchVerticalDetails } from "./verticalDetailsSlice";

const initialState = {
  data: {},
  status: "init",
  error: null,
};
const createOrUpdateSettings = createAsyncThunk("createOrUpdateSettings", (data, { dispatch }) => {
  let url;
  // console.log(data);

  url = `/admin/verticalsetting/update/${data.id}`;

  return axios.put(url, data.data).then((response) => {
    dispatch(fetchVerticalDetails(data.vertical_id));
    return response.data;
  });
});

const createOrUpdateSettingsSlice = createSlice({
  name: "createOrUpdateSettingsSlice",
  initialState,
  reducers: {
    resetCreateOrUpdateSettings: (state, action) => {
      state.data = null;
      state.status = "init";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrUpdateSettings.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(createOrUpdateSettings.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      state.data = action.payload.data;
    });
    builder.addCase(createOrUpdateSettings.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
  },
});

export default createOrUpdateSettingsSlice.reducer;
export const { resetCreateOrUpdateSettings } = createOrUpdateSettingsSlice.actions;
export { createOrUpdateSettings };
