import React, { useEffect, useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { Icon, InputSwitch } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { updateVerticals } from "../../../redux/features/verticalsSlice/updateVerticalsSlice";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../../../components/form/ImageUpload";
import { getImage, uploadToS3 } from "../../../utils/uploadImageToS3";
import { v4 as uuidv4 } from "uuid";

const EditVerticalModal = ({ isOpen, toggle, vertical }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toBeUpdated, settoBeUpdated] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      name: "",
      image: "",
      labelAr:"",
      description: "",
    },
  });
  const { handleSubmit, control, setValue } = methods;

  const [displayImg, setDisplayImg] = useState(null);

  const [updateVertical, setUpdateVertical] = useState(vertical);

  useEffect(() => {
    setUpdateVertical(vertical);
    async function test() {
      let url = await getImage(vertical?.image);
      setDisplayImg(url);
    }
    test();
  }, [vertical]);

  const handleUpdateDevDetails = (e) => {
    const { name, value } = e.target;
    setUpdateVertical((prev) => ({ ...prev, [name]: value }));
    settoBeUpdated((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateVertical = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setDisabled(true);
      if (toBeUpdated.image) {
        const fileName = `vertical/${uuidv4()}.${toBeUpdated.image.name?.split(".")?.pop()}`;
        await uploadToS3(toBeUpdated.image, fileName);
        toBeUpdated.image = fileName;
      }
      dispatch(updateVerticals({ id: vertical.id, ...toBeUpdated }));
      setLoading(false);
      toast.success("Saved");
      navigate(0);

      setLoading(false);
      toast.success("Updated successfully");
      toggle();
    } catch (err) {
      // console.log(err);
      toast.error("Failed to update vertical");
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };
  const handleImageChange = (e, field) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      field.onChange(selectedFile);
      settoBeUpdated((prev) => ({ ...prev, image: selectedFile }));
      setDisplayImg(URL.createObjectURL(selectedFile));
    } else {
      setDisplayImg(null);
    }
  };
  const handleRemove = (e) => {
    e.preventDefault();
    setDisplayImg("");
    setValue("image", "");
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = ""; // Reset the input field value
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader
        toggle={toggle}
        close={
          <button
            className="close"
            onClick={(ev) => {
              ev.preventDefault();
              toggle();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        Edit Vertical
      </ModalHeader>
      <ModalBody>
        <form id="add-vertical-form" onSubmit={(e) => handleUpdateVertical(e)}>
          <Row className="g-3">
            <Col sm="12">
              <Label>Vertical Name</Label>
              <Input
                autoComplete="off"
                name="name"
                defaultValue={vertical?.name}
                onChange={(e) => handleUpdateDevDetails(e)}
                placeholder="Enter Vertical Name"
              ></Input>
            </Col>
            <Col sm="12">
              <Label>Arabic Name</Label>
              <Input
                autoComplete="off"
                name="labelAr"
                defaultValue={vertical?.labelAr}
                onChange={(e) => handleUpdateDevDetails(e)}
                placeholder="Vertical Name in Arabic"
              ></Input>
            </Col>
            <Col sm="12">
              <Label>Description</Label>
              <Input
                autoComplete="off"
                name="description"
                defaultValue={vertical?.description}
                onChange={(e) => handleUpdateDevDetails(e)}
                placeholder="Enter Description"
              ></Input>
            </Col>
            {/* {vertical?.verticalsettings_id?.auctionStartingPrice && vertical?.verticalsettings_id?.item_list_mappings &&!vertical?.isActive &&
                   <InputSwitch 
                   label=""
                   checked={vertical?.isActive} 
                   id="simple-" 
                   onChange={(e) => handleUpdateDevDetails(e)}
                   />
            } */}
            <ImageUpload 
              accept="image/jpeg, image/png"
              customValidator={(img) => {
                // Validate that the image is square
                return img.width === img.height;
              }}
            handleImageChange={handleImageChange} required={false} control={control} name="image" label="image" />
            {displayImg && (
              <div className="position-relative" style={{ height: "200px", width: "200px" }}>
                <img height={"200px"} src={displayImg} alt="Avatar" />
                <button
                  type="button"
                  className="btn btn-danger position-absolute top-0 start-0"
                  onClick={(e) => {
                    handleRemove(e);
                  }}
                >
                  <Icon name="trash" />
                </button>
              </div>
            )}
          </Row>
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
              <li>
                <Button color="secondary" type="submit">
                  {!loading ? <>Save</> : <Spinner size="sm" color="light" />}
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default EditVerticalModal;
