import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Icon from "../icon/Icon";
import { useDispatch } from "react-redux";
import { getImage } from "../../utils/uploadImageToS3";


// Aviable sizes {xs,sm,md,lg,xl}
/* Aviable theme {blue-dim, blue, azure-dim, azure, indigo-dim, indigo, purple-dim, purple, pink-dim, pink, orange-dim, orange, teal-dim, teal, 
    primary-dim, primary, secondary-dim, secondary, success-dim, success, info-dim, info, warning-dim, warning, danger-dim, danger, dark-dim, dark, gray-dim, gray, lighter, light} */

const UserAvatar = ({ className, size, theme, icon, text, image, imageAlt, ...props }) => {
  const classes = classNames({
    "user-avatar": true,
    [`${className}`]: className,
    [`user-avatar-${size}`]: size,
    [`bg-${theme}`]: theme,
  });

  const [imageUrl, setImageUrl] = useState(null);
  console.log(image)

  useEffect(() => {
    async function fun() {
      if (image) {
        const obj = await getImage(image);
        if (obj) setImageUrl(obj);
      }
    }
    fun();
  }, []);
  return (
    <div className={classes}>
      {icon ? <Icon name={icon} /> : null}
      {imageUrl && <img src={imageUrl} alt={imageAlt} className={classes} style={{ objectFit: "cover" }} />}
      {text && !imageUrl && <span>{text.charAt(0).toUpperCase()}</span>}
      {props.children}
    </div>
  );
};

export default UserAvatar;
