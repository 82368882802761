import React, { useEffect, useState } from "react";

const InputSwitch = ({ label, id, checked, onClick }) => {
  const [inputCheck, setCheck] = useState(checked ? true : false);
  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <React.Fragment>
      <div key={id} className="custom-switch custom-control mt-2 mb-2">
        <input
          type="checkbox"
          className=" custom-control-input"
          checked={inputCheck}
          onClick={() => onClick(!inputCheck)}
          id={id}
        />
        <label className="custom-control-label " htmlFor={id}>
          {label}
        </label>
      </div>
    </React.Fragment>
  );
};

export default InputSwitch;
