import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormTextField from '../../../components/form/FormTextField'; // Using your custom form component for text fields
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Stack,
  Typography
} from '@mui/material';
import FormSingleImageUpload from '../../../components/form/FormSingleImageUpload';
import { Image } from '../../../components/Component';
import FormMultiImageUpload from '../../../components/form/FormMultiImageUpload';

export default function BannerSection1({
  data,
  onSubmit,
  toggleEdit,
  editMode
}) {

  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      banners: data?.banners,
    }
  });
  useEffect(() => {
    if (data) {
      reset({
        banners: data?.banners,
      });
    }
  }, [data, reset]);

  const handleFormSubmit = (data) => {
    
    if (editMode) {
      onSubmit(data);
      toggleEdit('bannerSection1');  // or whatever section name is appropriate
    }
  };

  return (
   
      <Card sx={{ mb: 2 }}>
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <CardHeader title={'Banner section 1'} />
          <Box sx={{ pr: 2 }}>
            {editMode ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="button"
                onClick={handleSubmit(handleFormSubmit)}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                type='button'
                onClick={() => toggleEdit('bannerSection1')}
              >
                Edit
              </Button>
            )}
          </Box>
        </Stack>
        <CardContent>
        <Grid2 container spacing={2}>
            <Grid2 item size={12}>
              {editMode ? (
                <FormMultiImageUpload
                  name={'banners'}
                  label={''}
                  control={control}
                  ratio={1268/500}
                />
              ) : (
              
                  <Stack direction='row' gap={1}>
                  {data?.banners?.map((banner, index) => (
                    <Box key={index} height={100} >
                      <Image image={banner} size={100} />
                    </Box>
                  ))}
                </Stack>
               
              )}
            </Grid2>
          
          </Grid2>
        </CardContent>
      </Card>
  );
}
