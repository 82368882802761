import React from "react";
import classNames from "classnames";
import { useEffect } from "react";
import { useState } from "react";
import msToTime from "../../utils/msToTime";

export const TimerText = ({ startTime, duration, onTimerEnd, className, alt, ...props }) => {
  const classes = classNames({
    "overline-title": true,
    [`${className}`]: className,
    "overline-title-alt": alt,
  });
  const [time, setTime] = useState(startTime + duration - Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => {
        if (time < 1000) {
          clearInterval(interval);
          onTimerEnd();
          return 0; 
        }
        return time - 1000;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    // console.log("triggered");
    if (time < 5000) {
    }
  }, [time < 100]);
  return (
    <React.Fragment>
      {!props.tag ? (
        <h6 className={classes}>{msToTime(time)}</h6>
      ) : (
        <props.tag className={classes}>{msToTime(time)}</props.tag>
      )}
    </React.Fragment>
  );
};
