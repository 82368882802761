import { Box } from '@mui/material';
import React from 'react';
import {
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Block,
  InputSwitch,
  DataTableHead,
  DataTableRow,
  PreviewAltCard,
  DataTableItem
} from '../../../components/Component';
import { Button, Input, Modal, Spinner } from 'reactstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {
  addFreePackage,
  deleteFreePackage,
  fetchPackage,
  updatePackage
} from '../../../redux/features/usersSlice/freePackageSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ConfirmModal from '../../verticals/components/ConfirmModal';
import SubRow from './SubRow';

export default function UserSubscription({ user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState(null);
  // const [freeAuctions, setFreeAuctions] = useState(null);
  // const [selectedVertical, setSelectedVertical] = useState(null);
  const data = useSelector((state) => state?.freePackage?.data ?? []);
  const subIds = data?.map((x) => x.vertical_id?._id);

  const initialLoadingStates = Array.from(
    { length: user?.selected_verticals.length || 0 },
    () => false
  );

  const initialFreeAuctions = Array.from(
    { length: user?.selected_verticals.length || 0 },
    () => null
  );
  const [freeAuctions, setFreeAuctions] = useState(initialFreeAuctions);
  const [loadingStates, setLoadingStates] = useState(initialLoadingStates);
  const [verticals, setVerticals] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [idx, setIdx] = useState(null);
  const [vId, setVId] = useState(null);
  const [updateItem, setUpdateItem] = useState(false);
  const initialFreeAuctionsBool = Array.from(
    { length: user?.selected_verticals.length || 0 },
    () => null
  );
  const [freeAuctionsBool, setFreeAuctionsBool] = useState(
    initialFreeAuctionsBool
  );

  const handleAdd = async (e, v_id, idx) => {
    e.preventDefault();
    const limit = freeAuctions[idx];
    if (!limit) {
      toast.error('Please specify the number of free auctions');
      return;
    }

    let body = {
      user_id: user?.id,
      vertical_id: v_id,
      limit: limit // Set the limit to the value for this vertical
    };

    try {
      // Create a copy of the loadingStates array
      const newLoadingStates = [...loadingStates];
      newLoadingStates[idx] = true; // Set loading to true for the specific vertical
      setLoadingStates(newLoadingStates); // Update loading states

      await dispatch(addFreePackage(body)); // Await the action to complete
    } catch (err) {
    } finally {
      const newLoadingStates = [...loadingStates];
      newLoadingStates[idx] = false;
      setLoadingStates(newLoadingStates);
    }
  };
  const handleSub = async (e, v_id, value) => {
    e.preventDefault();
    const limit = value;
    if (!limit) {
      toast.error('Please specify the number of free auctions');
      return;
    }
    let body = {
      limit: Number(limit) // Set the limit to the value for this vertical
    };

    try {
      dispatch(updatePackage({ data: body, user: user.id, vertical: v_id }));
    } catch (err) {
    } finally {
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const handleDel = async (e) => {
    e.preventDefault();

    try {
      let body = {
        isActive: false
      };
      dispatch(
        updatePackage({
          data: body,
          user: user.id,
          vertical: updateItem?.vertical_id._id
        })
      );
    } catch (err) {
    } finally {
      const newLoadingStates = [...loadingStates];
      newLoadingStates[idx] = false;
      setLoadingStates(newLoadingStates);
    }
  };

  const handleActivate = async (e) => {
    e.preventDefault();

    try {
      let body = {
        isActive: true
      };
      dispatch(
        updatePackage({
          data: body,
          user: user.id,
          vertical: updateItem?.vertical_id._id
        })
      );
    } catch (err) {
    } finally {
      const newLoadingStates = [...loadingStates];
      newLoadingStates[idx] = false;
      setLoadingStates(newLoadingStates);
    }
  };
  const handleFreeAuctionsChange = (value, idx) => {
    const newFreeAuctions = [...freeAuctions];
    newFreeAuctions[idx] = value;
    setFreeAuctions(newFreeAuctions);
  };
  useEffect(() => {
    if (user) {
      setUserDetails(user);
      let payload = {
        vertical_ids: user?.selected_verticals ?? [],
        user_id: user.id,
        type: 'VERTICAL'
      };
      dispatch(fetchPackage(payload));
      setVerticals(user?.selected_verticals);
    }
  }, [user]);

  //   useEffect(() => {
  //     if(data?.length>0 && verticals?.length){
  //   // Map through 'verticals' and find matching 'vertical_id' in 'data'
  //   const freeAuctionsArray = verticals.map((vertical) => {
  //     const matchingData = data?.find((item) => item.vertical_id._id === vertical._id);

  //     if (matchingData) {
  //       return matchingData.limit; // If a match is found, return the 'limit' value
  //     } else {
  //       return null; // If no match is found, return null
  //     }
  //   });
  //   setFreeAuctions(freeAuctionsArray)

  // }
  //   }, [data,verticals]);

  const handleSwitchToggle = (event, v_id, idx) => {
    setIdx(idx);
    setVId(v_id);
    setShowModal(true);
  };
  return (
    <Box sx={{ width: 1 }}>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Subscription</BlockTitle>
            <BlockDes>{/* <p>Basic info</p> */}</BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1  active`}
              onClick={() => {}}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {data && data?.length > 0 && (
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow size="md">
                <span className="sub-text">Vertical</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Credits</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Balance</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Suspend</span>
              </DataTableRow>
            </DataTableHead>

            {data && data?.length > 0
              ? data?.map((item, idx) => (
                  //           <DataTableItem key={item.id}>
                  //         <DataTableRow>
                  //         <span className="tb-sub">
                  //         {item?.vertical_id?.name}
                  //         </span>
                  // </DataTableRow>
                  // <DataTableRow>
                  // <div className="form-control-wrap">
                  //   <div style={{display:"flex"}}>
                  //         <Input
                  //         style={{width:"100%"}}
                  //           type="text"
                  //           id={`fv-full-name-${idx}`}
                  //           name="fullname"
                  //           className="form-control"
                  //           placeholder="Number of free auctions"
                  //           value={item.limit } // Use value from the state
                  //           onChange={(e) => handleFreeAuctionsChange(e.target.value, idx)} // Pass the value and index
                  //           onInput={(e) => {
                  //             let newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                  //             e.target.value = newValue;
                  //           }}
                  //         />
                  //            <Button
                  //       style={{marginLeft:"10px"}}
                  //     onClick={(e)=>handleSub(e,item.id,idx)}
                  //     disabled={freeAuctions[idx] === null || freeAuctions[idx] === undefined}
                  //     color="secondary" type="submit">
                  //            {!loadingStates[idx]  ? <>Save</> : <Spinner size="sm" color="light" />}
                  //           </Button>
                  //       </div>
                  //       </div>
                  // </DataTableRow>
                  // <DataTableRow>
                  //         <span className="tb-sub">
                  //         {item?.balance}
                  //         </span>
                  // </DataTableRow>
                  // <DataTableRow>
                  //         <span className="tb-sub">
                  //         <div className="custom-control custom-switch ">
                  //             {freeAuctionsBool[idx] ===true &&
                  //           <InputSwitch
                  //    checked={item?.isActive}
                  //   id={`active-${idx}`}
                  //   onClick={(e) => handleSwitchToggle(e,item.id,idx)}

                  // />}
                  //       </div>
                  //         </span>
                  // </DataTableRow>
                  // </DataTableItem>
                  <SubRow
                    item={item}
                    handleActivate={handleActivate}
                    setUpdateItem={setUpdateItem}
                    handleSub={handleSub}
                    handleDel={handleDel}
                  />
                ))
              : null}
          </div>
        </Block>
      )}
      {(!data || data?.length !== user?.selected_verticals?.length) &&
        subIds &&
        user?.selected_verticals && (
          <Block>
            <div className="nk-data data-list">
              <div className="data-head">
                <h6 className="overline-title">Selected Verticals</h6>
              </div>

              {(!data || data?.length !== user?.selected_verticals?.length) &&
                subIds &&
                user?.selected_verticals &&
                user?.selected_verticals
                  ?.filter((x) => !subIds?.includes(x._id))
                  .map((item, idx) => (
                    <>
                      <div
                        className="data-list"
                        key={idx}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start',
                          marginLeft: '20px'
                        }}
                      >
                        <div className="title" style={{ marginRight: '20px' }}>
                          {item?.name}
                        </div>

                        <div className="form-control-wrap">
                          <Input
                            style={{ width: '100%' }}
                            type="text"
                            id={`fv-full-name-${idx}`}
                            name="fullname"
                            className="form-control"
                            placeholder="Number of free auctions"
                            value={freeAuctions[idx]} // Use value from the state
                            onChange={(e) =>
                              handleFreeAuctionsChange(e.target.value, idx)
                            } // Pass the value and index
                            onInput={(e) => {
                              let newValue = e.target.value.replace(
                                /[^0-9]/g,
                                ''
                              ); // Allow only numbers
                              e.target.value = newValue;
                            }}
                          />
                        </div>

                        <Button
                          style={{ marginLeft: '10px' }}
                          onClick={(e) => handleAdd(e, item.id, idx)}
                          disabled={
                            freeAuctions[idx] === null ||
                            freeAuctions[idx] === undefined
                          }
                          color="secondary"
                          type="submit"
                        >
                          {!loadingStates[idx] ? (
                            <>Add</>
                          ) : (
                            <Spinner size="sm" color="light" />
                          )}
                        </Button>
                      </div>
                    </>
                  ))}
            </div>
          </Block>
        )}
      {/* ****** Modal Start ****** */}
      {showModal ? (
        <ConfirmModal
          msg={'suspend the package?'}
          name={''}
          action="Delete"
          open={showModal}
          loading={cancelLoading}
          handleClick={handleModal}
          handleConfirm={handleDel}
          handlecloseModal={closeModal}
        />
      ) : null}
      {/* ****** Modal End ****** */}
    </Box>
  );
}
