import React from 'react';
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor
} from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '../../../components/Component';
import Select from 'react-select';

function Image({
  image,
  removeImage,
  index,
  handleAmenitiesLabelChange
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ddd',
    borderRadius: '4px',
    width: '100%',
    height: 'auto',
    position: 'relative'
  };
  const linkType = [
    'All Items',
    // 'Create Auction',
    'Add Item',
    'All auctions',
    'NA'
  ];

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img
        src={
          !image?.file
            ? null
            : typeof image?.file === 'string'
            ? image?.file
            : URL.createObjectURL(image?.file)
        }
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderTopLeftRadius: '0.5rem'
        }}
        alt="Draggable"
      />
      <label style={{ width: '100%', padding: '6px' }}>{'Action Type*'}</label>
      <div className="form-control-select" style={{ width: '100%' }}>
        <Select
          placeholder="Action Type*"
          className={`react-select-container`}
          classNamePrefix="react-select"
          isMulti={false}
          options={linkType.map((e) => ({ label: e, value: e }))}
          defaultValue={{ label: image?.type, value: image?.type }}
          onChange={handleAmenitiesLabelChange('type', index)}
        />
      </div>

      <button
        type="button"
        onClick={() => removeImage(index)}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '999',
          padding: '0.5rem'
        }}
        className="bg-danger"
      >
        <Icon
          name="trash"
          className="text-white"
          style={{ cursor: 'pointer' }}
        />
      </button>
    </div>
  );
}

function DnDKitDemo({ images, setImages, removeImage, desc = true }) {
  const handleAmenitiesLabelChange = (name, index) => (e) => {
    let newArr = images?.map((item, i) => {
      if (index === i) {
        return { ...item, [name]: e.value };
      } else {
        return item;
      }
    });

    setImages(newArr);
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const oldIndex = images.findIndex((image) => image.id === active.id);
    const newIndex = images.findIndex((image) => image.id === over.id);

    if (oldIndex === newIndex) return;

    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      const [movedImage] = updatedImages.splice(oldIndex, 1);
      updatedImages.splice(newIndex, 0, movedImage);
      return updatedImages;
    });
  };

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px'
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={images} strategy={rectSortingStrategy}>
        <div style={gridContainerStyle}>
          {images.map((image, index) => (
            <Image
              key={image.id}
              index={index}
              image={image}
              removeImage={removeImage}
              handleAmenitiesLabelChange={handleAmenitiesLabelChange}
              desc={desc}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export default DnDKitDemo;
