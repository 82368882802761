import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Badge,
  Spinner,
  ButtonGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  debouncedFetchUsers,
  fetchUsers,
  setAllSelected,
  setSelected,
} from "../../redux/features/usersSlice/usersSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  debouncedFetchAuctioneerRequests,
  fetchAuctioneerRequests,
} from "../../redux/features/approvalsSlice/auctioneerRequestsSlice";

const AuctioneerRequests = () => {
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [type, setType] = useState("Dealers");
  const [filter, setFilter] = useState("active");
  let oldFilter = "active";

  const { data, totalCount, loading } = useSelector((state) => state.auctioneerRequests);

  const dispatch = useDispatch();

  // Changing state value when searching name
  useEffect(() => {
    if (filter != oldFilter) {
      setCurrentPage(1);
      oldFilter = filter;
    }
    if (onSearchText !== "") {
      dispatch(
        debouncedFetchAuctioneerRequests({ searchText: onSearchText.trim(), page: currentPage, status: filter, type })
      );
    } else {
      dispatch(fetchAuctioneerRequests({ page: currentPage, status: filter, type }));
    }
  }, [onSearchText.trim(), currentPage, filter, type]);

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Auctioneer Requests"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Auctioneer Requests</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: "block" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="user name,phone,email"
                          onChange={(e) => {}}
                        />
                      </div>
                    </li>
                    <li>
                      <ButtonGroup>
                        <Button
                          color="secondary"
                          outline
                          onClick={() => setType("Dealers")}
                          active={type === "Dealers"}
                        >
                          Dealers
                        </Button>
                        <Button
                          color="secondary"
                          outline
                          onClick={() => setType("EndUsers")}
                          active={type === "EndUsers"}
                        >
                          End Users
                        </Button>
                      </ButtonGroup>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          Status
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setFilter("active")}>
                                <span>Active</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setFilter("inactive")}>
                                <span>Inactive</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button className="toggle btn-icon d-md-none" color="primary" onClick={() => {}}>
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button className="toggle d-none d-md-inline-flex" color="primary" onClick={() => {}}>
                        <Icon name="plus"></Icon>
                        <span>Add User</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                  />
                  <label className="custom-control-label" htmlFor="pid-all"></label>
                </div>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Name</span>
              </DataTableRow>

              <DataTableRow size="md">
                <span className="sub-text">Email</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Phone</span>
              </DataTableRow>

              <DataTableRow size="md">
                <span className="sub-text">Created</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Status</span>
              </DataTableRow>

              <DataTableRow className="nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1 my-n1">
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="btn btn-trigger dropdown-toggle btn-icon me-n1">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#markasdone"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="truck"></Icon>
                              <span>Mark As Delivered</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#remove"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="trash"></Icon>
                              <span>Remove Orders</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </DataTableRow>
            </DataTableHead>

            {data?.length > 0
              ? data.map((item, index) => (
                  <DataTableItem key={item._id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={item.check}
                          id={item._id + "oId-all"}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, index)}
                        />
                        <label className="custom-control-label" htmlFor={item._id + "oId-all"}></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <Link to={`/users/${item._id}/approvals`}>{item.firstName}</Link>
                    </DataTableRow>
                    <DataTableRow>{item.email}</DataTableRow>
                    <DataTableRow>{item.phone}</DataTableRow>

                    <DataTableRow size="md">
                      <span>{new Date(item.createdAt).toDateString()}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className={`dot bg-${item.active === true ? "success" : "warning"} d-sm-none`}></span>
                      <Badge
                        className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                        color={item.active === true ? "success" : "warning"}
                      >
                        {item.active ? "Active" : "Inactive"}
                      </Badge>
                    </DataTableRow>

                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li className="nk-tb-action-hidden" onClick={() => {}}>
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={"view" + item.id}
                            icon="eye"
                            direction="top"
                            text="View Details"
                          />
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>Edit</span>
                                  </DropdownItem>
                                </li>
                                {item.active === true && (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdown"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="truck"></Icon>
                                      <span>Approve</span>
                                    </DropdownItem>
                                  </li>
                                )}
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Delete User</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {data?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No Auctioneer Request found</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AuctioneerRequests;
