import React, { useEffect, useState } from 'react';
import { Button, Input, Spinner } from 'reactstrap';
import { InputSwitch, RSelect } from '../../../components/Component';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { createOrUpdateSettings } from '../../../redux/features/verticalsSlice/createOrUpdateSettingsSlice';
import { startCase } from 'lodash';
import DnDKitDmo from './DnDKitDemo';
import { toast } from 'react-toastify';
import {
  getImage,
  uploadManyToS3,
  uploadToS3
} from '../../../utils/uploadImageToS3';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import PriceInput from './PriceInput';
import { ItemListMappings } from '../../../utils/Utils';
import {
  clearOptions,
  fetchAutocompleteOptions
} from '../../../redux/features/verticalsSlice/autocompleteOptionsSlice';
import ConfirmModal from './ConfirmModal';
import useDragAndDrop from '../../../utils/hooks/useDragAndDropImage';
import InputFile from '../../cars/components/InputFile';

export default function ItemListFieldMappingSettings() {
  const { data } = useSelector((state) => state.verticalDetails);
  const options = useSelector(
    (state) => state?.autocompleteOptions?.data?.data
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState([]);
  const [imageFields, setImageFields] = useState();
  const [advancedBannerCheck, setAdvancedBannerCheck] = useState(false);
  const [conciergeBannerCheck, setConciergeBannerCheck] = useState(false);
  const [simpleForm, setSimpleForm] = useState(true);
  const [advancedForm, setAdvancedForm] = useState(false);
  const [conciergeForm, setConciergeForm] = useState(false);
  const [simpleBannerImg, setSimpleBannerImg] = useState(null);
  const [advancedBannerImg, setAdvancedBannerImg] = useState(null);
  const [conciergeBannerImg, setConciergeBannerImg] = useState(null);
  const [defaultImg, setDefaultImg] = useState(null);
  const [defaultImgEdit, setDefaultImgEdit] = useState(false);

  const [simpleBanner, setSimpleBanner] = useState(null);
  const [advancedBanner, setAdvancedBanner] = useState(null);
  const [conciergeBanner, setConciergeBanner] = useState(null);

  const [compactCard, setCompactCard] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);

  const [simpleBannerEdit, setSimpleBannerEdit] = useState(null);
  const [advancedBannerEdit, setAdvancedBannerEdit] = useState(null);
  const [conciergeBannerEdit, setConciergeBannerEdit] = useState(null);

  const [banners, setBanners] = useState(null);
  const [bannersImg, setBannersImg] = useState(null);

  const [bannerEdit, setBannerEdit] = useState(false);

  const [bidIncrementOne, setBidIncrementOne] = useState(null);
  const [bidIncrementTwo, setBidIncrementTwo] = useState(null);
  const [bidIncrementThree, setBidIncrementThree] = useState(null);

  const [maxBidNumber, setMaxBidNumber] = useState(null);
  const [duration, setDuration] = useState(null);

  const [auctionStartingPrice, setAuctionStartingPrice] = useState(null);
  const [isAuctionPriceDynamic, setIsAuctionPriceDynamic] = useState(false);
  const [auctionPricePercentage, setAuctionPricePercentage] = useState(null);
  const [auctionFixed, setAuctionFixed] = useState(null);
  const [auctionPrice, setAuctionPrice] = useState(null);

  const [shouldSearch, setShouldSearch] = useState(false);
  const [searchableFields, setSearchableFields] = useState(null);
  const [relatedField, setRelatedField] = useState(null);
  const [shouldVerify, setShouldVerify] = useState(false);
  const [verifyAmount, setVerifyAmount] = useState(null);
  const [shipping, setShipping] = useState(false);

  const [primary, setPrimary] = useState(null);
  const [secondary, setSecondary] = useState(null);

  const [trendingField, setTrendingField] = useState(null);
  const [trendingSearchEnabled, setTrendingSearchEnabled] = useState(false);
  const [durationChanged, setDurationChanged] = useState(false);
  // const [trendingSearch,setTrendingSearch] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [amenitiesFilesDev, setAmenitiesFilesDev] = useState(null);

  const [errors, setErrors] = useState({});

  const [mappings, setMappings] = useState({});

  const [formData, setFormData] = useState({});

  const {
    active: activeAmenities,
    errorMessage: amenitiesStatus,
    dragenter: amenitiesDragEnter,
    dragleave: amenitiesDragLeave,
    drop: amenitiesDropVideo,
    handleFileChange: amenitiesFileChange,
    files: amenitiesFiles,
    setFiles: setAmenitiesFiles,
    removeImage: removeAmenityImage
  } = useDragAndDrop({
    multiple: true,
    inputType: 'image',
    maxFilesLength: 10,
    minFilesLength: 8,
    labelName: 'type'
  });

  const durationOptions = [
    '5 Mins',
    '10 Mins',
    '20 Mins',
    '1 Hr',
    '3 Hrs',
    '4 Hrs',
    '5 Hrs',
    '6 Hrs',
    '12 Hrs',
    '24 Hrs',
    '48 Hrs',
    '72 Hrs',
    '120 Hrs',
    '168 Hrs',
    '720 Hrs'
  ];

  const getDurationFromString = (data) => {
    switch (data) {
      case '5 Mins':
        return 300000;
      case '10 Mins':
        return 600000;
      case '20 Mins':
        return 1200000;
      case '1 Hr':
        return 3600000;
      case '2 Hrs':
        return 3600000 * 2;
      case '3 Hrs':
        return 3600000 * 3;
      case '4 Hrs':
        return 3600000 * 4;
      case '5 Hrs':
        return 3600000 * 5;
      case '6 Hrs':
        return 3600000 * 6;
      case '12 Hrs':
        return 43200000;
      case '24 Hrs':
        return 86400000;
      case '48 Hrs':
        return 172800000;
      case '72 Hrs':
        return 259200000;
      case '120 Hrs':
        return 432000000;
      case '168 Hrs':
        return 604800000;
      case '720 Hrs':
        return 2592000000;
    }
  };
  const getStringFromDuration = (milliseconds) => {
    switch (milliseconds) {
      case 300000:
        return '5 Mins';
      case 600000:
        return '10 Mins';
      case 1200000:
        return '20 Mins';
      case 3600000:
        return '1 Hr';
      case 3600000 * 2:
        return '2 Hrs';
      case 3600000 * 3:
        return '3 Hrs';
      case 3600000 * 4:
        return '4 Hrs';
      case 3600000 * 5:
        return '5 Hrs';
      case 3600000 * 6:
        return '6 Hrs';
      case 43200000:
        return '12 Hrs';
      case 86400000:
        return '24 Hrs';
      case 172800000:
        return '48 Hrs';
      case 259200000: // Added case for 72 Hrs
        return '72 Hrs';
      case 432000000: // Added case for 120 Hrs
        return '120 Hrs';
      case 2592000000: // Added case for 720 Hrs
        return '720 Hrs';
      case 604800000: // Added case for 128 Hrs
        return '128 Hrs';
      default:
        return 'Unknown';
    }
  };
  const handleBannerUploadSimple = (e, fieldName) => {
    const file = e.target.files[0]; // Get the selected file from the event object
    setSimpleBanner(file);
    setSimpleBannerEdit(true);
    setSimpleBannerImg(URL.createObjectURL(file));
  };
  const handleBannerUploadAdvanced = (e, fieldName) => {
    const file = e.target.files[0]; // Get the selected file from the event object
    setAdvancedBanner(file);
    setAdvancedBannerEdit(true);
    setAdvancedBannerImg(URL.createObjectURL(file));
  };
  const handleBannerUploadConcierge = (e, fieldName) => {
    const file = e.target.files[0]; // Get the selected file from the event object
    setConciergeBanner(file);
    setConciergeBannerEdit(true);
    setConciergeBannerImg(URL.createObjectURL(file));
  };
  const handleDefaultImgUpload = (e) => {
    const file = e.target.files[0]; // Get the selected file from the event object
    setDefaultImage(file);
    setDefaultImgEdit(true);
    setDefaultImg(URL.createObjectURL(file));
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleBannersUpload = (e) => {
    const files = e.target.files; // Get the selected file from the event object
    setBanners(files);
    setBannerEdit(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        let fields = data?.baseformsettings?.tabs
          ?.map((e) => [...e.fields])
          .flat();
        let nfields = fields?.filter((e) => e.type !== 'image');
        let ifields = fields?.filter(
          (e) => e.type === 'image' || e.type === 'multipleImages'
        );

        setFields(nfields);
        setImageFields(ifields);

        const mappings = {};
        const item_list_mappings =
          data?.verticalsettings_id?.item_list_mappings;
        if (item_list_mappings) {
          for (const key in item_list_mappings) {
            mappings[key] = {
              label: startCase(item_list_mappings[key]),
              value: item_list_mappings[key]
            };
          }
        }
        setAuctionStartingPrice(
          data?.verticalsettings_id?.auctionStartingPrice
        );
        if (data?.verticalsettings_id?.simpleForm)
          setSimpleForm(data?.verticalsettings_id?.simpleForm);
        if (data?.verticalsettings_id?.advancedForm)
          setAdvancedForm(data.verticalsettings_id.advancedForm);
        if (data?.verticalsettings_id?.conciergeForm)
          setConciergeForm(data?.verticalsettings_id?.conciergeForm);

        setAuctionFixed(data?.verticalsettings_id?.auctionFixed);
        setAuctionPrice(data?.verticalsettings_id?.auctionPrice);
        setIsAuctionPriceDynamic(
          data?.verticalsettings_id?.isAuctionPriceDynamic
        );
        setAuctionPricePercentage(
          data?.verticalsettings_id?.auctionPricePercentage
        );

        setBidIncrementOne(data?.verticalsettings_id?.bidIncrementOne);
        setBidIncrementTwo(data?.verticalsettings_id?.bidIncrementTwo);
        setBidIncrementThree(data?.verticalsettings_id?.bidIncrementThree);
        setMaxBidNumber(data?.verticalsettings_id?.maxBidNumber);

        setDuration(
          data?.verticalsettings_id?.duration?.map((s) => ({
            label: getStringFromDuration(s),
            value: getStringFromDuration(s)
          }))
        );

        setShouldVerify(data?.verticalsettings_id?.shouldVerify);
        setVerifyAmount(data?.verticalsettings_id?.verifyAmount);
        setShouldSearch(data?.verticalsettings_id?.shouldSearch);

        setCompactCard(data?.verticalsettings_id?.compactCard);
        setShipping(data?.verticalsettings_id?.shipping);
        setSearchableFields(
          data?.verticalsettings_id?.searchableFields?.map((s) => ({
            label: s,
            value: s
          }))
        );

        setRelatedField(
          data?.verticalsettings_id?.relatedField?.map((s) => ({
            label: s,
            value: s
          }))
        );

        setPrimary({
          label: data?.verticalsettings_id?.primary,
          value: data?.verticalsettings_id?.primary
        });
        setSecondary({
          label: data?.verticalsettings_id?.secondary,
          value: data?.verticalsettings_id?.secondary
        });

        setTrendingField({
          label: data?.verticalsettings_id?.trendingField,
          value: data?.verticalsettings_id?.trendingField
        });
        // setTrendingSearch(data?.verticalsettings_id?.trendingSearch);
        setTrendingSearchEnabled(
          data?.verticalsettings_id?.trendingSearchEnabled
        );

        // Fetch the image data asynchronously and set it once it's available
        const simpleBannerImgData = await getImage(
          data?.verticalsettings_id?.simpleBanner
        );
        setSimpleBannerImg(simpleBannerImgData);
        // For advanced
        const advancedBannerImgData = await getImage(
          data?.verticalsettings_id?.advancedBanner
        );
        setAdvancedBannerImg(advancedBannerImgData);

        // For concierge
        const conciergeBannerImgData = await getImage(
          data?.verticalsettings_id?.conciergeBanner
        );
        setConciergeBannerImg(conciergeBannerImgData);

        const defaultImgData = await getImage(
          data?.verticalsettings_id?.defaultImage
        );
        setDefaultImg(defaultImgData);

        setMappings(mappings);
        const banners = data?.verticalsettings_id?.banners; // Assuming 'banners' is an array of banner data

        const fetchAndSetBanners = async () => {
          const bannerImages = [];

          // Loop through each banner and fetch its image data
          for (const banner of banners) {
            const bannerImgData = await getImage(banner);
            bannerImages.push(bannerImgData);
          }

          // Set the fetched banner images using setBannersImg
          setBannersImg(bannerImages);
        };

        // Call the function to fetch and set banner images
        fetchAndSetBanners();
        setAmenitiesFiles([]);
        if (data?.verticalsettings_id?.newBanners) {
          const amenities = await Promise.all(
            data?.verticalsettings_id?.newBanners?.map(async (item, index) => {
              const src = await getImage(item?.photo);

              return {
                ...item,
                id: item?._id ?? uuidv4(),
                image: src,
                prevPhoto: item?.photo,
                file: src,
                index: index
              };
            })
          );
          setAmenitiesFilesDev(amenities);
        }
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, [data]);

  useEffect(() => {
    setAmenitiesFiles(amenitiesFilesDev);
  }, [amenitiesFilesDev]);
  useEffect(() => {
    if (simpleForm && advancedForm) {
      setAdvancedBannerCheck(true);
    } else {
      setAdvancedBannerCheck(false);
    }
    if (simpleForm && conciergeForm) {
      setConciergeBannerCheck(true);
    } else {
      setConciergeBannerCheck(false);
    }
  }, [simpleForm, advancedForm, conciergeForm]);

  const dispatch = useDispatch();

  const onItemListSwitchUpdated = async (e) => {
    e.preventDefault();
    if (!auctionStartingPrice) {
      toast.error('Please enter auction starting price');
      setErrors((prev) => ({
        ...prev,
        auctionStartingPrice: 'Cannot be zero or empty'
      }));
      return;
    }
    // else if(advancedBannerCheck && !advancedBanner){
    //   toast.error("Please upload Advanced Banner images");
    //   return;
    // }
    // else if(conciergeBannerCheck && !conciergeBanner){
    //   toast.error("Please upload Concierge Banner images");
    //   return;
    // }
    else if (!isAuctionPriceDynamic && !auctionPrice) {
      toast.error('Please enter auction price');
      setErrors((prev) => ({
        ...prev,
        auctionPrice: 'Cannot be zero or empty'
      }));
      return;
    } else if (
      isAuctionPriceDynamic &&
      (!auctionPricePercentage || !auctionFixed)
    ) {
      toast.error('Please enter percentage vale and fixed amount');
      setErrors((prev) => ({
        ...prev,
        auctionPricePercentage: 'Cannot be zero or empty',
        auctionFixed: 'Cannot be zero or empty'
      }));
      return;
    } else if (shouldVerify && !verifyAmount) {
      toast.error('Please enter verify amount');
      setErrors((prev) => ({
        ...prev,
        verifyAmount: 'Cannot be zero or empty'
      }));
      return;
    } else if (!amenitiesFiles || amenitiesFiles?.length === 0) {
      toast.error('Please upload atleast one banner');
      return;
    } else if (amenitiesFiles?.length > 0) {
      const nullAmenity = amenitiesFiles.find((item) => !item.type);

      if (nullAmenity)
        return toast.error('All banners must have an action type');
    } else {
    }

    try {
      setLoading(true);
      if (simpleBanner && simpleBannerEdit) {
        const simpleBannerName = `banners/simple/${uuidv4()}.${simpleBanner?.name
          ?.split('.')
          ?.pop()}`;
        await uploadToS3(simpleBanner, simpleBannerName);
        formData.simpleBanner = simpleBannerName;
      }
      if (advancedBanner && advancedBannerEdit) {
        const advancedBannerName = `banners/advanced/${uuidv4()}.${advancedBanner?.name
          ?.split('.')
          ?.pop()}`;
        await uploadToS3(advancedBanner, advancedBannerName);
        formData.advancedBanner = advancedBannerName;
      }
      if (conciergeBanner && conciergeBannerEdit) {
        const conciergeBannerName = `banners/advanced/${uuidv4()}.${conciergeBanner?.name
          ?.split('.')
          ?.pop()}`;
        await uploadToS3(conciergeBanner, conciergeBannerName);
        formData.conciergeBanner = conciergeBannerName;
      }
      if (defaultImage && defaultImgEdit) {
        const defaultImageName = `images/default/${uuidv4()}.${defaultImage?.name
          ?.split('.')
          ?.pop()}`;
        await uploadToS3(defaultImage, defaultImageName);
        formData.defaultImage = defaultImageName;
      }
      // if (banners?.length > 0 && bannerEdit) {
      //   const fileListArray = Array.from(banners);
      //   const images = fileListArray?.map((doc) => ({
      //     file: doc,
      //     fileName: `banners/home/${uuidv4()}.${doc?.name
      //         ?.split(".")
      //         ?.pop()}`,
      //     type: doc?.type ?? "image/*",
      // }));
      // await uploadManyToS3(images);
      //   // Update the formData with the new array of filenames
      //   let newBanners =images.map(banner=>banner.fileName);
      //   formData.banners = newBanners
      // }

      if (amenitiesFiles?.length > 0) {
        let uploadImages = [];
        amenitiesFiles?.map(
          (doc) =>
            typeof doc?.file !== 'string' &&
            uploadImages.push({
              file: doc.file,
              fileName: doc.fileName,
              type: doc?.type ?? 'image/*'
            })
        );

        uploadImages?.length > 0 && (await uploadManyToS3(uploadImages));
        // Update the formData with the new array of filenames
        let updatedBanners = amenitiesFiles.map((x) => ({
          photo: x.fileName ?? x.photo,
          type: x.type
        }));
        formData.newBanners = updatedBanners;
      }
      if (durationChanged) {
        formData.duration = duration?.map((d) =>
          getDurationFromString(d.value)
        );
      }
      // console.log(duration)
      const finalData = {
        id: data.verticalsettings_id.id,
        data: {
          ...formData,
          simpleForm,
          advancedForm,
          conciergeForm,
          auctionStartingPrice,
          auctionPricePercentage,
          isAuctionPriceDynamic,
          auctionFixed,
          auctionPrice,
          shouldVerify,
          verifyAmount,
          bidIncrementOne,
          bidIncrementTwo,
          bidIncrementThree,
          maxBidNumber,
          // duration:duration?.map(d=>getDurationFromString(d.value)),
          searchableFields: searchableFields?.map((s) => s.value),
          shouldSearch,
          shipping,
          compactCard,
          trendingField: trendingField?.key,
          primary: primary?.value,
          secondary: secondary?.value,
          relatedField: relatedField?.map((s) => s.value),
          trendingSearchEnabled
        },
        vertical_id: data.id
      };

      // console.log(finalData)
      dispatch(createOrUpdateSettings(finalData));
      setLoading(false);
      toast.success('Saved');
      navigate(0);
    } catch (err) {
      toast.error(err);
    }
  };
  const onItemListMappingUpdated = () => {
    let obj = {};

    const ddd = Object.entries(mappings).map(([key, value]) => ({
      [key]: Array.isArray(value)
        ? value.length > 1
          ? value.map((v) => v.value).join(',')
          : value[0].value
        : value.value
    }));

    ddd.map((e) => {
      Object.entries(e).map(([key, value]) => (obj[key] = value));
    });

    for (const key in ItemListMappings) {
      if (!obj.hasOwnProperty(key)) {
        toast.error(`${ItemListMappings[key]} is missing from the data.`);
        return;
      }
    }
    const d = {
      id: data.verticalsettings_id.id,
      data: { item_list_mappings: obj },
      vertical_id: data.id
    };

    try {
      dispatch(createOrUpdateSettings(d));
      toast.success('Saved');
      navigate(0);
    } catch (err) {
      toast.error(err);
    }
  };

  const onMappingsChanged = (name, value) => {
    let field = { ...mappings } ?? {};
    field[name] = value;
    // console.log(field);
    setMappings(field);
  };
  const fieldMappingValue = (name) => {
    return mappings[name] ? mappings[name] : null;
  };

  useEffect(() => {
    if (trendingField?.label === 'Make') {
      const data = {
        query: {
          key: 'CAR_MAKE',
          search: ''
        },
        options: {
          page: 1,
          limit: 800,
          sort: 'value'
        }
      };
      dispatch(fetchAutocompleteOptions(data));
    }
  }, [trendingField]);
  useEffect(() => {
    return () => {
      dispatch(clearOptions());
    };
  }, [dispatch]);
  return (
    <>
      <div style={{ padding: '1rem', borderRadius: '4px' }}>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Pricing</h6>

            <div className="note-text">
              *If dynamic pricing, Auction price will be Percentage of Auction
              starting price+Fixed Amount
            </div>
          </div>

          <div className="rows">
            <div className="data-col">
              <div className="data-label">Minimum Starting Price (AED) </div>
              <div className="data-value">
                <PriceInput
                  error={errors?.auctionStartingPrice}
                  value={auctionStartingPrice}
                  onChange={(e) => {
                    setAuctionStartingPrice(e.target.value);
                    setErrors((prev) => ({
                      ...prev,
                      auctionStartingPrice: null
                    }));
                  }}
                  label="AED"
                />
              </div>
            </div>
          </div>
          <div className="rows">
            <div className="data-col">
              <div className="data-label">Auction price type </div>

              <div className="data-value">
                <label
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span>Fixed</span>
                  <InputSwitch
                    label=""
                    checked={isAuctionPriceDynamic}
                    id="isAuctionPriceDynamic-"
                    onClick={(e) => {
                      setIsAuctionPriceDynamic(e);
                      setAuctionPrice(null);
                    }}
                  />
                  <span>Dynamic</span>
                </label>
              </div>
            </div>
          </div>
          {!isAuctionPriceDynamic ? (
            <div className="rows">
              <div className="data-col">
                <div className="data-label">Auction Price (AED) </div>
                <div className="data-value">
                  <PriceInput
                    error={errors?.auctionPrice}
                    value={auctionPrice}
                    onChange={(e) => {
                      setAuctionPrice(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        auctionPrice: null
                      }));
                    }}
                    label="AED"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Percentage (%) </div>
                  <div className="form-control-wrap">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">%</span>
                      </div>
                      <Input
                        type="text"
                        id="auctionPricePercentage"
                        onInput={(e) => {
                          let newValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                          if (newValue.length > 2) {
                            // Limit length to 2 characters
                            newValue = newValue.slice(0, 2);
                          }
                          e.target.value = newValue;
                        }}
                        className="form-control"
                        name="auctionPricePercentage"
                        value={auctionPricePercentage}
                        onChange={(e) => {
                          setAuctionPricePercentage(e.target.value);
                          // calculateAuctionPrice(auctionStartingPrice,e.target.value,auctionFixed)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Fixed Amount (AED) </div>
                  <div className="data-value">
                    <PriceInput
                      error={errors?.auctionFixed}
                      label="AED"
                      value={auctionFixed}
                      onChange={(e) => {
                        setAuctionFixed(e.target.value);
                        setErrors((prev) => ({
                          ...prev,
                          auctionFixed: null
                        }));
                        // calculateAuctionPrice(auctionStartingPrice,auctionPricePercentage,e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* {isAuctionPriceDynamic && auctionFixed && auctionPricePercentage&& auctionStartingPrice&&(
<> 

              <div className="rows">
                
            <div className="data-label">Auction Price (AED)  = (Min Starting Price * (Percentage / 100)) + Fixed Amount</div>
          
            <div className="data-value">
            <PriceInput
              value={auctionPrice}
              disabled={true}
              label="AED"
                    />
                 
          </div>
             
          </div>
          </>
        )} */}
            </>
          )}
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Bids</h6>
            </div>
            <div className="rows">
              <div className="data-col">
                <div className="data-label">Bid Increment One</div>
                <div className="data-value">
                  <PriceInput
                    error={errors?.bidIncrementOne}
                    label="AED"
                    value={bidIncrementOne}
                    onChange={(e) => {
                      setBidIncrementOne(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        bidIncrementOne: null
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="rows">
              <div className="data-col">
                <div className="data-label">Bid Increment Two</div>
                <div className="data-value">
                  <PriceInput
                    error={errors?.bidIncrementTwo}
                    label="AED"
                    value={bidIncrementTwo}
                    onChange={(e) => {
                      setBidIncrementTwo(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        bidIncrementTwo: null
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="rows">
              <div className="data-col">
                <div className="data-label">Bid Increment Three</div>
                <div className="data-value">
                  <PriceInput
                    error={errors?.bidIncrementThree}
                    label="AED"
                    value={bidIncrementThree}
                    onChange={(e) => {
                      setBidIncrementThree(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        bidIncrementThree: null
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            {/* <div className="rows">
          <div className="data-col">
            <div className="data-label">Maximum number of bids allowed without verification</div>
            <div className="data-value">
            <Input
                      type="number"
                      id="maxBidNumber"
                      onInput={(e) => {
                        let newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers 
                    
                        e.target.value = newValue;
                      }}
                      className="form-control" 
                      name="maxBidNumber"
                      value={maxBidNumber}
                      onChange={(e)=>{
                        setMaxBidNumber(e.target.value)
                      }
                      }
                    />
          </div>
          </div>
          </div> */}
            <div className="nk-data data-list">
              <div className="data-head">
                <h6 className="overline-title">Auction</h6>
              </div>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Primary Field</div>
                  <div className="expanded">
                    <RSelect
                      isMulti={false}
                      options={fields?.map((e) => ({
                        label: e.label,
                        value: e.name
                      }))}
                      onChange={(e) => {
                        setPrimary(e);
                      }}
                      value={primary}
                    />
                  </div>
                </div>
              </div>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Secondary Field</div>
                  <div className="expanded">
                    <RSelect
                      isMulti={false}
                      options={fields?.map((e) => ({
                        label: e.label,
                        value: e.name
                      }))}
                      onChange={(e) => {
                        setSecondary(e);
                      }}
                      value={secondary}
                    />
                  </div>
                </div>
              </div>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Duration</div>
                  <div className="expanded">
                    <RSelect
                      isMulti={true}
                      options={durationOptions?.map((e) => ({
                        label: e,
                        value: e
                      }))}
                      onChange={(e) => {
                        setDuration(e);
                        setDurationChanged(true);
                      }}
                      value={duration}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="rows">
          <div className="data-col">
            <div className="data-label">Enable Search</div>
            <div className="data-value">
            <InputSwitch 
            label=""
            checked={shouldSearch} 
            id="shouldSearch-" 
            onClick={(e) => setShouldSearch(e)}
            />
          </div>
          </div>
          </div> */}
              {/* <div className="rows">
          <div className="data-col">
            <div className="data-label">Searchable Fields</div>
            <div className="expanded">
              <RSelect
              isMulti={true}
                options={fields?.map((e) => ({ label: e.label, value: e.name }))}
                onChange={(e) => {
                  setSearchableFields(e);
                }}
                value={searchableFields}
              />
            </div>
          </div>
          </div> */}
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">
                    Related Field(for dashboard to show related auctions)
                  </div>
                  <div className="expanded">
                    <RSelect
                      isMulti={true}
                      options={fields?.map((e) => ({
                        label: e.label,
                        value: e.name
                      }))}
                      onChange={(e) => {
                        setRelatedField(e);
                      }}
                      value={relatedField}
                    />
                  </div>
                </div>
              </div>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Enable Trending Search </div>
                  <div className="data-value">
                    <InputSwitch
                      label=""
                      checked={trendingSearchEnabled}
                      id="trendingSearchEnabled-"
                      onClick={(e) => setTrendingSearchEnabled(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Trending search field</div>
                  <div className="expanded">
                    <RSelect
                      isMulti={false}
                      options={fields?.map((e) => ({
                        label: e.label,
                        value: e.name,
                        key: e.key
                      }))}
                      onChange={(e) => {
                        setTrendingField(e);
                      }}
                      value={trendingField}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="rows">
          <div className="data-col">
            <div className="data-label">Trending search values</div>
            <div className="expanded">
              <RSelect
               isMulti={true}
                options={options?.map((e) => ({ label: e.value, value: e.id,icon:e.icon }))}
                onChange={(e) => {
                  setTrendingSearch(e);
                }}
                value={trendingSearch}
              />
            </div>
          </div>
          </div> */}
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Shipping</div>
                  <div className="data-value">
                    <InputSwitch
                      label=""
                      checked={shipping}
                      id="shouldSearch-"
                      onClick={(e) => setShipping(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">Enable Verification</div>
                  <div className="data-value">
                    <InputSwitch
                      label=""
                      checked={shouldVerify}
                      id="shouldVerify-"
                      onClick={(e) => setShouldVerify(e)}
                    />
                  </div>
                </div>
              </div>
              {shouldVerify && (
                <div className="rows">
                  <div className="data-col">
                    <div className="data-label">Verify Amount(AED) </div>
                    <div className="data-value">
                      <PriceInput
                        error={errors?.verifyAmount}
                        value={verifyAmount}
                        onChange={(e) => {
                          setVerifyAmount(e.target.value);
                          setErrors((prev) => ({
                            ...prev,
                            verifyAmount: null
                          }));
                          // calculateAuctionPrice(e.target.value,auctionPricePercentage,auctionFixed)
                        }}
                        label="AED"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ padding: '1rem', borderRadius: '4px' }}>
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">
                Enable/Disable Inspection forms
              </h6>
            </div>
            <div className="rows">
              <div className="data-col">
                <div className="data-label">Simple</div>
                <div className="data-value">
                  <InputSwitch
                    label=""
                    checked={simpleForm}
                    id="simple-"
                    onClick={(e) => setSimpleForm(e)}
                  />
                </div>
              </div>
            </div>
            <div className="rows">
              <div className="data-col">
                <div className="data-label">Advanced</div>
                <div className="data-value">
                  <InputSwitch
                    label=""
                    checked={advancedForm}
                    id="advanced-"
                    onClick={(e) => setAdvancedForm(e)}
                  />
                </div>
              </div>
            </div>
            <div className="rows">
              <div className="data-col">
                <div className="data-label">Concierge</div>
                <div className="data-value">
                  <InputSwitch
                    label=""
                    checked={conciergeForm}
                    id="concierge-"
                    onClick={(e) => setConciergeForm(e)}
                  />
                </div>
              </div>
            </div>
            <div className="nk-data data-list">
              <div className="data-head">
                <h6 className="overline-title">Banner Images</h6>
              </div>
              {(advancedForm || conciergeForm) && (
                <>
                  <div className="rows">
                    <span className="data-label expanded">
                      Banner for simple
                    </span>
                    <div className="form-control">
                      <div className="form-file">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e, file) =>
                            handleBannerUploadSimple(e, 'simpleBanner', file)
                          }
                          style={{ width: '100%' }}
                        />
                      </div>
                      {simpleBannerImg && (
                        <img src={simpleBannerImg} alt={'simple banner'} />
                      )}
                    </div>
                  </div>

                  <div className="rows">
                    <span className="data-label expanded">
                      Banner for advanced
                    </span>
                    <div className="form-control">
                      <div className="form-file">
                        <input
                          type="file"
                          accept="image/*"
                          required={advancedBanner}
                          onChange={(e, file) =>
                            handleBannerUploadAdvanced(
                              e,
                              'advancedBanner',
                              file
                            )
                          }
                          style={{ width: '100%' }}
                        />
                      </div>
                      {advancedBannerImg && (
                        <img src={advancedBannerImg} alt={'advanced banner'} />
                      )}
                    </div>
                  </div>
                  <div className="rows">
                    <span className="data-label expanded">
                      Banner for concierge
                    </span>
                    <div className="form-control">
                      <div className="form-file">
                        <input
                          type="file"
                          accept="image/*"
                          required={conciergeBanner}
                          onChange={(e, file) =>
                            handleBannerUploadConcierge(
                              e,
                              'conciergeBanner',
                              file
                            )
                          }
                          style={{ width: '100%' }}
                        />
                      </div>
                      {conciergeBannerImg && (
                        <img
                          src={conciergeBannerImg}
                          alt={'concierge banner'}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div
                className=""
                style={{ marginTop: '10px', marginBottom: '12px' }}
              >
                <span className="data-label expanded">
                  Banners for home page
                </span>
                <div className="form-control">
                  <div className="form-file">
                    {/* <input type="file" 
            accept="image/*"
            multiple={true}
              required={banners}
              onChange={(e) => handleBannersUpload(e)}
            style={{ width: "100%" }} /> */}
                  </div>
                  {/* {bannersImg?.length > 0 && bannersImg.map((banner, index) => (
                <img
                  key={index} 
                  src={banner}
                  alt="banner"
                  style={{
                    display: 'inline-flex', 
                    width: '100px', 
                    marginRight: '10px', 
                  }}
                />
              ))} */}
                  <div>
                    <InputFile
                      active={activeAmenities}
                      dragenter={amenitiesDragEnter}
                      dragleave={amenitiesDragLeave}
                      drop={amenitiesDropVideo}
                      errorMessage={amenitiesStatus}
                      files={amenitiesFiles}
                      handleFileChange={amenitiesFileChange}
                      inputType="image"
                      removeImage={removeAmenityImage}
                      multiple
                      inputProps={{
                        accept: 'image/*'
                      }}
                    />

                    <div style={{ display: 'flex' }}></div>
                  </div>
                  {amenitiesFiles && amenitiesFiles.length > 0 && (
                    <DnDKitDmo
                      images={amenitiesFiles}
                      setImages={setAmenitiesFiles}
                      removeImage={removeAmenityImage}
                    />
                  )}
                </div>
              </div>
              <div className="rows">
                <div className="data-col">
                  <div className="data-label">
                    Compact card(*If image is not applicable for adding item,
                    turn on)
                  </div>
                  <div className="data-value">
                    <InputSwitch
                      label=""
                      checked={compactCard}
                      id="compactCard-"
                      onClick={(e) => setCompactCard(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="rows">
                <span className="data-label expanded">
                  Default Image(* To display if no image for item)
                </span>
                <div className="form-control">
                  <div className="form-file">
                    <input
                      type="file"
                      accept="image/*"
                      required={defaultImage}
                      onChange={(e, file) => handleDefaultImgUpload(e)}
                      style={{ width: '100%' }}
                    />
                  </div>
                  {defaultImg && <img src={defaultImg} alt={'Default'} />}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
            >
              {loading ? <Spinner size="sm" /> : '  Save'}
            </Button>
          </div>
        </div>
      </div>
      <div style={{ padding: '1rem', marginTop: '4rem', borderRadius: '4px' }}>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Item List Card Details</h6>
            <span className="sub-text">Data taken from item table</span>
            <div className="note-text">
              *Please make sure to map all the fields.
            </div>
          </div>
          <div hidden={fields}>Add basic form to set the fields</div>
          <div hidden={!fields}>
            <div className="rows">
              <span className="data-label expanded">Title</span>
              <div className="expanded">
                <RSelect
                  isMulti={true}
                  options={fields?.map((e) => ({
                    label: e.label,
                    value: e.name
                  }))}
                  onChange={(e) => {
                    onMappingsChanged('title', e);
                  }}
                  value={fieldMappingValue('title')}
                />
              </div>
            </div>
            <div className="rows">
              <span className="data-label expanded">Title Image</span>
              <div className="expanded">
                <RSelect
                  options={imageFields?.map((e) => ({
                    label: e.label,
                    value: e.name
                  }))}
                  onChange={(e) => {
                    onMappingsChanged('title_image', e);
                  }}
                  value={fieldMappingValue('title_image')}
                />
              </div>
            </div>
            <div className="rows">
              <span className="data-label expanded">Subtitle 1</span>

              <div className="expanded">
                <RSelect
                  options={fields?.map((e) => ({
                    label: e.label,
                    value: e.name
                  }))}
                  onChange={(e) => {
                    onMappingsChanged('subtitle1', e);
                  }}
                  value={fieldMappingValue('subtitle1')}
                />
              </div>
            </div>
            <div className="rows">
              <span className="data-label expanded">Subtitle 2</span>

              <div className="expanded">
                <RSelect
                  options={fields?.map((e) => ({
                    label: e.label,
                    value: e.name
                  }))}
                  onChange={(e) => {
                    onMappingsChanged('subtitle2', e);
                  }}
                  value={fieldMappingValue('subtitle2')}
                />
              </div>
            </div>
            <div className="rows">
              <span className="data-label expanded">Subtitle 3</span>

              <div className="expanded">
                <RSelect
                  options={fields?.map((e) => ({
                    label: e.label,
                    value: e.name
                  }))}
                  onChange={(e) => {
                    onMappingsChanged('subtitle3', e);
                  }}
                  value={fieldMappingValue('subtitle3')}
                />
              </div>
            </div>
            <div className="rows">
              <span className="data-label expanded">Subtitle 4</span>

              <div className="expanded">
                <RSelect
                  options={fields?.map((e) => ({
                    label: e.label,
                    value: e.name
                  }))}
                  onChange={(e) => {
                    onMappingsChanged('subtitle4', e);
                  }}
                  value={fieldMappingValue('subtitle4')}
                />
              </div>
            </div>
            <div className="rows">
              <span className="data-label expanded">Subtitle 5</span>

              <div className="expanded">
                <RSelect
                  options={fields?.map((e) => ({
                    label: e.label,
                    value: e.name
                  }))}
                  onChange={(e) => {
                    onMappingsChanged('subtitle5', e);
                  }}
                  value={fieldMappingValue('subtitle5')}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button onClick={onItemListMappingUpdated}>Save</Button>
        </div>
      </div>
      {/* ****** Modal Start ****** */}
      {showModal ? (
        <ConfirmModal
          msg={'update vertical settings?'}
          name={''}
          open={showModal}
          loading={loading}
          handleClick={handleModal}
          handleConfirm={onItemListSwitchUpdated}
          handlecloseModal={closeModal}
        />
      ) : null}
      {/* ****** Modal End ****** */}
    </>
  );
}
