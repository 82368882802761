import React from 'react';
import { useForm } from 'react-hook-form';
import FormTextField from '../../../components/form/FormTextField'; // Using your custom form component for text fields
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Stack,
  Typography
} from '@mui/material';
import FormPhoneField from '../../../components/form/FormPhoneField';
import { useDispatch } from 'react-redux';
import { createFatoorahVendor } from '../../../redux/features/dealerSettings/DealerSettingsSlice';
import { Spinner } from 'reactstrap';

export default function DealerFatoorah({ dealer }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      await dispatch(createFatoorahVendor(dealer.id))?.unwrap();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <CardHeader title={'Payment Provider Info'} />
        <Box sx={{ pr: 2 }}>
          {!dealer.fatoorahVendor && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="button"
              onClick={handleSubmit}
            >
              {loading ? <Spinner size={'sm'} /> : 'Create Fatoorah Vendor'}
            </Button>
          )}
        </Box>
      </Stack>
      <CardContent>
        <Grid2 container spacing={2}>
          {!dealer.fatoorahVendor ? (
            <div>No Account found</div>
          ) : (
            <Typography>
              <strong>Supplier Code :</strong> {dealer?.fatoorahVendor?.referenceId}
            </Typography>
          )}
        </Grid2>
      </CardContent>
    </Card>
  );
}
