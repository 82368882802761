import classNames from "classnames";
import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Icon } from "../../../components/Component";

export default function FieldMapper({ values }) {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div>
      <Nav tabs style={{ width: "100%" }}>
        {values?.map((e, index) => (
          <NavItem key={index}>
            <NavLink
              className={classNames({ active: selectedTab === index })}
              onClick={(ev) => {
                ev.preventDefault();
                setSelectedTab(index);
              }}
            >
              {e.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {values && values[selectedTab]?.fields?.map((e) => <SelectedField key={e.name} formField={e} />)}
    </div>
  );
}

function SelectedField({ formField }) {

  switch (formField.type) {
    default:
      return (
        // <label style={{ width: "100%" }}>
        //   {formField.label}
        //   <input className="form-control" style={{ width: "100%" }} />
        // </label>
        <div style={{
          border: "1px solid #ccc",  // Add a border for the card-like appearance
          borderRadius: "5px",        // Add rounded corners
          padding: "10px",  
          marginTop:"10px",          // Add some padding
          width: "100%",              // Set the width to 100%
          boxSizing: "border-box",  
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"  // Add a subtle box shadow
        }}>
         <label style={{ display: "flex", justifyContent: "space-between" }}>
            {formField.label}{formField.isRequired?"*":""}
            {formField.isFilterable?
            <div style={{ display: "block" }}>  <Icon
            className={`text-success`}
            name="check-circle"></Icon>{" "}Filterable</div>
                  
                    :null}
          </label>

        </div>
        
      );
  }
}
