import React, { useEffect } from 'react';
import Content from '../../layout/content/Content';
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import { Icon, UserAvatar } from '../../components/Component';
import { useState } from 'react';
import { findUpper } from '../../utils/Utils';
import { useDispatch } from 'react-redux';
import { fetchUserDetails } from '../../redux/features/usersSlice/userDetails';
import { Link, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BasicDetails from './components/BasicDetails';
import BasicForms from './components/BasicForms';
import InspectionForms from './components/SimplifiedInspectionForms';
import Settings from './components/Settings';
import { fetchVerticalDetails } from '../../redux/features/verticalsSlice/verticalDetailsSlice';
import SimplifiedInspectionForms from './components/SimplifiedInspectionForms';
import AdvancedInspectionForms from './components/AdvancedInspectionForms';
import AutoCompleteList from './components/AutoCompleteList';
import AutoCompleteListSecondary from './components/AutoCompleteListSecondary';
import AutoRepostAuctionSetting from './components/AutoRepostAuctionSetting';

export default function VerticalDetails() {
  const [selectedTab, setSelectedTab] = useState(0);

  const dispatch = useDispatch();

  const { id, tab } = useParams();

  useEffect(() => {
    setSelectedTab(tab ?? 0);
  }, [tab]);

  useEffect(() => {
    dispatch(fetchVerticalDetails(id));
  }, [id]);

  const vertical = useSelector((state) => state.verticalDetails.data);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg content-active
              `}
            >
              <div className="card-inner-group">
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li onClick={() => setSelectedTab(0)}>
                      <Link
                        to={`details`}
                        className={
                          window.location.pathname ===
                          `/verticals/${id}/details`
                            ? 'active'
                            : ''
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Vertical Details</span>
                      </Link>
                    </li>
                    <li onClick={() => setSelectedTab(1)}>
                      <Link
                        to={`settings`}
                        className={
                          window.location.pathname ===
                          `/verticals/${id}/settings`
                            ? 'active'
                            : ''
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Vertical Settings(*Required)</span>
                      </Link>
                    </li>
                    <li onClick={() => setSelectedTab(2)}>
                      <Link
                        to={`basic_forms`}
                        className={
                          window.location.pathname ===
                          `/verticals/${id}/basic_forms`
                            ? 'active'
                            : ''
                        }
                      >
                        <Icon name="bell-fill"></Icon>
                        <span>Basic Form(*Required)</span>
                      </Link>
                    </li>
                    <li onClick={() => setSelectedTab(3)}>
                      <Link
                        to={`simple_inspection_forms`}
                        className={
                          window.location.pathname ===
                          `/verticals/${id}/simple_inspection_forms`
                            ? 'active'
                            : ''
                        }
                      >
                        <Icon name="activity-round-fill"></Icon>
                        <span>Simplified Inspection Form(*Required)</span>
                      </Link>
                    </li>
                    <li onClick={() => setSelectedTab(4)}>
                      <Link
                        to={`advanced_inspection_forms`}
                        className={
                          window.location.pathname ===
                          `/verticals/${id}/advanced_inspection_forms`
                            ? 'active'
                            : ''
                        }
                      >
                        <Icon name="activity-round-fill"></Icon>
                        <span>Advanced Inspection Form</span>
                      </Link>
                    </li>
                    {
                      <li onClick={() => setSelectedTab(5)}>
                        <Link
                          to={`autocomplete_list`}
                          className={
                            window.location.pathname ===
                            `/verticals/${id}/autocomplete_list`
                              ? 'active'
                              : ''
                          }
                        >
                          <Icon name="activity-round-fill"></Icon>
                          <span>AutoComplete Options(Primary)</span>
                        </Link>
                      </li>
                    }
                    {
                      <li onClick={() => setSelectedTab(5)}>
                        <Link
                          to={`autocomplete_list_secondary`}
                          className={
                            window.location.pathname ===
                            `/verticals/${id}/autocomplete_list_secondary`
                              ? 'active'
                              : ''
                          }
                        >
                          <Icon name="activity-round-fill"></Icon>
                          <span>AutoComplete Options(Secondary)</span>
                        </Link>
                      </li>
                    }
                    <li onClick={() => setSelectedTab(6)}>
                      <Link
                        to={`auto-repost-auction-setting`}
                        className={
                          window.location.pathname ===
                          `/verticals/${id}/auto-repost-auction-setting`
                            ? 'active'
                            : ''
                        }
                      >
                        <Icon name="activity-round-fill"></Icon>
                        <span>Auto Repost Auction Setting</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner card-inner-lg">
              <Routes>
                <Route path="/" element={<Navigate replace to={`details`} />} />
                <Route path={`/details`} element={<BasicDetails />} />
                <Route path={`/settings`} element={<Settings />} />
                <Route path={`/basic_forms`} element={<BasicForms />} />
                <Route
                  path={`/simple_inspection_forms`}
                  element={<SimplifiedInspectionForms />}
                />
                <Route
                  path={`/autocomplete_list`}
                  element={<AutoCompleteList />}
                />
                <Route
                  path={`/autocomplete_list_secondary`}
                  element={<AutoCompleteListSecondary />}
                />
                <Route
                  path={`/advanced_inspection_forms`}
                  element={<AdvancedInspectionForms />}
                />
                <Route
                  path={`/auto-repost-auction-setting`}
                  element={<AutoRepostAuctionSetting vertical_id={id} vertical={vertical} />}
                />
              </Routes>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
}
