import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  data: [],
  totalCount: 0,
  perPage:10,
  loading: false,
  error: null,
};
const fetchFreeAuctions = createAsyncThunk("freeAuctionsNumber", () => {

  let url = `/admin/free-auction-on-register`;
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const editFreeAuctions = createAsyncThunk("freeAuctions", (data) => {
  let url = `/admin/free-auction-on-register`;
  return axios.post(url,data).then((response) => {
    toast.success('Successfully Updated');
    window.location.reload();
    return response.data;
  });
});

const freeAuctionSlice = createSlice({
  name: "freeAuctions",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFreeAuctions.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchFreeAuctions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data??null;
    });
    builder.addCase(fetchFreeAuctions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(editFreeAuctions.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(editFreeAuctions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data??null;
    });
    builder.addCase(editFreeAuctions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

  },
});

export default freeAuctionSlice.reducer;
// export const { setSelected, setAllSelected } = rolesSlice.actions;
export { editFreeAuctions,fetchFreeAuctions};
