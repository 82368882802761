import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTableHead,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard
} from '../../../components/Component';
import { Modal, Spinner } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  debouncedFetchAutocompleteOptions,
  fetchAutocompleteOptions
} from '../../../redux/features/verticalsSlice/autocompleteOptionsSlice';
import { EditImage } from './EditImage';
import ItemRow from './ItemRow';

export default function AutoCompleteListSecondary() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const vertical = useSelector((state) => state.verticalDetails);

  const { data, totalCount, loading, perPage } = useSelector(
    (state) => state?.autocompleteOptions
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemPerPage] = useState(10);
  const [updateProfileImageModal, setUpdateImageModal] = useState(false);
  const [onSearchText, setSearchText] = useState('');
  const toggleUpdateProfileImageModal = () => {
    setUpdateImageModal(!updateProfileImageModal);
  };
  useEffect(() => {
    if (vertical?.data?.name === 'Cars') {
      let data = {
        query: {
          key: 'CAR_MODEL'
        },
        options: {
          page: currentPage,
          limit: itemPerPage,
          sort: 'referenceValue'
        }
      };

      if (onSearchText !== '') {
        data.query.search = onSearchText.trim();
        dispatch(debouncedFetchAutocompleteOptions(data));
      } else {
        dispatch(fetchAutocompleteOptions(data));
      }
    }
    else{

        let data = {
          query: {
            key:`${vertical?.data?.name?.toLowerCase()}_secondary`
          },
          options: {
            page: currentPage,
            limit: itemPerPage,
            sort: 'value'
          }
        };
  
        if (onSearchText !== '') {
          data.query.search = onSearchText.trim();
          dispatch(debouncedFetchAutocompleteOptions(data));
        } else {
          dispatch(fetchAutocompleteOptions(data));
        }
      
    }
  }, [onSearchText.trim(), currentPage, vertical]);

  return (
    <React.Fragment>
      <Head title="Options"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Options</BlockTitle>
            {/* <BlockDes>
              <p>Form required to add product inspection details</p>
            </BlockDes> */}
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                href="#more"
                className="btn btn-icon btn-trigger toggle-expand me-n1"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="more-v"></Icon>
              </a>
              <div
                className="toggle-expand-content"
                style={{ display: 'block' }}
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <Icon name="search"></Icon>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="default-04"
                        placeholder="Search name"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-tb-list is-separate is-medium mb-3">
          <DataTableHead className="nk-tb-item">
            <DataTableRow size="md">
              <span className="sub-text">Name</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span className="sub-text">Reference</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span className="sub-text">Trending Search</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span className="sub-text">Icon</span>
            </DataTableRow>
          </DataTableHead>

          {data?.data?.length > 0
            ? data?.data?.map((item, index) => (
                <ItemRow
                  key={item.id}
                  item={item}
                  secondary={true}
                  setSelectedItem={setSelectedItem}
                  toggleUpdateProfileImageModal={toggleUpdateProfileImageModal}
                />
              ))
            : null}
        </div>
        <PreviewAltCard>
          {data?.data?.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={totalCount ?? 0}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : loading ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="text-center">
              <span className="text-silent">No options found</span>
            </div>
          )}
        </PreviewAltCard>
        <Modal
          size="lg"
          isOpen={updateProfileImageModal}
          toggle={toggleUpdateProfileImageModal}
        >
          <EditImage
            toggle={toggleUpdateProfileImageModal}
            item={selectedItem}
          />
        </Modal>
      </Block>
    </React.Fragment>
  );
}
