import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  Grid2
} from '@mui/material';
import { Upload } from 'lucide-react';
import { Image } from '../../../components/Component'; // Assuming you have this component
import FormSingleImageUpload from '../../../components/form/FormSingleImageUpload';
import PdfViewer from '../../../components/image/PdfPreview';

export default function DealerDocumentsForm({
  dealer,
  onSubmit,
  toggleEdit,
  editMode
}) {
  const doc = dealer?.documents;
  const { control, handleSubmit } = useForm({
    defaultValues: {
      ibanLetter: doc?.ibanLetter,
      eidFront: doc?.eidFront,
      eidBack: doc?.eidBack,
      trnCertificate: doc?.trnCertificate,
      tradeLicense: doc?.tradeLicense
    }
  });

  const handleFormSubmit = (data) => {
    if (editMode) {
      onSubmit(data);
      toggleEdit('documents'); // Assuming 'documents' is the section name
    }
  };

  const documents = [
    { name: 'ibanLetter', label: 'IBAN Letter(Valid - ID)' },
    { name: 'eidFront', label: 'EID Front Certificate' },
    { name: 'eidBack', label: 'EID Back Certificate' },
    { name: 'trnCertificate', label: 'TRN Certificate' },
    { name: 'tradeLicense', label: 'Trade License' }
  ];

  return (
    <Card sx={{ mb: 2 }}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <CardHeader title={'Documents'} />
        <Box sx={{ pr: 2 }}>
          {editMode ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="button"
              onClick={handleSubmit(handleFormSubmit)}
            >
              Save Changes
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              type="button"
              onClick={() => toggleEdit('documents')}
            >
              Edit
            </Button>
          )}
        </Box>
      </Stack>
      <CardContent>
        <Grid2 container spacing={2}>
          {documents.map((dc) => (
            <Grid2 item xs={12} sm={6} md={4} key={dc.name}>
              {editMode ? (
                <FormSingleImageUpload
                  name={dc.name}
                  label={dc.label}
                  control={control}
                  inpuType="file"
                  acceptTypes="image/*,application/pdf"
                />
              ) : (
                <Box>
                  <Typography variant="subtitle1">{dc.label}</Typography>

                  <Box width={150} height={150} overflow='hidden'>
                    {doc?.[dc.name] ? (
                      // Check if it's an image file based on file extension
                      /\.(jpg|jpeg|png|webp)$/i.test(doc[dc.name]) ? (
                        <Image image={doc[dc.name]} alt={dc.label} />
                      ) : /\.(pdf)$/i.test(doc[dc.name]) ? (
                        // If it's a PDF, show a PDF icon with a download link or view link
                        <PdfViewer file={doc[dc.name]}/>
                       
                      ) : (
                        // For any other document type, show a generic file icon or link
                        <a
                          href={doc[dc.name]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/file-icon.png" // Provide a generic file icon here
                            alt="Document Preview"
                            style={{ width: '100%', height: '100%' }}
                          />
                          <Typography variant="caption" color="white">
                            View Document
                          </Typography>
                        </a>
                      )
                    ) : (
                      // Placeholder when there's no file uploaded
                      <Typography variant="caption" color="white">
                        No File Uploaded
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Grid2>
          ))}
        </Grid2>
      </CardContent>
    </Card>
  );
}
