import classNames from "classnames";
import React, { useState } from "react";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import { RSelect } from "../../../components/Component";
import { useDispatch } from "react-redux";
import { addInspection } from "../../../redux/features/itemsSlice/itemDetailsSlice";
import TwodDiagram from "./TwodDiagram";
import { uploadToS3 } from "../../../utils/uploadImageToS3";
import { v4 as uuidv4 } from "uuid";

export default function FieldFormMapper({ values ,vertical_id,baseform_id}) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [formFields, setFormFields] = useState([]); // State to store field values


  function handleFieldChange(key, label, value, type) {
    // Find the index of the field with the same key
    const fieldIndex = formFields.findIndex((field) => field.key === key);

    if (fieldIndex !== -1) {
      // If the field already exists in the state, update its value
      const updatedFields = [...formFields];
      updatedFields[fieldIndex] = { key, label, value, type };
      setFormFields(updatedFields);
    } else {
      // If the field doesn't exist in the state, add it
      setFormFields([...formFields, { key, label, value, type }]);
    }
  }
  async function processArray(data) {
    const updatedData = await Promise.all(data.map(async (item) => {
      if (item.value.type === "file") {
        const fileUrl = await uploadToS3(item.value.file,item.value.name); // Upload the file and get the URL
        item.value.fileUrl = fileUrl; // Update the value with the file URL
      }
      return item;
    }));
  
    return updatedData;
  }
  
  const handleAddInspection =async (e)=>{
    e.preventDefault();
    if (formFields?.length > 0) {

      let images = formFields?.filter((v) => v.type === 'image');
      if (images?.length > 0) {
        const fileListArray = Array.from(images);
        const updatedFormFields = await Promise.all(
          fileListArray?.map(async (doc) => {
            const fileName = `temp/${uuidv4()}.${doc?.name?.split('.').pop()}`;
            await uploadToS3(doc, fileName); // Upload the image to S3
    
            // Update the formField with the fileNamesrc/pages/verticals/components/FieldFormMapper.jsx
            return {
              key: doc.key, // Assuming doc.key holds the key
              label: doc.label,
              fileName: fileName,
              type: doc.type,
            };
          })
        );
    
        // Replace the images in the original formFields array with the updated ones
        formFields.map((field) => {
          if (field.type === 'image') {
            const updatedImage = updatedFormFields.find((updatedField) => updatedField.key === field.key);
            return updatedImage || field; // Use the updated image or the original one if not found
          } else {
            return field; // Keep non-image fields as they are
          }
        });
      }
    }
    

    let data={
      "values":formFields,
      "vertical_id":vertical_id,
      "type":'advanced',
      "baseform_id":baseform_id
    }    
    // console.log(data)
    dispatch(addInspection(data));
  }
  return (
    <div>
      <Nav tabs style={{ width: "100%" }}>
        {values?.map((e, index) => (
          <NavItem key={index}>
            <NavLink
              className={classNames({ active: selectedTab === index })}
              onClick={(ev) => {
                ev.preventDefault();
                setSelectedTab(index);
              }}
            >
              {e.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {values &&
        values[selectedTab]?.fields?.map((e) => (
          <SelectedField key={e.name} formField={e} handleFieldChange={handleFieldChange} />
          
        ))}

      <div className="actions clearfix">
        
        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
          <li>
          {selectedTab < values?.length - 1 && ( 
   
              <Button
              style={{marginRight:"10px"}}
                onClick={() => setSelectedTab(selectedTab + 1)} 
                color="primary"
                type="button"
              >
                Next
              </Button>

            )}
            <Button
            disabled={selectedTab < values?.length - 1}
              //   onClick={(e) => submitFormData(e)}
              onClick={(e) => handleAddInspection(e)}
              color="secondary"
              type="submit"
            >
              Save
            </Button>
          </li>
        </ul>
      </div>
      
    </div>
  );
}

function SelectedField({ formField, handleFieldChange }) {
  const handleImageChange = (e, field) => {
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {
      handleFieldChange(formField.name, formField.label, selectedFile, formField.type);
    }
    else{
    }
    
  };
  const handleChange = (e) => {
    // Call the handleFieldChange function with the field data
    handleFieldChange(formField.name, formField.label, e.target.value, formField.type);
  };

  switch (formField.type) {
    case "text":
      return (
        <label style={{ width: "100%" }}>
          {formField.label}{formField.isRequired?"*":""}
          <input type="text" className="form-control" style={{ width: "100%" }} onChange={handleChange} />
        </label>
      );

    case "dropdown":
      return (
        <label style={{ width: "100%" }}>
          {formField.label}{formField.isRequired?"*":""}
          <RSelect
            isMulti={false}
            options={formField?.options.map((e) => ({ label: e, value: e }))}
            style={{ width: "100%" }}
            onChange={(selectedOption) => {
              // Extract the selected value from the option
              const selectedValue = selectedOption ? selectedOption.value : null;
              // Call the handleFieldChange function with the field data
              handleFieldChange(formField.label, formField.name, selectedValue, formField.type);
            }}
          />
        </label>
      );

      case "radio":
        return (
          <label style={{ width: "100%" }}>
            {formField.label}{formField.isRequired?"*":""}
            <RSelect
              isMulti={false}
              options={formField?.options.map((e) => ({ label: e, value: e }))}
              style={{ width: "100%" }}
              onChange={(selectedOption) => {
                // Extract the selected value from the option
                const selectedValue = selectedOption ? selectedOption.value : null;
                // Call the handleFieldChange function with the field data
                handleFieldChange(formField.label, formField.name, selectedValue, formField.type);
              }}
            />
          </label>
        );
  
        case "multiLine":
          return (
            <label style={{ width: "100%" }}>
              {formField.label}{formField.isRequired?"*":""}
              <input type="text" className="form-control" style={{ width: "100%" }} onChange={handleChange} />
            </label>
          );
     
    case "switch":
      return (
        <label style={{ width: "100%" }}>
          {formField.label}{formField.isRequired?"*":""}
          {/* Render switch field here */}
        </label>
      );
    case "image":
      
        return (
          <label style={{ width: "100%" }}>
            {formField.label}{formField.isRequired?"*":""}
            <div className="form-control">
              <div className="form-file">
                <input               
                 type="file" 
                 onChange={(e) => handleImageChange(e, formField)} 
                 style={{ width: "100%" }} />
              </div>
            </div>
          </label>
        );
    case "2dDiagram":
      return(
        <TwodDiagram formField={formField} diagram={formField.diagram} handleFieldChange={handleFieldChange}/>
      );
    // Add cases for other field types here

    default:
      return null; // Handle unknown field types or return null
  }
}
