import React, { useEffect, useState } from 'react';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
  Block,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard
} from '../../components/Component';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,
  Spinner,
  Col
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  clearItems,
  setAllSelected,
  setSelected
} from '../../redux/features/itemsSlice/itemsSlice';
import { startCase } from 'lodash';
import { getColor } from '../../utils/getColor';
import {
  Link,
  useSearchParams,
  useNavigate
} from 'react-router-dom';
import TableHeader from '../../components/table/TableHeader';
import ListBlockHeader from '../../components/list-block-header/ListBlockHeader';
import ReactDatePicker from 'react-datepicker';
import { debouncedFetchShipments, fetchShipments } from '../../redux/features/usersSlice/shipmentsSlice';
import ShipmentLink from './ShipmentLink';

const Shipments = () => {
  const navigate = useNavigate();

  const [listData, setListData] = useState([]);
  const [view, setView] = useState({
    add: false,
    details: false
  });
  const [onSearchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState('');
  const [sortByField, setSortByField] = useState('createdAt');
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);

  const user = useSelector((state) => state.userDetails.data);
  // const verticals = useSelector((state) => state.verticals);

  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  // const verticalItemGet = localStorage.getItem('verticalItemGetValue') || '';
  const { data, totalCount, loading, perPage } = useSelector(
    (state) => state.shipments
  );
  const [itemPerPage] = useState(perPage);
  const dispatch = useDispatch();
  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  // const [vertical, setVertical] = useState('');

  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({
        startDate: rangeStart.toISOString(),
        endDate: rangeEnd.toISOString()
      });
    }
  }, [rangeEnd, rangeStart]);
  const setRangeStart = (e) => {
    setStart(e);
  };
  const setRangeEnd = (e) => {
    setEnd(e);
  };

  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({
        startDate: rangeStart.toISOString(),
        endDate: rangeEnd.toISOString()
      });
    }
  }, [rangeEnd, rangeStart]);

  // Changing state value when searching name
  useEffect(() => {
    let data = {
      query: {},
      options: {
        page: currentPage,
        limit: perPage,
        // sort:"-createdAt",
        sort: sortByValue === 1 ? sortByField : '-' + sortByField,
      }
      //  page: currentPage, status, sortBy: { [sortByField]: sortByValue }
    };

    if (dateFilter) {
      data.query.date = dateFilter;
    }
    if (onSearchText !== '') {
      data.query.search = onSearchText.trim();
      dispatch(debouncedFetchShipments(data));
    } else {
      dispatch(fetchShipments(data));
    }
  }, [
    onSearchText.trim(),
    currentPage,
    status,
    sortByValue,
    dateFilter,
    user,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  useEffect(() => {
    if (data) {
      setListData(data?.data);
    }
  }, [data]);

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === 'add' ? true : false,
      details: type === 'details' ? true : false
    });
  };

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let clearBadge =
    status ||
    onSearchText !== '' ||
    rangeEnd ||
    rangeStart ;


  return (
    <React.Fragment>
      <Head title={`Shipments`}></Head>
      <Content>
        <ListBlockHeader
          msg={totalCount ? `Total items:  ${totalCount}` : ''}
          title="All Shipments"
          placeholder="Search ID"
          onSearchText={onSearchText}
          onSearchChanged={onFilterChange}
          onDateChanged={setDateFilter}
        >
          {!user?.id && (
            <div style={{ flexShrink: 0, flexGrow: 1 }}>
              <div className="form-control-wrap">
                <div className="input-daterange date-picker-range input-group">
                  <ReactDatePicker
                    selected={rangeStart}
                    onChange={setRangeStart}
                    selectsStart
                    startDate={rangeStart}
                    endDate={rangeEnd}
                    maxDate={rangeEnd ?? new Date()}
                    wrapperClassName="start-m"
                    className="form-control"
                  />{' '}
                  <div className="input-group-addon">TO</div>
                  <ReactDatePicker
                    selected={rangeEnd}
                    onChange={setRangeEnd}
                    startDate={rangeStart}
                    endDate={rangeEnd}
                    selectsEnd
                    minDate={rangeStart}
                    maxDate={new Date()}
                    wrapperClassName="end-m"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}

        </ListBlockHeader>

        <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">

          {clearBadge && (
            <Badge className="selected-option bg-primary me-1" color="danger">
              {'Clear All'}
              <Icon
                name="cross-sm"
                className="ms-1"
                onClick={() => {
                  setSearchText('');
                  setRangeEnd(null);
                  setRangeStart(null);
                  setDateFilter(null);
                  setStatus('');
                  // setVertical('');

                }}
              />
            </Badge>
          )}
        </Col>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="pid-all"
                  ></label>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>ID</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Consignee</span>
              </DataTableRow> 
              <DataTableRow size="sm">
                <span>Shipper</span>
              </DataTableRow> 
              <DataTableRow size="sm">
                <span>Auction</span>
              </DataTableRow>        
              <DataTableRow size="sm">
                <span>Status</span>
              </DataTableRow>   
              <DataTableRow size="sm">
                <span>Created At</span>
              </DataTableRow> 

            </DataTableHead>

            {listData?.length > 0
              ? listData.map((item, index) => (
                  <DataTableItem key={item.id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={item.check}
                          id={item.id + 'oId-all'}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, index)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={item.id + 'oId-all'}
                        ></label>
                      </div>
                    </DataTableRow>
       
                    <DataTableRow>
                 <ShipmentLink item={item}/>
                    </DataTableRow>
                    <DataTableRow>
                    <Link to={`/users/${item?.consigneeId?.id}/`}> 
                      {item?.consigneeId?.full_name}
                      </Link>
                    </DataTableRow>
                    <DataTableRow>
                    <Link to={`/users/${item?.shipperId?.id}/`}> 
                    {item?.shipperId?.full_name}
                    </Link>
                    </DataTableRow>
                    <DataTableRow>
                    <Link to={`/v/${item?.auctionId?.vertical_id}/auctions/${item?.auctionId?.id}`}> 
                      {item?.auctionId?.serial_number}
                      </Link>
                    </DataTableRow>
                           
                    <DataTableRow>
                      <span
                        className={`dot bg-${getColor(item?.status)} d-sm-none`}
                      ></span>
                      <Badge
                        className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                        color={getColor(item?.status)}
                      >
                        {startCase(item?.status)}
                      </Badge>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>
                        {item?.createdAt &&
                          new Date(item?.createdAt).toLocaleDateString(
                            undefined,
                            dateOptions
                          )}{' '}
                        {new Date(item?.createdAt).toLocaleTimeString(
                          undefined,
                          timeOptions
                        )}
                      </span>
                    </DataTableRow>
           
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {listData?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No items found</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Shipments;
