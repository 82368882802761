import React, { useState } from 'react';
import Head from '../layout/head/Head';
import Content from '../layout/content/Content';
import AuctionStatistics, {
  RealtimeUsers
} from '../components/partials/default/SalesStatistics';
import BidsSourceStatistics from '../components/partials/default/BidsSourceStatistics';
import RavoStatistics from '../components/partials/default/RavoStatistics';
import RunningAuctions from '../components/partials/default/recent-orders/RecentOrders';
import TopBiddedAuctions from '../components/partials/default/top-products/TopProducts';
import DataCard from '../components/partials/default/DataCard';
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem
} from 'reactstrap';
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  BlockBetween
} from '../components/Component';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DefaultCustomerChart,
  DefaultOrderChart,
  DefaultRevenueChart,
  DefaultVisitorChart
} from '../components/partials/charts/default/DefaultCharts';
import LiveAuctions from '../components/live-auctions/LiveAuctions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { startCase } from 'lodash';
import { axios } from '../utils/axios';
import {
  UserRegisterTypes,
  UserVerificationStatus,
  auctionStatus
} from '../utils/Utils';
import moment from 'moment';

const Homepage = () => {
  const tzOffset = new Date().getTimezoneOffset() * 60000; // timezone offset in milliseconds

  const today = new Date();

  const [sm, updateSm] = useState(false);
  const [dataFilter, setDataFilter] = useState('today');

  const [dayStart, setDayStart] = useState(
    new Date(`${new Date().toISOString().slice(0, 10)}T00:00:00.000`)
  );
  const [dayEnd, setDayEnd] = useState(
    new Date(`${new Date().toISOString().slice(0, 10)}T23:59:59.999`)
  );

  const [liveAuctions, setLiveAuctions] = useState(0);
  const [auctionStats, setAuctionStats] = useState(null);
  const [auctionStatsGraphData, setAuctionStatsGraphData] = useState(null);
  const [topBiddedAuctions, setTopBiddedAuctions] = useState(null);

  const [dateError, setDateError] = useState('');

  const auctionsStatistics = useSelector((state) => state.dashboard.auctions);
  const userssStatistics = useSelector((state) => state.dashboard.users);
  const bidssStatistics = useSelector((state) => state.dashboard.bids);
  const servicessStatistics = useSelector((state) => state.dashboard.services);
  const vouchersStatistics = useSelector((state) => state.dashboard.vouchers);
  const revenueStatistics = useSelector((state) => state.dashboard.revenue);
  const carsStatistics = useSelector((state) => state.dashboard.items);

  const [allRegisteredUsers, setAllRegisteredUsers] = useState(0);
  const [allRegisteredUsersGraphData, setAllRegisteredUsersGraphData] =
    useState(null);
  const [returningUsers, setReturningUsers] = useState(0);
  const [returningUsersGraphData, setReturningUsersGraphData] = useState(null);
  const [verifiedUsers, setVerifiedUsers] = useState(0);
  const [verifiedUsersGraphData, setVerifiedUsersGraphData] = useState(null);
  const [ravoProUsers, setRavoProUsers] = useState(0);
  const [ravoProUsersGraphData, setRavoProUsersGraphData] = useState(null);
  const [activeRavoProUsers, setActiveRavoProUsers] = useState(0);
  const [activeRavoProUsersGraphData, setActiveRavoProUsersGraphData] =
    useState(null);
  const [realtimeUsers, setRealtimeUsers] = useState(null);

  const [totalRevenue, setTotalRevenue] = useState(null);
  const [revenueGraphData, setRevenueGraphData] = useState(null);

  const [bidsAdded, setBidsAdded] = useState(null);
  const [bidsAddedGraphData, setBidsAddedGraphData] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDateError('');
    }, 3000);

    return () => clearTimeout(timeout);
  }, [dateError]);

  useEffect(() => {
    getAllRegisteredUsers();
    getReturningUsers();
    getVerifiedUsers();
    getRavoProUsers();
    getActiveRavoProUsers();
    getAuctionStats();
    getGeneratedPayments();
    getBidsAdded();
    getTopBiddedAuctions();
  }, [dayStart, dayEnd]);

  useEffect(() => {
    getLiveAuctions();
    getRealtimeUsers();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getRealtimeUsers();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const getAllRegisteredUsers = async () => {
    try {
      let fromDate = new Date(dayStart.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const toDate = new Date(dayEnd.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const daysDifference = moment(toDate, 'YYYY-MM-DD').diff(
        moment(fromDate, 'YYYY-MM-DD'),
        'day'
      );

      // if difference between dayEnd and dayStart is less than 7 days, then change fromDate to 7 days from dayEnd

      if (daysDifference < 6) {
        const _7daysFromEndDate = moment(toDate, 'YYYY-MM-DD').subtract(
          6,
          'days'
        );

        fromDate = new Date(new Date(_7daysFromEndDate).getTime() - tzOffset)
          .toISOString()
          .slice(0, 10);
      }

      const fromTime =
        `${
          dayStart.getHours() < 10
            ? '0' + dayStart.getHours()
            : dayStart.getHours()
        }` +
        ':' +
        `${
          dayStart.getMinutes() < 10
            ? '0' + dayStart.getMinutes()
            : dayStart.getMinutes()
        }` +
        ':00.000';

      const toTime =
        `${
          dayEnd.getHours() < 10 ? '0' + dayEnd.getHours() : dayEnd.getHours()
        }` +
        ':' +
        `${
          dayEnd.getMinutes() < 10
            ? '0' + dayEnd.getMinutes()
            : dayEnd.getMinutes()
        }` +
        ':59.999';

      const { data } = await axios.post('/admin/reports/users', {
        fromDate,
        toDate,
        fromTime,
        toTime
      });

      const result = data?.data;

      const graphData = [];

      // structure graph data
      let currentCount = 6;
      while (currentCount >= 0) {
        const currentDate = moment(toDate, 'YYYY-MM-DD')
          .subtract(currentCount, currentCount > 1 ? 'days' : 'day')
          .toISOString()
          .slice(0, 10);

        const found = result?.find((item) => item?.date === currentDate);

        graphData?.push({
          count: found?.count ?? 0,
          date: currentDate
        });

        currentCount--;
      }

      // calculate value for query
      if (daysDifference < 6) {
        const startDate = moment.utc(
          new Date(dayStart.getTime() - tzOffset).toISOString().split('T')[0] +
            'T00:00:00.000Z',
          'YYYY-MM-DD'
        );

        const queryData = result?.filter((item) => {
          const date = moment.utc(item?.date, 'YYYY-MM-DD');

          return date.isSameOrAfter(startDate);
        });

        setAllRegisteredUsers(
          queryData
            ?.map((item) => Number(item?.count))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      } else {
        setAllRegisteredUsers(
          result
            ?.map((item) => Number(item?.count))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      }

      setAllRegisteredUsersGraphData(graphData);

      // setAllRegisteredUsers(data?.data?.result ?? 0);
    } catch (err) {
      console.log(err);
    }
  };

  const getRealtimeUsers = async () => {
    try {
      const { data } = await axios.post('/admin/reports/analytics/real-time', {
        dimension: 'minutesAgo'
      });

      setRealtimeUsers(
        data?.data?.sort((a, b) => b?.dimension?.localeCompare(a?.dimension))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getReturningUsers = async () => {
    try {
      let fromDate = new Date(dayStart.getTime() - tzOffset)
        .toISOString()
        ?.split('T')[0];
      const toDate = new Date(dayEnd.getTime() - tzOffset)
        .toISOString()
        ?.split('T')[0];

      const daysDifference = moment(toDate, 'YYYY-MM-DD').diff(
        moment(fromDate, 'YYYY-MM-DD'),
        'day'
      );

      // if difference between dayEnd and dayStart is less than 7 days, then change fromDate to 7 days from dayEnd

      if (daysDifference < 6) {
        const _7daysFromEndDate = moment(toDate, 'YYYY-MM-DD').subtract(
          6,
          'days'
        );

        fromDate = new Date(new Date(_7daysFromEndDate).getTime() - tzOffset)
          .toISOString()
          .slice(0, 10);
      }

      const { data } = await axios.post('/admin/reports/analytics', {
        fromDate,
        toDate,
        metric: 'active1DayUsers',
        dimension: 'date'
      });

      const result = data?.data;

      const graphData = [];

      // structure graph data
      let currentCount = 6;
      while (currentCount >= 0) {
        currentCount--;

        const currentDate = moment(toDate, 'YYYY-MM-DD')
          .subtract(currentCount, currentCount > 1 ? 'days' : 'day')
          .toISOString()
          .slice(0, 10);

        const found = result?.find(
          (item) => item?.dimension === currentDate?.replaceAll('-', '')
        );

        graphData?.push({
          count: found?.metricValue ?? 0,
          date: currentDate
        });
      }

      // calculate value for query
      if (daysDifference < 6) {
        const startDate = moment.utc(
          new Date(dayStart.getTime() - tzOffset).toISOString().split('T')[0] +
            'T00:00:00.000Z',
          'YYYY-MM-DD'
        );

        let currentDate = moment.utc(toDate, 'YYYY-MM-DD');

        let queryData = [];

        while (currentDate.isSameOrAfter(startDate, 'date')) {
          const dimensionDate = currentDate
            .toISOString()
            ?.split('T')[0]
            ?.replaceAll('-', '');

          queryData?.push(
            result?.find((item) => item?.dimension === dimensionDate) ?? {
              dimension: dimensionDate,
              metricValue: 0
            }
          );

          currentDate = currentDate.subtract(1, 'day').utc();
        }

        setReturningUsers(
          queryData
            ?.map((item) => Number(item?.metricValue))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      } else {
        setReturningUsers(
          result
            ?.map((item) => Number(item?.metricValue))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      }

      setReturningUsersGraphData(graphData);
    } catch (err) {
      console.log(err);
    }
  };

  const getVerifiedUsers = async () => {
    try {
      let fromDate = new Date(dayStart.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);
      const toDate = new Date(dayEnd.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const daysDifference = moment(toDate, 'YYYY-MM-DD').diff(
        moment(fromDate, 'YYYY-MM-DD'),
        'day'
      );

      // if difference between dayEnd and dayStart is less than 7 days, then change fromDate to 7 days from dayEnd

      if (daysDifference < 6) {
        const _7daysFromEndDate = moment(toDate, 'YYYY-MM-DD').subtract(
          6,
          'days'
        );

        fromDate = new Date(new Date(_7daysFromEndDate).getTime() - tzOffset)
          .toISOString()
          .slice(0, 10);
      }

      const fromTime =
        `${
          dayStart.getHours() < 10
            ? '0' + dayStart.getHours()
            : dayStart.getHours()
        }` +
        ':' +
        `${
          dayStart.getMinutes() < 10
            ? '0' + dayStart.getMinutes()
            : dayStart.getMinutes()
        }` +
        ':00.000';

      const toTime =
        `${
          dayEnd.getHours() < 10 ? '0' + dayEnd.getHours() : dayEnd.getHours()
        }` +
        ':' +
        `${
          dayEnd.getMinutes() < 10
            ? '0' + dayEnd.getMinutes()
            : dayEnd.getMinutes()
        }` +
        ':59.999';

      const { data } = await axios.post('/admin/reports/users', {
        fromDate,
        toDate,
        fromTime,
        toTime,
        verifiedStatus: UserVerificationStatus.APPROVED
      });

      const result = data?.data;

      const graphData = [];

      // structure graph data
      let currentCount = 6;
      while (currentCount >= 0) {
        currentCount--;

        const currentDate = moment(toDate, 'YYYY-MM-DD')
          .subtract(currentCount, currentCount > 1 ? 'days' : 'day')
          .toISOString()
          .slice(0, 10);

        const found = result?.find((item) => item?.date === currentDate);

        graphData?.push({
          count: found?.count ?? 0,
          date: currentDate
        });
      }

      // calculate value for query
      if (daysDifference < 6) {
        const startDate = moment.utc(
          new Date(dayStart.getTime() - tzOffset).toISOString().split('T')[0] +
            'T00:00:00.000Z',
          'YYYY-MM-DD'
        );

        const queryData = result?.filter((item) => {
          const date = moment(item?.date, 'YYYY-MM-DD');

          return date.isSameOrAfter(startDate);
        });

        setVerifiedUsers(
          queryData
            ?.map((item) => Number(item?.count))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      } else {
        setVerifiedUsers(
          result
            ?.map((item) => Number(item?.count))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      }

      setVerifiedUsersGraphData(graphData);
    } catch (err) {
      console.log(err);
    }
  };

  const getRavoProUsers = async () => {
    try {
      let fromDate = new Date(dayStart.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);
      const toDate = new Date(dayEnd.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const daysDifference = moment(toDate, 'YYYY-MM-DD').diff(
        moment(fromDate, 'YYYY-MM-DD'),
        'day'
      );

      // if difference between dayEnd and dayStart is less than 7 days, then change fromDate to 7 days from dayEnd

      if (daysDifference < 6) {
        const _7daysFromEndDate = moment(toDate, 'YYYY-MM-DD').subtract(
          6,
          'days'
        );

        fromDate = new Date(new Date(_7daysFromEndDate).getTime() - tzOffset)
          .toISOString()
          .slice(0, 10);
      }

      const fromTime =
        `${
          dayStart.getHours() < 10
            ? '0' + dayStart.getHours()
            : dayStart.getHours()
        }` +
        ':' +
        `${
          dayStart.getMinutes() < 10
            ? '0' + dayStart.getMinutes()
            : dayStart.getMinutes()
        }` +
        ':00.000';

      const toTime =
        `${
          dayEnd.getHours() < 10 ? '0' + dayEnd.getHours() : dayEnd.getHours()
        }` +
        ':' +
        `${
          dayEnd.getMinutes() < 10
            ? '0' + dayEnd.getMinutes()
            : dayEnd.getMinutes()
        }` +
        ':59.999';

      const { data } = await axios.post('/admin/reports/users', {
        fromDate,
        toDate,
        fromTime,
        toTime,
        registerType: UserRegisterTypes.Dealer
      });

      const result = data?.data;

      const graphData = [];

      // structure graph data
      let currentCount = 6;
      while (currentCount >= 0) {
        currentCount--;

        const currentDate = moment(toDate, 'YYYY-MM-DD')
          .subtract(currentCount, currentCount > 1 ? 'days' : 'day')
          .toISOString()
          .slice(0, 10);

        const found = result?.find((item) => item?.date === currentDate);

        graphData?.push({
          count: found?.count ?? 0,
          date: currentDate
        });
      }

      // calculate value for query
      if (daysDifference < 6) {
        const startDate = moment.utc(
          new Date(dayStart.getTime() - tzOffset).toISOString().split('T')[0] +
            'T00:00:00.000Z',
          'YYYY-MM-DD'
        );

        const queryData = result?.filter((item) => {
          const date = moment(item?.date, 'YYYY-MM-DD');

          return date.isSameOrAfter(startDate);
        });

        setRavoProUsers(
          queryData
            ?.map((item) => Number(item?.count))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      } else {
        setRavoProUsers(
          result
            ?.map((item) => Number(item?.count))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      }

      setRavoProUsersGraphData(graphData);
    } catch (err) {
      console.log(err);
    }
  };

  const getActiveRavoProUsers = async () => {
    try {
      let lastLoggedInFrom = new Date(dayStart.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);
      const lastLoggedInEnd = new Date(dayEnd.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const daysDifference = moment(lastLoggedInEnd, 'YYYY-MM-DD').diff(
        moment(lastLoggedInFrom, 'YYYY-MM-DD'),
        'day'
      );

      // if difference between dayEnd and dayStart is less than 7 days, then change fromDate to 7 days from dayEnd

      if (daysDifference < 6) {
        const _7daysFromEndDate = moment(
          lastLoggedInEnd,
          'YYYY-MM-DD'
        ).subtract(6, 'days');

        lastLoggedInFrom = new Date(
          new Date(_7daysFromEndDate).getTime() - tzOffset
        )
          .toISOString()
          .slice(0, 10);
      }

      const lastLoggedInStartTime =
        `${
          dayStart.getHours() < 10
            ? '0' + dayStart.getHours()
            : dayStart.getHours()
        }` +
        ':' +
        `${
          dayStart.getMinutes() < 10
            ? '0' + dayStart.getMinutes()
            : dayStart.getMinutes()
        }` +
        ':00.000';

      const lastLoggedInEndTime =
        `${
          dayEnd.getHours() < 10 ? '0' + dayEnd.getHours() : dayEnd.getHours()
        }` +
        ':' +
        `${
          dayEnd.getMinutes() < 10
            ? '0' + dayEnd.getMinutes()
            : dayEnd.getMinutes()
        }` +
        ':59.999';

      const { data } = await axios.post('/admin/reports/users', {
        lastLoggedInFrom,
        lastLoggedInEnd,
        lastLoggedInStartTime,
        lastLoggedInEndTime,
        registerType: UserRegisterTypes.Dealer
      });

      const result = data?.data;

      const graphData = [];

      // structure graph data
      let currentCount = 6;
      while (currentCount >= 0) {
        const currentDate = moment(lastLoggedInEnd, 'YYYY-MM-DD')
          .subtract(currentCount, currentCount > 1 ? 'days' : 'day')
          .toISOString()
          .slice(0, 10);

        const found = result?.find((item) => item?.date === currentDate);

        graphData?.push({
          count: found?.count ?? 0,
          date: currentDate
        });

        currentCount--;
      }

      // calculate value for query
      if (daysDifference < 6) {
        const startDate = moment.utc(
          new Date(dayStart.getTime() - tzOffset).toISOString().split('T')[0] +
            'T00:00:00.000Z',
          'YYYY-MM-DD'
        );

        const queryData = result?.filter((item) => {
          const date = moment(item?.date, 'YYYY-MM-DD');

          return date.isSameOrAfter(startDate);
        });

        setActiveRavoProUsers(
          queryData
            ?.map((item) => Number(item?.count))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      } else {
        setActiveRavoProUsers(
          result
            ?.map((item) => Number(item?.count))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      }

      setActiveRavoProUsersGraphData(graphData);
    } catch (err) {
      console.log(err);
    }
  };

  const getGeneratedPayments = async () => {
    try {
      let fromDate = new Date(dayStart.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);
      const toDate = new Date(dayEnd.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const daysDifference = moment(toDate, 'YYYY-MM-DD').diff(
        moment(fromDate, 'YYYY-MM-DD'),
        'day'
      );

      // if difference between dayEnd and dayStart is less than 7 days, then change fromDate to 7 days from dayEnd

      if (daysDifference < 6) {
        const _7daysFromEndDate = moment(toDate, 'YYYY-MM-DD').subtract(
          6,
          'days'
        );

        fromDate = new Date(new Date(_7daysFromEndDate).getTime() - tzOffset)
          .toISOString()
          .slice(0, 10);
      }

      const fromTime =
        `${
          dayStart.getHours() < 10
            ? '0' + dayStart.getHours()
            : dayStart.getHours()
        }` +
        ':' +
        `${
          dayStart.getMinutes() < 10
            ? '0' + dayStart.getMinutes()
            : dayStart.getMinutes()
        }` +
        ':00.000';

      const toTime =
        `${
          dayEnd.getHours() < 10 ? '0' + dayEnd.getHours() : dayEnd.getHours()
        }` +
        ':' +
        `${
          dayEnd.getMinutes() < 10
            ? '0' + dayEnd.getMinutes()
            : dayEnd.getMinutes()
        }` +
        ':59.999';

      const { data } = await axios.post('/admin/reports/payment-transactions', {
        fromDate,
        toDate,
        fromTime,
        toTime
      });

      const result = data?.data;

      const graphData = [];

      // structure graph data
      let currentCount = 6;
      while (currentCount >= 0) {
        const currentDate = moment
          .utc(toDate, 'YYYY-MM-DD')
          .subtract(currentCount, currentCount > 1 ? 'days' : 'day')
          .toISOString()
          .slice(0, 10);

        const found = result?.find((item) => item?.date === currentDate);

        graphData?.push({
          count: found?.count ?? 0,
          totalRevenue: found?.totalRevenue ?? 0,
          date: currentDate
        });

        currentCount--;
      }

      // calculate value for query
      if (daysDifference < 6) {
        const startDate = moment.utc(
          new Date(dayStart.getTime() - tzOffset).toISOString().split('T')[0] +
            'T00:00:00.000Z',
          'YYYY-MM-DD'
        );

        const queryData = result?.filter((item) => {
          const date = moment.utc(item?.date, 'YYYY-MM-DD');

          return date.isSameOrAfter(startDate);
        });

        setTotalRevenue(
          queryData
            ?.map((item) => Number(item?.totalRevenue ?? '0'))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      } else {
        setTotalRevenue(
          result
            ?.map((item) => Number(item?.totalRevenue ?? '0'))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      }

      setRevenueGraphData(graphData);
    } catch (err) {
      console.log(err);
    }
  };

  const getBidsAdded = async () => {
    try {
      let fromDate = new Date(dayStart.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);
      const toDate = new Date(dayEnd.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const daysDifference = moment(toDate, 'YYYY-MM-DD').diff(
        moment(fromDate, 'YYYY-MM-DD'),
        'day'
      );

      // if difference between dayEnd and dayStart is less than 7 days, then change fromDate to 7 days from dayEnd

      if (daysDifference < 6) {
        const _7daysFromEndDate = moment(toDate, 'YYYY-MM-DD').subtract(
          6,
          'days'
        );

        fromDate = new Date(new Date(_7daysFromEndDate).getTime() - tzOffset)
          .toISOString()
          .slice(0, 10);
      }

      const fromTime =
        `${
          dayStart.getHours() < 10
            ? '0' + dayStart.getHours()
            : dayStart.getHours()
        }` +
        ':' +
        `${
          dayStart.getMinutes() < 10
            ? '0' + dayStart.getMinutes()
            : dayStart.getMinutes()
        }` +
        ':00.000';

      const toTime =
        `${
          dayEnd.getHours() < 10 ? '0' + dayEnd.getHours() : dayEnd.getHours()
        }` +
        ':' +
        `${
          dayEnd.getMinutes() < 10
            ? '0' + dayEnd.getMinutes()
            : dayEnd.getMinutes()
        }` +
        ':59.999';

      const { data } = await axios.post('/admin/reports/bids', {
        fromDate,
        toDate,
        fromTime,
        toTime
      });

      const result = data?.data;

      const graphData = [];

      // structure graph data
      let currentCount = 6;
      while (currentCount >= 0) {
        const currentDate = moment
          .utc(toDate, 'YYYY-MM-DD')
          .subtract(currentCount, currentCount > 1 ? 'days' : 'day')
          .toISOString()
          .slice(0, 10);

        const found = result?.find((item) => item?.date === currentDate);

        graphData?.push({
          count: found?.count ?? 0,
          date: currentDate
        });

        currentCount--;
      }

      // calculate value for query
      if (daysDifference < 6) {
        const startDate = moment.utc(
          new Date(dayStart.getTime() - tzOffset).toISOString().split('T')[0] +
            'T00:00:00.000Z',
          'YYYY-MM-DD'
        );

        const queryData = result?.filter((item) => {
          const date = moment.utc(item?.date, 'YYYY-MM-DD');

          return date.isSameOrAfter(startDate);
        });

        setBidsAdded(
          queryData
            ?.map((item) => Number(item?.count ?? '0'))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      } else {
        setBidsAdded(
          result
            ?.map((item) => Number(item?.count ?? '0'))
            ?.reduce((a, b) => a + b, 0)
            ?.toString() ?? '0'
        );
      }

      setBidsAddedGraphData(graphData);
    } catch (err) {
      console.log(err);
    }
  };

  const getLiveAuctions = async () => {
    try {
      const { data: liveAuctions } = await axios.post(
        '/admin/reports/auctions',
        {
          status: auctionStatus.LIVE
        }
      );

      setLiveAuctions(liveAuctions?.data?.result ?? 0);
    } catch (err) {
      console.log(err);
    }
  };

  const getAuctionStats = async () => {
    try {
      let fromDate = new Date(dayStart.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const toDate = new Date(dayEnd.getTime() - tzOffset)
        .toISOString()
        .slice(0, 10);

      const daysDifference = moment(toDate, 'YYYY-MM-DD').diff(
        moment(fromDate, 'YYYY-MM-DD'),
        'day'
      );

      // if difference between dayEnd and dayStart is less than 7 days, then change fromDate to 7 days from dayEnd

      if (daysDifference < 6) {
        const _7daysFromEndDate = moment(toDate, 'YYYY-MM-DD').subtract(
          6,
          'days'
        );

        fromDate = new Date(new Date(_7daysFromEndDate).getTime() - tzOffset)
          .toISOString()
          .slice(0, 10);
      }

      const fromTime =
        `${
          dayStart.getHours() < 10
            ? '0' + dayStart.getHours()
            : dayStart.getHours()
        }` +
        ':' +
        `${
          dayStart.getMinutes() < 10
            ? '0' + dayStart.getMinutes()
            : dayStart.getMinutes()
        }` +
        ':00.000';

      const toTime =
        `${
          dayEnd.getHours() < 10 ? '0' + dayEnd.getHours() : dayEnd.getHours()
        }` +
        ':' +
        `${
          dayEnd.getMinutes() < 10
            ? '0' + dayEnd.getMinutes()
            : dayEnd.getMinutes()
        }` +
        ':59.999';

      const { data: auctionStats } = await axios.post(
        '/admin/reports/auctions/auction-status-stats',
        {
          fromDate,
          toDate,
          fromTime,
          toTime
        }
      );

      const result = auctionStats?.data;

      const graphData = [];

      // structure graph data
      let currentCount = 6;

      while (currentCount >= 0) {
        const currentDate = moment
          .utc(toDate, 'YYYY-MM-DD')
          .subtract(currentCount, currentCount > 1 ? 'days' : 'day')
          .toISOString()
          .slice(0, 10);

        const found = result?.filter((item) => {
          const itemDate = moment
            .utc(item?.createdAt)
            .toISOString()
            .split('T')[0];

          return itemDate === currentDate;
        });

        graphData?.push({
          data: {
            auctionsLive: found
              ?.map((item) => item?.auctionsLive)
              ?.reduce((a, b) => a + b, 0),
            auctionsCompleted: found
              ?.map((item) => item?.auctionsCompleted)
              ?.reduce((a, b) => a + b, 0),
            auctionsReposted: found
              ?.map((item) => item?.auctionsReposted)
              ?.reduce((a, b) => a + b, 0),
            auctionsAdded: found
              ?.map((item) => item?.auctionsAdded)
              ?.reduce((a, b) => a + b, 0),
            auctionsCancelled: found
              ?.map((item) => item?.auctionsCancelled)
              ?.reduce((a, b) => a + b, 0)
          },
          date: currentDate
        });

        currentCount--;
      }

      // calculate value for query
      if (daysDifference < 6) {
        const startDate = moment.utc(
          new Date(dayStart.getTime() - tzOffset).toISOString().split('T')[0] +
            'T00:00:00.000Z',
          'YYYY-MM-DD'
        );

        const queryData = result?.filter((item) => {
          const date = moment.utc(item?.createdAt, 'YYYY-MM-DD');

          return date.isSameOrAfter(startDate, 'date');
        });

        setAuctionStats({
          auctionsLive: queryData
            ?.map((item) => item?.auctionsLive)
            ?.reduce((a, b) => a + b, 0),
          auctionsCompleted: queryData
            ?.map((item) => item?.auctionsCompleted)
            ?.reduce((a, b) => a + b, 0),
          auctionsReposted: queryData
            ?.map((item) => item?.auctionsReposted)
            ?.reduce((a, b) => a + b, 0),
          auctionsAdded: queryData
            ?.map((item) => item?.auctionsAdded)
            ?.reduce((a, b) => a + b, 0),
          auctionsCancelled: queryData
            ?.map((item) => item?.auctionsCancelled)
            ?.reduce((a, b) => a + b, 0)
        });
      } else {
        setAuctionStats({
          auctionsLive: result
            ?.map((item) => item?.auctionsLive)
            ?.reduce((a, b) => a + b, 0),
          auctionsCompleted: result
            ?.map((item) => item?.auctionsCompleted)
            ?.reduce((a, b) => a + b, 0),
          auctionsReposted: result
            ?.map((item) => item?.auctionsReposted)
            ?.reduce((a, b) => a + b, 0),
          auctionsAdded: result
            ?.map((item) => item?.auctionsAdded)
            ?.reduce((a, b) => a + b, 0),
          auctionsCancelled: result
            ?.map((item) => item?.auctionsCancelled)
            ?.reduce((a, b) => a + b, 0)
        });
      }

      setAuctionStatsGraphData(graphData);
    } catch (err) {
      console.log(err);
    }
  };

  const getTopBiddedAuctions = async () => {
    try {
      const {
        data: { data }
      } = await axios.get('/admin/reports/auctions/top-bidded');
      setTopBiddedAuctions(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                <p className="m-0px">Dashboard</p>
                {/* <span className="fs-12px">
                  Total Registered Users: {allRegisteredUsers}
                </span> */}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? 'active' : ''
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? 'block' : 'none' }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-group">
                        <div className="form-control-wrap position-relative">
                          <div className="text-danger fs-sm text-center position-absolute start-50 bottom-100 translate-middle-x w-100">
                            {dateError}
                          </div>
                          <div className="input-daterange date-picker-range input-group">
                            <DatePicker
                              selected={dayStart}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }} // prevent user from typing in date
                              onChange={(date, e) => {
                                if (date > dayEnd) {
                                  setDateError(
                                    'Cannot be greater than end date'
                                  );
                                  return;
                                }

                                setDayStart(date);
                              }}
                              selectsStart
                              // showTimeSelect
                              dateFormat="MM/dd/yyyy"
                              startDate={dayStart}
                              endDate={dayEnd}
                              maxDate={dayEnd}
                              wrapperClassName="start-m"
                              className="form-control"
                            />
                            <div className="input-group-addon">TO</div>
                            <DatePicker
                              selected={dayEnd}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }} // prevent user from typing in date
                              onChange={(date, e) => {
                                if (date < dayStart) {
                                  setDateError(
                                    'End date cannot be less than start date'
                                  );
                                  return;
                                }

                                setDayEnd(date);
                              }}
                              // showTimeSelect
                              dateFormat="MM/dd/yyyy"
                              startDate={dayStart}
                              endDate={dayEnd}
                              selectsEnd
                              minDate={dayStart}
                              maxDate={today}
                              wrapperClassName="end-m"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                        >
                          <Icon
                            className="d-none d-sm-inline"
                            name="calender-date"
                          />
                          <span>
                            <span
                              className="d-none d-md-inline"
                              style={{ textTransform: 'uppercase' }}
                            ></span>
                            {startCase(dataFilter)}
                          </span>
                          <Icon className="dd-indc" name="chevron-right" />
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  setDataFilter('today');
                                  setDayStart(
                                    new Date(
                                      `${today
                                        .toISOString()
                                        .slice(0, 10)}T00:00:00.000`
                                    )
                                  );
                                  setDayEnd(
                                    new Date(
                                      `${today
                                        .toISOString()
                                        .slice(0, 10)}T23:59:59.999`
                                    )
                                  );
                                }}
                                href="#!"
                              >
                                <span>Today</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  setDataFilter('thisWeek');
                                  const thisDay = today
                                    .toISOString()
                                    .split('T')[0];

                                  const weekStart = moment(
                                    thisDay,
                                    'YYYY-MM-DD'
                                  )
                                    .startOf('week')
                                    .toDate();

                                  const weekEnd = moment(thisDay, 'YYYY-MM-DD')
                                    .endOf('week')
                                    .toDate();

                                  setDayStart(weekStart);
                                  setDayEnd(weekEnd);
                                }}
                                href="#dropdownitem"
                              >
                                <span>This week</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  setDataFilter('thisMonth');
                                  const thisDay = today
                                    .toISOString()
                                    .split('T')[0];

                                  const monthStart = moment(
                                    thisDay,
                                    'YYYY-MM-DD'
                                  )
                                    .startOf('month')
                                    .toDate();

                                  const monthEnd = moment(thisDay, 'YYYY-MM-DD')
                                    .endOf('month')
                                    .toDate();

                                  setDayStart(monthStart);
                                  setDayEnd(monthEnd);
                                }}
                                href="#dropdownitem"
                              >
                                <span>This Month</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary">
                        <Icon name="reports" />
                        <span>Reports</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col xxl="3" sm="6">
              <DataCard
                title={`Total Registered Users`}
                chart={
                  <DefaultOrderChart
                    data={{
                      datasets: [
                        {
                          data: allRegisteredUsersGraphData?.map(
                            (item) => item?.count
                          ),
                          label: 'users'
                        }
                      ],
                      labels: allRegisteredUsersGraphData?.map(
                        (item) => item?.date
                      )
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={allRegisteredUsers}
                lastPeriodValue={auctionsStatistics?.totalAuctionsLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Returning Visitors`}
                chart={
                  <DefaultRevenueChart
                    data={{
                      datasets: [
                        {
                          data: returningUsersGraphData?.map(
                            (item) => item?.count
                          ),
                          label: 'users'
                        }
                      ],
                      labels: returningUsersGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={returningUsers}
                lastPeriodValue={userssStatistics?.activeUsersLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Verified Users`}
                chart={
                  <DefaultRevenueChart
                    data={{
                      // returningUsersGraphData
                      datasets: [
                        {
                          data: verifiedUsersGraphData?.map(
                            (item) => item?.count
                          ),
                          label: 'users'
                        }
                      ],
                      labels: verifiedUsersGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={verifiedUsers}
                lastPeriodValue={userssStatistics?.activeUsersLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Total Ravo Pro Users`}
                chart={
                  <DefaultRevenueChart
                    data={{
                      datasets: [
                        {
                          data: ravoProUsersGraphData?.map(
                            (item) => item?.count
                          ),
                          label: 'users'
                        }
                      ],
                      labels: ravoProUsersGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={ravoProUsers}
                lastPeriodValue={userssStatistics?.activeUsersLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Active Ravo Pro Users`}
                chart={
                  <DefaultRevenueChart
                    data={{
                      datasets: [
                        {
                          data: activeRavoProUsersGraphData?.map(
                            (item) => item?.count
                          ),
                          label: 'users'
                        }
                      ],
                      labels: activeRavoProUsersGraphData?.map(
                        (item) => item?.date
                      )
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={activeRavoProUsers}
                lastPeriodValue={userssStatistics?.activeUsersLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Total Live Auctions`}
                chart={
                  <DefaultCustomerChart
                    data={{
                      datasets: [
                        {
                          data: auctionStatsGraphData?.map(
                            (item) => item?.data?.auctionsLive ?? 0
                          ),
                          label: 'auctions'
                        }
                      ],
                      labels: auctionStatsGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={liveAuctions}
                lastPeriodValue={bidssStatistics?.totalBidsLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Total Completed Auctions`}
                chart={
                  <DefaultVisitorChart
                    data={{
                      datasets: [
                        {
                          data: auctionStatsGraphData?.map(
                            (item) => item?.data?.auctionsCompleted ?? 0
                          ),
                          label: 'auctions'
                        }
                      ],
                      labels: auctionStatsGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={auctionStats?.auctionsCompleted ?? 0}
                lastPeriodValue={
                  auctionsStatistics?.totalAuctionsCompletedLastPeriod
                }
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Total Reposted Auctions`}
                chart={
                  <DefaultOrderChart
                    data={{
                      datasets: [
                        {
                          data: auctionStatsGraphData?.map(
                            (item) => item?.data?.auctionsReposted ?? 0
                          ),
                          label: 'auctions'
                        }
                      ],
                      labels: auctionStatsGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={auctionStats?.auctionsReposted ?? 0}
                lastPeriodValue={auctionsStatistics?.transactionsLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Total Cancelled Auctions`}
                chart={
                  <DefaultOrderChart
                    data={{
                      datasets: [
                        {
                          data: auctionStatsGraphData?.map(
                            (item) => item?.data?.auctionsCancelled ?? 0
                          ),
                          label: 'auctions'
                        }
                      ],
                      labels: auctionStatsGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={auctionStats?.auctionsCancelled ?? 0}
                lastPeriodValue={auctionsStatistics?.transactionsLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Generated Revenue`}
                chart={
                  <DefaultRevenueChart
                    data={{
                      datasets: [
                        {
                          data: revenueGraphData?.map(
                            (item) => item?.totalRevenue
                          ),
                          label: 'AED'
                        }
                      ],
                      labels: revenueGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={totalRevenue}
                lastPeriodValue={revenueStatistics?.revenueLastPeriod}
              />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard
                title={`Bids Placed`}
                chart={
                  <DefaultCustomerChart
                    data={{
                      datasets: [
                        {
                          data: bidsAddedGraphData?.map((item) => item?.count),
                          label: 'bids'
                        }
                      ],
                      labels: bidsAddedGraphData?.map((item) => item?.date)
                    }}
                  />
                }
                state={dataFilter}
                currentPeriodValue={bidsAdded ?? 0}
                lastPeriodValue={vouchersStatistics?.vouchersUsedLastPeriod}
              />
            </Col>

            <Col xxl="6">
              <AuctionStatistics
                data={{
                  labels:
                    auctionStatsGraphData?.map((item) => item?.date) ?? [],
                  lineTension: 0.3,
                  datasets: [
                    {
                      data: auctionStatsGraphData?.map(
                        (item) => item?.data?.auctionsLive
                      ),
                      label: 'Live',
                      color: '#559bfb',
                      borderColor: '#559bfb',
                      pointHoverBorderColor: '#559bfb',
                      title: 'Live Auctions'
                    },
                    {
                      data: auctionStatsGraphData?.map(
                        (item) => item?.data?.auctionsCompleted
                      ),
                      label: 'Completed',
                      color: '#9d72ff',
                      borderColor: '#9d72ff',
                      pointHoverBorderColor: '#9d72ff',
                      title: 'Completed Auctions'
                    },
                    {
                      data: auctionStatsGraphData?.map(
                        (item) => item?.data?.auctionsReposted
                      ),
                      label: 'Reposted',
                      color: '#ff63a5',
                      borderColor: '#ff63a5',
                      pointHoverBorderColor: '#ff63a5',
                      title: 'Reposted Auctions'
                    },
                    {
                      data: auctionStatsGraphData?.map(
                        (item) => item?.data?.auctionsCancelled
                      ),
                      label: 'Cancelled',
                      color: '#eb6459',
                      borderColor: '#eb6459',
                      pointHoverBorderColor: '#eb6459',
                      title: 'Cancelled Auctions'
                    }
                  ]
                }}
              />
            </Col>
            <Col xxl="6">
              <RealtimeUsers
                data={{
                  labels: realtimeUsers?.map((item) => item?.dimension) ?? [],
                  lineTension: 0.3,
                  datasets: [
                    {
                      data: realtimeUsers?.map((item) => item?.metricValue),
                      label: 'users',
                      color: '#559bfb',
                      backgroundColor: '#559bfb',
                      borderColor: '#559bfb',
                      pointHoverBorderColor: '#559bfb',
                      title: 'Realtime Users'
                    }
                  ]
                }}
              />
            </Col>
            {/* <Col xxl="3" md="6">
              <BidsSourceStatistics data={bidssStatistics} />
            </Col>
            <Col xxl="3" md="6">
              <RavoStatistics
                items={carsStatistics?.totalCars}
                users={userssStatistics?.totalUsers}
                auctions={auctionsStatistics?.totalAuctions}
                bids={bidssStatistics?.totalBids}
              />
            </Col> */}
            <Col xxl="8">
              <LiveAuctions />
            </Col>
            <Col xxl="4" md="8" lg="6">
              <TopBiddedAuctions data={topBiddedAuctions} />
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
