import { Button, } from '@mui/material';
import React from 'react';
import { Col,Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import { fetchAllRefunds, processRefundRequest } from '../../../redux/features/usersSlice/refundSlice';
import { toast } from 'react-toastify';

export default function ConfirmRefundModalButton({refund}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const dispatch = useDispatch();

  const onConfirm =async () => {
    try {
        setLoading(true);
        const response =await dispatch(processRefundRequest(refund._id)).unwrap();
        toggle();
        dispatch(fetchAllRefunds())
        toast.success(response.message?? 'Refund Initiated Successfully')
        console.log(response)
    } catch (error) {
        console.log(error)
        toast.error('Refund Failed')
        
    }finally{
        setLoading(false);
    }
  };


  return (
    <div>
      <div className="me-3">
        <Button variant="contained" onClick={toggle}>
          Refund
        </Button>
      </div>
  
        <Modal isOpen={isOpen}>
          <ModalHeader
            close={
              <button className="close" onClick={toggle} type="button">
                <Icon name="cross" />
              </button>
            }
          >
            Confirm Refund
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to refund this payment?</p>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button
                  color="light"
                  onClick={toggle}
                  style={{ width: '100%' }}
                  type="button"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  color="primary"
                  onClick={onConfirm}
                  style={{ width: '100%' }}
                >
                  {loading ? (
                    <Spinner size="sm" />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
  
    </div>
  );
}
