import React, { useEffect, useState } from 'react'
import Content from '../../layout/content/Content';
import ListBlockHeader from '../../components/list-block-header/ListBlockHeader';
import { Block, DataTableHead, DataTableItem, DataTableRow, PaginationComponent, PreviewAltCard } from '../../components/Component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Head from '../../layout/head/Head';
import { Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import { capitalize, formatCreatedAtDateTime } from '../../utils/Utils';
import TableHeader from '../../components/table/TableHeader';
import { fetchAllReservations } from '../../redux/features/usersSlice/vehiclereservationSlice';

import { Link } from 'react-router-dom';
import { settleDealerRemote } from '../../redux/features/usersSlice/settleDealerSlice';
import { toast } from 'react-toastify';
import { refundReservationRemote } from '../../redux/features/usersSlice/refundReservationSlice';
function DealerReservations() {

  const { data, totalCount, perPage } = useSelector((state) => state.reservations);
  const [loading, setLoading] = useState(false);
  const [onSearchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [reservations, setReservations] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [sortByField, setSortByField] = useState('createdAt');
  const [sortByValue, setSortByValue] = useState(-1);
  const [vertical, setVertical] = useState(null);
  const onFilterChange = (e) => {

  };

  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      options: {
        page: currentPage,
        limit: 10,
      }
    };
    dispatch(fetchAllReservations(data))
  }, [currentPage, perPage]);

  useEffect(() => {
    console.log("build");
    setReservations(data.data);
  }, [data]);

  const settleDealerSelector = useSelector((state) => state.settleDealer);

  useEffect(() => {

    console.log(settleDealerSelector)
    if (settleDealerSelector.error != null) {
      toast.error(settleDealerSelector.error)
      return;
    }
    else if (settleDealerSelector?.data?.fatoorahInvoiceId ?? false) {
      toast.success(`Dealer settled sucessfully, payment reference: ${settleDealerSelector.data.fatoorahInvoiceId}`)
      let data = {
        options: {
          page: 1,
          limit: 10,
        }
      };
      dispatch(fetchAllReservations(data))
    }
  }, [settleDealerSelector]);

  const settleDealer = (id) => {
    dispatch(settleDealerRemote({ reservationId: id }));
  }

  const refundReservationSelector = useSelector((state) => state.refundReservation);

  useEffect(() => {
    if (refundReservationSelector.error != null) {
      toast.error(refundReservationSelector.error)
      return;
    }
    else if (refundReservationSelector?.data._id != null) {
      toast.success(`Refund initiated sucessfully.`)
      let data = {
        options: {
          page: 1,
          limit: 10,
        }
      };
      dispatch(fetchAllReservations(data))
    }
  }, [refundReservationSelector]);

  const intiateRefund = (id) => {
    dispatch(refundReservationRemote({ reservationId: id }));
  }
  const setDateSort = (value) => {

  }

  const getStatusBadgeColor = (status) => {
    // ["initiated", "captured", "refund_initiated", "refund_success", "cancelled"],
    switch (status) {
      case "initiated":
        return "gray";
      case "captured":
        return "success";
      case "refund_initiated":
        return "secondary";
      case "refund_success":
        return "primary";
      case "cancelled":
        return "danger";
      default:
        return "gray";
    }
  }

  return (
    <>
      <Head title={`Reservation Requests`}></Head>
      <Content>
        <ListBlockHeader
          msg={0 ? `Total items:  (Hardocded)}` : ''}
          title="Reservation Requests"
          placeholder="Search ID"
          onSearchText={onSearchText}
          onSearchChanged={onFilterChange}
          onDateChanged={setDateFilter}
        >

           <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
               Filter by dealer
            </DropdownToggle>

            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(ev) => {
                      setVertical('');
                      localStorage.removeItem('verticalItemGetValue');
                    }}
                  >
                    <span>ALL</span>
                  </DropdownItem>
                </li>
                {["initiated", "captured", "settled", "refund_initiated", "refund_success", "cancelled"]
                  .map((e) => (
                    <li key={e}>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          setVertical(e);
                          const selectedOptionString = JSON.stringify(e);
                          localStorage.setItem(
                            'verticalItemGetValue',
                            selectedOptionString
                          );
                        }}
                      >
                        <span>{capitalize(e ?? '')}</span>
                      </DropdownItem>
                    </li>
                  ))}
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
               Filter by status
            </DropdownToggle>

            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(ev) => {
                      setVertical('');
                      localStorage.removeItem('verticalItemGetValue');
                    }}
                  >
                    <span>ALL</span>
                  </DropdownItem>
                </li>
                {["initiated", "captured", "settled", "refund_initiated", "refund_success", "cancelled"]
                  .map((e) => (
                    <li key={e}>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          setVertical(e);
                          const selectedOptionString = JSON.stringify(e);
                          localStorage.setItem(
                            'verticalItemGetValue',
                            selectedOptionString
                          );
                        }}
                      >
                        <span>{capitalize(e)}</span>
                      </DropdownItem>
                    </li>
                  ))}
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ListBlockHeader>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow size="sm">
                <span>ID</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>User</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Dealer</span>
              </DataTableRow>
              <DataTableRow size="sm" >
                <TableHeader
                  name="Date"
                  setSort={setDateSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Amount</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Status</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Type</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Actions</span>
              </DataTableRow>
            </DataTableHead>
            {
              ((reservations?.length ?? 0) > 0) && reservations.map((e) => {
                return <DataTableItem key={e._id}>
                  <DataTableRow>
                    {e.reservation_id}
                  </DataTableRow>
                  <DataTableRow>
                    <Link to={`/users/${e.user?.id}`}> {e.user?.full_name} </Link>
                    <br></br> {e.user?.phone_number}
                  </DataTableRow>
                  <DataTableRow>
                    <Link to={`/users/${e.dealer?.id}`}> {e.dealer?.company.name} </Link>
                    <br></br> {e.dealer?.phone_number}
                  </DataTableRow>
                  <DataTableRow>
                    {formatCreatedAtDateTime(e.createdAt)}
                  </DataTableRow>
                  <DataTableRow>
                    {e.amount}
                  </DataTableRow>
                  <DataTableRow>
                    <Badge color={getStatusBadgeColor(e.status)}> {capitalize(e?.status.toString() ?? '')}</Badge>

                  </DataTableRow>
                  <DataTableRow>
                    {capitalize(e?.orderFrom ?? '')}
                  </DataTableRow>
                  <DataTableRow>
                    {(!["settled", "cancelled", "refund_initiated", "refund_success",].includes(e.status)) && (<div><Button color="secondary" onClick={(ev) => {
                      ev.preventDefault();
                      intiateRefund(e._id);
                    }}> Refund </Button>

                      <Button style={{ marginLeft: '10px' }} color="primary" onClick={(ev) => {
                        ev.preventDefault();
                        settleDealer(e._id)
                      }}> Settle </Button></div>)}


                  </DataTableRow>
                </DataTableItem>
              })
            }
            <PreviewAltCard>
              {reservations?.length > 0 ? (
                <PaginationComponent
                  itemPerPage={perPage > 0 ? perPage : 1 ?? 0}
                  totalItems={totalCount ?? 0}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : loading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className="text-center">
                  <span className="text-silent">No items found</span>
                </div>
              )}
            </PreviewAltCard>
          </div>
        </Block>
      </Content>
    </>
  );
}

export default DealerReservations