import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  data: {},
  loading: false,
  error: null,
};
const fetchCarDetails = createAsyncThunk("cardetails", (id) => {
  // console.log(id);
  let url = `/admin/baseformvalues/${id}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const updateItem = createAsyncThunk("updateItem",async (id,data) => {
  try {
    let url = `/admin/baseformvalues/update/${id}`;
    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});
const addInspection = createAsyncThunk("addInspection",async (data) => {
  try {
    let url = `/admin/inspectionformvalues/create`;
    const response = await axios.post(url, data);
    toast.success(' Added');
    window.location.reload();
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

const carDetails = createSlice({
  name: "carDetails",
  initialState,
  reducers: {
    updateCarInspection: (state, action) => {
      const data = state.data;
      data.carInspection = action.payload;
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    });
    builder.addCase(fetchCarDetails.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchCarDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = action.error.message;
    });
    builder.addCase(updateItem.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    });
    builder.addCase(updateItem.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(updateItem.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = action.error.message;
    });
  },
});

export default carDetails.reducer;
export const { updateCarInspection } = carDetails.actions;
export { fetchCarDetails ,addInspection,updateItem};
