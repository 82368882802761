import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const PriceInput = ({ value, onChange, label, disabled = false, error = null }) => {
  // Initialize state to keep track of the display value
  

  return (
    <div className="form-control-wrap">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">{label}</span>
        </div>
        <Input
          className={error && 'border border-danger'}
          disabled={disabled}
          type="text" // Use text type to display formatted input
          value={value}
          onInput={onChange}
          maxLength="12"
          onKeyDown={(e) => {
            const notAllowedKeys = ['e', 'E', '+', '-'];
            if (notAllowedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={onChange}
        />
      </div>
      {error && (
        <small className="text-danger ff-italic">{error}</small>
      )}
    </div>
  );
};


export default PriceInput;
