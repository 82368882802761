import React, { useEffect, useState } from 'react';
import { ModalBody, Col, Input } from 'reactstrap';
import { Icon, Button, Row } from '../../../components/Component';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { getImage, uploadToS3 } from '../../../utils/uploadImageToS3';
import { useDispatch } from 'react-redux';
import { updateAutocompleteOptions } from '../../../redux/features/verticalsSlice/autocompleteOptionsSlice';

export const EditImage = ({ toggle, item }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState('');
  const folderName = 'Make';
  const [displayAvatar, setDisplayAvatar] = useState('');

  useEffect(() => {
    if (item?.icon) {
      async function fetchImage() {
        const resData = await getImage(item?.icon);
        const s3url = resData.signedRequest;
        setDisplayAvatar(s3url);
      }

      fetchImage();
    }
  }, []);

  const editImage = async (e) => {
    e.preventDefault();
    let fileName = item?.icon;
    if (avatar && avatar.name) {
      setLoading(true);
      fileName = `${folderName}/${uuidv4()}.${avatar.name.split('.').pop()}`;
      await uploadToS3(avatar, fileName, 'image/*');
      const formData = {
        icon: fileName
      };
      try {
        dispatch(updateAutocompleteOptions({ id: item?.id, data: formData }));
        toast.success('Updated successfully');
        setLoading(false);
        toggle();
      } catch (err) {
        toast.error('Not modified with changes!');
      }
    }
  };

  const handleImage = async (e) => {
    if (e.target.files) {
      let result = e.target.files[0];
      let url = URL.createObjectURL(e.target.files[0]);
      setDisplayAvatar(url);
      setAvatar(result);
    }
  };
  return (
    <ModalBody>
      <a href="#cancel" className="close">
        {' '}
        <Icon
          name="cross-sm"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
        ></Icon>
      </a>
      <div className="p-2">
        <h5 className="title">{'Upload Image'}</h5>
        <div className="mt-4">
          <form onSubmit={(e) => editImage(e)}>
            <Row className="g-3">
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">Image</label>
                  <div className="form-control-wrap">
                    <div className="form-file">
                      <Input
                        type="file"
                        id="customFile"
                        onChange={(e) => handleImage(e)}
                      />
                    </div>
                  </div>
                </div>
                <Col sm="6">
                  {displayAvatar && (
                    <img
                      src={displayAvatar}
                      alt={''}
                      style={{ height: '8rem' }}
                    />
                  )}
                </Col>
              </Col>
              <Col size="12" className="mt-3">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                  <li>
                    <Button color="primary" size="lg" disabled={loading}>
                      {loading ? 'Updating...' : 'Update'}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </ModalBody>
  );
};
