import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";

const initialState = {
  auctions: {},
  bids: {},
  users: {},
  items: {},
  services: {},
  vouchers: {},
  revenue: {},
  loading: false,
  error: null,
};
const fetchAuctionsStatistics = createAsyncThunk("dashboard/auctions", (data) => {
  let url = `/v1/admin/dashboard/auctions/${data}`;
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const fetchUsersStatistics = createAsyncThunk("dashboard/users", (data) => {
  let url = `/v1/admin/dashboard/users/${data}`;
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const fetchCarsStatistics = createAsyncThunk("dashboard/items", (data) => {
  let url = `/v1/admin/dashboard/items/${data}`;
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const fetchBidsStatistics = createAsyncThunk("dashboard/bids", (data) => {
  let url = `/v1/admin/dashboard/bids/${data}`;
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const fetchServicesStatistics = createAsyncThunk("dashboard/services", (data) => {
  let url = `/v1/admin/dashboard/services/${data}`;
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const fetchVouchersStatistics = createAsyncThunk("dashboard/vouchers", (data) => {
  let url = `/v1/admin/dashboard/vouchers/${data}`;
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const fetchRevenueStatistics = createAsyncThunk("dashboard/revenue", (data) => {
  let url = `/v1/admin/dashboard/revenue/${data}`;
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    //auctions statistics
    builder.addCase(fetchAuctionsStatistics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAuctionsStatistics.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.auctions = action.payload;
    });
    builder.addCase(fetchAuctionsStatistics.rejected, (state, action) => {
      state.loading = false;
      state.auctions = {};
      state.error = action.error.message;
    });
    // users statistics
    builder.addCase(fetchUsersStatistics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUsersStatistics.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.users = action.payload;
    });
    builder.addCase(fetchUsersStatistics.rejected, (state, action) => {
      state.loading = false;
      state.users = {};
      state.error = action.error.message;
    });
    // bids statistics
    builder.addCase(fetchBidsStatistics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchBidsStatistics.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.bids = action.payload;
    });
    builder.addCase(fetchBidsStatistics.rejected, (state, action) => {
      state.loading = false;
      state.bids = {};
      state.error = action.error.message;
    });
    //services statistics
    builder.addCase(fetchServicesStatistics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchServicesStatistics.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.services = action.payload;
    });
    builder.addCase(fetchServicesStatistics.rejected, (state, action) => {
      state.loading = false;
      state.services = {};
      state.error = action.error.message;
    });
    //vouchers statistics
    builder.addCase(fetchVouchersStatistics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchVouchersStatistics.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.vouchers = action.payload;
    });
    builder.addCase(fetchVouchersStatistics.rejected, (state, action) => {
      state.loading = false;
      state.vouchers = {};
      state.error = action.error.message;
    });
    //revenue statistics
    builder.addCase(fetchRevenueStatistics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchRevenueStatistics.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.revenue = action.payload;
    });
    builder.addCase(fetchRevenueStatistics.rejected, (state, action) => {
      state.loading = false;
      state.revenue = {};
      state.error = action.error.message;
    });
    // items statistics
    builder.addCase(fetchCarsStatistics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCarsStatistics.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.items = action.payload;
    });
    builder.addCase(fetchCarsStatistics.rejected, (state, action) => {
      state.loading = false;
      state.items = {};
      state.error = action.error.message;
    });
  },
});

export default dashboardSlice.reducer;
export {
  fetchAuctionsStatistics,
  fetchBidsStatistics,
  fetchServicesStatistics,
  fetchUsersStatistics,
  fetchVouchersStatistics,
  fetchRevenueStatistics,
  fetchCarsStatistics,
};
