import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  totalCount: 0,
  selectAllChecked: false,
  loading: false,
  error: null,
};
const fetchVouchers = createAsyncThunk("vouchers", (data) => {
  let url = `/v1/admin/vouchers?page=${data?.page ?? 1}&type=${data.type}&status=${data.status}`;
  if (data?.searchText) {
    url = url.concat(`&searchText=${data.searchText}`);
  }
  if (data?.sortBy) {
    url = url.concat(`&sortBy=${JSON.stringify(data.sortBy)}`);
  }
  if (data?.dateFilter) {
    url = url.concat(`&dateFilter=${JSON.stringify(data.dateFilter)}`);
  }
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});

const vouchersSlice = createSlice({
  name: "vouchers",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const { index, value } = action.payload;
      // console.log(index);
      let data = state.data;
      data[index].check = value;
      state.data = data;
      state.selectAllChecked = data.every((e) => e.check);
    },
    setAllSelected: (state, action) => {
      const { value } = action.payload;
      // console.log(value);
      let data = state.data;
      state.data = data.map((e) => {
        e.check = value;
        return e;
      });
      state.selectAllChecked = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVouchers.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchVouchers.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchVouchers.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchVouchers({ ...arg })), 500);
const debouncedFetchVouchers =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export default vouchersSlice.reducer;
export const { setSelected, setAllSelected } = vouchersSlice.actions;
export { fetchVouchers, debouncedFetchVouchers };
