import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { FormTextField, Icon } from "../../../components/Component";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createVerticals, resetCreateVerticals } from "../../../redux/features/verticalsSlice/createVerticalsSlice";
import FormUpload from "../../../components/form/FormUpload";
import ImageUpload from "../../../components/form/ImageUpload";
import { v4 as uuidv4 } from "uuid";

import { useForm } from "react-hook-form";
import { uploadToS3 } from "../../../utils/uploadImageToS3";
import { useNavigate } from "react-router-dom";

export default function AddVerticalButtonWithModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [displayImg, setDisplayImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      name: "",
      image: "",
      labelAr:"",
      description: "",
    },
  });
  const { handleSubmit, control,setValue } = methods;

  const dispatch = useDispatch();
  const create = async(data,e) => {
    e.preventDefault();
    if (!data.name || !data.image || !data.description) {
      toast.error("All fields must be filled out.");
      return;
    }
    else if (data.name.length < 3 || data.description.length < 3) {
      // If name or description has fewer than three characters, display an error message or handle it accordingly.
      toast.error("Name and description must have at least 3 characters.");
      return;
    }
    else if (/^\d+$/.test(data.name)) {
      // If the name consists entirely of digits, display an error message or handle it accordingly.
      toast.error("Name cannot consist entirely of digits.");
      return;
    } 
    else if (/([^\w\s])\1+/.test(data.name)) {
      // If the name contains repeated special characters, display an error message.
      toast.error("Name cannot contain repeated special characters.");
      return;
    }
    else{

    }

      try{
        setLoading(true);
        if(data.image){
          const fileName = `vertical/${uuidv4()}.${data.image.name?.split(".")?.pop()}`;
            await uploadToS3(data.image,fileName );
            data.image = fileName;
          }   
          dispatch(createVerticals(data))
          .then((responseData) => {
            const verticalId = responseData.payload.data.id;
             navigate(`/verticals/${verticalId}/basic_forms`,{ replace: true });
          })
          .catch((error) => {
            // Handle errors here
            console.error(error);
          });
      }
      catch(err){
          toast.error(err);
      }
  
  };
  const { status } = useSelector((state) => state.createVertical);
  useEffect(() => {
    if (status === "success") {
      toast.success("Created Vertical Successfully");
      dispatch(resetCreateVerticals());
      setIsOpen(false);
    } else if (status === "error") {
      toast.error("Create vertical failed");
      dispatch(resetCreateVerticals());
    }
  }, [status]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const handleImageChange = (e, field) => {
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {
      field.onChange(selectedFile);
      setDisplayImg(URL.createObjectURL( selectedFile ));
    }
    else{
      setDisplayImg(null);
    }
    
  };
  const handleRemove = (e) => {
    e.preventDefault();
    setDisplayImg("");
    setValue("image", ""); 
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
        fileInput.value = ""; // Reset the input field value
    }
    
};
  return (
    <div>
      <Button onClick={toggle}>Add Vertical</Button>
      {isOpen && (
        <Modal isOpen={isOpen}>
          <ModalHeader
            close={
              <button className="close" onClick={toggle}>
                <Icon name="cross" />
              </button>
            }
          >
            Add Vertical
          </ModalHeader>
          <form onSubmit={handleSubmit(create)}>
          <ModalBody>       
            <FormTextField required control={control} name="name" label="Name" maxLength={15} />
            <FormTextField required={false} control={control} name="labelAr" label="Arabic Name" maxLength={30} />
            <FormTextField required control={control} name="description" label="Description" />
            <ImageUpload 
               customValidator={(img) => {
                // Validate that the image is square
                return img.width === img.height;
              }}
              accept="image/jpeg, image/png"
            handleImageChange={handleImageChange} required control={control} name="image" label="image"  />
            {displayImg && (
                <div className="position-relative">
                    <img
                        src={
                          displayImg
                        }
                        alt="Avatar"
                    />
                    <button type="button"
                        className="btn btn-danger position-absolute top-0 start-0"
                        onClick={(e) => {
                          handleRemove(e);                      
                        }}
                    >
                        <Icon name="trash" />
                    </button>
                </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" >
              {loading ? <Spinner size="sm" color="light" />:"Create"}
              </Button>
          </ModalFooter>
          </form>
        </Modal>
      )}
    </div>
  );
}
