import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 10,
  loading: false,
  error: null
};

// Get All Flags
const fetchFlags = createAsyncThunk('flags', (data) => {
  let url = `/admin/api/v1/flags/list`;
  return axios.post(url, data).then((response) => {
    return response.data;
  });
});

// Get Flag
const getFlag = createAsyncThunk('flag', async ({ id }) => {
  let url = `/admin/api/v1/flags/${id}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error with the message
  }
});

// Update Flag
const updateFlag = createAsyncThunk('flags/update', async ({ id, data }) => {
  let url = `/admin/api/v1/flags/update/${id}`;
  try {
    const response = await axios.put(url, data);
    toast.success('Flag Updated');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error with the message
  }
});

export const clearFlagDetails = createAsyncThunk(
  'clearFlagDetails',
  async () => {
    return null; // You can return any value here as it won't be used.
  }
);

const flagSlice = createSlice({
  name: 'flags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFlags.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchFlags.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.data ?? null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchFlags.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(updateFlag.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateFlag.fulfilled, (state, action) => {
      state.loading = false;
      state.details = action.payload?.data ?? [];
    });
    builder.addCase(updateFlag.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(clearFlagDetails.fulfilled, (state) => {
      state.data = {};
    });
  }
});

export default flagSlice.reducer;
export { fetchFlags, getFlag, updateFlag };
