import React from 'react';
import { Controller } from 'react-hook-form';
import { Upload, X } from 'lucide-react';
import { CSS } from '@dnd-kit/utilities';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { Image as CustomImage } from '../Component';

// Helper function to check image ratio
const checkImageRatio = (file, ratio) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const imageRatio = img.width / img.height;
      const tolerance = 0.1; // 10% tolerance for ratio
      const isValidRatio = !ratio || 
        Math.abs(imageRatio - ratio) <= tolerance;
      URL.revokeObjectURL(img.src);
      resolve(isValidRatio);
    };
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      reject(new Error('Failed to load image'));
    };
    img.src = URL.createObjectURL(file);
  });
};

export function SortableImage({ id, file, onRemove, ratio }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  // Calculate padding based on ratio (height/width * 100)
  const paddingTop = ratio ? `${(1 / ratio) * 100}%` : '100%';

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="upload-preview-wrapper"
      {...attributes}
      {...listeners}
    >
      <div 
        className="upload-preview"
        style={{ paddingTop }}
      >
        <div className="upload-preview-content">
          {(file instanceof File) ? (
            <img
              src={URL.createObjectURL(file)}
              alt="Preview"
              className="upload-preview-image"
            />
          ) : (
            <CustomImage 
              image={file} 
              alt="Preview"
              size={150}
              className="upload-preview-image"
            />
          )}
          <button
            type="button"
            onClick={onRemove}
            className="upload-remove-button z-10"
          >
            <X size={16} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default function FormMultiImageUpload({
  control,
  name,
  label,
  ...props
}) {
  const {
    maxFilesLength = 5,
    minFilesLength = 1,
    inputType = 'image',
    active = 'false',
    dragenter = () => null,
    dragleave = () => null,
    drop = () => null,
    handleFileChange = () => null,
    removeImage = (ind) => null,
    errorMessage = '',
    multiple = true,
    ratio = null, // New prop for desired width/height ratio
    inputProps
  } = props;

  const inputId = React.useId();
  const [isDraggingFile, setIsDraggingFile] = React.useState(false);
  const [ratioError, setRatioError] = React.useState('');

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const validateAndAddFiles = async (files, field) => {
    const validFiles = [];
    setRatioError('');

    for (const file of files) {
      try {
        const isValidRatio = await checkImageRatio(file, ratio);
        if (isValidRatio) {
          validFiles.push(file);
        } else {
          setRatioError(`Images must have a ${ratio}:1 ratio (±10% tolerance)`);
        }
      } catch (error) {
        console.error('Error validating image:', error);
      }
    }

    if (validFiles.length > 0) {
      const updatedFiles = multiple
        ? [...(field.value || []), ...validFiles].slice(0, maxFilesLength)
        : validFiles;
      field.onChange(updatedFiles);
    }
    return validFiles;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingFile(true);
    dragenter(e);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingFile(false);
    dragleave(e);
  };

  const handleDrop = async (e, field) => {
    e.preventDefault();
    setIsDraggingFile(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length) {
      const validFiles = await validateAndAddFiles(droppedFiles, field);
      if (validFiles.length > 0) {
        handleFileChange(e);
      }
    }
    drop(e);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className="upload-container">
          <label className="upload-label">{label}</label>
          <div
            className={`upload-area ${
              isDraggingFile
                ? 'upload-active'
                : active === 'true'
                  ? 'upload-active'
                  : active === 'bad'
                    ? 'upload-error'
                    : 'upload-default'
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, field)}
          >
            <input
              type="file"
              id={inputId}
              onChange={async (e) => {
                const files = Array.from(e.target.files);
                const validFiles = await validateAndAddFiles(files, field);
                if (validFiles.length > 0) {
                  handleFileChange(e);
                }
              }}
              className="upload-input"
              {...inputProps}
              multiple={multiple}
              accept="image/*"
              hidden
            />

            <div>
              {field?.value?.length > 0 ? (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={(event) => {
                    const { active, over } = event;
                    if (active.id !== over.id) {
                      const oldIndex = field.value.findIndex(
                        (_, i) => `item-${i}` === active.id
                      );
                      const newIndex = field.value.findIndex(
                        (_, i) => `item-${i}` === over.id
                      );
                      field.onChange(
                        arrayMove(field.value, oldIndex, newIndex)
                      );
                    }
                  }}
                >
                  <SortableContext
                    items={field.value.map((_, i) => `item-${i}`)}
                    strategy={rectSortingStrategy}
                  >
                    <div className="upload-preview-container">
                      {field.value.map((file, index) => (
                        <SortableImage
                          key={`item-${index}`}
                          id={`item-${index}`}
                          file={file}
                          ratio={ratio}
                          onRemove={() => {
                            console.log("fffffffffffffff")
                            const updatedFiles = field.value.filter(
                              (_, i) => i !== index
                            );
                            field.onChange(updatedFiles);
                            removeImage(index);
                          }}
                        />
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
              ) : (
                <label htmlFor={inputId} className="upload-label-content">
                  <Upload size={40} className="upload-icon" />
                  <span className="upload-text">
                    Drag and drop or click to upload
                  </span>
                  <span className="upload-subtext">
                    (Max {maxFilesLength} files)
                    {ratio && ` - ${ratio}:1 ratio required`}
                  </span>
                </label>
              )}
            </div>
            {error && <p className="upload-error-text">{error.message}</p>}
            {ratioError && <p className="upload-error-text">{ratioError}</p>}
            {field.value?.length !== 0 &&
              field.value?.length < maxFilesLength && (
                <label htmlFor={inputId} className="upload-add-more">
                  Add more files
                </label>
              )}
          </div>
        </div>
      )}
    />
  );
}