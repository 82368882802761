import React, { useEffect, useState } from 'react';

const ShipmentLink = ({ item }) => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    async function fun() {
      if (item?.shipmentId) {
        let pdfUrl = item?.shipmentId
          ? `https://www.aramex.com/ae/en/track/track-results-new?ShipmentNumber=${item?.shipmentId}`
          : null;
        setUrl(pdfUrl);
      }
    }
    fun();
  }, [item]);
  return (
    <div key={item?.id}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {item?.shipmentId}
      </a>
    </div>
  );
};

export default ShipmentLink;
