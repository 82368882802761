import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  options: null,
  totalCount: 0,
  perPage:10,
  loading: false,
  error: null,
};
const fetchAutocompleteOptions = createAsyncThunk("AutocompleteOptions", (data) => {
  let url = `/admin/api/v1/autocomplete/list`;
  return axios.post(url,data).then((response) => {
    return response.data;
  });
});
const updateAutocompleteOptions = createAsyncThunk("updateAutocompleteOptions", (data) => {
  let url = `/admin/api/v1/autocomplete/update/${data.id}`;
  return axios.put(url,data.data).then((response) => {
    window.location.reload();
    return response.data;
  });
});
const bulkInsertAutocompleteOptions = createAsyncThunk("bulkInserAutocompleteOptions", (data) => {
  let url = `/admin/api/v1/autocomplete/addBulk`;
  return axios.post(url,data).then((response) => {
    window.location.reload();
    return response.data;
  });
});
const bulkInsertAutocompleteOptionsRef = createAsyncThunk("bulkInsertAutocompleteOptionsRef", (data) => {
  let url = `/admin/api/v1/autocomplete/addBulkRef`;
  return axios.post(url,data).then((response) => {
    // window.location.reload();
    return response.data;
  });
});
const clearOptions= createAsyncThunk('clearOptions', async () => {
  return [];
});
const autocompleteOptionsSlice = createSlice({
  name: "AutocompleteOptions",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchAutocompleteOptions.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchAutocompleteOptions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data??null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchAutocompleteOptions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(clearOptions.fulfilled, (state) => {
      state.data = [];
    });
    builder.addCase(updateAutocompleteOptions.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(updateAutocompleteOptions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      // state.data = action.payload.data;
    });
    builder.addCase(updateAutocompleteOptions.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
    builder.addCase(bulkInsertAutocompleteOptions.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(bulkInsertAutocompleteOptions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      // state.data = action.payload.data;
    });
    builder.addCase(bulkInsertAutocompleteOptions.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
    builder.addCase(bulkInsertAutocompleteOptionsRef.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(bulkInsertAutocompleteOptionsRef.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      // state.data = action.payload.data;
    });
    builder.addCase(bulkInsertAutocompleteOptionsRef.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
  },
});

export default autocompleteOptionsSlice.reducer;
const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchAutocompleteOptions({ ...arg })), 500);
const debouncedFetchAutocompleteOptions =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export { fetchAutocompleteOptions, debouncedFetchAutocompleteOptions,clearOptions,updateAutocompleteOptions ,bulkInsertAutocompleteOptions,bulkInsertAutocompleteOptionsRef};
