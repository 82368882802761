export default function msToTime(s) {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  hrs = hrs.toString().padStart(2, 0);
  mins = mins.toString().padStart(2, 0);
  secs = secs.toString().padStart(2, 0);

  return hrs + " : " + mins + " : " + secs;
}
