import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  DataTableItem,
  DataTableRow,
  Icon,
  InputSwitch
} from '../../../components/Component';
import { useDispatch } from 'react-redux';
import { Button, Input, Spinner } from 'reactstrap';
import ConfirmModal from '../../verticals/components/ConfirmModal';

const SubRow = ({
  item,
  setUpdateItem,
  handleSub,
  handleDel,
  handleActivate
}) => {
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [idx, setIdx] = useState(null);
  const [inputValue, setInputValue] = useState(item?.limit);

  const dispatch = useDispatch();
  const handleSwitchToggle = (item) => {
    setUpdateItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <DataTableItem key={item.id}>
      <DataTableRow>
        <span className="tb-sub">{item?.vertical_id?.name}</span>
      </DataTableRow>
      <DataTableRow>
        <div className="form-control-wrap">
          <div style={{ display: 'flex' }}>
            <Input
              style={{ width: '100%' }}
              disabled={!item.isActive}
              type="text"
              id={`fv-full-name-${idx}`}
              name="fullname"
              className="form-control"
              placeholder="Number of free auctions"
              value={inputValue} // Use value from the state
              onChange={(e) => setInputValue(e.target.value, idx)} // Pass the value and index
              onInput={(e) => {
                let newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                e.target.value = newValue;
              }}
            />
            <Button
              style={{ marginLeft: '10px' }}
              onClick={(e) => handleSub(e, item.vertical_id._id, inputValue)}
              disabled={!item?.isActive}
              color="secondary"
              type="submit"
            >
              {!loading ? <>Save</> : <Spinner size="sm" color="light" />}
            </Button>
          </div>
        </div>
      </DataTableRow>
      <DataTableRow>
        <span className="tb-sub">{item?.balance}</span>
      </DataTableRow>
      <DataTableRow>
        <span className="tb-sub">
          <div className="custom-control custom-switch ">
            <InputSwitch
              checked={item?.isActive}
              id={`active-${item.id}`}
              onClick={(e) => handleSwitchToggle(item)}
            />
          </div>
        </span>
      </DataTableRow>
      {/* ****** Modal Start ****** */}
      {showModal ? (
        <ConfirmModal
          msg={item.isActive ? 'suspend the package?' : 'Activate'}
          name={''}
          action={item.isActive ? 'Suspend' : 'Activate'}
          open={showModal}
          loading={cancelLoading}
          handleClick={handleModal}
          handleConfirm={item.isActive ? handleDel : handleActivate}
          handlecloseModal={closeModal}
        />
      ) : null}
      {/* ****** Modal End ****** */}
    </DataTableItem>
  );
};

export default SubRow;
