import React, { useEffect, useState } from 'react';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
  Block,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
  RSelect,
  InputSwitch
} from '../../components/Component';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Badge,
  Spinner,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Col
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  debouncedFetchUsers,
  fetchUsers,
  setAllSelected,
  setSelected
} from '../../redux/features/usersSlice/usersSlice';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import TableHeader from '../../components/table/TableHeader';
import ReactDatePicker from 'react-datepicker';
import DateFilter from '../../components/date-filter/DateFilter';
import ListBlockHeader from '../../components/list-block-header/ListBlockHeader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input';
import {
  createUser,
  updateUserDetails
} from '../../redux/features/usersSlice/userDetails';
import 'react-phone-number-input/style.css';

import {
  LeadTypes,
  UserRegisterTypes,
  UserVerificationStatus,
  validateEmail
} from '../../utils/Utils';
import { fetchLeads } from '../../redux/features/usersSlice/leadsSlice';

const LeadsData = () => {
  const navigate = useNavigate();
  const [onSearchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState(null);
  const [filter, setFilter] = useState(null);
  const [sortByField, setSortByField] = useState('createdAt');
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);
  const [user, setUser] = useState(null);
  const [value, setValue] = useState();
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const [fullName, setFullName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  // const [password, setPassword] = useState('');
  // const [cpassword, setCPassword] = useState('');
  const [errorX, setErrorX] = useState(null);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [role, setRole] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  const [phoneVerified, setPhoneVerified] = useState(null);
  const [active, setActive] = useState(null);
  const fileName = 'leads-data';

  function formatDate(dateString) {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit',
      // timeZoneName: 'short',
    };

    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }

  function formatPhone(phoneObj) {
    if (!phoneObj) return '';
    else return `${phoneObj.dial_code}${phoneObj.number}`;
  }

  const exportCSV = (e) => {
    e.preventDefault();
    const headers = ['Email', 'Phone', 'Country', 'Source', 'Created At'];

    // Create a CSV string with only the data rows
    const csvContent = listData
      .map((item) =>
        [
          item.email,
          formatPhone(item.phone),
          item.country,
          item.source,
          formatDate(item.createdAt)
        ].join(',')
      )
      .join('\n'); // Data rows

    // Combine the headers with the data
    const fullCsvContent = headers.join(',') + '\n' + csvContent;

    // Create a Blob with the CSV content
    const blob = new Blob([fullCsvContent], { type: 'text/csv;charset=utf-8' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a hidden anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'app_leads.csv';

    // Trigger a click event on the anchor element to initiate the download
    a.click();

    // Clean up by revoking the URL
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({
        startDate: rangeStart.toISOString(),
        endDate: rangeEnd.toISOString()
      });
    }
  }, [rangeEnd, rangeStart]);
  const setRangeStart = (e) => {
    setStart(e);
    // if (rangeEnd) {
    //   onChange({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    // }
  };
  const setRangeEnd = (e) => {
    setEnd(e);
    // if (rangeStart) {
    //   onChange({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    // }
  };
  const { data, totalCount, loading, perPage } = useSelector(
    (state) => state?.leads
  );

  const [itemPerPage] = useState(perPage);

  const roles = useSelector((state) => state?.roles?.data);
  let roleOptions = roles
    ?.filter(
      (role) =>
        role.name !== 'User' &&
        role.name !== 'Dealer' &&
        role.name !== 'ServiceProvider' &&
        role.name !== 'System_User'
    )
    .map((role) => ({ label: role.name, value: role.id }));
  const [listData, setData] = useState([]);

  useEffect(() => {
    if (data) {
      setData(data?.data || []);
    }
  }, [data]);

  const dispatch = useDispatch();

  // Changing state value when searching name
  useEffect(() => {
    let data = {
      query: {},
      options: {
        page: currentPage,
        limit: perPage,
        sort: '-createdAt'
      }
    };
    // { page: currentPage, status: filter, type, sortBy: { [sortByField]: sortByValue } };
    if (dateFilter) {
      data.query.date = dateFilter;
    }
    if (type) {
      data.query.source = type;
    }
    if (filter === 'Active') {
      setCurrentPage(1);
      data.query.isActive = true;
    } else if (filter === 'Inactive') {
      setCurrentPage(1);
      data.query.isActive = false;
    }
    if (onSearchText !== '') {
      data.query.search = onSearchText.trim();
      // dispatch(debouncedFetchLeads(data));
    } else {
      dispatch(fetchLeads(data));
    }
  }, [onSearchText.trim(), currentPage, filter, type, sortByValue, dateFilter]);

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, handleSubmit } = useForm();
  const toggleModal = () => {
    setOpen(!open);
  };
  const toggleEditModal = () => {
    setEdit(!edit);
  };

  const validate = (fullName, email, role) => {
    let isError = false;
    if (fullName.length < 2) {
      toast.error('Enter valid First Name');
      isError = true;
    }
    if (!validateEmail(email)) {
      isError = true;
      toast.error('Enter valid email address');
    }

    if (!value || !value.trim()) {
      isError = true;
      toast.error('Enter valid phone number');

      const isPossiblePhone = isPossiblePhoneNumber(value, 'AE');

      if (isPossiblePhone) {
        isError = true;
        const isValidNum = isValidPhoneNumber(value, 'AE');

        if (isValidNum) {
          isError = false;
        }
      } else {
        isError = true;
        toast.error('Phone number is not possible');
      }
    }

    if (!role || role?.length === 0) {
      isError = true;
      toast.error('Select the Role');
    }
    return isError ? false : true;
  };
  // Add User Info in DB
  const addMember = async (e) => {
    e.preventDefault();
    if (!validate(fullName, email, role)) {
      return;
    }
    // console.log(value)
    const formData = {
      full_name: fullName,
      email,
      phone: {
        code: 'ae',
        dial_code: '+971',
        number: value.slice(4)
      },
      role: role.map((r) => r.value)
    };
    try {
      setLoadingUser(true);
      dispatch(createUser(formData));
      setLoadingUser(false);
      // navigate(0);
    } catch (err) {
      // console.log(err)
      // toast.error('Server error. Try Again !');
    }
  };
  const editMember = async (e) => {
    e.preventDefault();

    // console.log(value)
    const formData = {
      is_phone_verified: phoneVerified
    };
    try {
      setLoadingUser(true);
      dispatch(updateUserDetails({ id: user?.id, body: formData }));
      setPhoneVerified(null);
      setUser(null);
      setEdit(false);
      setLoadingUser(false);
      // navigate(0);
    } catch (err) {
      // console.log(err)
      // toast.error('Server error. Try Again !');
    }
  };
  let clearBadge =
    type || filter || onSearchText !== '' || rangeEnd || rangeStart;

  return (
    <React.Fragment>
      <Head title="Users List"></Head>
      <Content>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: '20px',
            columnGap: '20px',
            rowGap: '20px'
          }}
        >
          <div>
            <h3 style={{ flex: 3 }}>RAVO Leads</h3>
            <span>Total leads: {totalCount}</span>
          </div>
          <div
            className="form-control-wrap"
            style={{ flexShrink: 0, flexGrow: 1 }}
          ></div>
          {/* <ListBlockHeader
          title="RAVO Leads"
          onSearchChanged={onFilterChange}
          onDateChanged={setDateFilter}
        > */}
          <ButtonGroup>
            <Button
              color="secondary"
              outline
              onClick={() => {
                setType(LeadTypes.Website);
                localStorage.setItem('typeGetValue', LeadTypes.Website);
              }}
              active={type === LeadTypes.Website}
            >
              Website
            </Button>
            <Button
              color="secondary"
              outline
              onClick={() => {
                setType(LeadTypes.App);
                localStorage.setItem('typeGetValue', LeadTypes.App);
              }}
              active={type === LeadTypes.App}
            >
              Social Logins
            </Button>
          </ButtonGroup>
          <div className="dt-export-buttons d-flex align-center">
            {/* <div className="dt-export-title d-none d-md-inline-block">Export</div> */}
            <div className="dt-buttons btn-group flex-wrap">
              <button
                className="btn btn-primary buttons-csv buttons-html5"
                type="button"
                onClick={(e) => exportCSV(e)}
              >
                <span>Export CSV</span>
              </button>
            </div>
          </div>
          {/* </ListBlockHeader> */}
        </div>
        <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
          {clearBadge && (
            <Badge className="selected-option bg-primary me-1" color="danger">
              {'Clear All'}
              <Icon
                name="cross-sm"
                className="ms-1"
                onClick={() => {
                  setType(null);
                  setFilter(null);
                  setSearchText('');
                  setRangeEnd(null);
                  setRangeStart(null);
                  setDateFilter(null);
                }}
              />
            </Badge>
          )}
        </Col>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              {/* <DataTableRow size="md">
                <TableHeader name="lastName" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow> */}
              <DataTableRow size="md">
                <span className="sub-text">Phone</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Email</span>
              </DataTableRow>

              <DataTableRow>
                <span className="sub-text">Created At</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Country</span>
              </DataTableRow>
              {/* <DataTableRow size="md">
                <TableHeader name="createdAt" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow> */}
              <DataTableRow>
                <span className="sub-text">Source</span>
              </DataTableRow>

              <DataTableRow className="nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1 my-n1"></ul>
              </DataTableRow>
            </DataTableHead>

            {listData?.length > 0
              ? listData.map((item, index) => (
                  <DataTableItem key={item.id}>
                    {/* <DataTableRow>{item.lastName}</DataTableRow> */}
                    <DataTableRow>
                      {item.phone?.dial_code}
                      {item.phone?.number}{' '}
                      {item.is_phone_verified ? (
                        <Icon
                          className={`text-success`}
                          name="check-circle"
                        ></Icon>
                      ) : null}
                    </DataTableRow>
                    <DataTableRow>{item.email}</DataTableRow>

                    <DataTableRow size="md">
                      <span>{new Date(item.createdAt).toDateString()}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item?.country}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item?.source}</span>
                    </DataTableRow>

                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1"></ul>
                    </DataTableRow>
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {listData?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No Leads found</span>
              </div>
            )}
          </PreviewAltCard>
          <Modal size="md" isOpen={open} toggle={toggleModal} backdrop="static">
            <ModalHeader toggle={toggleModal}>
              Add New User
              <button className="close" onClick={toggleModal}></button>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => addMember(e)}>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Full Name*
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="full-name"
                        className="form-control"
                        name="fullName"
                        required
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>

                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Email*{' '}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        id="first-name"
                        className="form-control"
                        name="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Phone*{' '}
                    </label>
                    <PhoneInput
                      className="phoneInput "
                      defaultCountry="AE"
                      value={value}
                      name="phone"
                      placeholder="Enter Your Phone *"
                      onChange={(value) => setValue(value)}
                      onBlur={(e) => {
                        const { value } = e.target;

                        const isPossiblePhone = isPossiblePhoneNumber(
                          value,
                          'AE'
                        );

                        if (isPossiblePhone) {
                          const isValidNum = isValidPhoneNumber(value, 'AE');

                          if (isValidNum) {
                            setErrorX(null);
                            return;
                          }

                          setErrorX('Number is not valid');
                          return;
                        }

                        setErrorX('Number is not possbible');
                      }}
                    ></PhoneInput>
                    <div className="text-black flex"> {errorX && errorX}</div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="customer">
                      Role
                    </label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <RSelect
                          isMulti
                          options={roleOptions}
                          onChange={(e) => setRole(e)}
                          className="customInput"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <Button type="submit" className="bg-primary text-white mt-2">
                    {loadingUser ? (
                      <>
                        <Spinner size="sm" color="light" />
                        Please Wait
                      </>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Col>
              </form>
            </ModalBody>
          </Modal>
          <Modal
            size="md"
            isOpen={edit}
            toggle={toggleEditModal}
            backdrop="static"
          >
            <ModalHeader toggle={toggleEditModal}>
              Edit User
              <button className="close" onClick={toggleEditModal}></button>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => editMember(e)}>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Full Name*
                    </label>
                    <div className="form-control-wrap">
                      <input
                        disabled
                        type="text"
                        id="full-name"
                        className="form-control"
                        name="fullName"
                        required
                        value={user?.full_name}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Email*{' '}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        id="first-name"
                        className="form-control"
                        name="email"
                        disabled
                        value={user?.email}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="customer">
                      Phone verified
                    </label>
                    <div className="form-control-wrap">
                      <InputSwitch
                        checked={phoneVerified}
                        id="phone_verified-"
                        onClick={(e) => setPhoneVerified(e)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <Button type="submit" className="bg-primary text-white mt-2">
                    {loadingUser ? (
                      <>
                        <Spinner size="sm" color="light" />
                        Please Wait
                      </>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Col>
              </form>
            </ModalBody>
          </Modal>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default LeadsData;
