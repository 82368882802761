
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid2, InputAdornment, InputLabel, OutlinedInput, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import Price from '../../../components/price/Price';
import { useDispatch } from 'react-redux';
import { addTransactionToPayment } from '../../../redux/features/ordersSlice/orderUpdateSlice';


function AddTransaction({ order, open, onClose, }) {

    const [isFullSettlemet, setFullSettlement] = useState(false);
    const [isDelivered, setDelivered] = useState(false);
    const [amount, setAmount] = useState("");
    const [error, setError] = useState(null);


    const dispatch = useDispatch();

    const handleDialogClose = (e) => {
        setFullSettlement(false);
        setDelivered(false);
        setAmount("");
        setError(null);
        onClose();
    }

    const changeFullSettlement = (e) => {
        const selected = !isFullSettlemet;
        setFullSettlement(selected);
        if (selected) {
            setAmount(Math.round(Number(order.balanceAmount)));
        }
        else {
            setAmount("");
        }
        setDelivered(selected);
    }

    const changeDispatchStatus = (e) => {
        const selected = !isDelivered;
        setDelivered(selected);
    }

    const handleAmountChange = (e) => {

        const amount = Number(e.target.value);
        if (amount == undefined) {
            setError("Please enter a valid number");
        }
        else {
            setAmount(e.target.value);
            setError(null);
        }

    }

    const onSubmit = (e) => {
        console.log("Called onSubmit")
        if (!amount) {
            setError("Please enter an amount");
            return;
        }
        if (amount == 0) {
            setError("Please enter a valid number");
            return;
        }
        addTransactionRemote(amount, isDelivered, isFullSettlemet);
        onClose();
    }

    const addTransactionRemote = (amount, isDelivered, isFullSettlemet) => {
        dispatch(addTransactionToPayment({ userId: order.user.id, dealer: order.dealer.id, amount: amount, orderId: order.id, isDelivered: isDelivered, isFullSettlemet: isFullSettlemet }));

    };

    return (
        <div>
            <Dialog

                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h5" sx={{ fontWeight: 'bold', }}>
                        Add a transaction
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box>
                            <Grid2 container spacing={2}>
                                <Grid2 item xs={4}>
                                    <Price label={"Total Amount:"} amount={Math.round(Number(order.amount))} />
                                </Grid2>
                                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                                <Grid2 item xs={4}>
                                    <Price label={"Received Amount:"} amount={Math.round(Number(order.amount) - Number(order.balanceAmount))} />

                                </Grid2>
                                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                                <Grid2 item xs={4}>
                                    <Price label={"Balance Amount:"} amount={Math.round(Number(order.balanceAmount))} />

                                </Grid2>
                            </Grid2>
                        </Box>
                    </DialogContentText>
                    <br />

                    <FormControl fullWidth sx={{ m: 1 }} error={error != null}>
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Amount"
                            value={amount}
                            onChange={handleAmountChange}
                            type="number"
                            inputProps={{
                                min: 1000,
                            }}
                        />
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch checked={isFullSettlemet} onChange={changeFullSettlement} name="final_settlement" />
                        }
                        label="Add as final settlement and mark as sold"
                    />
                    <FormControlLabel
                        control={
                            <Switch checked={isDelivered} onChange={changeDispatchStatus} name="final_settlement" />
                        }
                        label="Mark as Delivered"
                    />


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={onSubmit} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default AddTransaction