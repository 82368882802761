import React from "react";
import { useState } from "react";
import { Button, Icon, RSelect } from "../../../components/Component";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import PriceInput from "../../verticals/components/PriceInput";
import { useDispatch } from "react-redux";
import { updateAuctionDetails } from "../../../redux/features/auctionSlice/auctionDetailsSlice";
import { Emirates } from "../../../utils/Utils";

export default function EditButtonWithModal({auction}) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);  
  const [loading, setLoading] = useState(false);  

  const [errors, setErrors] = useState({});

  const [startingPrice, setStartingPrice] = useState(auction?.initial_price);  
  const [reservePrice, setReservePrice] = useState(auction?.reserve_price);  
  const [emirate, setEmirate] = useState({"label":auction?.emirate,"value":auction?.emirate});  

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const editAuction=(e)=>{
    e.preventDefault();
    let data={
      "initial_price":Number(startingPrice),
      "reserve_price":Number(reservePrice),
      "emirate":emirate.label,
      "transport":"Included"
    }
    dispatch(
      updateAuctionDetails({id: auction?.id,body:data})
    );
    toggle();
  }
  return (
    <div>
      <div className="me-3">
        <Button color="light" onClick={toggle} style={{ width: "100%" }}>
          Edit
        </Button>
      </div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Edit Auction
        </ModalHeader>

              <ModalBody>
                <form onSubmit={(e) => editAuction(e)}>

                  <Col sm="12">
                    <div className="form-group mb-2">
                      <label className="form-label" htmlFor="first-name">
                      Reserve Price
                      </label>
                      <div className="form-control-wrap">
                      <PriceInput
                      error={errors?.reservePrice}
                      value={reservePrice}
                      onChange={(e)=>
                        {
                        setReservePrice(e.target.value)
                        setErrors((prev) => ({
                          ...prev,
                          reservePrice: null
                      }));
                        }
                      }
                      label="AED"
                    />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="form-group mb-2">
                      <label className="form-label" htmlFor="first-name">
                      Starting Bid
                      </label>
                      <div className="form-control-wrap">
                      <PriceInput
                      error={errors?.startingPrice}
                      value={startingPrice}
                      onChange={(e)=>
                        {
                        setStartingPrice(e.target.value)
                        setErrors((prev) => ({
                          ...prev,
                          startingPrice: null
                      }));
                        }
                      }
                      label="AED"
                    />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="form-group mb-2">
                      <label className="form-label" htmlFor="first-name">
                        Emirate
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                        isMulti={false}
                        value={emirate}
                        onChange={(e) => setEmirate(e)}
                        options ={Emirates.map(e=>({"label":e,"value":e}))}
                        />
                       
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <Button
                      type="submit"
                      className="bg-primary text-white mt-2"
                    >
                      {loading ? (
                        <>
                          <Spinner size="sm" color="light" />
                          Please Wait
                        </>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </Col>
                </form>
              </ModalBody>
      </Modal>
    </div>
  );
}
