import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  totalCount: 0,
  perPage:10,
  loading: false,
  error: null,
};
const fetchShipments = createAsyncThunk("shipments", (data) => {

  let url = `/admin/api/v1/shipments`;
  return axios.post(url,data).then((response) => {
    return response.data;
  });
});

const shipmentslice = createSlice({
  name: "shipments",
  initialState,
  reducers: {
    // setSelected: (state, action) => {
    //   const { index, value } = action.payload;
    //   // console.log(index);
    //   let data = state.data;
    //   data[index].check = value;
    //   state.data = data;
    // },
    // setAllSelected: (state, action) => {
    //   const { value } = action.payload;
    //   // console.log(value);
    //   let data = state.data;
    //   state.data = data.map((e) => {
    //     e.check = value;
    //     return e;
    //   });
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShipments.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchShipments.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data??null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchShipments.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

  },
});
const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchShipments({ ...arg })), 500);
const debouncedFetchShipments =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export default shipmentslice.reducer;
// export const { setSelected, setAllSelected } = rolesSlice.actions;
export { fetchShipments,debouncedFetchShipments };
