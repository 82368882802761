import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { uploadManyToS3 } from "../../../utils/uploadImageToS3";
import { fetchCarDetails } from "./itemDetailsSlice";

const initialState = {
  data: {},
  updateStatus: "init",
  error: null,
};
const updateImages = createAsyncThunk("carImageUpate", async (data, { dispatch }) => {
  const { id, body } = data;
  // console.log(body);
  const imagesRequireuploadToS3 = body.filter((e) => !e.url);
  const uploadedUrls = await uploadManyToS3(
    imagesRequireuploadToS3.map((e) => ({
      file: e.image,
      type: e.type,
      fileName: e.name,
    }))
  );
  const update = body.map((e) => {
    if (!e.url) {
      e.url = uploadedUrls.find((u) => u.fileName === e.name).url;
    }
    return e;
  });

  let url = `/v1/admin/items/${id}/updateImages`;
  await axios.post(url, update).then((response) => {
    return response.data;
  });
  await dispatch(fetchCarDetails(id));
  return null;
});

const carImageUpate = createSlice({
  name: "carImageUpate",
  initialState,
  reducers: {
    resetImageUpdate: (state) => {
      state.updateStatus = "init";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateImages.pending, (state) => {
      state.updateStatus = "loading";
      state.error = null;
      state.data = {};
    });
    builder.addCase(updateImages.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.updateStatus = "success";
      state.data = action.payload;
    });
    builder.addCase(updateImages.rejected, (state, action) => {
      state.updateStatus = "error";
      state.data = {};
      state.error = action.error.message;
    });
  },
});

export default carImageUpate.reducer;
export const { resetImageUpdate } = carImageUpate.actions;
export { updateImages };
