import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import { fetchTopBiddedAuctions } from '../../../../redux/features/auctionSlice/topBiddedAuctionsSlice';
import { useDispatch } from 'react-redux';
import { UserAvatar } from '../../../Component';
import { stringAuctionStatus } from '../../../../utils/Utils';
import { startCase } from 'lodash';
import { useNavigate } from 'react-router-dom';

const TopBiddedAuctions = ({ data = null }) => {
  const navigate = useNavigate()
  return (
    <Card className="h-100">
      <div className="card-inner">
        <div className="card-title-group mb-2">
          <div className="card-title">
            <h6 className="title">Top Bidded Auctions</h6>
          </div>
        </div>
        <ul className="nk-top-products">
          {data?.map((item, idx) => (
            <li
              className="item cursor-pointer"
              key={idx}
              onClick={() =>
                navigate(`/v/${item?.vertical_id}/auctions/${item?.id}`)
              }
            >
              <div className="thumb">
                <UserAvatar
                  className="sm"
                  image={item?.item?.title_image && item?.item?.title_image}
                />
                {/*  <img src={item.car.images?.length > 0 && item.car.images.at(0)} alt="" /> */}
              </div>
              <div className="info">
                <div className="title">{item?.item?.title}</div>
                <div className="count">Bids: {item.bid_count}</div>
              </div>
              <div className="total">
                <div className="amount">AED {item.current_bid?.amount}</div>
                <div className="count">
                  {startCase(stringAuctionStatus[item.status])}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default TopBiddedAuctions;
