import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard,
} from "../../components/Component";
import {  Badge, Spinner, Col } from "reactstrap";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearItems, setAllSelected, setSelected } from "../../redux/features/itemsSlice/itemsSlice";
import {  startCase } from "lodash";
import { Link, useParams, useSearchParams } from "react-router-dom";
import TableHeader from "../../components/table/TableHeader";
import ListBlockHeader from "../../components/list-block-header/ListBlockHeader";
import ReactDatePicker from "react-datepicker";
import { fetchReviews } from "../../redux/features/usersSlice/reviewSlice";

const Reviews = () => {
  const { verticalName } = useParams();

  const [listData, setListData] = useState([]);
  const [smOption, setSmOption] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    firstName: "",
    lastName: "",
    date: new Date(),
    status: "",
    customer: "",
    purchased: "",
    total: "",
    check: false,
  });
  const [view, setView] = useState({
    add: false,
    details: false,
  });
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [sortByField, setSortByField] = useState("createdAt");
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);
  const user = useSelector((state) => state.userDetails.data);
  const { data, totalCount, loading,perPage } = useSelector((state) => state.review);
  const [itemPerPage] = useState(perPage);



  const dispatch = useDispatch();
  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    }
  }, [rangeEnd, rangeStart]);
  const setRangeStart = (e) => {
    setStart(e);

  };
  const setRangeEnd = (e) => {
    setEnd(e);

  };

  useEffect(() => {
    let data = {
      "query":{"isDeleted":false},
      "options":{
        page: currentPage,
        limit:perPage,
        sort: sortByValue === 1 ? sortByField:'-'+sortByField
      }}
    // { page: currentPage, status: filter, type, sortBy: { [sortByField]: sortByValue } };
    if (dateFilter) {
      data.query.date= dateFilter;
    }    
    if (user?.id) {
      data.query.reviewee_id =user?.id;
    }
    if (onSearchText !== "") {
      data.query.search = onSearchText.trim();
      dispatch(fetchReviews(data));
    } else {
      dispatch(fetchReviews(data));
    }
  }, [onSearchText.trim(), currentPage, sortByValue, dateFilter]);

  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  useEffect(() => {
    if(data){
    setListData(data?.data);
    }
  }, [data]);

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let clearBadge = status ||onSearchText!==''||rangeEnd ||rangeStart;

  return (
    <React.Fragment>
      <Head title={`Reviews`}></Head>
      <Content>
        <ListBlockHeader title={"Reviews"} onSearchText={onSearchText} onSearchChanged={onFilterChange} onDateChanged={setDateFilter}>
        <div style={{ flexShrink: 0, flexGrow: 1 }}>
      <div className="form-control-wrap">
        <div className="input-daterange date-picker-range input-group">
          <ReactDatePicker
            selected={rangeStart}
            onChange={setRangeStart}
            selectsStart
            startDate={rangeStart}
            endDate={rangeEnd}
            maxDate={rangeEnd ?? new Date()}
            wrapperClassName="start-m"
            className="form-control"
          />{" "}
          <div className="input-group-addon">TO</div>
          <ReactDatePicker
            selected={rangeEnd}
            onChange={setRangeEnd}
            startDate={rangeStart}
            endDate={rangeEnd}
            selectsEnd
            minDate={rangeStart}
            maxDate={new Date()}
            wrapperClassName="end-m"
            className="form-control"
          />
        </div>
      </div>
    </div>
        </ListBlockHeader>

        <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
          {clearBadge && (
              <Badge className="selected-option bg-primary me-1" color="danger">
                {'Clear All'}
                <Icon
                  name="cross-sm"
                  className="ms-1"
                  onClick={() => {
                    setSearchText('');
                    setRangeEnd(null);
                    setRangeStart(null);
                    setDateFilter(null);
                    setStatus("")
                  }}
                />
              </Badge>
            )}
            </Col>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                  />
                  <label className="custom-control-label" htmlFor="pid-all"></label>
                </div>
              </DataTableRow>
        
              <DataTableRow>
                <span className="sub-text">Reviewee</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Reviewer</span>
              </DataTableRow>
          
              <DataTableRow>
                <span className="sub-text">Message</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Rating</span>
              </DataTableRow>
  
              <DataTableRow>
                <span className="sub-text">Auction</span>
              </DataTableRow>
         
       
              <DataTableRow>
                <span className="sub-text">Created At</span>
              </DataTableRow>
            </DataTableHead>

            {listData?.length > 0
              ? listData.map((item, index) => (
                  <DataTableItem key={item.id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={item.check}
                          id={item.id + "oId-all"}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, index)}
                        />
                        <label className="custom-control-label" htmlFor={item.id + "oId-all"}></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow> 
                    <Link to={`/users/${item?.reviewee_id?.id}`}>
                      {startCase(item?.reviewee_id?.full_name)}
                      </Link>
                      </DataTableRow>
                    <DataTableRow> 
                    <Link to={`/users/${item?.reviewer_id?.id}`}>
                      {startCase(item?.reviewer_id?.full_name)}
                      </Link>
                      </DataTableRow>
                                     
                    <DataTableRow>{startCase(item?.message)}</DataTableRow>
                    <DataTableRow>{item?.rating}</DataTableRow>  
                    <DataTableRow> 
                    <Link to={`/v/${item?.auction_id?.vertical_id}/auctions/${item?.auction_id?.id}`} replace={false}>
                      {item?.auction_id?.serial_number}</Link>   
                      </DataTableRow> 
                      <DataTableRow>{new Date(item.createdAt).toDateString()}</DataTableRow>                        
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {listData?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No items found</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Reviews;
