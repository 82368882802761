import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { persistor, store } from "../redux/store";

const ProtectedRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);

  const auth = useSelector((state) => state.auth.isLoggedIn);
  useEffect(() => {
    let mounted = true;
    async function fun() {
      setIsLoading(true);
      await persistor.flush();
      if (mounted) {
        setIsLoading(false);
      }
    }
    fun();
    return () => {
      mounted = false;
    };
  }, [auth]);

  if (isLoading) {
    return <div></div>;
  }

  if (!auth) {
    return <Navigate to={`/auth-login`} />;
  }
  return <Outlet />;
};

export default ProtectedRoutes;
