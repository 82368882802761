import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import { Icon, InputSwitch } from '../../../components/Component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyUserDocuments } from '../../../redux/features/usersSlice/userDetails';

const VerifyModal = ({ isOpen, toggle, userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verifyLoading, setVerifyLoading] = useState(null);
  const [passportNo, setPassportNo] = useState(null);
  const [eidNo, setEdidNo] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (e) => {
  
    e.preventDefault();
    if (!eidNo && !passportNo) 
    {
      toast.error('Plese enter one of the ids');
      return;
    }
    else {
      setVerifyLoading(true);
      dispatch(
        verifyUserDocuments({
          userId: userId,
          eidNumber: eidNo,
          passportNumber: passportNo
        })
      ).then(() => setVerifyLoading(false));
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader
        toggle={toggle}
        close={
          <button
            className="close"
            onClick={(ev) => {
              ev.preventDefault();
              toggle();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        Add user ID number
      </ModalHeader>
      <ModalBody>
        <form id="add-vertical-form" onSubmit={(e) => null}>
          <Row className="g-3">
            <Col sm="12">
              <Label>Emirates Id Number</Label>
              <Input
                autoComplete="off"
                name="name"
                value={passportNo}
                onChange={(e) => setPassportNo(e.target.value)}
                placeholder="Enter Emirates Id Number"
              ></Input>
            </Col>
            <Col sm="12">
              <Label>Passport Number</Label>
              <Input
                autoComplete="off"
                name="name"
                value={eidNo}
                onChange={(e) => setEdidNo(e.target.value)}
                placeholder="Enter Passport Number"
              ></Input>
            </Col>
          </Row>
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
              <li>
                <Button color="success" type="submit" onClick={handleSubmit}>
                  {!verifyLoading ? (
                    <>Save & Confirm</>
                  ) : (
                    <Spinner size="sm" color="light" />
                  )}
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default VerifyModal;
