import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  data: [],
  totalCount: 0,
  perPage:10,
  loading: false,
  error: null,
};
const sendNotifications = createAsyncThunk("notifications", (data) => {

  let url = `/admin/pushnotification/send-push-notification`;
  return axios.post(url,data).then((response) => {
    toast.success('Successfully Sent Notifications to users');
    window.location.reload();
    return response.data;
  });
});

const reviewSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(sendNotifications.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(sendNotifications.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data??null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(sendNotifications.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

  },
});

export default reviewSlice.reducer;
// export const { setSelected, setAllSelected } = rolesSlice.actions;
export { sendNotifications };
