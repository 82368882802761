import React, { useState, useEffect, useCallback } from "react";
import
{
  Button,
  Col,
  Row,
  Icon,
} from "../../../components/Component";
import { Card, Modal, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DnDKitProperty from "./DnDKitProperty";
import useDragAndDrop from "../../../utils/hooks/useDragAndDropImage";
import InputFile from "./InputFile";


const ImageUpload = ( props ) =>
{

  const { handleFieldChange,propImages,formField, handleRemoveAll } = props;


  const [ imgLoading, setImgLoading ] = useState( false );
  const [ loading, setLoading ] = useState( true );
  const [ tobeDeleted, setToBeDeleted ] = useState( [] );
  const [ tobeAdded, setToBeAdded ] = useState( [] );

  const {
    active: activeImages,
    errorMessage: ImagesStatus,
    dragenter: ImagesDragEnter,
    dragleave: ImagesDragLeave,
    drop: ImagesDrop,
    handleFileChange: ImagesFileChange,
    files: imageFiles ,
    setFiles: setImageFiles,
    removeImage: removeImage,
} = useDragAndDrop({
    multiple: true,
    inputType: "image",
    maxFilesLength: 50,
    minFilesLength: 8,
    labelName: "title",
    id: "xyz"
});

  const handleUpload = async ( file ) =>
  {
    let data = [
      { file: file.imgfile, type: "image/*", fileName: file.fileName },
      {
        file: file.thumbnailFile,
        type: "image/*",
        fileName: file.thumbnailName,
      },
    ];

    // await uploadManyToS3( data, axios );
  };


  const imageUploadNew = async ( images ) =>
  {
    if ( images.length !== 0 )
    {
      setImgLoading( true );
      for ( let i = 0; i < images.length; i++ )
      {
        await handleUpload( images[ i ] );
      }

    }
  };
  useEffect(() => {
    const newImgArray = imageFiles?.map((e) => (
      e.file
    ));
  handleFieldChange(formField.label, formField.name,newImgArray, formField.type);
  },[imageFiles])


  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setImgLoading(true);
  
      const newImgArray = imageFiles?.map((e) => (
        e.file
      ));
    handleFieldChange(formField.label, formField.name,newImgArray, formField.type);
   
    } catch (error) {
      // console.error("Error occurred:", error);
      toast.error("Server error. Try Again!");
    } finally {
      setImgLoading(false);
    }
  };

  async function updatePropertyImages(tobeDeleted, tobeAdded, imgDta) {
    if (tobeDeleted) {
      await handleDelete();
    }
  
    if (tobeAdded) {
      await imageUploadNew(tobeAdded);
    }
  
    // await propertyUpdate(imgDta);
  }
  

  const handleDelete = async () =>
  {
    await Promise.all(
      tobeDeleted.map( async function ( file )
      {
        // await deleteManyFromS3( [ file?.original, file?.thumbnail ] );
      } )
    );
  };


  return (

      <Card    
      style={ {
        top: "0.2rem",
        border: "solid 1px",
        borderColor: "",
        backgroundColor: "transparent",
        cursor: "pointer",
      } }>
        <button
          className="close-btn"
          style={ {
            position: "absolute",
            top: "1.5rem",
            right: "1rem",
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
          } }
        >
          {/* <Icon name="cross" style={ { fontSize: "1.5rem" } } /> */}
        </button>

        <Row className="d-flex justify-content-center">
          <Col sm="12">
            <Button
              color=""
              className="w-100  mt-4 text-center d-flex 
            justify-content-center align-items-center"
              type="button"
            >
              <div className="nk-upload-form relative">
                <h5 className="title mb-3">Upload Images</h5>
                {/* <p>Note: The file size should be at least 800 x 600 px</p> */}
                <InputFile
                            active={activeImages}
                            dragenter={ImagesDragEnter}
                            dragleave={ImagesDragLeave}
                            drop={ImagesDrop}
                            errorMessage={ImagesStatus}
                            files={imageFiles}
                            handleFileChange={ImagesFileChange}
                            inputType="image"
                            removeImage={removeImage}
                            multiple
                            inputProps={{
                                accept: "image/*",
                            }}
                        />
    
       
              </div>
            </Button>
            {imageFiles && imageFiles.length>0 &&
                    <DnDKitProperty images={imageFiles} setImages={setImageFiles} removeImage={removeImage}/>
                    }
          </Col>
        </Row>

        <div className="d-flex justify-content-center align-items-center mt-4">
          {/* <Button
            onClick={ ( e ) => handleSubmit( e ) }
            color="success"
            className="d-flex mb-5
            justify-content-center align-items-center"
          >
            { imgLoading ? (
              <Spinner size="sm" color="light" />
            ) : (
              "Save changes"
            ) }
          </Button> */}
          {/* { imageFiles?.length !== 0 && (
            <Button
              style={ {

                cursor: "pointer",
              } }
              onClick={ ( e ) => handleRemoveAll( e ) }
              color="danger"
              className="d-flex mb-5 ms-3
            justify-content-center align-items-center"
            >
              Delete all
            </Button>
          ) } */}
        </div>
      </Card>

  );
};

export default ImageUpload;
