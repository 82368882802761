import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  DataTableItem,
  DataTableRow,
  Icon,
  InputSwitch,
  TooltipComponent
} from '../../../components/Component';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateAuctionDetails } from '../../../redux/features/auctionSlice/auctionDetailsSlice';
import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import formatDate from '../../../utils/dateFormat';
import { auctionStatus } from '../../../utils/Utils';
import { TimerText } from '../../../components/timer/timer';
import msToTime from '../../../utils/msToTime';
import { getAuctionStatus, getAuctionStatusBgColor, getAuctionStatusDotColor } from '../../../utils/getColor';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';


const AuctionRow = ({ item, index }) => {
  const dispatch = useDispatch();
  const [wasCallMade, setWasCallMade] = useState(false);
  
  const handleSearch = async (e) => {
    setWasCallMade(e)
    console.log(e)
    let formData={
      "was_call_made":e
    }
    console.log(formData)
    try {
      dispatch(updateAuctionDetails({ id: item?.id, body: formData }));
      toast.success('Updated successfully');

    } catch (err) {
      toast.error('Not modified with changes!');
    }
  };
  useEffect(() => {

    if(item.was_call_made){
      setWasCallMade(item.was_call_made);
    }
  }, [item]);

  return (
    <DataTableItem key={item._id}>
    <DataTableRow className="nk-tb-col-check">
      <div className="custom-control custom-control-sm custom-checkbox notext">
        <input
          type="checkbox"
          className="custom-control-input"
          defaultChecked={item.check}
          id={item._id + "oId-all"}
          key={Math.random()}
        //   onChange={(e) => onSelectChange(e, index)}
        />
        <label className="custom-control-label" htmlFor={item.id + "oId-all"}></label>
      </div>
    </DataTableRow>
    <DataTableRow>
      <Link to={`/v/${item?.vertical_id}/auctions/${item.id}`} replace={false}>{item?.serial_number}</Link>
    </DataTableRow>
    <DataTableRow>
      <Link to={`/v/${item?.vertical_id}/auctions/${item.id}`} replace={false}>{startCase(item?.baseform?.title)}</Link>
    </DataTableRow>               
    <DataTableRow>
   {startCase(item?.user?.full_name)}
    </DataTableRow>
    <DataTableRow>{formatDate(new Date(item.start_time))}</DataTableRow>              
    <DataTableRow>
      {item.status === auctionStatus.LIVE ? (
        <TimerText
          startTime={item.start_time}
          duration={item.duration}
          onTimerEnd={() => {
            console.log("Timer ended");
          }}
        />
      ) : (
        msToTime(item.duration)
      )}
    </DataTableRow>
    <DataTableRow>{item.initial_price?.toLocaleString('en-US')}</DataTableRow>

    <DataTableRow>
      <span className={`dot bg-${getAuctionStatusDotColor(item.status)} d-sm-none`}></span>
      <Badge
        className={`badge-sm badge-dot has-bg d-none d-sm-inline-flex ${
          item.status === auctionStatus.LIVE ? "live" : ""
        }`}
        color={getAuctionStatusBgColor(item.status)}
      >
        {startCase(getAuctionStatus(item.status))}
      </Badge>
    </DataTableRow>
    <DataTableRow>
        <span className="tb-sub">
        <div className="form-group">
                   
          <div className="form-control-wrap">
            <InputSwitch checked={wasCallMade} id={item.id} onClick={(e) => handleSearch(e)} />
          </div>
        </div>
        </span>
      </DataTableRow>

    <DataTableRow className="nk-tb-col-tools">
      <ul className="nk-tb-actions gx-1">
        <li
          className="nk-tb-action-hidden"
          onClick={() => {
            // toggle("details");
          }}
        >
          <TooltipComponent
            tag="a"
            containerClassName="btn btn-trigger btn-icon"
            id={"view" + item._id}
            icon="eye"
            direction="top"
            text="View Details"
          />
        </li>
        <li>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
              <li key={index + Math.random()}>
                      <a
                        href={`/v/${item?.vertical_id}/auctions/${item.id}`} 
                      >
                        <Icon name="eye"></Icon>
                        <span>View Details</span>
                      </a>
                    </li>
                {item.active === true && (
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#dropdown"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="truck"></Icon>
                      <span>Approve</span>
                    </DropdownItem>
                  </li>
                )}

              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    </DataTableRow>
  </DataTableItem>
  );
};

export default AuctionRow;
