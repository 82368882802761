import { Controller } from "react-hook-form";
import { validateImage } from "../../utils/Utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ImageUpload({ control, label, name, handleImageChange, customValidator,...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={(field) => {
        return (
          <label style={{ width: "100%" }}>
            {label} {props?.required && <span className="required-label">*</span>}
            <div className="form-control">
              <div className="form-file">
                <input       
                onChange={(e) => {
                  const selectedFile = e.target.files[0];

                  if (selectedFile) {
                    validateImage(selectedFile, customValidator)
                      .then(() => {
                        handleImageChange(e, field);
                      })
                      .catch((error) => {
                        toast.error(error);
                        e.target.value = ''; // Clear the input field
                      });
                  }
                }}         
                 type="file" {...props} 
                //  onChange={(e) => handleImageChange(e, field)} 
                 style={{ width: "100%" }} />
              </div>
            </div>
          </label>
        );
      }}
    />
  );
}
