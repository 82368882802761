import React, { useEffect, useState } from 'react'
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import ListBlockHeader from '../../components/list-block-header/ListBlockHeader';
import { Block, DataTableHead, DataTableItem, DataTableRow, PaginationComponent, PreviewAltCard } from '../../components/Component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchAllRefunds } from '../../redux/features/usersSlice/refundSlice';
import { Badge, Button, Spinner } from 'reactstrap';
import { capitalize, formatCreatedAtDateTime } from '../../utils/Utils';
import TableHeader from '../../components/table/TableHeader';
import ConfirmRefundModalButton from './components/ConfirmRefundModalButton';


function RefundRequests() {

  const { data, loading, totalCount, perPage } = useSelector((state) => state.refunds);

  const [onSearchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [refunds, setRefunds] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [sortByField, setSortByField] = useState('createdAt');
  const [sortByValue, setSortByValue] = useState(-1);
  const paginate = (pageNumber) => {
    console.log("Page Number :: ", pageNumber)
    setCurrentPage(pageNumber);
  }
  const onFilterChange = (e) => {

  };

  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      options: {
        page: currentPage,
        limit: 10,
      }
    };

    dispatch(fetchAllRefunds(data));
  }, [currentPage,perPage]);

  useEffect(() => {
    console.log("build");
    console.log("Total count ", totalCount);
    console.log("Items per page :", perPage);

    setRefunds(data.data);
  }, [data]);


  const setDateSort = (value) => {

  }

  const getStatusBadgeColor = (status) => {
    // ['initiated', 'success', 'failed', 'cancelled'],
    switch (status) {
      case "initiated":
        return "gray";
      case "success":
        return "success";
      case "cancelled":
        return "warning";
      case "failed":
        return "danger";
      default:
        return "gray";
    }
  }

  const [itemPerPage] = useState(perPage ?? 1);
  return (
    <>
      <Head title={`Refunds`}></Head>
      <Content>
        <ListBlockHeader
          msg={0 ? `Total items:  (Hardocded)}` : ''}
          title="Refund Requests"
          placeholder="Search ID"
          onSearchText={onSearchText}
          onSearchChanged={onFilterChange}
          onDateChanged={setDateFilter}
        >

        </ListBlockHeader>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow size="sm">
                <span>ID</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>User</span>
              </DataTableRow>
              <DataTableRow size="sm" >
                <TableHeader
                  name="Date"
                  setSort={setDateSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Amount</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Status</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Type</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Actions</span>
              </DataTableRow>
            </DataTableHead>
            {
              ((refunds?.length ?? 0) > 0) && refunds.map((e) => {
                return <DataTableItem key={e._id}>
                  <DataTableRow>
                    {e.refund_id}
                  </DataTableRow>
                  <DataTableRow>
                    {e.user?.full_name} | {e.user?.phone_number}
                  </DataTableRow>
                  <DataTableRow>
                    {formatCreatedAtDateTime(e.createdAt)}
                  </DataTableRow>
                  <DataTableRow>
                    {e.amount}
                  </DataTableRow>
                  <DataTableRow>
                    <Badge color={getStatusBadgeColor(e.status)}>  {capitalize(e.status.toString())} </Badge>

                  </DataTableRow>
                  <DataTableRow>
                    {capitalize(e.type)}
                  </DataTableRow>
                  <DataTableRow>
                    {e.status !== 'PROCESSED' && e.status !== 'cancelled' &&<ConfirmRefundModalButton refund={e}/>}
                  </DataTableRow>
                </DataTableItem>
              })
            }
            <PreviewAltCard>
              {refunds?.length > 0 ? (
                <PaginationComponent
                  itemPerPage={perPage > 0 ? perPage : 1 ?? 0}
                  totalItems={totalCount ?? 0}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : loading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className="text-center">
                  <span className="text-silent">No items found</span>
                </div>
              )}
            </PreviewAltCard>
          </div>
        </Block>
      </Content>
    </>
  )
}

export default RefundRequests