import React from "react";
import uuid from "react-uuid";

export default function InputFile({
    maxFilesLength = 1,
    minFilesLength = 1,
    inputType = "image",
    active = "false",
    dragenter = () => null,
    dragleave = () => null,
    drop = () => null,
    handleFileChange = () => null,
    removeImage = (ind) => null,
    files = [],
    errorMessage = "",
    multiple = false,
    inputProps,
}) {
    const inputId = uuid();
    return (
       <>
       {files&&
<div
    style={{
        border: '1px solid',
        borderRadius: '0.375rem',
        gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
        gridTemplateRows: 'minmax(0, 1fr)',
        gridAutoRows: 'minmax(0, 1fr)',
        padding: '1rem',
        backgroundColor:'rgb(245, 246, 250)',
        borderColor:
            active === 'true' ? 'rgb(229, 233, 242)' : active === 'bad' ? 'red' : active === 'more' ? 'rgb(229, 233, 242)' : 'rgb(229, 233, 242)',
        // boxShadow: active === 'true' ? '0 0 0 3px rgba(51, 153, 219, 0.5)' : '',
    }}
    onDragEnter={dragenter}
    onDragLeave={dragleave}
    onDrop={drop}
    onDragOver={dragenter}
>
    <input
        type="file"
        name="fileupload"
        id={inputId}
        onChange={handleFileChange}
        style={{
            outline: 'none',
            border: 'none',
            position: 'absolute',
            visibility: 'hidden',
        }}
        {...inputProps}
        multiple={multiple}
        min={minFilesLength}
        max={maxFilesLength}
    />
    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'center' }}>
        {files?.length ? (
            <></>
        ) : (
            <label
                htmlFor={inputId}
                style={{
                    width: '100%',
                    height: '75px',
                    margin: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
            >
                 <span style={{ textAlign: 'center',color:'#854fff' }}>  
                 Drag and drop &nbsp;</span> <span style={{color:"rgba(82, 100, 132, 0.6)"}}>
                    or&nbsp;</span> <span style={{ textAlign: 'center',color:'#854fff' }}>
                        browse</span>
             
                {/* <img src="/img/uploadfile.png" alt="upload file" style={{ objectFit: 'cover' }} /> */}
            </label>
        )}
    </div>
  
    {errorMessage && <p style={{ textAlign: 'center' }}>{errorMessage}</p>}
    {files?.length!==0 &&  
            <label
               
             htmlFor={inputId}
             style={{
                display: "block",
                textAlign: "center",
                color: "#854fff",
                margin: "0 auto",
            }}
           >
            Upload</label>}
</div>
}
</>
    );
}


