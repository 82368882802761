import React from 'react';
import { useForm } from 'react-hook-form';
import FormTextField from '../../../components/form/FormTextField'; // Using your custom form component for text fields
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Stack,
  Typography
} from '@mui/material';

export default function DealerAccountForm({
  dealer,
  onSubmit,
  toggleEdit,
  editMode
}) {
  const accountDetails = dealer?.account;
  const { control, handleSubmit } = useForm({
    defaultValues: {
      bank: accountDetails?.bank || '',
      accountName: accountDetails?.accountName || '',
      accountNumber: accountDetails?.accountNumber || '',
      iban: accountDetails?.iban || '',
      routingNumber: accountDetails?.routingNumber || '',
      swiftCode: accountDetails?.swiftCode || ''
    }
  });

  const handleFormSubmit = (data) => {
    
    if (editMode) {
      onSubmit(data);
      toggleEdit('account');  // or whatever section name is appropriate
    }
  };

  return (
   
      <Card sx={{ mb: 2 }}>
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <CardHeader title={'Bank Account Info'} />
          <Box sx={{ pr: 2 }}>
            {editMode ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="button"
                onClick={handleSubmit(handleFormSubmit)}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                type='button'
                onClick={() => toggleEdit('account')}
              >
                Edit
              </Button>
            )}
          </Box>
        </Stack>
        <CardContent>
          <Grid2 container spacing={2}>
            <Grid2 item size={6}>
              {editMode ? (
                <FormTextField
                  name={'bank'}
                  label={'Bank Name'}
                  control={control}
                />
              ) : (
                <Typography>
                  <strong>Bank Name :</strong> {accountDetails?.bank}
                </Typography>
              )}
            </Grid2>
            <Grid2 item size={6}>
              {editMode ? (
                <FormTextField
                  name={'accountName'}
                  label={'Account Name'}
                  control={control}
                />
              ) : (
                <Typography>
                  <strong>Account Name :</strong> {accountDetails?.accountName}
                </Typography>
              )}
            </Grid2>
            <Grid2 item size={6}>
              {editMode ? (
                <FormTextField
                  name={'accountNumber'}
                  label={'Account Number'}
                  control={control}
                />
              ) : (
                <Typography>
                  <strong>Account Number :</strong>{' '}
                  {accountDetails?.accountNumber}
                </Typography>
              )}
            </Grid2>
            <Grid2 item size={6}>
              {editMode ? (
                <FormTextField name={'iban'} label={'IBAN'} control={control} />
              ) : (
                <Typography>
                  <strong>IBAN :</strong> {accountDetails?.iban}
                </Typography>
              )}
            </Grid2>
            <Grid2 item size={6}>
              {editMode ? (
                <FormTextField
                  name={'routingNumber'}
                  label={'Routing Number'}
                  control={control}
                />
              ) : (
                <Typography>
                  <strong>Routing Number :</strong>{' '}
                  {accountDetails?.routingNumber}
                </Typography>
              )}
            </Grid2>
            <Grid2 item size={6}>
              {editMode ? (
                <FormTextField
                  name={'swiftCode'}
                  label={'Swift Code'}
                  control={control}
                />
              ) : (
                <Typography>
                  <strong>SWIFT Code :</strong> {accountDetails?.swiftCode}
                </Typography>
              )}
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
  );
}
