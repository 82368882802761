import React, { useEffect, useState } from 'react';
import {
  Input,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { axios } from '../../../utils/axios';
import Head from '../../../layout/head/Head';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  RSelect,
  Row,
  TooltipComponent
} from '../../../components/Component';

export default function AutoRepostAuctionSetting({ vertical_id, vertical }) {
  const { id } = useParams();

  const optionIntervals = [
    { label: 'Minutes', value: 'minutes' },
    { label: 'Hours', value: 'hours' },
    { label: 'Days', value: 'days' },
    { label: 'Months', value: 'months' }
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [warningText, setWarningText] = useState('Hello warning');

  const [autoRepostOption, setAutoRepostOption] = useState(null);

  const [activeState, setActiveState] = useState(
    autoRepostOption ? autoRepostOption?.isActive : true
  );

  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState(null);
  const [interval, setInterval] = useState(null);

  const verticalData = {
    ...(vertical ?? {}),
    label: vertical?.name,
    value: vertical?.name
  };

  const getAutoRepostOption = async () => {
    try {
      if (!vertical_id) {
        console.log('Invalid vertical id');
        return;
      }

      const { data } = await axios.get(
        `/admin/api/v1/auto-repost-auction/vertical/${id}`
      );

      const { scheduler } = data?.data;

      const splittedSchedule = scheduler?.split(' ');

      const interval = splittedSchedule[splittedSchedule?.length - 1];
      const intervalDuration = splittedSchedule[splittedSchedule?.length - 2];

      setAutoRepostOption(data?.data);

      setInterval(
        optionIntervals?.find((item) => item?.value?.includes(interval)) ?? null
      );

      setDuration(intervalDuration ?? null);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    vertical_id && getAutoRepostOption();
  }, []);

  const toggle = () => setModalOpen((prevState) => !prevState);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const scheduler = edited
        ? `in ${duration} ${
            Number(duration) > 1
              ? interval?.value
              : interval?.value?.slice(0, interval?.value?.length - 1)
          }`
        : null;

      if (autoRepostOption) {
        // update
        const params = {
          isActive: activeState
        };

        if (scheduler) {
          params.scheduler = scheduler;
          params.isActive = true;
        }

        const { data } = await axios.put(
          `/admin/api/v1/auto-repost-auction/${autoRepostOption?.id}`,
          params
        );

        setAutoRepostOption(data?.data);

        toggle();
        setEdited(false);
      } else {
        const params = {
          vertical_id,
          scheduler
        };

        const { data } = await axios.post(
          '/admin/api/v1/auto-repost-auction',
          params
        );

        setAutoRepostOption(data?.data);

        toggle();
        setEdited(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Auto Repost Auction On End Setting</BlockTitle>
            <BlockDes>
              <p>
                This setting lets users' auctions to be reposted automatically
                after the auction ends.
                <p>
                  <b>
                    {autoRepostOption
                      ? autoRepostOption?.isActive
                        ? `Auction will be reposted [${autoRepostOption?.scheduler}] after auction ends.`
                        : 'Setting for this vertical has been disabled'
                      : 'Setting not enabled for this vertical'}
                  </b>
                </p>
              </p>
            </BlockDes>
          </BlockHeadContent>

          <BlockHeadContent className="align-self-start">
            {autoRepostOption && (
              <Button
                color={'secondary'}
                disabled={edited}
                onClick={() => {
                  setWarningText(
                    autoRepostOption?.isActive
                      ? 'Are you sure you want to deactivate this setting?'
                      : 'Are you sure you want to activate this setting?'
                  );
                  setModalOpen(true);
                  setActiveState(!autoRepostOption?.isActive);
                }}
                className={'me-1'}
              >
                {autoRepostOption?.isActive ? 'Deactivate' : 'Activate'}
              </Button>
            )}
            {/* <Button color={'primary'} onClick={() => {}}>
              Add New
            </Button> */}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Dropdown></Dropdown>
        <BlockTitle tag="h6">Vertical</BlockTitle>
        {/* <Label className="form-label">Vertical</Label> */}
        <RSelect value={verticalData} isDisabled />
        <p></p>
        <BlockTitle tag="h6">
          Edit Timing{' '}
          <TooltipComponent
            icon={'info'}
            text={
              'After how much duration do you want to wait before auction gets reposted automatically?'
            }
            direction={'right'}
            iconClass={'cursor-help'}
            id={'tolltip'}
          />
        </BlockTitle>
        <Row>
          <Col sm={'6'}>
            <Input
              placeholder="Enter duration"
              type="number"
              min="1"
              required
              onKeyDown={(e) => {
                const value = e.currentTarget.value;

                const notAllowedKeys = ['e', 'E', '+', '-'];

                if (!value || value === '') {
                  notAllowedKeys.push('0');
                }

                if (notAllowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                setDuration(e.target?.value);
                setEdited(true);
              }}
              value={duration}
            />
          </Col>
          <Col sm={'6'}>
            <RSelect
              value={interval}
              options={optionIntervals}
              onChange={(value) => {
                setInterval(value);
                setEdited(true);
              }}
              placeholder="Please select an interval"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            disabled={!edited}
            color={'primary'}
            className={'mt-2'}
            onClick={() => {
              setWarningText(
                autoRepostOption ? (
                  !autoRepostOption?.isActive ? (
                    <span>
                      Saving changes will also <b>activate</b> this setting. Do
                      you want to continue?
                    </span>
                  ) : (
                    'Are you sure you want to update these settings?'
                  )
                ) : (
                  'Please confirm creation of this setting'
                )
              );

              if (!autoRepostOption) {
                if (
                  !interval ||
                  !duration ||
                  isNaN(Number(duration)) ||
                  Number(duration) < 1
                ) {
                  toast.error('Interval and duration must be valid');
                  return;
                }
              }
              setModalOpen(true);
            }}
          >
            Save Changes
          </Button>
        </div>
      </Block>
      <Modal isOpen={modalOpen} toggle={toggle} className="modal-md">
        <ModalHeader toggle={toggle}>
          <Icon name={'alert-c'} id={'warning-icon'} /> Warning
        </ModalHeader>
        <ModalBody>{warningText}</ModalBody>
        <ModalFooter>
          <Button color={'secondary'} onClick={toggle}>
            Cancel
          </Button>
          <Button color={'primary'} onClick={handleConfirm} disabled={loading}>
            {loading ? <Spinner size={'sm'} /> : 'Confirm'}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
