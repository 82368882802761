import React from 'react';
import { useForm } from 'react-hook-form';
import FormTextField from '../../../components/form/FormTextField'; // Using your custom text field component
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Stack,
  Typography
} from '@mui/material';
import FormLocationField from '../../../components/form/FormLocationField';

export default function DealerLocationForm({
  dealerLocation,
  onSubmit,
  toggleEdit,
  editMode
}) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      address: dealerLocation?.address || '',
      city: dealerLocation?.city || '',
      country: dealerLocation?.country || '',
      location:dealerLocation?.location || null
    }
  });

  const handleFormSubmit = (data) => {
    if (editMode) {
      onSubmit(data);
      toggleEdit('location'); // or whatever section name is appropriate
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <CardHeader title={'Location Details'} />
        <Box sx={{ pr: 2 }}>
          {editMode ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="button"
              onClick={handleSubmit(handleFormSubmit)}
            >
              Save Changes
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              type="button"
              onClick={() => toggleEdit('location')}
            >
              Edit
            </Button>
          )}
        </Box>
      </Stack>
      <CardContent>
        {' '}
        <Grid2 container spacing={2}>
         
          <Grid2 item size={6}>
            {editMode ? (
              <FormLocationField
                name={'location'}
                label={'Location'}
                control={control}
              />
            ) : (
              <Box>
                <Typography variant="subtitle1">Location</Typography>
                <Box width={350} height={150} >
                  {/* <Image image={dealer?.hero_image} /> */}
                </Box>
              </Box>
            )}
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
}
