export const formatDuration = (milliseconds) => {
  const minutes = Math.floor(milliseconds / 60000);
  const hours = Math.floor(minutes / 60);

  if (minutes < 60) {
    return `${minutes} Mins`;
  } else if (hours === 1) {
    return '1 Hr';
  } else {
    return `${hours} Hrs`;
  }
};

export const parseDurationString = (durationString) => {
  const match = durationString.match(/^(\d+)\s+(\w+)$/);

  if (!match) {
    throw new Error('Invalid duration string format');
  }

  const value = parseInt(match[1]);
  const unit = match[2].toLowerCase();

  if (unit === 'mins' || unit === 'min') {
    return value * 60000; // 1 minute = 60,000 milliseconds
  } else if (unit === 'hr' || unit === 'hrs') {
    return value * 3600000; // 1 hour = 3,600,000 milliseconds
  } else {
    throw new Error('Invalid duration unit');
  }
};
