import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Spinner } from "reactstrap";
import { Col, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, InputSwitch, PaginationComponent, PreviewAltCard, RSelect } from "../../../components/Component";
import { baseformValidation } from "../validations/baseformValidation";
import { createOrUpdateFormFields } from "../../../redux/features/verticalsSlice/createOrUpdateFormFieldsSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { fieldTypes } from "../data/field_types";
import { startCase } from "lodash";
import { getImage, uploadToS3 } from "../../../utils/uploadImageToS3";
import { bulkInsertAutocompleteOptions, bulkInsertAutocompleteOptionsRef } from "../../../redux/features/verticalsSlice/autocompleteOptionsSlice";


export default function DragDropFormBuilder({ id, formId, formType, formData, setFormData }) {

  const [isOpen, setIsOpen] = useState({ open: false, isEdit: false, fieldType: null });
  const [lastAddedField, setLastAddedField] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [fileContents, setFileContents] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabName, setTabName] = useState(null);
  const [isOpenAddTab, setIsOpenAddTab] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [isEditTab, setIsEditTab] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [csvFileName, setCsvFileName] = useState('');
  const [filCount, setFilCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const indexOfLastItemLog = currentPage * itemsPerPage;
  const indexOfFirstItemLog = indexOfLastItemLog - itemsPerPage;
  const [loading, setLoading] = useState(false);

  const currentItems = fileData.slice(1).slice(indexOfFirstItem, indexOfLastItem);
  const [inputs, setInputs] = useState([{ name: '', bgColor: '', textColor: '' }]);
  const bgColoroptions = [{ "name": "Red(primary)", "color": "0xFFA50C0C" }, { "name": "Yellow", "color": "0XFFFFD954" }, { "name": "Green(secondary)", "color": "0xFF28604A" }];
  const textColoroptions = [{ "name": "White", "color": "0xFFFFFFFF" }, { "name": "Black(for yellow bg)", "color": "0xFF000000" }];

  const handleInputChange = (index, field, value) => {
    const newInputs = [...inputs];
    newInputs[index][field] = value;
    setInputs(newInputs);
  };

  const handleAddClick = () => {
    setInputs([...inputs, { name: '', bgColor: '', textColor: '' }]);
  };
  const handleRemoveClick = (index) => {

    if (index > -1) {
      const newInputs = [...inputs]; // Create a copy of the inputs array
      newInputs.splice(index, 1); // Remove the element at the specified index
      setInputs(newInputs); // Update the state with the new array
    }
  };

  const downloadTemplate = (key) => {
    // Create your dynamic template content here
    const csvContent = `${key}\nToyota`;

    // Create a Blob (binary large object) from the template content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element and simulate a click to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'template.csv';
    a.click();

    // Revoke the URL to free up resources
    window.URL.revokeObjectURL(url);
  };
  const downloadTemplateReference = (key, referenceKey) => {
    // Create your dynamic template content here
    const csvContent = `${referenceKey},${key}\nToyota,Camry\nToyota,Corolla`;

    // Create a Blob (binary large object) from the template content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element and simulate a click to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'template2.csv';
    a.click();

    // Revoke the URL to free up resources
    window.URL.revokeObjectURL(url);
  };

  const handleViewData = () => {
    const rows = fileContents.split('\n');
    const data = rows.map((row) => row.split(','));
    setFileData(data);
    setFilCount(data.slice(1).length);
  };
  const handleSaveCsv = (e, key) => {
    e.preventDefault();

    if (fileData.length > 0) {
      const firstInnerArrayLength = fileData[0].length;
      if (firstInnerArrayLength > 1)
        toast.error(`Invalid template.Please download the template and follow the format`);
    } else {
      toast.error("The data is empty");
    }
    const keyValuePairArray = fileData.slice(1).map(innerArray => ({
      "key": key,
      "value": innerArray[0],
      "vertical_id": id,
    }));

    dispatch(bulkInsertAutocompleteOptions({ "data": keyValuePairArray }))
  }
  const handleSaveCsvSub = (e, key, referenceKey) => {
    e.preventDefault();
    if (fileData.length > 0) {
      const firstInnerArrayLength = fileData[0].length;
      if (firstInnerArrayLength !== 2)
        toast.error(`Invalid template.Please download the template and follow the format`);
    } else {
      toast.error("The data is empty");
    }
    const keyValuePairArray = fileData.slice(1).map(innerArray => ({
      "key": key,
      "value": innerArray[1],
      "hasReference": true,
      "referenceKey": referenceKey,
      "referenceValue": innerArray[0],
      "vertical_id": id,
    }));

    dispatch(bulkInsertAutocompleteOptionsRef({ "data": keyValuePairArray }))
  }
  const handleClearData = () => {
    setFileData([]);
    setFileContents([]);
    setCsvFileName('');
    setFilCount(0);
    document.getElementById('fileInput').value = null;
  };
  const onDragEnd = (data) => {
    const { draggableId, source, destination } = data;
    if (source && destination) {
      if (source.droppableId === "controls_droppable") {
        const newFormControl = {
          id: `${formData.length}`,
          type: draggableId,
          config: {},
        };

        let newFormData = [...formData[selectedTabIndex].fields];
        newFormData.splice(destination.index, 0, newFormControl);
        let forms = [...formData];
        forms[selectedTabIndex].fields = newFormData;
        setLastAddedField(destination.index);
        setFormData(forms);
        setFieldData(fieldTypes.find((e) => e.type === draggableId));
        setIsOpen({ open: true, isEdit: false, fieldType: draggableId });
      }
      if (source.droppableId.includes("form_droppable")) {
        if (source.index !== destination.index) {
          const newFormData = [...formData[selectedTabIndex].fields];
          const movedFormControl = newFormData.splice(source.index, 1)[0];
          newFormData.splice(destination.index, 0, movedFormControl);
          const forms = [...formData];
          forms[selectedTabIndex].fields = newFormData;
          setFormData(forms);
        }
      }
    }
  };
  const addField = async () => {
    setLoading(true)
    if (fieldData.type === "2dDiagram") {
      const upload = await uploadToS3(fieldData.diagram);
      fieldData.diagram = upload;
    }
    formData[selectedTabIndex].fields[lastAddedField] = fieldData;
    setLastAddedField(null);
    setIsOpen(false);
    setLoading(false)
    if (fieldData.type === "autocomplete") {
      setFieldData(fieldData);
      setIsOpenUpload({ open: true, key: fieldData.key, referenceKey: fieldData.referenceKey, hasReference: fieldData.hasReference, fieldType: fieldData.type });
    }

  };

  const onAddFieldCancelled = () => {
    const fields = [...formData[selectedTabIndex].fields];
    const newFields = fields.filter((e, i) => i !== lastAddedField);
    const newformData = [...formData];
    newformData[selectedTabIndex].fields = newFields;
    setFormData(newformData);
    setIsOpen({ open: false });
  };
  const onUploadOptions = () => {
    // const fields = [...formData[selectedTabIndex].fields];
    // const newFields = fields.filter((e, i) => i !== lastAddedField);
    // const newformData = [...formData];
    // newformData[selectedTabIndex].fields = newFields;
    // setFormData(newformData);
    setIsOpenUpload({ open: false });
  };
  const deleteFormField = (index) => {
    const fields = [...formData[selectedTabIndex].fields];
    const newFields = fields.filter((e, i) => i !== index);
    const newformData = [...formData];
    newformData[selectedTabIndex].fields = newFields;
    setFormData(newformData);
  };
  const editFormField = async (index) => {
    setLoading(true)
    let fields = [...formData[selectedTabIndex].fields];
    let field = fieldData;
    if (field.type === "2dDiagram") {
      const upload = await uploadToS3(field.diagram, field.diagram.name);
      field.diagram = field.diagram.name;
    }
    fields.splice(index, 1, field);
    const newformData = [...formData];
    newformData[selectedTabIndex].fields = fields;
    setFormData(newformData);
    setIsOpen({ open: false });
    setLoading(false)
  };
  const addTab = () => {
    const form = [...formData];
    if (isEditTab) {
      form[selectedTabIndex].name = tabName;
    } else {
      form.push({ name: tabName, fields: [] });
    }
    setFormData(form);
    setIsOpenAddTab(false);
  };
  const dispatch = useDispatch();
  const onSubmit = () => {
    delete formData.requiredFields;
    delete formData.fieldLabel;
    if (baseformValidation(formData)) {
      setLoading(true);
      const data = {
        action: formId ? "update" : "create",
        formType: formType,
        id: formId,
        data: {
          vertical_id: id,
          tabs: formData,
        },
      };
      dispatch(createOrUpdateFormFields(data));
      setLoading(false);
    } else {
      toast.error("Every tab should contain atleast one field");
    }
  };
  const onFormDataChanged = (name, value) => {
    let field = { ...fieldData } ?? {};
    field[name] = value;
    setFieldData(field);
    console.log(field)

  };


  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      toast.error("Please choose a file.");
      return;
    }

    setFileData([]);
    setFileData([]);
    setFileContents([]);
    setCsvFileName('');
    setFilCount(0);

    if (file.type !== 'text/csv') {
      toast.error("Only CSV files are accepted.");
      return;
    }

    const reader = new FileReader();
    const fileName = file.name;
    setCsvFileName(fileName);

    reader.onload = (e) => {
      const contents = e.target.result;
      const rows = contents.split('\n');
      const data = rows.map((row) => row.split(','));

      setFileContents(contents);
    };

    reader.readAsText(file);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="card-aside-wrap h-100">
          <div
            className={`card-aside card-aside-left content-active
              `}
          >
            <div className="card-inner-group">
              <Droppable droppableId="controls_droppable" type="controls" isDropDisabled={true}>
                {(provided, snapshot) => (
                  <div className="card-inner p-0">
                    <ul className="link-list-menu" {...provided.droppableProps} ref={provided.innerRef}>
                      {fieldTypes.map((e, i) => (
                        <Draggable key={`control_draggable_${e.type}`} draggableId={e.type} index={i}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <Card key={i} className="m-2 p-2">
                                {e.fieldLabel}
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  </div>
                )}
              </Droppable>
            </div>
          </div>
          <div className="card-inner " style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Nav tabs style={{ width: "100%" }}>
                {formData.map((e, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={classNames({ active: selectedTabIndex === index })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setSelectedTabIndex(index);
                      }}
                    >
                      {e.name}
                    </NavLink>
                    {formType !== 'Basic' &&
                      <Icon
                        style={{ paddingLeft: 10 }}
                        name="pen"
                        className={classNames({ active: selectedTabIndex === index })}
                        onClick={() => {
                          setSelectedTabIndex(index);
                          setTabName(e.name);
                          setIsOpenAddTab(true);
                          setIsEditTab(true);
                        }}
                      />}
                  </NavItem>
                ))}
              </Nav>
              {formType !== 'Basic' &&
                <Button
                  style={{ width: "110px" }}
                  onClick={() => {
                    setTabName(null);
                    setIsOpenAddTab(true);
                    setIsEditTab(false);
                  }}
                >
                  Add Tab
                </Button>}
            </div>
            <div className="h-100" style={{ alignSelf: "stretch" }}>
              <Droppable droppableId={`form_droppable`} type="controls" className="h-100">
                {(provided, snapshot) => (
                  <div className="card-inner p-0 h-100">
                    <ul className="link-list-menu h-100" {...provided.droppableProps} ref={provided.innerRef}>
                      {formData[selectedTabIndex]?.fields?.length > 0 &&
                        formData[selectedTabIndex].fields.map((e, i) => (
                          <Draggable key={`form_draggable_${i}`} draggableId={`form_draggable_${i}`} index={i}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Card key={i} className="m-2 p-2">
                                  <div style={{ display: "flex", columnGap: 8 }}>
                                    <div style={{ flex: 1 }}>
                                      <div> {e.fieldLabel}</div>
                                      <div>{e?.name ?? ""}</div>
                                    </div>
                                    <div>
                                      {e.type === 'autocomplete' &&
                                        <Button
                                          onClick={() => {
                                            // console.log(e);
                                            setFieldData(e);
                                            setIsOpenUpload({ open: true, key: e.key, referenceKey: e.referenceKey, hasReference: e.hasReference, fieldType: e.type });
                                          }}
                                        >
                                          <Icon name="upload" />
                                        </Button>
                                      }
                                    </div>
                                    <div>

                                      {e.type === 'radio' && e?.radioOptions?.length > 0 ?
                                        <Button
                                          onClick={() => {
                                            setFieldData(e);
                                            setInputs(e.radioOptions.map((x) => ({ name: x.name, bgColor: x.bgColor, textColor: x.textColor })));
                                            setIsOpen({ open: true, isEdit: true, index: i, fieldType: e.type });
                                          }}
                                          disabled={e.isRequired}
                                        >
                                          <Icon name="edit" />
                                        </Button>
                                        :
                                        <Button
                                          onClick={() => {

                                            setFieldData(e);
                                            setIsOpen({ open: true, isEdit: true, index: i, fieldType: e.type });
                                          }}
                                          disabled={e.isRequired}
                                        >
                                          <Icon name="edit" />
                                        </Button>}
                                    </div>
                                    <div>
                                      <Button onClick={() => deleteFormField(i)} disabled={e.isRequired}>
                                        <Icon name="trash" />
                                      </Button>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </ul>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={onSubmit}>
                {loading ? (
                  <>
                    <Spinner size="sm" color="light" />
                    Please Wait
                  </>
                ) : (
                  'Submit'
                )}
              </Button>
            </div>
          </div>
        </div>
      </DragDropContext>
      <Modal size="md" backdrop="static" isOpen={isOpen.open}>
        <ModalHeader
          close={
            <button className="close" onClick={onAddFieldCancelled}>
              <Icon name="cross" />
            </button>
          }
        >
          Add Form Details
        </ModalHeader>
        <ModalBody>
          {fieldTypes
            .find((e) => e.type === isOpen.fieldType)
            ?.requiredFields.map((e, i) => {
              switch (e.type) {
                case 'string':
                  return (
                    <label key={i} style={{ width: '100%' }}>
                      {e.label}
                      <input
                        className="form-control"
                        style={{ width: '100%' }}
                        placeholder={e.placeholder}
                        onChange={(v) =>
                          onFormDataChanged(e.value, v.target.value)
                        }
                        value={fieldData[e?.value] ?? ''}
                      />
                    </label>
                  );
                case 'boolean':
                  return (
                    <InputSwitch
                      checked={fieldData[e?.value] ?? false}
                      label={e.label}
                      id={i}
                      onClick={(v) => onFormDataChanged(e.value, v)}
                    />
                  );
                case 'dropdown':
                  return (
                    <label key={i} style={{ width: '100%' }}>
                      {e.label}
                      <RSelect
                        className=""
                        style={{ width: '100%' }}
                        options={e.options.map((e) => ({ label: e, value: e }))}
                        onChange={(v) => onFormDataChanged(e.value, v.value)}
                        value={
                          {
                            label: fieldData[e.value],
                            value: fieldData[e.value]
                          } ?? ''
                        }
                      />
                    </label>
                  );
                case 'array':
                  return (
                    <label key={i} style={{ width: '100%' }}>
                      {e.label}
                      <input
                        className="form-control"
                        style={{ width: '100%' }}
                        placeholder={e.placeholder}
                        onChange={(v) =>
                          onFormDataChanged(e.value, v.target.value.split(','))
                        }
                        value={fieldData[e.value] ?? ''}
                      />
                    </label>
                  );
                case 'multiselect':
                  return (
                    <label key={i} style={{ width: '100%' }}>
                      {e.label}
                      <RSelect
                        className="form-control"
                        isMulti={true}
                        options={e.options.map((e) => ({ label: e, value: e }))}
                        style={{ width: '100%' }}
                        onChange={(v) => onFormDataChanged(e.value, v.value)}

                      />
                    </label>
                  );
                case 'object':
                  return (
                    <>
                      <label key={i} style={{ width: '100%' }}>
                        {e.label}
                      </label>
                      {inputs.map((input, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'grid',
                            gridTemplateColumns: "repeat(4, 1fr)",
                            gap: "10px",
                            justifyContent: "space-between",
                            alignItems: 'start',

                          }}
                        >
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ width: '100%' }}>
                              {"Name"}
                            </label>
                            <input
                              className="form-control"
                              placeholder={'Option'}
                              type="text"
                              value={input.name}
                              onChange={(e) =>
                                handleInputChange(index, 'name', e.target.value)
                              }
                            />
                          </div>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ width: '100%' }}>
                              {"Text color"}
                            </label>
                            <RSelect
                              style={{ width: '100%' }}
                              isMulti={false}
                              defaultValue={{ "label": input?.textColor ?? "", "value": input?.textColor } ?? ""}
                              placeholder={"Text color"}
                              options={textColoroptions.map((e) => ({
                                label: e.name,
                                value: e.color
                              }))}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  'textColor',
                                  e.value
                                )
                              }
                            /></div>

                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ width: '100%' }}>
                              {"Bg color"}
                            </label>
                            <RSelect
                              placeholder={"Bg color"}
                              style={{ width: '100%' }}
                              isMulti={false}
                              defaultValue={{ "label": input?.bgColor ?? "", "value": input?.bgColor } ?? ""}
                              options={bgColoroptions.map((e) => ({
                                label: e.name,
                                value: e.color
                              }))}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  'bgColor',
                                  e.value
                                )
                              }
                            />
                          </div>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ width: '100%' }}>
                              {"Remove"}
                            </label>
                            <Button
                              type="button"
                              color="primary"
                              style={{ width: '25%' }}
                              className="btn-icon"
                              onClick={() => handleRemoveClick(index)}
                            >
                              <Icon name="minus"></Icon>
                            </Button>
                          </div>
                        </div>
                      ))}
                      <Button
                        type="button"
                        style={{ marginRight: "10px", marginTop: "20px" }}
                        className="btn-icon mr-4"
                        onClick={handleAddClick}
                      >
                        <Icon name="plus"></Icon>
                      </Button>

                      <Button
                        type="button"
                        style={{ marginRight: "10px", marginTop: "20px" }}
                        onClick={() => onFormDataChanged(e.value, inputs)}
                      >
                        Save
                      </Button>
                    </>
                  );
                case 'diagramImage':
                  return (
                    <label style={{ width: '100%' }}>
                      {e.label}
                      <div className="form-control">
                        <div className="form-file">
                          <input
                            type="file"
                            style={{ width: '100%' }}
                            onChange={(v) =>
                              onFormDataChanged(e.value, v.target.files[0])
                            }
                          />
                        </div>
                      </div>
                    </label>
                  );
                default:
                  return (
                    <></>
                  )
              };

            })}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={isOpen.isEdit ? () => editFormField(isOpen.index) : addField}>
            {loading ? (
              <>
                <Spinner size="sm" color="light" />
                Please Wait
              </>
            ) : (
              'Submit'
            )}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isOpenUpload.open}>
        <ModalHeader
          close={
            <button className="close" onClick={onUploadOptions}>
              <Icon name="cross" />
            </button>
          }
        >
          Upload
        </ModalHeader>
        <ModalBody>
          {!isOpenUpload.hasReference ?
            <div className="m-2" style={{ display: "flex", justifyContent: "end" }}>
              <Button color="primary" onClick={() => downloadTemplate(isOpenUpload.key)}>Download Template</Button>
            </div> :
            <div className="m-2" style={{ display: "flex", justifyContent: "end" }}>
              <Button color="primary" onClick={() => downloadTemplateReference(isOpenUpload.key, isOpenUpload.referenceKey)}>Download Template</Button>
            </div>
          }
          <Col sm="12">
            <span className="data-label expanded m-2">Upload the file</span>
            <div className="">
              <Button color="light">
                <input type="file" id="fileInput" onChange={handleFileUpload}></input>
              </Button>
              <div>
                <div className="mt-3">
                  <Button outline={fileContents.length > 0} color="danger" className="ml-2" onClick={handleClearData} disabled={fileContents.length === 0}>
                    Clear
                  </Button>
                  <Button outline={fileContents.length > 0} color="secondary" className="ml-2" onClick={handleViewData} disabled={fileContents.length === 0}>
                    Load & Preview
                  </Button>
                </div>
              </div>
            </div>
            <DataTable className="card-stretch mt-3">
              <DataTableBody compact>
                <DataTableHead className="nk-tb-item">
                  {fileData.length > 0 &&
                    fileData[0].map((header, index) => (
                      <DataTableRow key={index}>
                        <span className="sub-text">{header}</span>
                      </DataTableRow>
                    ))}
                </DataTableHead>


                {currentItems.map((row, rowIndex) => (
                  <DataTableItem key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <DataTableRow key={cellIndex}>
                        <span href="#id" onClick={(ev) => ev.preventDefault()}>
                          {cell}
                        </span>
                      </DataTableRow>
                    ))}
                  </DataTableItem>
                ))}
              </DataTableBody>
              <PreviewAltCard>
                {fileData.slice(1)?.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemsPerPage}
                    totalItems={fileData.slice(1).length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">Upload CSV file to see the data</span>
                  </div>
                )}
              </PreviewAltCard>
            </DataTable>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={
            !isOpenUpload.hasReference ?
              (e) => handleSaveCsv(e, isOpenUpload.key) :
              (e) => handleSaveCsvSub(e, isOpenUpload.key, isOpenUpload.referenceKey)
          }>
            Submit
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isOpenAddTab}>
        <ModalHeader
          close={
            <button className="close" onClick={() => setIsOpenAddTab(false)}>
              <Icon name="cross" />
            </button>
          }
        >
          Add Tab
        </ModalHeader>
        <ModalBody>
          <label style={{ width: "100%" }}>
            Tab Name
            <input
              className="form-control"
              style={{ width: "100%" }}
              onChange={(e) => setTabName(e.target.value)}
              defaultValue={tabName}
            />
          </label>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addTab}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
