import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Stack, Card, CardContent, Divider } from '@mui/material';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchOrderDetails, resetOrderDetailSlice } from '../../redux/features/ordersSlice/orderDetailSlice';
import { Spinner } from 'reactstrap';
import Content from '../../layout/content/Content';

function OrderDetailsPage() {

    const {orderId} = useParams()

    const {order} = useSelector((state)=>state.orderDetails);

    const dispatch = useDispatch()

    console.log(orderId)

    useEffect(()=>{
        if(orderId){
        dispatch(fetchOrderDetails(orderId))
        }
        return ()=>{
            dispatch(resetOrderDetailSlice())
        }
    },[orderId])


  const handleEditUserInfo = () => {

  };

  const handleUpdateUserInfo = () => {

  };

  if(!order){
    return <Spinner/>
  }

  return (
    <Content>
    <Box sx={{ p: 3 }}>
        <Stack direction='row' justifyContent='space-between'>
      {/* Car Details */}
      <Typography variant="h4" gutterBottom>
        {order?.vehicle?.make} {order?.vehicle?.model} ({order?.vehicle?.year})
      </Typography>
      {/* Action Buttons */}
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="contained" color="error">
          Cancel Order
        </Button>
        <Button variant="contained" color="primary">
          Add Payment
        </Button>
        <Button variant="contained" color="success">
          Mark as Sold
        </Button>
        <Button variant="contained" color="secondary">
          Mark as Delivered
        </Button>
      </Stack>
      </Stack>

      {/* User Information Section */}
      <Card sx={{ mb: 3 ,mt:3}}>
        <CardContent>
          <Typography variant="h6">User Information</Typography>
          <Divider sx={{ my: 2 }} />
              <Typography variant="body1" gutterBottom>
                Username: {order?.user?.full_name}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Phone: {order?.user?.phone_number}
              </Typography>
              <Button variant="contained" onClick={handleEditUserInfo}>
                { 'Update User Info'}
              </Button>
              {/* {isEditingUserInfo && (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    label="Username"
                    value={updatedUserInfo.username}
                    onChange={(e) => setUpdatedUserInfo({ ...updatedUserInfo, username: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Phone Number"
                    value={updatedUserInfo.phone}
                    onChange={(e) => setUpdatedUserInfo({ ...updatedUserInfo, phone: e.target.value })}
                    fullWidth
                    margin="normal"
                  />
                  <Button variant="contained" color="primary" onClick={handleUpdateUserInfo}>
                    Save User Info
                  </Button>
                </Box>)} */}
        </CardContent>
      </Card>

      {/* Sale Type */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6">Order Type</Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1">
            Sale Type: {order?.vehicle?.sale_type === 'AUCTION' ? 'Live Auction' : 'Timed Auction'}
          </Typography>
        </CardContent>
      </Card>

      {/* Payment Information */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6">Payment Information</Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1">Total Price: ${order?.amount?.toLocaleString()}</Typography>
          <Typography variant="body1">
            Advance Payment: ${(order?.transactions?.[0]?.amount || 0)?.toLocaleString()}
          </Typography>
          <Typography variant="body1">Balance: ${order?.balanceAmount?.toLocaleString()}</Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
      <CardContent>
      <Typography variant="h6">Transactions</Typography>
      <Divider sx={{ my: 2 }} />
      {order?.transactions?.length&& order?.transactions?.map((transaction) => (
        
          <Card>
            <CardContent>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Invoice Number: {transaction.invoice_number}
              </Typography>
              <Typography color="textSecondary">
                Payment For: {transaction.payment_for}
              </Typography>
              <Typography color="textSecondary">
                Payment Type: {transaction.payment_type}
              </Typography>
              <Typography color="textSecondary">
                Status: {transaction.status}
              </Typography>
              <Typography color="textSecondary">
                Amount: ${transaction.amount.toFixed(2)}
              </Typography>
              <Typography color="textSecondary">
                Payment Time: {new Date(transaction.payment_time).toLocaleString()}
              </Typography>
              <Typography color="textSecondary">
                Created At: {new Date(transaction.createdAt).toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        
      ))}
      </CardContent>
      </Card>

      
    </Box>
    </Content>
  );
}

export default OrderDetailsPage;
