import React, { useEffect, useState } from 'react';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
  Block,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard
} from '../../components/Component';
import { RSelect } from '../../components/Component';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Badge,
  Spinner,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row
} from 'reactstrap';
import ConfirmModal from '../verticals/components/ConfirmModal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { sendNotifications } from '../../redux/features/usersSlice/notificationSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editFreeAuctions, fetchFreeAuctions } from '../../redux/features/usersSlice/freeAuctionSlice';
import { useSelector } from 'react-redux';

export default function FreeAuctions() {
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState(0);

  const [showModal, setShowModal] = useState(false);
  
  const dispatch = useDispatch();
  const { data} = useSelector((state) => state?.freeAuctions);
  const closeModal = () => {
    setShowModal(false);
    setNumber(null);
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const handleSubmit = () => {
    if(!number){
      toast.error('Please enter a value');
      return;
    }
    setShowModal(true);
  };
  useEffect(() => {
dispatch(fetchFreeAuctions());
  }, []);

  useEffect(() => {
    if(data &&data?.number ) {
      setNumber(data?.number);
    }
      }, [data]);



  const broadcast = (e) => {
    e.preventDefault();

    let data={
      number:Number(number)
    }
    // console.log(data)
    dispatch(editFreeAuctions(data));

  };

  return (
    <React.Fragment>
      <Head title="Free Auctions"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Free Auctions</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <form onSubmit={(e) =>
            {
              e.preventDefault();
              handleSubmit();
             }
            }
               >
              <Col sm="4">
                <div className="form-group mb-2">
                  <label className="form-label" htmlFor="title">
                    Number of free auctions when a user register*
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      id="number"
                      onInput={(e) => {
                        let newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers                     
                        e.target.value = newValue;
                      }}
                      className="form-control"
                      name="number"
                      required
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              <Col sm="6">
                <Button type="submit" className="bg-primary text-white mt-2">
                  {loading ? (
                    <>
                      <Spinner size="sm" color="light" />
                      Please Wait
                    </>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Col>
            </form>
          </div>
        </Block>
      </Content>
      {/* ****** Modal Start ****** */}
      {showModal ? (
        <ConfirmModal
          msg={'Update the number of free auctions'}
          name={''}
          open={showModal}
          loading={loading}
          handleClick={handleModal}
          handleConfirm={broadcast}
          handlecloseModal={closeModal}
        />
      ) : null}
      {/* ****** Modal End ****** */}
    </React.Fragment>
  );
}
