import React, { useEffect } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import { useState } from "react";
import { BackButton } from "../../components/Component";
import { useDispatch } from "react-redux";
import { resetCreateOrUpdtaeFormFields } from "../../redux/features/verticalsSlice/createOrUpdateFormFieldsSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import qp from "query-string";
import { fetchVerticalDetails } from "../../redux/features/verticalsSlice/verticalDetailsSlice";
import DragDropFormBuilder from "./components/DragDropFormBuilder";

// formdata : {
//   name:string,
//   fields:{}[]
// }

export default function CreateForms() {
  const { id } = useParams();
  const location = useLocation();
  const { formType, formId } = qp.parse(location.search);

  const [formData, setFormData] = useState([
    {
      name: "Default",
      fields: [],
    },
  ]);

  const { data } = useSelector((state) => state.verticalDetails);
  useEffect(() => {
    // console.log(data);
    if (data && formId) {
      let forms;
      switch (formType) {
        case "Basic":
          forms = JSON.parse(JSON.stringify(data?.baseformsettings?.tabs));
          setFormData(forms);
          break;
        case "Simple":
          forms = JSON.parse(JSON.stringify(data?.simplifiedinspectionformsettings?.tabs));
          setFormData(forms);
          break;
        case "Advanced":
          forms = JSON.parse(JSON.stringify(data?.advancedinspectionformsettings?.tabs));
          setFormData(forms);
          break;
        default:
          forms = JSON.parse(JSON.stringify(data?.baseformsettings?.tabs));
          setFormData(forms);
      }
    }
    if (!data && formId) {
      dispatch(fetchVerticalDetails(id));
    }
  }, [data]);

  const navigate = useNavigate();
  const { status } = useSelector((state) => state.formFields);
  useEffect(() => {
    if (status === "success") {
      dispatch(resetCreateOrUpdtaeFormFields());
      navigate(`/verticals/${id}/settings`, { replace: true });
    }
  }, [status]);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Content>
        <BackButton />

        <Card className="card-bordered h-100">
          <div
            style={{
              height: "50px",
              padding: "1rem",
              borderBottom: "line-width",
            }}
          >
            <h6>Add tabs and fields in this step</h6>
          </div>
          <DragDropFormBuilder
            id={id}
            formId={formId}
            formType={formType}
            formData={formData}
            setFormData={setFormData}
          />
        </Card>
      </Content>
    </React.Fragment>
  );
}
