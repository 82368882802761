import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Icon } from "../../../components/Component";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  forceCancelAuction,
  resetForceCancelAuction,
} from "../../../redux/features/auctionSlice/forceCancelAuctionSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchAuctionDetails } from "../../../redux/features/auctionSlice/auctionDetailsSlice";
import { toast } from "react-toastify";

export default function ForceCancelButtonWithModal() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const { loading, status } = useSelector((state) => state.forceCancelAuction);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (status === "success") {
      toast.success("Auction Cancelled Successfully");
      dispatch(resetForceCancelAuction());
      dispatch(fetchAuctionDetails(id));
      setIsOpen(false);
    } else if (status === "error") {
      toast.error("Auction cancel failed");
      dispatch(resetForceCancelAuction());
    }
  }, [status]);

  const handleForceCancel = async () => {
    dispatch(forceCancelAuction(id));
  };
  return (
    <div>
      <div className="me-3">
        <Button color="light" onClick={toggle} style={{ width: "100%" }}>
          Force Cancel
        </Button>
      </div>
      <Modal isOpen={isOpen}>
        <ModalHeader
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Force Cancel Auction
        </ModalHeader>
        <ModalBody>
          <p>Are you sure to cancel the auction?</p>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <Button color="light" onClick={toggle} style={{ width: "100%" }}>
                No
              </Button>
            </Col>
            <Col>
              <Button color="primary" onClick={handleForceCancel} style={{ width: "100%" }}>
                {loading ? <Spinner size="sm" /> : "Yes"}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
}
