import React from "react";
import { Controller } from "react-hook-form";
import PhoneInputWithCountrySelect, { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

export default function FormPhoneField({ control, name, label, ...props }) {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                validate: (val) => {
                    // Ensure the value is an object and has the required properties
                    if (!val || typeof val !== 'object') {
                        return "Phone number is required";
                    }
                    const { code, dial_code, number, value } = val;
                    if (!code || !dial_code || !number) {
                        return "Incomplete phone number data";
                    }
                    if (!isPossiblePhoneNumber(value)) {
                        return "Phone number is not possible";
                    }
                    if (!isValidPhoneNumber(value)) {
                        return "Phone number is not valid";
                    }
                    return true;
                },
            }}
            render={({ field, fieldState: { error } }) => {
                const handleChange = (value) => {
                    if (value) {
                        try {
                            const parsedNumber = parsePhoneNumber(value);
                            field.onChange({
                                code: parsedNumber?.country,
                                dial_code: "+" + parsedNumber?.countryCallingCode,
                                number: parsedNumber?.nationalNumber,
                                value: value
                            });
                        } catch (error) {
                            console.error("Error parsing phone number", error);
                            field.onChange(null);
                        }
                    } else {
                        field.onChange(null);
                    }
                };
                return (
                    <label style={{ width: "100%" }}>
                        {label} {props?.required && <span className="required-label">*</span>}
                        <PhoneInputWithCountrySelect
                            {...field}
                            value={field.value?.value}
                            className="phoneInput"
                            defaultCountry="AE"
                            placeholder="Enter Your Phone *"
                            onChange={handleChange} />
                        {error && (
                            <div className="text-red-500 my-1">
                                {error.message}
                            </div>
                        )}
                    </label>
                );
            }}
        />
    );
}
