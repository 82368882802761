import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';


const initialState = {
  order: null,
  fetchOrderStatus: 'init',
  error: null
};
const fetchOrderDetails = createAsyncThunk('orders', (id) => {
  let url = `/admin/order/${id}`;
  return axios.get(url,).then((response) => {
    return response.data?.data;
  });
});

const orderDetails = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetOrderDetailSlice:(state)=>{
        state = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderDetails.pending, (state) => {
      state.fetchOrderStatus = 'loading';
      state.error = null;
      state.order = null;
    });
    builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
      state.fetchOrderStatus = 'success';
      state.order = action.payload ?? null;
    });
    builder.addCase(fetchOrderDetails.rejected, (state, action) => {
      state.fetchOrderStatus = 'error';
      state.order = null;
      state.error = action.error.message;
    });
  }
});

export default orderDetails.reducer;
export const { resetOrderDetailSlice } = orderDetails.actions;
export { fetchOrderDetails };
