import { Box } from "@mui/material";
import React from "react";
import {
  FormTextField,
  FormSelect,
  HorizontalTabs,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Block,
} from "../../../components/Component";
import { Badge, Button, Modal, Spinner } from "reactstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { getImage } from "../../../utils/uploadImageToS3";
import axios from "axios";
import { useDispatch } from "react-redux";
import { blockUser, clearUserDetails, fetchUserDetails, updateUserDetails, verifyUserDocuments } from "../../../redux/features/usersSlice/userDetails";
import { AssignRM } from "./AssignRM";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserVerificationStatus } from "../../../utils/Utils";
import VerifyModal from "./VerifyModal";
import RejectModal from "./RejectModal";
import ConfirmModal from "../../verticals/components/ConfirmModal";


export default function UserDocumentsPage({}) {

  const navigate =useNavigate();

  const [idCardFront, setIdCardFront] = useState(null);
  const [idCardFrontPdf, setIdCardFrontPdf] = useState(null);
  const [idCardBack, setIdCardBack] = useState(null);
  const [idCardBackPdf, setIdCardBackPdf] = useState(null);
  const [passportFront, setPassportFront] = useState(null);
  const [passportFrontPdf, setPassportFrontPdf] = useState(null);
  const [passportBack, setPassportBack] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const [tradeLisc, setTradeLisc] = useState(null);
  const [tradeLiscPdf, setTradeLiscPdf] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [verifyLoading, setVerifyLoading] = useState(null);
  const [blockLoading, setBlockLoading] = useState(null);


  const [open, setOpen] = useState(null);
  const [rejectOpen, setRejectOpen] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [isOpenFull, setIsOpenFull] = useState(false);
  const [fullViewImage, setFullViewimage] = useState(null);
  const toggleFull = () => {
    setIsOpenFull(!isOpenFull);
  };
  const closeModal = () => {
    setShowModal(false);
};
const handleModal = (e) => {
  e.preventDefault();
  setShowModal(true);
};
  const [ assignAgentModal, setAssignAgentModal ] = useState( false );

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUserDetails());
    dispatch(fetchUserDetails(id));
  }, [id, dispatch]);
  const user = useSelector((state) => state.userDetails.data);

  useEffect(() => {
   setUserDetails(user)
   async function fun() {
    if(user?.documents){
      let selfieImg= user?.documents?.selfie?await getImage(user?.documents?.selfie):null;
        setSelfie(selfieImg)
        let eidImg= user?.documents?.id_card?.front?await getImage(user?.documents?.id_card?.front):null;
        setIdCardFront(eidImg)
        let eidBackImg= user?.documents?.id_card?.back?await getImage(user?.documents?.id_card?.back):null;
        setIdCardBack(eidBackImg)
        let ppImg= user?.documents?.passport?.front?await getImage(user?.documents?.passport?.front):null;
        setPassportFront(ppImg)
        let ppbackImg= user?.documents?.passport?.back?await getImage(user?.documents?.passport?.back):null;
        setPassportBack(ppbackImg)
        let tdImg= user?.documents?.trade_license?.document?await getImage(user?.documents?.trade_license?.document):null;
        setTradeLisc(tdImg)
        if( user?.documents?.trade_license?.document?.split(".")?.pop()==='pdf'){
          setTradeLiscPdf(true)
        }
        if( user?.documents?.id_card?.front?.split(".")?.pop()==='pdf'){
          setIdCardFrontPdf(true)
        }
        if( user?.documents?.id_card?.back?.split(".")?.pop()==='pdf'){
          setIdCardBackPdf(true)
        }
        if( user?.documents?.passport?.front?.split(".")?.pop()==='pdf'){
          setPassportFrontPdf(true)
        }
  }
}
  fun();
  }, [user]);

  const toggle = () => {
    setOpen(!open);
  };
  const rejectToggle = () => {
    setRejectOpen(!rejectOpen);
  };

  const unVerifyUser = () => {
    const formData = {
      is_verified: UserVerificationStatus.PENDING_DOCUMENTS,
    };
    dispatch(updateUserDetails({ id: user?.id, body: formData })).then(()=>  setVerifyLoading(false))

  };
  const handleBlock = async(e) => {
    e.preventDefault();
    dispatch(blockUser({ userId: userDetails?.id })).then(()=>  setBlockLoading(false));
  };
  
  

  let downloadFile = async (file, name) => {
    const signedUrl = await getImage(file);
    axios({
      url: signedUrl, //your url
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      // console.log(response);
      const url = URL.createObjectURL(new Blob([response.data]));
      // console.log(url);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", name);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  function getFileObjects(data) {
    const li = [];
    for (let i = 0; i < data.length; i++) {
      const e = data[i];
      const name = e.split("/")?.pop() ?? e;
      const type = name?.split(".").pop();
      let icon;
      switch (type) {
        case "jpg":
          icon = (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
              <rect
                x="15"
                y="5"
                width="56"
                height="70"
                rx="6"
                ry="6"
                fill="#e3e7fe"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                d="M69.88,85H30.12A6.06,6.06,0,0,1,24,79V21a6.06,6.06,0,0,1,6.12-6H59.66L76,30.47V79A6.06,6.06,0,0,1,69.88,85Z"
                fill="#fff"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <polyline
                points="60 16 60 31 75 31.07"
                fill="none"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <rect
                x="40"
                y="45"
                width="23"
                height="19"
                fill="#e3e7fe"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <rect
                x="36"
                y="49"
                width="23"
                height="19"
                fill="#fff"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <polyline
                points="37 62.88 45.12 55.94 52.81 63.06 55.99 59.44 59 62.76"
                fill="none"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <circle
                cx="52.11"
                cy="54.98"
                r="2.02"
                fill="none"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          );
          break;
        case "doc":
          icon = (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
              <path
                d="M49,61H23a5.0147,5.0147,0,0,1-5-5V16a5.0147,5.0147,0,0,1,5-5H40.9091L54,22.1111V56A5.0147,5.0147,0,0,1,49,61Z"
                style={{ fill: "#e3edfc" }}
              />
              <path
                d="M54,22.1111H44.1818a3.3034,3.3034,0,0,1-3.2727-3.3333V11s1.8409.2083,6.9545,4.5833C52.8409,20.0972,54,22.1111,54,22.1111Z"
                style={{ fill: "#b7d0ea" }}
              />
              <path
                d="M19.03,59A4.9835,4.9835,0,0,0,23,61H49a4.9835,4.9835,0,0,0,3.97-2Z"
                style={{ fill: "#c4dbf2" }}
              />
              <path
                d="M42,31H30a3.0033,3.0033,0,0,0-3,3V45a3.0033,3.0033,0,0,0,3,3H42a3.0033,3.0033,0,0,0,3-3V34A3.0033,3.0033,0,0,0,42,31ZM29,38h6v3H29Zm8,0h6v3H37Zm6-4v2H37V33h5A1.001,1.001,0,0,1,43,34ZM30,33h5v3H29V34A1.001,1.001,0,0,1,30,33ZM29,45V43h6v3H30A1.001,1.001,0,0,1,29,45Zm13,1H37V43h6v2A1.001,1.001,0,0,1,42,46Z"
                style={{ fill: "#36c684" }}
              />
            </svg>
          );
          break;
        case "pdf":
          icon = (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
              <rect
                x="15"
                y="5"
                width="56"
                height="70"
                rx="6"
                ry="6"
                fill="#e3e7fe"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                d="M69.88,85H30.12A6.06,6.06,0,0,1,24,79V21a6.06,6.06,0,0,1,6.12-6H59.66L76,30.47V79A6.06,6.06,0,0,1,69.88,85Z"
                fill="#fff"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <polyline
                points="60 16 60 31 75 31.07"
                fill="none"
                stroke="#6576ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                d="M57.16,60.13c-.77,0-1.53,0-2.28.08l-.82.07c-.28-.31-.55-.63-.81-1a32.06,32.06,0,0,1-4.11-6.94,28.83,28.83,0,0,0,.67-3.72,16.59,16.59,0,0,0-.49-7.29c-.29-.78-1-1.72-1.94-1.25S46.08,42.2,46,43.28a11,11,0,0,0,.12,2.63,20.88,20.88,0,0,0,.61,2.51c.23.76.49,1.51.77,2.25-.18.59-.37,1.16-.56,1.72-.46,1.28-1,2.49-1.43,3.65l-.74,1.7C44,59.52,43.18,61.26,42.25,63a27.25,27.25,0,0,0-5.72,2.85,12.36,12.36,0,0,0-2.26,2A4.33,4.33,0,0,0,33,70.24a1.62,1.62,0,0,0,.59,1.39,2.36,2.36,0,0,0,2,.27c2.19-.48,3.86-2.48,5.29-4.15a46.09,46.09,0,0,0,3.27-4.41h0a47.26,47.26,0,0,1,6.51-1.63c1.06-.18,2.15-.34,3.26-.44a15.74,15.74,0,0,0,2.54,2.07,11.65,11.65,0,0,0,2.28,1.16,15.78,15.78,0,0,0,2.45.65,7,7,0,0,0,1.3.07c1,0,2.4-.44,2.49-1.71a1.93,1.93,0,0,0-.2-1C64,61,61.33,60.55,60.1,60.34A17,17,0,0,0,57.16,60.13Zm-16,4.68c-.47.75-.91,1.44-1.33,2-1,1.48-2.2,3.25-3.9,3.91a3,3,0,0,1-1.2.22c-.4,0-.79-.21-.77-.69a2,2,0,0,1,.3-.89,5,5,0,0,1,.7-1,11.3,11.3,0,0,1,2.08-1.79,24.17,24.17,0,0,1,4.4-2.33C41.36,64.49,41.27,64.65,41.18,64.81ZM47,45.76a9.07,9.07,0,0,1-.07-2.38,6.73,6.73,0,0,1,.22-1.12c.1-.3.29-1,.61-1.13.51-.15.67,1,.73,1.36a15.91,15.91,0,0,1-.36,5.87c-.06.3-.13.59-.21.88-.12-.36-.24-.73-.35-1.09A19.24,19.24,0,0,1,47,45.76Zm3.55,15A46.66,46.66,0,0,0,45,62a14.87,14.87,0,0,0,1.38-2.39,29.68,29.68,0,0,0,2.42-5.92,28.69,28.69,0,0,0,3.87,6.15l.43.51C52.22,60.48,51.36,60.6,50.52,60.74Zm13.15,2.64c-.07.41-.89.65-1.27.71A6.84,6.84,0,0,1,59,63.74a10,10,0,0,1-2.15-1.06,12.35,12.35,0,0,1-1.9-1.51c.73,0,1.47-.07,2.21-.06a18.42,18.42,0,0,1,2.23.15,7.6,7.6,0,0,1,4,1.63C63.63,63.07,63.7,63.24,63.67,63.38Z"
                fill="#6576ff"
              />
            </svg>
          );
          break;
      }

      li.push({
        id: i,
        type,
        icon,
        name,
        file: e,
      });
    }
    return li;
  }

  // useEffect(() => {

  // }, [userDetails]);

  const toggleAssignAgentModal = () =>
  {
      setAssignAgentModal( !assignAgentModal );
  };

  return (
    <Box sx={{ width: 1 }}>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4"> Documents</BlockTitle>
            <BlockDes>
              <p>Documents uploaded by user</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className=" d-sm-inline-flex" style={{gap:"10px", justifyContent: "space-between" }}>
            {userDetails?.user_register_type==='Dealer' && !userDetails?.assignedRM &&
              <Button
                className="bg-success text-white"
                onClick={(ev) => {
                  ev.preventDefault();
                  toggleAssignAgentModal();
                }}
              >
                {"Assign RM"}
              </Button>
              }
              {userDetails?.assignedRM &&
                <Button
                className="bg-primary text-white"
                onClick={(ev) => {
                  ev.preventDefault();
                  toggleAssignAgentModal();
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span>{userDetails?.assignedRM?.full_name}</span>
                <Icon name="pen" style={{ marginLeft: '5px' }} />
              </Button>              
              }
                    {userDetails?.deletedCount>0&&
            <>
          <Badge
          className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
          color={ "danger"}
        >
         Deleted count: { userDetails?.deletedCount}
        </Badge>{"  "}
        </>
        
}
              {userDetails?.isActive &&userDetails?.is_verified=== UserVerificationStatus.APPROVED&&
              <Button
              className="bg-primary text-white"
              // disabled={userDetails?.user_register_type==='Dealer'&&!userDetails?.assignedRM}
              onClick={(ev) => {
                ev.preventDefault();
                // setVerifyLoading(true);
                // unVerifyUser();
                setRejectOpen(true);
              }}
            >
              {verifyLoading?
              <Spinner size="sm" color="light" />
              :
             "UnVerify"}
            </Button>
              }
              {"  "}
              {userDetails?.isActive &&userDetails?.is_verified=== UserVerificationStatus.REJECTED &&
           <span style={{marginRight:"8px"}}>Rejected</span>  
}
{userDetails?.is_blocked&&
          <Badge
          className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
          color={ "danger"}
        >
          { "Blocked"}
        </Badge>
}

{userDetails?.isDeleted&&
          <Badge
          className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
          color={ "danger"}
        >
          { "Deleted"}
        </Badge>
}


{userDetails?.isActive && userDetails?.is_verified=== UserVerificationStatus.PENDING&&
      <>
       <div className=" d-sm-inline-flex" style={{gap:"10px"}}>
              <Button
                className="bg-success text-white"
                disabled={userDetails?.user_register_type==='Dealer'&&!userDetails?.assignedRM}
                onClick={(ev) => {
                  ev.preventDefault();
                  // setVerifyLoading(true);
                  setOpen(true);
                  // verifyUser();
                }}
              >
                {verifyLoading?
                <Spinner size="sm" color="light" />
                :
               "Confirm"}
              </Button>
              {"   "}
              <Button
                className="bg-primary text-white"
                disabled={userDetails?.user_register_type==='Dealer'&&!userDetails?.assignedRM}
                onClick={(ev) => {
                  ev.preventDefault();
                  // setVerifyLoading(true);
                  // verifyUser();
                  setRejectOpen(true);
                }}
              >
                {verifyLoading?
                <Spinner size="sm" color="light" />
                :
               "Reject"}
              </Button>

              </div>
              </>
}
{userDetails?.isActive&&
        <Button className="bg-primary text-white"
              // disabled={userDetails?.user_register_type==='Dealer'&&!userDetails?.assignedRM}
              onClick={(ev) => {
                ev.preventDefault();
                // setRejectOpen(true);
                setShowModal(true);
                // setVerifyLoading(true);
                // unVerifyUser();
              }}
            >
              {verifyLoading?
              <Spinner size="sm" color="light" />
              :
             "Block"}
            </Button>
}
              </div>
            </BlockHeadContent>

          {/* <BlockHeadContent className="align-self-start d-lg-none">
            <Button className={`toggle btn btn-icon btn-trigger mt-n1  active`} onClick={() => {}}>
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent> */}
        </BlockBetween>
      </BlockHead>
      {/* <div>No documents found</div> */}
      <Block>
        <BlockHead size="xs">
          <BlockBetween className="g-2">
            <BlockHeadContent>
              <BlockTitle tag="h6">Emirates ID </BlockTitle>
              {userDetails?.documents?.id_card?.expiry_date &&
              <span>Expiry: {new Date(userDetails?.documents?.id_card?.expiry_date).toDateString()}</span>}
              {userDetails?.eidNumber&&
                            <p>Emirates ID Number: {userDetails?.eidNumber}</p>
              }
            </BlockHeadContent>
            {/* <BlockHeadContent>
              <a href="#toggle" onClick={(ev) => {}} className={`"link link-primary toggle-opt active`}>
                <div className="active-text">Add</div>
              </a>
            </BlockHeadContent> */}
          </BlockBetween>
        </BlockHead>
        <div
          className="nk-files nk-files-view-grid"
          style={{ backgroundColor: "aliceblue", borderRadius: 4, marginTop: 16, marginBottom: 16 }}
        >
          <div className="nk-files-list" style={{ padding: 10, paddingLeft: 20 }}>
            {(idCardFront&& !idCardFrontPdf)?
            <img 
            onClick={() => {
              setFullViewimage(idCardFront);
              toggleFull();
            }}
            height="200px" src={idCardFront}/>
            :(idCardFront && idCardFrontPdf)? (
              <div>
              <a href={idCardFront} target="_blank" rel="noopener noreferrer">
                Show Emirates ID Front
              </a>
            </div>
            )
            : (
              <div>No documents found</div>
            )}
                {(idCardBack &&!idCardBackPdf)?
            <img 
            onClick={() => {
              setFullViewimage(idCardBack);
              toggleFull();
            }}
            height="200px" src={idCardBack} style={{ marginLeft: 20 }}/>
            :(idCardBack && idCardBackPdf)? (
              <div style={{ marginLeft: 20 }}>      
              <a href={idCardBack} target="_blank" rel="noopener noreferrer">
              Show Emirates ID Back
              </a>
            </div>
            )
             
            : (
              <div></div>
            )}
          </div>
        </div>
      </Block>
      <Block>
        <BlockHead size="xs">
          <BlockBetween className="g-2">
            <BlockHeadContent>
              <BlockTitle tag="h6">Passport</BlockTitle>
              {userDetails?.documents?.passport?.expiry_date &&
              <span>Expiry:{new Date(userDetails?.documents?.passport?.expiry_date).toDateString()}</span>
              }
              {userDetails?.passportNumber&&
                   <p>Passport Number: {userDetails?.passportNumber}</p>
              }
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div
          className="nk-files nk-files-view-grid"
          style={{ backgroundColor: "aliceblue", borderRadius: 4, marginTop: 16, marginBottom: 16 }}
        >
          <div className="nk-files-list" style={{ padding: 10, paddingLeft: 20 }}>
            {(passportFront && !passportFrontPdf)?
            <img 
               onClick={() => {
              setFullViewimage(passportFront);
              toggleFull();
            }}
            
            height="200px" src={passportFront}/>
            : (passportFront && passportFrontPdf)? (
              <div>
              <a href={passportFront} target="_blank" rel="noopener noreferrer">
                Show Passport
              </a>
            </div>
            )
            : (
              <div>No documents found</div>
            )}
                  {passportBack?
            <img
            onClick={() => {
              setFullViewimage(passportBack);
              toggleFull();
            }}
             height="200px" src={passportBack} style={{ marginLeft: 20 }}/>
            : (
              <div></div>
            )}
          </div>
        </div>
      </Block>
      <Block>
        <BlockHead size="xs">
          <BlockBetween className="g-2">
            <BlockHeadContent>
              <BlockTitle tag="h6">Selfie</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div
          className="nk-files nk-files-view-grid"
          style={{ backgroundColor: "aliceblue", borderRadius: 4, marginTop: 16, marginBottom: 16 }}
        >
          <div className="nk-files-list" style={{ padding: 10, paddingLeft: 20 }}>
            {selfie?
            <img height="200px" src={selfie}/>
            : (
              <div>No documents found</div>
            )}
          </div>
        </div>
      </Block>
      <Block>
        <BlockHead size="xs">
          <BlockBetween className="g-2">
            <BlockHeadContent>
              <BlockTitle tag="h6">Trade License</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div
          className="nk-files nk-files-view-grid"
          style={{ backgroundColor: "aliceblue", borderRadius: 4, marginTop: 16, marginBottom: 16 }}
        >
          <div className="nk-files-list" style={{ padding: 10, paddingLeft: 20 }}>
            {tradeLisc&&!tradeLiscPdf?
            <img height="200px" src={tradeLisc}/>
            :(tradeLisc && tradeLiscPdf)? (
              <div>
              <a href={tradeLisc} target="_blank" rel="noopener noreferrer">
                Show PDF
              </a>
            </div>
            )
            : (
              <div>No documents found</div>
            )}
          </div>
        </div>
      </Block>
      <Modal
                        size="lg"
                        isOpen={ assignAgentModal }
                        toggle={ toggleAssignAgentModal }
                    >
                        <AssignRM
                            toggle={ toggleAssignAgentModal }
                            user={ userDetails }
                        />
                    </Modal>

                    <VerifyModal isOpen={open} toggle={toggle} userId={userDetails?.id}/>
                    <RejectModal isOpen={rejectOpen} toggle={rejectToggle} userId={userDetails?.id}/>
                              {/* ****** Modal Start ****** */}
          {showModal ? (                   
            <ConfirmModal
              msg={"block this user?"}
              name={""}
              action="Block"
              open={showModal}
              loading={blockLoading}
              handleClick={handleModal}
              handleConfirm={handleBlock}
              handlecloseModal={closeModal}
            />
          ) : null}
          {/* ****** Modal End ****** */}
          {isOpenFull && (
        <Modal modalClassName="modallikkkk" isOpen={isOpenFull} toggle={toggleFull} size="xl">
          <div style={{ width: "100%", height: "100%", objectPosition: "center" }}>
            <img src={fullViewImage} style={{ objectFit: "fill", height: "100%", width: "100%" }} />
          </div>
        </Modal>
      )}
      {/* <Block>
        <BlockHead size="xs">
          <BlockBetween className="g-2">
            <BlockHeadContent>
              <BlockTitle tag="h6">RTA Passing Reports</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <a href="#toggle" onClick={(ev) => {}} className={`"link link-primary toggle-opt active`}>
                <div className="active-text">Add</div>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div
          className="nk-files nk-files-view-grid"
          style={{
            backgroundColor: "aliceblue",
            borderRadius: 4,
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <div className="nk-files-list" style={{ padding: 10, paddingLeft: 20 }}>
            {rtaReportFiles?.length > 0 ? (
              rtaReportFiles.map((file) => (
                <div className="nk-file-item nk-file" key={file.id}>
                  <div className="nk-file-info">
                    <a className="nk-file-link" href="#link" onClick={() => downloadFile(file.file, file.name)}>
                      <div className="nk-file-title">
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">{file.icon}</span>
                        </div>
                        <div className="nk-file-name">
                          <div className="nk-file-name-text">
                            <span
                              className="title"
                              style={{
                                textOverflow: "ellipsis",
                                maxLines: 2,
                              }}
                            >
                              {file.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="nk-file-actions hideable">
                    <a
                      href="#folder"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn btn-sm btn-icon btn-trigger"
                    >
                      <Icon name="cross"></Icon>
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <div>No documents found</div>
            )}
          </div>
        </div>
      </Block> */}
    </Box>
  );
}