import React, { useEffect, useState } from 'react';
import { getPdf } from '../../utils/uploadImageToS3';

const Invoice = ({ item }) => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    async function fun() {
      if (item?.invoice_url) {
        let pdfUrl = item?.invoice_url
          ? await getPdf(item?.invoice_url)
          : null;
        setUrl(pdfUrl);
      }
    }
    fun();
  }, [item]);
  return (
    <div key={item?.id}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {item?.invoice_number}
      </a>
    </div>
  );
};

export default Invoice;
