import React from "react";
import { Controller } from "react-hook-form";
import { RSelect } from "../Component";

export default function FormMultiSelect({ name, control, label, options, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div style={{ width: "100%" }}>
          <label>
            {label}
          </label>
          <RSelect
            options={options.map((e) => ({ label: e, value: e }))}
            value={{ label: field.value, value: field.value }}
            onChange={(e) => field.onChange(e.value)}
            style={{ width: "100%" }}
            {...props}
          />
          {error && <div className="text-red-500 my-1">{error.message}</div>}
        </div>
      )}
    />
  );
}
