import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  totalCount: 0,
  perPage:10,
  loading: false,
  error: null,
};
const fetchAuctions = createAsyncThunk("auctions", (data) => {
  let url = `/admin/auction/list`;

  // if (data?.searchText) {
  //   url = url.concat(`&searchText=${data.searchText}`);
  // }
  // if (data?.sortBy) {
  //   url = url.concat(`&sortBy=${JSON.stringify(data.sortBy)}`);
  // }
  // if (data?.dateFilter) {
  //   url = url.concat(`&dateFilter=${JSON.stringify(data.dateFilter)}`);
  // }
  return axios.post(url,data).then((response) => {
    return response.data;
  });
});
const clearAuctions= createAsyncThunk('clearAuctions', async () => {
  return null;
});
const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const { index, value } = action.payload;
      // console.log(index);
      let data = state.data;
      data[index].check = value;
      state.data = data;
    },
    setAllSelected: (state, action) => {
      const { value } = action.payload;
      // console.log(value);
      let data = state.data;
      state.data = data.map((e) => {
        e.check = value;
        return e;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuctions.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchAuctions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchAuctions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(clearAuctions.fulfilled, (state) => {
      state.data = {};
    });
  },
});

const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchAuctions({ ...arg })), 500);
const debouncedFetchAuctions =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export default auctionsSlice.reducer;
export const { setSelected, setAllSelected } = auctionsSlice.actions;
export { fetchAuctions, debouncedFetchAuctions,clearAuctions };
