import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  LoadScript,
  GoogleMap,
  Autocomplete,
  MarkerF
} from '@react-google-maps/api';

const libraries = ['places']; // Include the places library for autocomplete

export default function FormLocationField({ control, name, label, ...props }) {
  // const [position, setPosition] = useState({ lat: 51.505, lng: -0.09 }); // Initial default position (London)
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autoC) => {
    setAutocomplete(autoC); // Set the autocomplete instance when it loads
  };



  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div style={{ width: '100%' }}>
            <label>
              {label}{' '}
              {props?.required && <span className="required-label">*</span>}
            </label>

            {/* Google Places Autocomplete */}
            <LoadScript
              googleMapsApiKey="AIzaSyDgleA7tIk8lVcxr_jZ0-rIUWVXhoVPtzg"
              libraries={libraries}
            >
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={() => {
                  if (autocomplete !== null) {
                    const place = autocomplete.getPlace();
                    if (place.geometry) {
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      field.onChange({
                        type: 'Point',
                        coordinates: [lng,lat]
                      });
                    }
                  }
                }}
              >
                <input
                  type="text"
                  placeholder="Search places"
                  className="form-control mb-3"
                />
              </Autocomplete>

              {/* Google Map */}
              <div
                style={{ height: '400px', width: '100%', marginBottom: '10px' }}
              >
                <GoogleMap
                
                  center={field.value?.coordinates?{lat:field.value.coordinates?.[1],lng:field.value.coordinates?.[0]}: {lat:55.2707828,lng:25.2048493}}
                  zoom={10}
                  mapContainerStyle={{ height: '100%', width: '100%' }}
                  onClick={(e) => {
                    if (e.latLng) {
                      field.onChange({
                        type: 'Point',
                        coordinates: [e.latLng.lng(),e.latLng.lat()]
                      });
                    }
                   
                  }} // Handle click on map to get coordinates
                >
                  {field.value?.coordinates && <MarkerF position={{lat:field.value.coordinates?.[1],lng:field.value.coordinates?.[0]}} />}
                </GoogleMap>
              </div>
            </LoadScript>

            {/* Coordinates Input */}
            <input
              type="text"
              {...field}
              value={`${field.value?.coordinates?.[1] ?? ''}, ${field.value?.coordinates?.[0] ?? ''}`}
              readOnly
              className={`form-control ${error ? 'input-error' : ''}`}
              style={{ width: '100%' }}
            />
            {error && <div className="text-red-500 my-1">{error.message}</div>}
          </div>
        );
      }}
    />
  );
}
