import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  totalCount: 0,
  perPage:10,
  loading: false,
  error: null,
};
const fetchConciergeCars = createAsyncThunk("items", (data) => {
  let url = `/admin/api/v1/concierge-booking/list`;
  return axios.post(url,data).then((response) => {
    return response.data["data"];
  });
});
const clearItems= createAsyncThunk('clearItems', async () => {
  return null;
});
const itemsSlice = createSlice({
  name: "conciergeItems",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const { index, value } = action.payload;
      // console.log(index);
      let data = state.data;
      data[index].check = value;
      state.data = data;
    },
    setAllSelected: (state, action) => {
      const { value } = action.payload;
      // console.log(value);
      let data = state.data;
      state.data = data.map((e) => {
        e.check = value;
        return e;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConciergeCars.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchConciergeCars.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data;
      state.totalCount = action.payload?.paginator?.itemCount;
      state.perPage = action.payload?.paginator?.perPage;
    });
    builder.addCase(fetchConciergeCars.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(clearItems.fulfilled, (state) => {
      state.data = {};
    });
  },
});

const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchConciergeCars({ ...arg })), 500);
const debouncedFetchConciergeCars =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export default itemsSlice.reducer;
export const { setSelected, setAllSelected } = itemsSlice.actions;
export { fetchConciergeCars, debouncedFetchConciergeCars,clearItems };
