import React from "react";
import Head from "../../../layout/head/Head";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from "../../../components/Component";
import { Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FieldMapper from "./FieldMapper";

export default function BasicForms() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useSelector((state) => state.verticalDetails);

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Vertical Basic Form</BlockTitle>
            <BlockDes>
              <p>Form required to add an item</p>
            </BlockDes>
            Note: Please add one image field, one field for title and 4 fields to show data in the item card for app
          </BlockHeadContent>
          {data?.baseformsettings && (
            <BlockHeadContent className="align-self-start">
              <Button
                onClick={() => {
                  navigate(`/verticals/${id}/create_form?formId=${data?.baseformsettings.id}&formType=Basic`);
                }}
              >
                Edit
              </Button>
            </BlockHeadContent>
          )}
        </BlockBetween>
      </BlockHead>

      <Block>
        {!data?.baseformsettings ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>No Basic Form Found</div>
            <Button
              color="primary"
              style={{ margin: 10 }}
              onClick={() => navigate(`/verticals/${id}/create_form?formType=Basic`)}
            >
              Create
            </Button>
          </div>
        ) : (
          <FieldMapper values={data?.baseformsettings?.tabs} />
        )}
      </Block>
    </React.Fragment>
  );
}
