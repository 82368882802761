import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import uuid from 'react-uuid';
import { uploadToS3 } from '../../../utils/uploadImageToS3';
import { resetUpdateStatus } from '../usersSlice/userDetails';

// Async thunk for fetching dealer details (replace with actual API call)
export const fetchDealerDetails = createAsyncThunk(
  'dealer/fetchDealerDetails',
  (data,) => {
    const dealerId = data;
    return axios.get(`/admin/companies/${dealerId}`).then((e) => {
      return e.data;
    });
  }
);

export const createFatoorahVendor = createAsyncThunk(
    'dealer/createFatoorahVendor',
    (data,) => {
      const dealerId = data;
      return axios.post(`/admin/companies/${dealerId}/create-fatoorah-vendor`).then((e) => {
        return e.data;
      });
    }
  );

// Async thunk for updating dealer details (replace with actual API call)
export const updateDealerDetails = createAsyncThunk(
  'dealer/updateDealerDetails',
  async (payload) => {
    const {dealerId,data} = payload;
    const logo = data?.logo;
    if (logo && logo instanceof File) {
      const fileType = logo.name.split('.').pop();
      const filePath = `dealer_public/${dealerId}/${uuid()}.${fileType}`;
      await uploadToS3(logo, filePath, logo.type);
      data.logo = filePath;
    }
    const hero_image = data?.hero_image;
    if (hero_image && hero_image instanceof File) {
      const fileType = hero_image.name.split('.').pop();
      const filePath = `dealer_public/${dealerId}/${uuid()}.${fileType}`;
      await uploadToS3(hero_image, filePath, hero_image.type);
      data.hero_image = filePath;
    }
    if (data.documents && typeof data.documents === 'object') {
      for (let key in data.documents) {
        const file = data.documents[key]; // Each document file is a property in the documents object

        if (file && file instanceof File) {
          const fileType = file.name.split('.').pop();
          const filePath = `dealer/${dealerId}/${uuid()}.${fileType}`;

          // Upload the file to S3
          await uploadToS3(file, filePath, file.type);

          // Update the document field with the S3 file path
          data.documents[key] = filePath;
        }
      }
    }

    const response = await axios.patch(
      `/admin/companies/${dealerId}`,
      data
    );
    return response.data;
  }
);

const initialState = {
  data: null,
  status: 'idle', // For API status tracking (loading, succeeded, etc.)
  updateStatus: 'idle', // For API status tracking (loading, succeeded, etc.)
  error: null
};

const dealerSlice = createSlice({
  name: 'dealer',
  initialState,
  reducers: {
    resetDealerUpdateStatus(state) {
      state.updateStatus = 'init';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealerDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDealerDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add dealer details to the state
        state.data = action.payload.data;
      })
      .addCase(fetchDealerDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateDealerDetails.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateDealerDetails.fulfilled, (state, action) => {
        state.updateStatus = 'success';
        // Update dealer details
        state.data = action.payload.data;
      })
      .addCase(updateDealerDetails.rejected, (state, action) => {
        state.updateStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(createFatoorahVendor.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(createFatoorahVendor.fulfilled, (state, action) => {
        state.updateStatus = 'success';
        // Update dealer details
        state.data = action.payload.data;
      })
      .addCase(createFatoorahVendor.rejected, (state, action) => {
        state.updateStatus = 'error';
        state.error = action.error.message;
      });
  }
});

export const { resetDealerUpdateStatus } = dealerSlice.actions;

export const dealerSettingsReducer = dealerSlice.reducer;
