import React, { Suspense, useLayoutEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from '../pages/Homepage';
import UsersData from '../pages/users/users';
import AuctionsData from '../pages/auctions/auctions';
import CarsData from '../pages/cars/cars';
import AuctionDetails from '../pages/auctions/AuctionDetails';
import VouchersData from '../pages/vouchers/vouchers';
import ServicesData from '../pages/services/services';
import CarDetails from '../pages/cars/CarDetails';
import ServiceProvidersData from '../pages/services/serviceProviders';
import UserDetails from '../pages/users/UserDetails';
import UnlockUsers from '../pages/approvals/UnlockUser';
import AuctioneerRequests from '../pages/approvals/AuctioneerRequest';
import ActivateUser from '../pages/approvals/ActivateUser';
import CarInspections from '../pages/approvals/CarInspection';
import UserProfileRegularPage from '../pages/users/components/UserProfileRegularPage';
import UserRelationshipManagerPage from '../pages/users/components/UserRelationshipManagerPage';
import UserApprovalsPage from '../pages/users/components/UserApprovalsPage';
import UserProfileSettingPage from '../pages/users/components/UserProfileSettingPage';
import VerticalsData from '../pages/verticals/Verticals';
import CreateVertical from '../pages/verticals/CreateVertical';
import BasicDetails from '../pages/verticals/components/BasicDetails';
import BasicForms from '../pages/verticals/components/BasicForms';
import InspectionForms from '../pages/verticals/components/SimplifiedInspectionForms';
import Settings from '../pages/verticals/components/Settings';
import VerticalDetails from '../pages/verticals/VerticalDetails';
import CreateForms from '../pages/verticals/CreateForms';
import SimplifiedInspectionForms from '../pages/verticals/components/SimplifiedInspectionForms';
import AdvancedInspectionForms from '../pages/verticals/components/AdvancedInspectionForms';

import Concierge from '../pages/auctions/Concierge';
import AllAuctions from '../pages/auctions/AllAuctions';
import AllItems from '../pages/items/AllItems';
import Payments from '../pages/payments/Payments';
import Leads from '../pages/users/Leads';
import LeadsData from '../pages/users/Leads';
import AutoCompleteListSecondary from '../pages/verticals/components/AutoCompleteListSecondary';
import NoBidsAuctions from '../pages/auctions/NoBidsAuctions';
import RejectedAuctions from '../pages/auctions/RejectedAuctions';
import StaffData from '../pages/users/staff';
import Reviews from '../pages/users/Reviews';
import ConfirmedAuctions from '../pages/auctions/ConfirmedAuctions';
import PushNotificationData from '../pages/pushNotification/pushNotification';
import SellerRejectedAuctions from '../pages/auctions/SellerRejectedAuctions';
import FreeAuctions from '../pages/freeAuctions/freeAuction';
import Shipments from '../pages/shipments/Shipments';
import Flags from '../pages/flags/flags';
import DealersData from '../pages/dealers/dealers';
import AddDealerForm from '../pages/dealers/addDealerForm';
import OrdersListPage from '../pages/orders/OrdersList';
import OrderDetailsPage from '../pages/orders/OrderDetailsPage';
import RefundRequests from '../pages/payments/refund_requests';
import DealerReservations from '../pages/payments/dealer_reservations';
import SettingsPage from '../pages/settings/Settings';
import AutoCompleteList from '../pages/settings/MakesSettings';
import DealerDetails from '../pages/dealers/DealerDetails';

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path={`/dashboard`} element={<Homepage />}></Route>
        <Route path={`/leads`} element={<LeadsData />}></Route>
        <Route path={`/users`} element={<UsersData />}></Route>
        <Route path={`/dealers`} element={<DealersData />}></Route>
        <Route path={`/dealers/:id`} element={<DealerDetails />}></Route>
        <Route path={`/add-dealer`} element={<AddDealerForm />}></Route>
        <Route path={`/staff`} element={<StaffData />}></Route>
        <Route path={`/reviews`} element={<Reviews />}></Route>
        <Route path={`/shipments`} element={<Shipments />}></Route>
        <Route path={`/orders`} element={<OrdersListPage />}></Route>
        <Route path={`/orders/:orderId`} element={<OrderDetailsPage />}></Route>
        <Route path={`/settings`} element={<SettingsPage />}></Route>
        <Route path={`/make-settings`} element={<AutoCompleteList />}></Route>
        <Route path={`/flags`} element={<Flags />}>
          {' '}
        </Route>
        <Route path={`/users/:id/`} element={<UserDetails />}>
          {/* <Route path={`/users/:id/personalInformation`} element={<UserProfileRegularPage />}></Route> */}
          {/* <Route path={`/user-profile/:id/`} element={<UserProfileLayout/>}></Route> */}

          {/* <Route path={`/users/:id/relationshipManager`} element={<UserRelationshipManagerPage />} /> */}
          {/* <Route path={`/users/:id/approvals`} element={<UserApprovalsPage />} /> */}
          {/* <Route path={`/users/:id/documents`} element={<UserApprovalsPage />} /> */}
          {/* <Route path={`/users/:id/checks`} element={<UserApprovalsPage />} />
          <Route path={`/users/:id/settings`} element={<UserProfileSettingPage />} /> */}
        </Route>
        <Route
          path={`/pushNotification`}
          element={<PushNotificationData />}
        ></Route>
        <Route path={`/freeAuctions`} element={<FreeAuctions />}></Route>
        <Route path={`/verticals`} element={<VerticalsData />}></Route>
        <Route path={`/verticals/:id/`} element={<VerticalDetails />}>
          <Route
            path={`/verticals/:id/details`}
          // element={<BasicDetails />}
          ></Route>
          <Route path={`/verticals/:id/basic_forms`}
          // element={<BasicForms />} 
          />
          <Route
            path={`/verticals/:id/simple_inspection_forms`}
          // element={<SimplifiedInspectionForms />}
          />
          <Route
            path={`/verticals/:id/advanced_inspection_forms`}
          // element={<AdvancedInspectionForms />}
          />

          <Route
            path={`/verticals/:id/autocomplete_list_secondary`}
          // element={<AutoCompleteListSecondary />}
          />

          <Route path={`/verticals/:id/settings`}
          // element={<Settings />} 
          />
          <Route
            path={`/verticals/:id/auto-repost-auction-setting`}
          // element={<></>}
          />
        </Route>
        <Route path={`/verticals/:id/create_form`} element={<CreateForms />} />
        <Route path={`/createVertical`} element={<CreateVertical />}></Route>
        <Route path={`/auctions`} element={<AllAuctions />}></Route>
        <Route path={`/items`} element={<AllItems />}></Route>
        <Route path={`/payments`} element={<Payments />}></Route>
           <Route path={`/refund`} element={<RefundRequests />}></Route>
              <Route path={`/reservation`} element={<DealerReservations />}></Route>
        <Route path={`/nobids-auctions`} element={<NoBidsAuctions />}></Route>
        <Route
          path={`/rejected-auctions`}
          element={<RejectedAuctions />}
        ></Route>
        <Route
          path={`/seller-rejected-auctions`}
          element={<SellerRejectedAuctions />}
        ></Route>
        <Route
          path={`/confirmed-auctions`}
          element={<ConfirmedAuctions />}
        ></Route>
        <Route path={`/concierge`} element={<Concierge />}></Route>

        <Route
          path={`/v/:verticalName/auctions`}
          element={<AuctionsData />}
        ></Route>
        <Route
          path={`/v/:verticalName/auctions/:id`}
          element={<AuctionDetails />}
        ></Route>
        <Route
          path={`/v/:verticalName/auctions/:id/cardetails/:itemId`}
          element={<CarDetails />}
        ></Route>
        <Route path={`/v/:verticalName/items`} element={<CarsData />}></Route>
        <Route
          path={`/v/:verticalName/items/:ItemId`}
          element={<CarDetails />}
        ></Route>
        <Route
          path={`/v/:verticalName/vouchers`}
          element={<VouchersData />}
        ></Route>
        <Route
          path={`/v/:verticalName/services`}
          element={<ServicesData />}
        ></Route>
        <Route
          path={`/v/:verticalName/serviceProviders`}
          element={<ServiceProvidersData />}
        ></Route>
        <Route path={`/unlockUsers`} element={<UnlockUsers />}></Route>
        <Route
          path={`/auctioneerRequests`}
          element={<AuctioneerRequests />}
        ></Route>
        <Route path={`/activateUsers`} element={<ActivateUser />}></Route>
        <Route path={`/carInspections`} element={<CarInspections />}></Route>
      </Routes>
    </Suspense>
  );
};
export default Pages;
