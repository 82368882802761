import React, { useEffect, useState } from 'react';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
  Block,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
  RSelect
} from '../../components/Component';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,
  Spinner,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  clearItems,
  setAllSelected,
  setSelected
} from '../../redux/features/itemsSlice/itemsSlice';
import { startCase } from 'lodash';
import { getFlagStatusColor } from '../../utils/getColor';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import TableHeader from '../../components/table/TableHeader';
import ListBlockHeader from '../../components/list-block-header/ListBlockHeader';
import ReactDatePicker from 'react-datepicker';
import {
  fetchFlags,
  getFlag,
  updateFlag
} from '../../redux/features/flagsSlice/flagSlice';
import ConfirmModal from '../verticals/components/ConfirmModal';
import { flagType, flagStatus } from '../../utils/Utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Flags = () => {
  const navigate = useNavigate();

  const [listData, setListData] = useState([]);
  const [view, setView] = useState({
    add: false,
    details: false
  });
  const [onSearchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState('');
  const [sortByField, setSortByField] = useState('createdAt');
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);

  const user = useSelector((state) => state.userDetails.data);
  // const verticals = useSelector((state) => state.verticals);

  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  // const verticalItemGet = localStorage.getItem('verticalItemGetValue') || '';
  const { data, totalCount, loading, perPage } = useSelector(
    (state) => state.flags
  );
  const [itemPerPage] = useState(perPage);
  const dispatch = useDispatch();
  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [flag, setFlag] = useState(null);

  //Edit
  const [edit, setEdit] = useState(false);
  const [editType, setEditType] = useState(null);
  const [editStatus, setEditStatus] = useState(null);
  const [editReason, setEditReason] = useState('');
  const [editNote, setEditNote] = useState('');

  //Display
  const [display, setDisplay] = useState(false);
  const [viewType, setViewType] = useState(null);
  const [viewStatus, setViewStatus] = useState(null);
  const [viewReason, setViewReason] = useState('');
  const [viewNote, setViewNote] = useState('');

  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({
        startDate: rangeStart.toISOString(),
        endDate: rangeEnd.toISOString()
      });
    }
  }, [rangeEnd, rangeStart]);
  const setRangeStart = (e) => {
    setStart(e);
  };
  const setRangeEnd = (e) => {
    setEnd(e);
  };

  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({
        startDate: rangeStart.toISOString(),
        endDate: rangeEnd.toISOString()
      });
    }
  }, [rangeEnd, rangeStart]);

  // Changing state value when searching name
  useEffect(() => {
    let data = {
      query: {},
      options: {
        page: currentPage,
        limit: perPage,
        sort: sortByValue === 1 ? sortByField : '-' + sortByField,
        populate: [
          {
            path: 'user',
            select: 'full_name'
          }
        ],
        if(dateFilter) {
          data.query.date = dateFilter;
        }
      }

      //  page: currentPage, status, sortBy: { [sortByField]: sortByValue }
    };

    if (dateFilter) {
      data.query.date = dateFilter;
    }
    if (onSearchText !== '') {
      data.query.search = onSearchText.trim();
      dispatch(fetchFlags(data));
    } else {
      dispatch(fetchFlags(data));
    }
  }, [onSearchText.trim(), currentPage, status, sortByValue, dateFilter, user]);

  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  useEffect(() => {
    if (data) {
      setListData(data?.data);
    }
  }, [data]);

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let clearBadge = status || onSearchText !== '' || rangeEnd || rangeStart;

  const toggleEditModal = () => {
    setEdit(!edit);
  };

  const toggleViewModal = () => {
    setDisplay(!display);
  };

  const handleDelete = () => {
    closeModal();
    navigate(0);
  };
  const closeModal = () => {
    setShowModal(false);
    setFlag(null);
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const editFlag = async (e) => {
    e.preventDefault();
    if (!editReason.trim()) {
      toast.error('Enter valid Reason');
      return;
    }

    const id = flag?.id;

    const formData = {
      user_id: flag?.user?.id,
      Type: editType?.value,
      Status: editStatus?.value,
      Reason: editReason,
      Notes: editNote
    };

    try {
      setLoadingFlag(true);
      dispatch(updateFlag({ id, data: formData }));
      setEditType(null);
      setEditStatus(null);
      setEditReason('');
      setEditNote('');
      setFlag(null);
      setEdit(false);
      setLoadingFlag(false);
      window.location.reload();
    } catch (err) {
      console.log(err);
      // toast.error('Server error. Try Again !');
    }
  };

  return (
    <React.Fragment>
      <Head title={`Flags`}></Head>
      <Content>
        <ListBlockHeader
          msg={totalCount ? `Total items:  ${totalCount}` : ''}
          title="All Flags"
          placeholder="Type / Status / Reason"
          onSearchText={onSearchText}
          onSearchChanged={onFilterChange}
          onDateChanged={setDateFilter}
        >
          {!user?.id && (
            <div style={{ flexShrink: 0, flexGrow: 1 }}>
              <div className="form-control-wrap">
                <div className="input-daterange date-picker-range input-group">
                  <ReactDatePicker
                    selected={rangeStart}
                    onChange={setRangeStart}
                    selectsStart
                    startDate={rangeStart}
                    endDate={rangeEnd}
                    maxDate={rangeEnd ?? new Date()}
                    wrapperClassName="start-m"
                    className="form-control"
                  />{' '}
                  <div className="input-group-addon">TO</div>
                  <ReactDatePicker
                    selected={rangeEnd}
                    onChange={setRangeEnd}
                    startDate={rangeStart}
                    endDate={rangeEnd}
                    selectsEnd
                    minDate={rangeStart}
                    maxDate={new Date()}
                    wrapperClassName="end-m"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
        </ListBlockHeader>

        <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
          {clearBadge && (
            <Badge className="selected-option bg-primary me-1" color="danger">
              {'Clear All'}
              <Icon
                name="cross-sm"
                className="ms-1"
                onClick={() => {
                  setSearchText('');
                  setRangeEnd(null);
                  setRangeStart(null);
                  setDateFilter(null);
                  setStatus('');
                  // setVertical('');
                }}
              />
            </Badge>
          )}
        </Col>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="pid-all"
                  ></label>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>User</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <TableHeader
                  name={'Type'}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
                {/* <span>Type</span> */}
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Reason</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>Created At</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <TableHeader
                  name={'Status'}
                  setSort={setSort}
                  sortByField={sortByField}
                  sortByValue={sortByValue}
                />
                {/* <span>Status</span> */}
              </DataTableRow>
              <DataTableRow size="sm"></DataTableRow>
            </DataTableHead>

            {listData?.length > 0
              ? listData.map((item, index) => (
                  <DataTableItem key={item.id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked={item.check}
                          id={item.id + 'oId-all'}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, index)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={item.id + 'oId-all'}
                        ></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <Link to={`/users/${item?.user?.id}/`}>
                        {item?.user?.full_name}
                      </Link>
                    </DataTableRow>
                    <DataTableRow>{item?.Type}</DataTableRow>
                    <DataTableRow>{item?.Reason}</DataTableRow>
                    <DataTableRow size="md">
                      <span>
                        {item?.createdAt &&
                          new Date(item?.createdAt).toLocaleDateString(
                            undefined,
                            dateOptions
                          )}{' '}
                        {new Date(item?.createdAt).toLocaleTimeString(
                          undefined,
                          timeOptions
                        )}
                      </span>
                    </DataTableRow>
                    <DataTableRow>
                      <span
                        className={`dot bg-${getFlagStatusColor(
                          item?.Status
                        )} d-sm-none`}
                      ></span>
                      <Badge
                        className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                        color={getFlagStatusColor(item?.Status)}
                      >
                        {startCase(item?.Status)}
                      </Badge>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <UncontrolledDropdown
                        onClick={(ev) => ev.stopPropagation()}
                      >
                        <DropdownToggle
                          tag="a"
                          className="btn btn-icon dropdown-toggle btn-trigger"
                        >
                          <Icon name="more-h"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setFlag(item);
                                  setEditNote(item?.Notes);
                                  setEditType({
                                    label: item?.Type,
                                    value: item?.Type
                                  });
                                  setEditStatus({
                                    label: item?.Status,
                                    value: item?.Status
                                  });
                                  setEditReason(item?.Reason);
                                  toggleEditModal();
                                }}
                              >
                                <Icon name="pen2"></Icon>
                                <span>Edit</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setFlag(item);
                                  setViewType(item?.Type);
                                  setViewStatus(item?.Status);
                                  setViewReason(item?.Reason);
                                  setViewNote(item?.Notes);
                                  toggleViewModal();
                                }}
                              >
                                <Icon name="eye"></Icon>
                                <span>View</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </DataTableRow>
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {listData?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No flags found</span>
              </div>
            )}
          </PreviewAltCard>
          {/* ****** View Modal Start ****** */}
          <Modal
            size="md"
            isOpen={display}
            toggle={toggleViewModal}
            backdrop="static"
          >
            <ModalHeader toggle={toggleViewModal}>
              View Flag
              <button className="close" onClick={toggleViewModal}></button>
            </ModalHeader>
            <ModalBody>
              <form>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      User
                    </label>
                    <div className="form-control-wrap">
                      {flag?.user?.full_name}
                    </div>
                  </div>
                </Col>

                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Type
                    </label>
                    <div className="form-control-wrap">{viewType}</div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Status
                    </label>
                    <div className="form-control-wrap">{viewStatus}</div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Reason
                    </label>
                    <div className="form-control-wrap">{viewReason}</div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Notes
                    </label>
                    <div className="form-control-wrap">{viewNote}</div>
                  </div>
                </Col>
              </form>
            </ModalBody>
          </Modal>
          {/* ****** View Modal End ****** */}
          {/* ****** Edit Modal Start ****** */}
          <Modal
            size="md"
            isOpen={edit}
            toggle={toggleEditModal}
            backdrop="static"
          >
            <ModalHeader toggle={toggleEditModal}>
              Edit Flag
              <button className="close" onClick={toggleEditModal}></button>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => editFlag(e)}>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      User*
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="full-name"
                        className="form-control"
                        name="fullName"
                        required
                        value={flag?.user?.full_name}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Type
                    </label>
                    <div className="form-control-wrap">
                      <RSelect
                        isMulti={false}
                        value={editType}
                        onChange={(e) => setEditType(e)}
                        options={Object.keys(flagType).map((e) => ({
                          label: flagType[e],
                          value: e
                        }))}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Status
                    </label>
                    <div className="form-control-wrap">
                      <RSelect
                        isMulti={false}
                        value={editStatus}
                        onChange={(e) => setEditStatus(e)}
                        options={Object.keys(flagStatus).map((e) => ({
                          label: flagStatus[e],
                          value: e
                        }))}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Reason*
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="full-name"
                        className="form-control"
                        name="reason"
                        required
                        value={editReason}
                        onChange={(e) => setEditReason(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Notes
                    </label>
                    <div className="form-control-wrap">
                      <textarea
                        type="text"
                        id="notes"
                        className="form-control"
                        name="notes"
                        value={editNote}
                        onChange={(e) => setEditNote(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <Button type="submit" className="bg-primary text-white mt-2">
                    {loadingFlag ? (
                      <>
                        <Spinner size="sm" color="light" />
                        Please Wait
                      </>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Col>
              </form>
            </ModalBody>
          </Modal>
          {/* ****** Edit Modal End ****** */}
          {/* ****** Modal Start ****** */}
          {showModal ? (
            <ConfirmModal
              msg={'Update user flag?'}
              name={''}
              open={showModal}
              loading={loading}
              handleClick={handleModal}
              handleConfirm={handleDelete}
              handlecloseModal={closeModal}
            />
          ) : null}
          {/* ****** Modal End ****** */}
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Flags;
