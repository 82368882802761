import React from 'react';
import { useForm } from 'react-hook-form';
import FormMultiImageUpload from '../../../components/form/FormMultiImageUpload'; // The component you provided
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Stack,
  Typography
} from '@mui/material';
import { Image } from '../../../components/Component';
import FormSingleImageUpload from '../../../components/form/FormSingleImageUpload';

export default function DealerImagesForm({
  dealer,
  onSubmit,
  toggleEdit,
  editMode
}) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      logo: dealer?.logo,
      hero_image: dealer?.hero_image
    }
  });

  const handleFormSubmit = (data) => {
  
    if (editMode) {
      onSubmit(data);
      toggleEdit('images');  // or whatever section name is appropriate
    }
  };

  return (
      <Card sx={{ mb: 2 }}>
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <CardHeader title={'Images '} />
          <Box sx={{ pr: 2 }}>
            {editMode ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="button"
                onClick={handleSubmit(handleFormSubmit)}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                 type="button"
                onClick={() => toggleEdit('images')}
              >
                Edit
              </Button>
            )}
          </Box>
        </Stack>
        <CardContent>
          <Grid2 container spacing={2}>
            <Grid2 item size={6}>
              {editMode ? (
                <FormSingleImageUpload
                  name={'logo'}
                  label={'Logo'}
                  control={control}
                />
              ) : (
                <Box>
                  <Typography variant="subtitle1">Logo</Typography>
                  <Box width={150} height={150} bgcolor="black">
                    <Image image={dealer?.logo} />
                  </Box>
                </Box>
              )}
            </Grid2>
            <Grid2 item size={6}>
              {editMode ? (
                <FormSingleImageUpload
                  name={'hero_image'}
                  label={'Hero Section Image'}
                  control={control}
                />
              ) : (
                <Box>
                  <Typography variant="subtitle1">Hero Section Image</Typography>
                  <Box width={350} height={150} bgcolor="black">
                    <Image image={dealer?.hero_image}  />
                  </Box>
                </Box>
                
              )}
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
  );
}
