import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Icon,
  InputSwitch,
  RSelect
} from '../../../components/Component';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap';
import PriceInput from '../../verticals/components/PriceInput';
import DatePicker from 'react-datepicker';
import FormDateTime from '../../../components/form/FormDateTime';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addAuction } from '../../../redux/features/auctionSlice/auctionDetailsSlice';
import { toast } from 'react-toastify';
import { Emirates, TransportTypes } from '../../../utils/Utils';
import { fetchVerticalDetails } from '../../../redux/features/verticalsSlice/verticalDetailsSlice';
import { useSelector } from 'react-redux';
import {
  formatDuration,
  parseDurationString
} from '../../../utils/formatDuration';

export default function AddAuctionModal({
  open,
  toggleModal,
  baseform_id,
  vertical_id,
  isConciergeBooking,
  itemUserId
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [startingPrice, setStartingPrice] = useState(null);
  const [reservePrice, setReservePrice] = useState(null);
  const [duration, setDuration] = useState(null);
  const initialStartTime = new Date();
  initialStartTime.setMinutes(initialStartTime.getMinutes() + 10);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [emirate, setEmirate] = useState({ label: 'Dubai', value: 'Dubai' });

  const [transport, setTransport] = useState(null);

  const minAllowedTime = new Date();
  minAllowedTime.setMinutes(minAllowedTime.getMinutes() + 10);

  const [isConcierge, setIsConcierge] = useState(null);

  const handleDateChange = (date) => {
    setStartTime(date);
  };

  const methods = useForm({
    defaultValues: {
      duration: '',
      startTime: Date.now(),
      initialPrice: ''
    }
  });
  useEffect(() => {
    dispatch(fetchVerticalDetails(vertical_id));
  }, [vertical_id]);
  const selectedVertical = useSelector((state) => state.verticalDetails?.data);

  useEffect(() => {
    dispatch(fetchVerticalDetails(vertical_id));
  }, [vertical_id]);

  const formattedDurations =
    selectedVertical?.verticalsettings_id?.duration.map(formatDuration);

  const addNewAuction = (e) => {
    e.preventDefault();
    let time = new Date(startTime);
    const minAllowedTime = new Date();
    minAllowedTime.setMinutes(minAllowedTime.getMinutes() + 9);

    if (time < minAllowedTime) {
      toast.error(
        'Please select a time that is at least 10 minutes in the future.'
      );
      return;
    }

    let data = {
      initial_price: Number(startingPrice),
      reserve_price: Number(reservePrice),
      duration: parseDurationString(duration),
      type: isConcierge ? 'CONCIERGE' : 'PUBLIC',
      start_time: time.getTime(),
      baseform_id: baseform_id,
      vertical_id: vertical_id,
      emirate: emirate?.label,
      transport: transport?.label,
      user_id: isConciergeBooking ? undefined : itemUserId
    };

    dispatch(addAuction(data));
  };

  return (
    <Modal size="md" isOpen={open} toggle={toggleModal} backdrop="static">
      <ModalHeader toggle={toggleModal}>
        Add New Auction
        <button className="close" onClick={toggleModal}></button>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={(e) => addNewAuction(e)}>
          <Col sm="12">
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="first-name">
                Reserve Price (Minimum AED{' '}
                {selectedVertical?.verticalsettings_id?.auctionStartingPrice})
              </label>
              <div className="form-control-wrap">
                <PriceInput
                  error={errors?.reservePrice}
                  value={reservePrice}
                  onChange={(e) => {
                    const newReservePrice = e.target.value;
                    if (isNaN(Number(e?.target?.value))) {
                      setErrors((prev) => ({
                        ...prev,
                        reservePrice: 'Must be a number'
                      }));
                      return;
                    }

                    if (
                      Number(
                        e?.target?.value <
                          selectedVertical?.verticalsettings_id
                            ?.auctionStartingPrice
                      )
                    ) {
                      setErrors((prev) => ({
                        ...prev,
                        reservePrice: `Must be greater than or equal to ${selectedVertical?.verticalsettings_id?.auctionStartingPrice}`
                      }));
                      // return;
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        reservePrice: null
                      }));
                    }
                    // Update reserve price
                    setReservePrice(newReservePrice);
                    // Calculate starting price as 75% of reserve price and round off decimal places
                    const calculatedStartingPrice = Math.round(
                      Number(newReservePrice) * 0.75
                    );
                    setStartingPrice(calculatedStartingPrice);
                  }}
                  label="AED"
                />
              </div>
            </div>
          </Col>
          <Col sm="12">
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="first-name">
                Starting Bid (75% of Reserve Price)
              </label>
              <div className="form-control-wrap">
                <PriceInput
                  error={errors?.startingPrice}
                  value={startingPrice}
                  disabled
                  // onChange={(e) => {
                  //   setStartingPrice(e.target.value);
                  //   setErrors((prev) => ({
                  //     ...prev,
                  //     startingPrice: null
                  //   }));
                  // }}
                  label="AED"
                />
              </div>
            </div>
          </Col>

          <Col sm="12">
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="first-name">
                Duration
              </label>
              <div className="form-control-wrap">
                <RSelect
                  options={formattedDurations?.map((e) => ({
                    label: e,
                    value: e
                  }))}
                  // value={{ label: field.value, value: field.value }}
                  onChange={(e) => setDuration(e.value)}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </Col>
          <Col sm="12">
            <label className="form-label" htmlFor="first-name">
              Start Time
            </label>
            {/* <FormDateTime label="Start Time" control={control}  name="startTime" /> */}
            <DatePicker
              className="form-control"
              minDate={new Date()}
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeInput
              selected={startTime}
              onChange={handleDateChange}
              style={{ width: '100%' }}
            />
          </Col>
          <Col sm="12">
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="first-name">
                Emirate
              </label>
              <div className="form-control-wrap">
                <RSelect
                  isMulti={false}
                  value={emirate}
                  onChange={(e) => setEmirate(e)}
                  options={Emirates.map((e) => ({ label: e, value: e }))}
                />
              </div>
            </div>
          </Col>
          <Col sm="12">
            <div className="form-group mb-2">
              <label className="form-label" htmlFor="transport">
                Transport
              </label>
              <div className="form-control-wrap">
                <RSelect
                  isMulti={false}
                  value={transport}
                  onChange={(e) => setTransport(e)}
                  options={TransportTypes.map((e) => ({
                    label: e,
                    value: e
                  }))}
                />
              </div>
            </div>
          </Col>
          {isConciergeBooking && (
            <Col sm="12">
              <div className="form-group mb-2">
                <div className="rows">
                  <div className="data-col">
                    <div className="data-value">
                      <label
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px'
                        }}
                      >
                        <span>All</span>
                        <InputSwitch
                          label=""
                          checked={isConcierge}
                          id="isAuctionPriceDynamic-"
                          onClick={(e) => {
                            setIsConcierge(true);
                          }}
                        />
                        <span>Ravo Pro Only</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}

          <Col sm="6">
            <Button type="submit" className="bg-primary text-white mt-2">
              {loading ? (
                <>
                  <Spinner size="sm" color="light" />
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </Col>
        </form>
      </ModalBody>
    </Modal>
  );
}
